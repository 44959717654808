import { FacilitySearchOptionsPicker } from './FacilitySearchOptionsPicker'
import { ProviderSearchOptionsPicker, SPECIALTY_TO_GENDER } from './ProviderSearchOptionsPicker'
import { Box, Typography, Grid, Chip, Collapse, Button, IconButton } from '@mui/material'
import { East, KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material'
import doctor_icon from '~/assets/images/doctor.svg'
import location_icon from '~/assets/images/location.svg'
import { logger } from '~/utils/logger'

import PropTypes from 'prop-types'
import { makeStyles } from 'tss-react/mui'
import { useState, useEffect, useRef } from 'react'
import type { FC } from 'react'
import { DistancePicker } from './DistancePicker'
import { SearchNotAvailable } from './SearchNotAvailable'
import {
  Specialty,
  SpecialtyGroup,
  LocationType,
  CPTCode,
  SearchAvailability,
} from '@fireflyhealth/core'
import { Person } from '@fireflyhealth/core'
import Loader from '~/components/Loader'
import InlineLoader from '~/components/InlineLoader'
import { apiClient } from '~/api/rest'
import { ProviderList } from './ProviderList'
import { useHistory } from 'react-router'
import { useLocation } from 'react-router-dom'
import qs from 'query-string'
import EditField from '~/components/Generic/EditField'
import {
  useReviewSteerageProviders,
  useSteerage,
  useSteerageProviders,
} from '~/api/SteerageService'
import PersonPreference from '../SteerageView/PersonPreference'
import { SteerageProviderStatus } from '../SteerageView/types'
import { useProviderSearch, sortingOrderForSuggestedPartners } from './utils'
import { ProviderDetailWithAdditionalData, ProviderListItem } from './ProviderListItem'
import { queueNotification } from '~/redux/actions/notifications'
import { useDispatch } from 'react-redux'
import {
  ProviderListItemReasonModal,
  ProviderListItemReasonModalHandle,
} from './ProviderListItemReasonModal'

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`providersearch-tabpanel-${index}`}
      aria-labelledby={`providersearch-tab-${index}`}
      {...other}
      style={{ width: '85vh' }}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
}

const useStyles = makeStyles()(theme => {
  return {
    searchTermChip: {
      color: theme.palette.secondary.main,
      fontWeight: 'bold',
      marginRight: '4px',
      marginBottom: '4px',
      backgroundColor: 'transparent',
    },
    searchTermFilledChip: {
      color: theme.palette.background.default,
      backgroundColor: theme.palette.primary.main,
      fontWeight: 'bold',
    },
    tiny: {
      fontSize: '1rem',
    },
    soft: {
      color: theme.palette.secondary.main,
    },
    selectedChip: {
      color: theme.palette.primary.main,
      backgroundColor: '#E5F3FD',
      '&:hover, &:focus': {
        backgroundColor: '#E5F3FD',
      },
    },
    unSelectedChip: {
      color: theme.palette.secondary.main,
      backgroundColor: 'transparent',
      '&:hover, &:focus': {
        backgroundColor: 'transparent',
      },
    },
  }
})

export type SpecialtySearchCategory = Pick<Specialty, 'id' | 'label'> & {
  type: 'Specialty'
  category: 'Specialty'
}
export type LocationTypeSearchCategory = Pick<LocationType, 'id' | 'label'> & {
  type: 'Facility'
  category: 'Facility'
}
export type SpecialtyGroupSearchCategory = Pick<SpecialtyGroup, 'id' | 'label'> & {
  type: 'SpecialtyGroup'
  category: 'Specialty'
}
export type ReferralSearchCategories =
  | SpecialtySearchCategory
  | LocationTypeSearchCategory
  | SpecialtyGroupSearchCategory

export interface ProviderSearchProps {
  person: Person
}

const ProviderSearch: FC<ProviderSearchProps> = props => {
  const { classes } = useStyles()
  const dispatch = useDispatch()
  const maxZipcodeCharLimit = 5

  /**
   * isLoading is alrady checked on the parent component, but this is here
   * for an easier migration
   */
  const { data, isLoading } = useProviderSearch()

  /*
    Parse the search terms from the URL query params,
    and then use them to seed the inititial state of the component.
  */
  const location = useLocation()
  const path = location.pathname
  const urlParams = qs.parse(location.search)

  const specialtyGroupParam = urlParams['specialty_group']
    ? JSON.parse(urlParams['specialty_group'] as string)
    : null

  const locationTypeParam = urlParams['location_type']
    ? JSON.parse(urlParams['location_type'] as string)
    : null

  const zipCodeParam = urlParams['zipCode'] ? (urlParams['zipCode'] as string) : null
  const npiParam = urlParams['npi'] ? (urlParams['npi'] as string) : null
  const providerNameParam = urlParams['providerName'] ? (urlParams['providerName'] as string) : null
  const facilityNameParam = urlParams['facilityName'] ? (urlParams['facilityName'] as string) : null
  const procedureCodeParam = urlParams['procedureCode']
    ? JSON.parse(urlParams['procedureCode'] as string)
    : null

  const caseDescriptionParam = urlParams['caseDescription']
    ? (urlParams['caseDescription'] as string)
    : null

  const steerageIdParam = urlParams['steerage_id'] ? Number(urlParams['steerage_id']) : null

  const caseIdParam = urlParams['caseId'] ? Number(urlParams['caseId']) : null

  const { data: steerageProviderList = [], isLoading: steerageProvidersIsLoading } =
    useSteerageProviders(steerageIdParam)
  const { data: steerage, isLoading: steerageIsLoading } = useSteerage(steerageIdParam)

  const searchRadius = urlParams['distance'] ? Number(urlParams['distance']) : 10

  // User entered search parameters
  const [locationType, setLocationType] = useState<LocationTypeSearchCategory | null>(
    locationTypeParam
  )

  // Set the preferred gender based on the specialty if applicable
  let genderParam = urlParams['gender'] ? (urlParams['gender'] as string) : null
  let defaultSpecialtyGroup
  const specialty = data?.specialtyGroups.find(s => s.id === specialtyGroupParam)
  if (specialty) {
    defaultSpecialtyGroup = { ...specialty, type: 'SpecialtyGroup', category: 'Specialty' }
    genderParam = SPECIALTY_TO_GENDER[specialty.label] ?? genderParam
  }

  const [specialtyGroup, setSpecialtyGroup] = useState<SpecialtyGroupSearchCategory | null>(
    defaultSpecialtyGroup
  )

  const [zipCode, setZipCode] = useState<string | null | undefined>(
    zipCodeParam
      ? zipCodeParam
      : // return first five chars of a zipcode in cases where zipcode of member contains 9 digits
        props.person?.insuranceInfo?.addresses?.[0]?.zipcode?.substring(0, 5)
  )
  const [npi, setNPI] = useState<string | null>(npiParam)
  const [providerName, setProviderName] = useState<string | null>(providerNameParam)

  const [gender, setGender] = useState<string | null>(genderParam)
  const [facilityName, setFacilityName] = useState<string | null>(facilityNameParam)
  const [procedureCode, setProcedureCode] = useState<CPTCode | null>(procedureCodeParam)
  const [isSearchEnabled, setIsSearchEnabled] = useState<boolean>(false)
  const [isUserEditingZipCode, setIsUserEditingZipCode] = useState<boolean>(false)
  const [caseDescription] = useState<string | null>(caseDescriptionParam)
  const [steerageId] = useState<number | null>(steerageIdParam)

  const [isProviderSearch, setIsProviderSearch] = useState<boolean>(true)
  const refreshIsSearchAvailable = async () => {
    setIsSearchAvailableLoading(true)
    const searchAvailability = await apiClient.referral.createSearchAvailability(
      String(props.person?.id),
      isProviderSearch,
      !isProviderSearch,
      zipCode
    )
    setIsSearchAvailableLoading(false)
    setSearchAvailability(searchAvailability)
    setIsUserEditingZipCode(false)
  }
  const [searchAvailablility, setSearchAvailability] = useState<SearchAvailability>()
  const [isSearchAvailableLoading, setIsSearchAvailableLoading] = useState<boolean>(false)

  useEffect(() => {
    refreshIsSearchAvailable()
    // Verify that search is available when the user toggles between facility/provider search
    // For the internal directory it is possible that providers have been loaded for a zip but
    // care orgs have not been loaded completely
  }, [isProviderSearch])

  const [collapse, setCollapse] = useState<boolean>(false)

  const [distance, setDistance] = useState<number>(searchRadius)
  const [resultCount, setResultCount] = useState<number>(0)
  const [hasSearchTerms, setHasSearchTerms] = useState<boolean>(locationType != null)
  const [loadingResults, setLoadingResults] = useState<boolean>(false)

  const history = useHistory()

  const setSearchTerms = (
    locationType: LocationTypeSearchCategory | null,
    specialtyGroup: SpecialtyGroupSearchCategory | null,
    npi: string | null,
    providerName: string | null,
    gender: string | null,
    facilityName: string | null,
    procedureCode: CPTCode | null
  ) => {
    if (locationType != null || facilityName != null) {
      setIsProviderSearch(false)
    } else if (
      specialtyGroup != null ||
      npi != null ||
      providerName != null ||
      procedureCode != null
    ) {
      setIsProviderSearch(true)
    }
    setIsSearchEnabled(
      locationType != null ||
        specialtyGroup != null ||
        npi != null ||
        providerName != null ||
        facilityName != null ||
        procedureCode != null
    )
    setNPI(npi)
    setSpecialtyGroup(specialtyGroup)
    setLocationType(locationType)
    setProviderName(providerName)
    setGender(gender)
    setFacilityName(facilityName)
    setProcedureCode(procedureCode)
  }

  let genderLabel: string | null = null
  if (gender === 'M') genderLabel = 'Male Providers'
  if (gender === 'F') genderLabel = 'Female Providers'

  const setSearchRadius = (miles: number) => {
    const queryParams = {
      ...urlParams,
      distance: miles,
    }
    history.replace(`${path}?${qs.stringify(queryParams)}`)

    setDistance(miles)
  }

  const setZip = event => {
    // Set a flag that disables submission of the form
    // till we check whether search is possible for this
    // zipcode
    setIsUserEditingZipCode(true)
    const zip = event.target?.value
    const queryParams = {
      ...urlParams,
      zipCode: zip,
    }
    history.replace(`${path}?${qs.stringify(queryParams)}`)

    setZipCode(zip)
  }

  const returnToCase = () => {
    // Link back to the cases
    // patients/<user_id>/todos?caseId=<case_id>.
    const basepath = path.substring(0, path.lastIndexOf('/'))

    history.replace(`${basepath}/todos?caseId=${caseIdParam}`)
  }

  const DoctorIcon = <img src={doctor_icon} alt="Doctor" />
  const LocationIcon = <img src={location_icon} alt="Location" />

  let filteredSteerageProviders =
    steerageProviderList?.filter(p => {
      return p.isSystemSuggested && p.status === SteerageProviderStatus.UnderReview
    }) ?? []
  const showSystemSuggested = filteredSteerageProviders.length > 0
  filteredSteerageProviders = showSystemSuggested ? filteredSteerageProviders : steerageProviderList

  let providersFromResults: ProviderDetailWithAdditionalData[] = []
  let searchResultsByProviderId: Record<number, ProviderDetailWithAdditionalData> = {}
  filteredSteerageProviders.forEach(p => {
    if (p.searchResult) {
      providersFromResults.push(p.searchResult)
      searchResultsByProviderId[p.id] = p.searchResult
    }
  })

  // sort the filteredSteerageProviders
  filteredSteerageProviders.slice().sort((a, b) => {
    const orderA = sortingOrderForSuggestedPartners(a)
    const orderB = sortingOrderForSuggestedPartners(b)

    return orderA - orderB
  })

  const { mutateAsync: handleReviewSuggestions, isLoading: reviewSuggestionLoading } =
    useReviewSteerageProviders(steerageId!, {
      onError: () => {
        dispatch(
          queueNotification({
            variant: 'error',
            message: 'Failed to review the suggestions',
          })
        )
      },
    })

  const disableDismissAllButton =
    reviewSuggestionLoading || steerageIsLoading || steerageProvidersIsLoading
  const providerReasonRef = useRef<ProviderListItemReasonModalHandle>(null)

  const handleRejectSuggestions = async () => {
    // Get exception reason from the modal if the provider is unknown or not recommended
    let exception: string | null = null
    exception = (await providerReasonRef.current?.chooseReason()) ?? null
    if (exception === null) return
    const ids = filteredSteerageProviders?.map(p => p.id) ?? []
    handleReviewSuggestions({
      providers: ids,
      approve: false,
      reasonForRejection: exception,
    })
  }

  if (isLoading) return <Loader />

  return (
    <Box display="flex" flexDirection="column" height="100%">
      <ProviderListItemReasonModal
        ref={providerReasonRef}
        providers={providersFromResults}
        isProviderSearch={true}
        isSystemSuggested
      />
      {hasSearchTerms || showSystemSuggested ? (
        <Box display="flex" flexDirection="row" alignSelf="flex-end" mr={2} mb={-3}>
          <IconButton
            edge="end"
            onClick={() => {
              setCollapse(c => !c)
              logger.info('Provider Search: Header collapsed')
            }}
          >
            {collapse ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        </Box>
      ) : null}
      <Collapse in={!collapse}>
        <Box mx={3} mt={1} mb={1}>
          <PersonPreference personId={props.person?.id} showTitle={true} />
        </Box>
        {showSystemSuggested ? (
          <Typography variant="body1" style={{ fontWeight: 500 }} ml={3}>
            Top Recommendations&nbsp;
            {specialtyGroup?.label ? (
              <Typography variant="body1" component="span">
                for {specialtyGroup.label}
              </Typography>
            ) : null}
          </Typography>
        ) : (
          <Typography variant="body1" style={{ fontWeight: 500 }} ml={3}>
            Provider Search
          </Typography>
        )}
        {loadingResults || isSearchAvailableLoading ? <Loader /> : null}
        <Box mx={3}>
          <Grid container spacing={2} alignContent="space-between" alignItems="baseline">
            {showSystemSuggested ? (
              <Grid item xs={8} />
            ) : (
              <Grid item xs={8}>
                {hasSearchTerms ? (
                  <Box>
                    Showing results for:&nbsp;
                    <Button
                      onClick={() => {
                        setHasSearchTerms(false)
                      }}
                      size="small"
                      disabled={isLoading || loadingResults || isSearchAvailableLoading}
                      color="primary"
                      variant="text"
                    >
                      Edit Search
                    </Button>
                  </Box>
                ) : (
                  <Box mb={1}>Search for:</Box>
                )}
              </Grid>
            )}
            {caseDescription && (
              <Grid item xs={4}>
                <Box alignItems="baseline" mt={1}>
                  Navigation for:
                  <Button
                    disabled={
                      isLoading || loadingResults || isSearchAvailableLoading ? true : false
                    }
                    size="small"
                    color="primary"
                    onClick={returnToCase}
                    title="View Case"
                  >
                    &nbsp; View Case
                  </Button>
                </Box>
              </Grid>
            )}
          </Grid>
        </Box>
        <Box mx={3}>
          {showSystemSuggested || hasSearchTerms ? (
            <Grid container spacing={2} alignContent={'space-between'} alignItems="baseline">
              <Grid item xs={8}>
                <Grid container>
                  <Grid item>
                    <Chip
                      variant="outlined"
                      size="small"
                      icon={
                        isProviderSearch ? (
                          <img src={doctor_icon} alt="Doctor" style={{ marginLeft: '1rem' }} />
                        ) : (
                          <img src={location_icon} alt="Location" style={{ marginLeft: '1rem' }} />
                        )
                      }
                      label={isProviderSearch ? 'Doctors' : 'Locations'}
                      className={classes.searchTermChip}
                    />
                  </Grid>

                  {locationType != null && (
                    <Grid item>
                      <Chip
                        size="small"
                        variant="outlined"
                        label={locationType.label}
                        className={classes.searchTermChip}
                      />
                    </Grid>
                  )}
                  {specialtyGroup != null && (
                    <Grid item>
                      <Chip
                        size="small"
                        variant="outlined"
                        label={specialtyGroup.label}
                        className={classes.searchTermChip}
                      />
                    </Grid>
                  )}
                  {gender != null && (
                    <Grid item>
                      <Chip
                        size="small"
                        variant="outlined"
                        label={genderLabel}
                        className={classes.searchTermChip}
                      />
                    </Grid>
                  )}
                  {zipCode && (
                    <Grid item>
                      <Chip
                        size="small"
                        variant="outlined"
                        label={zipCode}
                        className={classes.searchTermChip}
                      />
                    </Grid>
                  )}
                  {npi && (
                    <Grid item>
                      <Chip
                        size="small"
                        variant="outlined"
                        label={npi}
                        className={classes.searchTermChip}
                      />
                    </Grid>
                  )}
                  {providerName && (
                    <Grid item>
                      <Chip
                        size="small"
                        variant="outlined"
                        label={providerName}
                        className={classes.searchTermChip}
                      />
                    </Grid>
                  )}
                  {facilityName && (
                    <Grid item>
                      <Chip
                        size="small"
                        variant="outlined"
                        label={facilityName}
                        className={classes.searchTermChip}
                      />
                    </Grid>
                  )}
                  {procedureCode && (
                    <Grid item>
                      <Chip
                        size="small"
                        variant="outlined"
                        label={'CPT: ' + procedureCode.code}
                        className={classes.searchTermChip}
                      />
                    </Grid>
                  )}
                </Grid>
              </Grid>
              {caseDescription && (
                <Grid item xs={4}>
                  <Chip
                    size="small"
                    label={caseDescription}
                    variant="outlined"
                    color="secondary"
                    className={classes.searchTermChip}
                  />
                </Grid>
              )}
            </Grid>
          ) : (
            <Box>
              <Box my={1}>
                <Grid container alignContent={'space-between'} alignItems="baseline">
                  <Grid item xs={8}>
                    <Box mr={1}>
                      <Chip
                        size="small"
                        label={'Doctors'}
                        icon={DoctorIcon}
                        className={isProviderSearch ? classes.selectedChip : classes.unSelectedChip}
                        variant={isProviderSearch ? 'filled' : 'outlined'}
                        onClick={() => {
                          setIsProviderSearch(true)
                          // reset the search radius to 10miles
                          setSearchRadius(10)
                        }}
                      />
                      &nbsp;
                      <Chip
                        size="small"
                        label={'Locations'}
                        className={
                          !isProviderSearch ? classes.selectedChip : classes.unSelectedChip
                        }
                        icon={LocationIcon}
                        variant={!isProviderSearch ? 'filled' : 'outlined'}
                        onClick={() => {
                          setIsProviderSearch(false)
                          // reset the search radius to 10miles
                          setSearchRadius(10)
                        }}
                      />
                    </Box>
                  </Grid>
                  {caseDescription && (
                    <Grid item xs={4}>
                      <Box ml={1}>
                        <Chip
                          size="small"
                          label={caseDescription}
                          variant="outlined"
                          color="secondary"
                          className={classes.searchTermChip}
                        />
                      </Box>
                    </Grid>
                  )}
                </Grid>
              </Box>
              <Box display="flex">
                <Box>
                  <Box display="flex" flexDirection="column" pt={5} style={{ gap: 20 }}>
                    {isProviderSearch && (
                      <ProviderSearchOptionsPicker
                        cptCodes={data?.cptCodes!}
                        specialtyGroups={data?.specialtyGroups!}
                        onSelect={setSearchTerms}
                        disabled={!searchAvailablility?.isAvailable}
                        loading={isLoading || loadingResults || isSearchAvailableLoading}
                        selectedSpecialtyGroup={specialtyGroup}
                        selectedNPI={npi}
                        selectedGender={gender}
                        selectedProviderName={providerName}
                        selectedProcedureCode={procedureCode}
                        setSearchRadius={setSearchRadius}
                      />
                    )}
                    {!isProviderSearch && (
                      <FacilitySearchOptionsPicker
                        locationTypes={data?.locationTypes!}
                        onSelect={setSearchTerms}
                        disabled={!searchAvailablility?.isAvailable}
                        loading={isLoading || loadingResults || isSearchAvailableLoading}
                        selectedLocationType={locationType}
                        selectedFacilityName={facilityName}
                        setSearchRadius={setSearchRadius}
                      />
                    )}
                    <Box>
                      <EditField
                        InputLabelProps={{ shrink: true }}
                        label="Zipcode"
                        type="text"
                        name="zip"
                        value={zipCode}
                        onChange={setZip}
                        // Verify that data is available for the zipcode
                        // only when the user finishes typing and moves out of the
                        // zipcode input
                        onBlur={refreshIsSearchAvailable}
                        disabled={
                          isLoading ||
                          loadingResults ||
                          isSearchAvailableLoading ||
                          !searchAvailablility?.isAvailable
                        }
                        inputProps={{ maxLength: maxZipcodeCharLimit }}
                      />
                    </Box>
                  </Box>
                  <Box pt={2} display="flex" justifyContent="flex-start">
                    <Button
                      onClick={() => {
                        const queryParams = {
                          ...urlParams,
                          location_type: JSON.stringify(locationType),
                          specialty_group: JSON.stringify(specialtyGroup),
                          npi: npi,
                          providerName: providerName,
                          facilityName: facilityName,
                        }
                        history.replace(`${path}?${qs.stringify(queryParams)}`)
                        setHasSearchTerms(
                          locationType != null ||
                            specialtyGroup != null ||
                            zipCode != null ||
                            npi != null
                        )
                      }}
                      disabled={
                        isLoading ||
                        loadingResults ||
                        isSearchAvailableLoading ||
                        !searchAvailablility?.isAvailable ||
                        !isSearchEnabled ||
                        isUserEditingZipCode
                      }
                      color="primary"
                      variant="contained"
                    >
                      Search
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Box>
          )}
          {!(isLoading || loadingResults || isSearchAvailableLoading) &&
            searchAvailablility != null &&
            !searchAvailablility.isAvailable && (
              <Box pt={2}>
                <SearchNotAvailable searchAvailability={searchAvailablility} />{' '}
              </Box>
            )}
        </Box>
        <Box mx={3} mt={1}>
          {!showSystemSuggested && hasSearchTerms && distance && (
            <Grid container alignItems="center" justifyContent="flex-end">
              <Grid item>
                <Box display="flex" justifyContent="flex-end">
                  <Typography className={classes.soft}>
                    {isLoading || loadingResults || isSearchAvailableLoading ? (
                      <InlineLoader />
                    ) : (
                      resultCount
                    )}{' '}
                    Results &nbsp;
                  </Typography>
                </Box>
              </Grid>
              <Grid item>
                <Box display="flex" justifyContent="flex-end">
                  <Box>
                    <DistancePicker distance={distance} callback={setSearchRadius} />
                  </Box>
                </Box>
              </Grid>
            </Grid>
          )}
        </Box>
      </Collapse>
      {showSystemSuggested ? (
        <Box sx={{ flex: 1, overflow: 'auto' }}>
          {filteredSteerageProviders?.map(p => (
            <ProviderListItem
              searchRequestId={null}
              isSystemSuggested
              provider={searchResultsByProviderId[p.id]}
              steerageProvider={p}
              steerageID={steerage?.id}
              locationType=""
              specialtyGroupSearchCategory={specialtyGroup}
              isProviderSearch={isProviderSearch}
              indexInResults={0}
              key={p.id}
            />
          ))}
          <Box sx={{ display: 'flex', justifyContent: 'center', my: 2 }}>
            <Button
              variant="outlined"
              endIcon={<East />}
              onClick={handleRejectSuggestions}
              disabled={disableDismissAllButton}
            >
              Dismiss all suggestion(s) and start a new search
            </Button>
          </Box>
        </Box>
      ) : null}
      {!showSystemSuggested && hasSearchTerms && distance && (
        <ProviderList
          person={props.person}
          locationType={locationType}
          specialtyGroup={specialtyGroup}
          distance={distance}
          zipCode={zipCode}
          npi={npi}
          providerName={providerName}
          gender={gender}
          facilityName={facilityName}
          procedureCode={procedureCode}
          resultCountCallback={setResultCount}
          loadingResultsCallback={setLoadingResults}
          steerageProviderList={steerageProviderList}
          states={data?.states}
          steerageId={steerageId}
          isProviderSearch={isProviderSearch}
          searchAvailabilityId={searchAvailablility?.id}
        />
      )}
    </Box>
  )
}

export default ProviderSearch
