import { useQuery } from '~/components/Providers/ApiProvider'

import { LimitOffsetPagination as CoreLimitOffsetPagination } from '@fireflyhealth/core'
import { InboxWorkUnit } from '~/models/InboxWorkUnit'
import { apiClient } from '~/api/rest'
import { formatRFC3339DateTime } from '~/utils/date'

interface LimitOffsetPaginationWithAggregates<T, U> extends CoreLimitOffsetPagination<T> {
  next: string | null
  previous: string | null
  aggregates: U
}

interface Params {
  ownerIds?: number[]
  ownerGroupIds?: number[]
  podIds?: number[]
  dueDateRange?: {
    start?: Date
    end?: Date
  }
  carePlanAutocreatedFromIds?: number[]
  caseCategoryIds?: number[]
  limit: number
  offset: number
}

const getInbox = ({
  ownerIds,
  ownerGroupIds,
  podIds,
  dueDateRange,
  carePlanAutocreatedFromIds,
  caseCategoryIds,
  limit,
  offset,
}: Params) => {
  const contentTypes = ['careplan', 'case']
  const contentTypeCounts = {
    careplan: carePlanAutocreatedFromIds?.length,
    case: caseCategoryIds?.length,
  }
  // Create a list of content type with results
  const contentTypeFilterList = contentTypes.filter(
    contentType => contentTypeCounts[contentType] > 0
  )
  return apiClient.rest.get<
    LimitOffsetPaginationWithAggregates<
      InboxWorkUnit,
      {
        careplanAutocreatedFrom: {
          autocreatedFromId: number | null
          autocreatedFromTitle: string | null
          count: number
        }[]
        caseCategory: { categoryId: number; categoryTitle: string; count: number }[]
      }
    >
  >('/providers/me/work_units/inbox/', {
    sortBy: 'priority',
    owner_id__in: ownerIds?.length ? ownerIds.join(',') : undefined,
    owner_group_id__in: ownerGroupIds?.length ? ownerGroupIds.join(',') : undefined,
    pod_id__in: podIds?.length ? podIds.join(',') : undefined,
    due_date__gte: dueDateRange?.start ? formatRFC3339DateTime(dueDateRange.start) : undefined,
    due_date__lte: dueDateRange?.end ? formatRFC3339DateTime(dueDateRange.end) : undefined,
    content_type__in: contentTypeFilterList?.length ? contentTypeFilterList.join(',') : undefined,
    careplan__autocreated_from_id__in: carePlanAutocreatedFromIds?.length
      ? carePlanAutocreatedFromIds.join(',')
      : undefined,
    case__category_id__in: caseCategoryIds?.length ? caseCategoryIds.join(',') : undefined,
    limit,
    offset,
  })
}

export const getInboxKey = 'getInbox'
export const useInbox = (params: Params) => {
  const { data, isLoading } = useQuery(
    [getInboxKey, params],
    () => getInbox(params),
    {},
    {
      error: 'Failed to fetch inbox',
    }
  )

  return {
    data,
    isLoading,
  }
}
