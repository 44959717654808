import { Box, Chip, Typography } from '@mui/material'
import { parse } from 'date-fns'
import { groupBy, partition } from 'lodash'
import { FC, Fragment } from 'react'
import { AvailablePhysicianSlot } from '~/api/AppointmentService'

const NoAvailability = () => <p>No availability</p>

type PhysicianSlotPickerGroupProps = {
  slots: AvailablePhysicianSlot[]
  value: AvailablePhysicianSlot | null
  onChange: (slot: AvailablePhysicianSlot) => void
}

const physicianDisplayName = (slot: AvailablePhysicianSlot) =>
  `${slot.physicianFirstName} ${slot.physicianLastName}, ${slot.physicianTitle}`

const PhysicianSlotPickerGroup: FC<PhysicianSlotPickerGroupProps> = ({
  slots,
  value,
  onChange,
}) => {
  const slotsByPhysician = groupBy(slots, physicianDisplayName)
  const sorted = Object.keys(slotsByPhysician).sort() // alphabetical order of physicians

  return (
    <>
      {sorted.map(displayName => {
        const physicianSlots = slotsByPhysician[displayName]
        const slotsEl = physicianSlots.length ? (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
              marginBottom: 2,
              marginTop: 1,
              flexWrap: 'wrap',
              rowGap: 1,
            }}
          >
            <PhysicianSlotPicker slots={physicianSlots} value={value} onChange={onChange} />
          </Box>
        ) : (
          <NoAvailability />
        )
        return (
          <Fragment key={displayName}>
            <Typography sx={{ fontSize: '1.4rem', marginTop: 2, fontWeight: 500 }}>
              {displayName}
            </Typography>
            {slotsEl}
          </Fragment>
        )
      })}
    </>
  )
}

type PhysicianSlotPickerProps = {
  slots: AvailablePhysicianSlot[]
  value: AvailablePhysicianSlot | null
  onChange: (slot: AvailablePhysicianSlot) => void
}

const PhysicianSlotPicker: FC<PhysicianSlotPickerProps> = ({ slots, value, onChange }) => {
  return (
    <>
      {slots.map(slot => {
        const time = parse(slot.scheduledDate).toLocaleTimeString(undefined, {
          hour12: true,
          hour: '2-digit',
          minute: '2-digit',
        })

        return (
          <Fragment key={slot.id}>
            <Chip
              label={time}
              onClick={() => onChange(slot)}
              color={value === slot ? 'primary' : 'default'}
              variant="outlined"
              sx={{
                padding: 0,
                marginRight: 1,
                '&.MuiButtonBase-root.MuiChip-colorPrimary': {
                  backgroundColor: '#071F3E',
                  color: 'white',
                  borderColor: '#071F3E',
                },
                '&.MuiButtonBase-root.MuiChip-colorDefault': {
                  backgroundColor: 'transparent',
                },
              }}
            />
          </Fragment>
        )
      })}
    </>
  )
}

type PhysicianSlotPickerGroupsProps = {
  slots: AvailablePhysicianSlot[]
  value: AvailablePhysicianSlot | null
  onChange: (slot: AvailablePhysicianSlot) => void
}

const PhysicianSlotPickerGroups: FC<PhysicianSlotPickerGroupsProps> = props => {
  const slots = props.slots
  const [careTeamSlots, othersSlots] = partition(slots, slot => slot.inCareTeam)

  const careTeamEl = careTeamSlots.length ? (
    <Box
      sx={{
        marginTop: 2,
      }}
    >
      <Typography
        sx={{
          fontSize: '1.3rem',
          color: '#4D5358',
        }}
      >
        {"Patient's care team:"}
      </Typography>
      <PhysicianSlotPickerGroup {...props} slots={careTeamSlots} />
    </Box>
  ) : null

  const othersEl = othersSlots.length ? (
    <Box
      sx={{
        marginTop: 4,
      }}
    >
      <Typography
        sx={{
          fontSize: '1.3rem',
          color: '#4D5358',
        }}
      >
        {"Other providers licensed in patient's state:"}
      </Typography>
      <PhysicianSlotPickerGroup {...props} slots={othersSlots} />
    </Box>
  ) : null

  return (
    <>
      {slots.length == 0 ? (
        <Typography sx={{ marginTop: 1 }}>No clinician available</Typography>
      ) : (
        <Box>
          {careTeamEl}
          {othersEl}
        </Box>
      )}
    </>
  )
}

export default PhysicianSlotPickerGroups
