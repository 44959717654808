import { Box, Button, Paper, Typography } from '@mui/material'

import {
  CASE_TASK_FIELDS,
  CASE_TASK_FIELDS_USER_GROUPS,
  ICase,
  ICaseCategory,
  ICaseRelation,
} from '~/models/Case'
import { isEqual, pick } from 'lodash'
import {
  useCaseCategories,
  useTaskCollectionPreviewMutation,
  useUpdateCase,
} from '~/api/CaseService'
import { useSelector } from 'react-redux'
import { useEffect, useState } from 'react'

import { ITask } from '~/models/Task'
import Loader from '~/components/Loader'
import Moment from 'moment'
import { PatientUser } from '~/types'
import { format } from 'date-fns'
import CaseCategorySelect from './CaseCategorySelect'

export interface Props {
  patient: PatientUser
  handleClose: Function
  case?: ICase
  onAfterSave?: (c: ICase) => void
}

export const CaseForm = (props: Props) => {
  const { patient, handleClose, onAfterSave } = props
  const me = useSelector<any, any>(state => state.me)

  const { result: categories, isLoading: isListingCaseCategories } = useCaseCategories()

  const { isLoading: isUpdatingPatientCase, mutateAsync: handleUpdatePatientCase } = useUpdateCase()
  const taskCollectionPreviewMutation = useTaskCollectionPreviewMutation()

  const [category, setCategory] = useState<ICaseCategory | null>(
    categories.find(c => c.id === props.case?.category) || null
  )
  const [description, setDescription] = useState<string>(props.case?.description || '')
  const [notes, setNotes] = useState<string>(props.case?.notes || '')

  const isNew: boolean = props.case?.id === undefined

  // If its a new case, then set the due date as today.
  // If its a old case, then format the dueDate only if its not null.
  // dueDate is store as data time with timezone, so format and retrive only date.
  const caseDueDate = props.case?.dueDate ? Moment(props.case?.dueDate).format('YYYY-MM-DD') : ''
  const dueDate = isNew ? format(new Date(), 'YYYY-MM-DD') : caseDueDate
  const ownerGroup = props.case?.ownerGroup || me.assigneeGroup?.id

  const [tasks, setTasks] = useState<ITask[]>(
    // TODO: Update assumption when Cases can have multiple Tasks.
    props.case?.tasks
      ? (props.case?.tasks.map(t => pick(t, ...CASE_TASK_FIELDS_USER_GROUPS)) as ITask[]) || [
          {
            title: '',
            dueDate: format(new Date(), 'YYYY-MM-DD'),
            ownerGroup: me.assigneeGroup?.id || undefined,
            priority: 0,
            isComplete: false,
          },
        ]
      : []
  )

  const tasksHaveBeenEdited = (): boolean => {
    // Hacky way to tell if the user has been working on tasks and should be left alone
    // or if they could be safely overwritten by task templates
    return tasks.length != 0 && (tasks.length > 1 || !!tasks[0].title)
  }

  useEffect(() => {
    if (!props.case?.description) {
      setDescription(category?.description || '')
    }
    if (!props.case?.notes) {
      setNotes(category?.notes || '')
    }
    // Load any Tasks templates linked to the Case Category
    if (category?.taskCollectionId) {
      taskCollectionPreviewMutation.mutateAsync({
        caseCategoryId: category.id,
        userId: patient.id,
        me: me,
        meAssigneeGroup: me.assigneeGroup,
      })
    }
  }, [category])

  useEffect(() => {
    // Replace the current Tasks with any templated Tasks linked to the Case Category
    if (!taskCollectionPreviewMutation.data || taskCollectionPreviewMutation.isLoading) {
      return
    }
    // Don't overwrite tasks that the user has been working on
    if (tasksHaveBeenEdited() || !taskCollectionPreviewMutation.data.taskPreviews) {
      return
    }
    // To modify the array and ensure that sub-components know to re-render
    // overwrite existing tasks first...
    const updatedTasks = [...tasks]
    let indexOfLastOverwrittenTask = 0
    for (const taskPreview of taskCollectionPreviewMutation.data.taskPreviews) {
      if (updatedTasks.length <= indexOfLastOverwrittenTask) {
        break
      }
      Object.assign(updatedTasks[indexOfLastOverwrittenTask], taskPreview)
      indexOfLastOverwrittenTask += 1
    }
    // ...then, for any Task templates that we didn't use to overwrite existing tasks
    // we can safely append them to the list
    const newTasks = taskCollectionPreviewMutation.data.taskPreviews.slice(
      indexOfLastOverwrittenTask
    )
    setTasks([...updatedTasks, ...newTasks])
  }, [taskCollectionPreviewMutation.data, taskCollectionPreviewMutation.isLoading])

  const hasAllTasksTitles = () => {
    if (!tasks || (tasks && tasks.length == 0)) return true
    else {
      for (let i = 0; i < tasks.length; i++) {
        if (tasks[i].title == '') return false
      }
      return true
    }
  }
  const isTaskChanged: boolean = !isEqual(
    props.case?.tasks.map(t => pick(t, ...CASE_TASK_FIELDS)),
    tasks
  )

  const isCategoryChanged: boolean = (category && props.case?.category !== category.id) || false
  const isDescriptionChanged: boolean = props.case?.description !== description
  const isNotesChanged: boolean = props.case?.notes !== notes
  const isDueDateChanged: boolean =
    (dueDate && Moment(props.case?.dueDate).format('YYYY-MM-DD') !== dueDate) || false
  const isOwnerGroupChanged: boolean =
    (ownerGroup && props.case?.ownerGroup !== ownerGroup) || false
  const hasUnsavedChanges: boolean =
    isTaskChanged ||
    isCategoryChanged ||
    isDescriptionChanged ||
    isNotesChanged ||
    isDueDateChanged ||
    isOwnerGroupChanged

  const isLoading: boolean = isListingCaseCategories || isUpdatingPatientCase
  const hasDueDate: boolean = dueDate ? true : false
  const hasOwnerGroup: boolean = ownerGroup ? true : false
  const hasCategory: boolean = category ? true : false
  // Has description if it is a new case (old cases or machine-generated cases will not)
  const hasDescription: boolean = isNew ? !!description : true
  const hasTaskTitles: boolean = hasAllTasksTitles()

  // The same editor is used even for care plan and it doesn't have owner and due date. So skipping this check.
  const hasRequiredFields: boolean =
    hasCategory && hasDueDate && hasOwnerGroup && hasDescription && hasTaskTitles

  const handleUpdate = async () => {
    if (props.case) {
      let relations: ICaseRelation[] = []
      relations = [...props.case.relations]
      const updatedCase = await handleUpdatePatientCase({
        id: props.case.id,
        user: patient.id,
        person: patient.person.id,
        category: category ? category.id : props.case.category,
        tasks: tasks,
        relations: relations,
        description: description,
        notes: notes,
        dueDate: dueDate != '' ? dueDate : undefined,
        ownerGroup: ownerGroup ? ownerGroup : undefined,
      })
      onAfterSave?.(updatedCase)
    }

    handleClose()
  }

  return (
    <Box p={2}>
      <Box m={2}>
        <Typography variant="h6" mb={2}>
          {'Change Case'}
        </Typography>
        <CaseCategorySelect categories={categories} category={category} setCategory={setCategory} />
      </Box>
      <Box mt={4} display="flex" flex={1} justifyContent="flex-end">
        <Box ml={1} mr={2}>
          <Button disabled={isLoading} onClick={() => handleClose()}>
            Cancel
          </Button>
          <Button
            disabled={isLoading || !hasUnsavedChanges || !hasRequiredFields}
            color="primary"
            variant={
              isLoading || !hasUnsavedChanges || !hasRequiredFields ? 'outlined' : 'contained'
            }
            onClick={handleUpdate}
            sx={{
              '& p': {
                fontSize: '1.4rem',
              },
              '&.MuiButton-containedSizeMedium': {
                borderRadius: '24px',
              },
            }}
          >
            <Typography>{'Change Case'}</Typography>
            {isLoading && <Loader inline={true} />}
          </Button>
        </Box>
      </Box>
    </Box>
  )
}

const CaseEditor = (props: Props) => (
  <Paper style={{ maxHeight: '90vh', overflow: 'auto' }}>
    <CaseForm {...props} />
  </Paper>
)

export default CaseEditor
