import { FC } from 'react'
import { Person } from '@fireflyhealth/core'
import NavigationList from '~/components/PatientDetail/Navigation/NavigationList'

export interface NavigationProps {
  // Note that the Tabs correctly pass in a PERSON even though the attribute
  // is named for the vestigial PATIENT.
  patient: Person
}

const Navigation: FC<NavigationProps> = props => {
  return <NavigationList person={props?.patient} />
}

export default Navigation
