import { useState, useRef } from 'react'
import {
  AttachMoneyRounded as AttachMoneyRoundedIcon,
  AutoAwesome,
  Bookmark,
  BookmarkBorder,
  Check,
  CalendarToday as CalendarTodayIcon,
  MoreVert as MoreVertIcon,
  NotInterested as NotInterestedIcon,
  PermContactCalendar,
  Place as PlaceIcon,
  Roofing as RoofingIcon,
  Inventory as InventoryIcon,
  PhonelinkRing as PhonelinkRingIcon,
  RemoveCircleOutline as RemoveCircleOutlineIcon,
  SignalCellular1BarOutlined as SignalCellular1BarOutlinedIcon,
  SignalCellular4BarOutlined as SignalCellular4BarOutlinedIcon,
  ThumbDown as ThumbDownIcon,
  ThumbUp as ThumbUpIcon,
  VerifiedUser as VerifiedUserIcon,
} from '@mui/icons-material'
import { useQueryClient } from 'react-query'
import {
  Alert,
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Modal,
  Rating,
  styled,
  Typography,
  colors,
  Collapse,
} from '@mui/material'
import type { FC } from 'react'
import { makeStyles } from 'tss-react/mui'
import { ProviderDetail, ProviderLocation, SpecialtyGroup } from '@fireflyhealth/core'
import { format, parse } from 'date-fns'
import { useDispatch } from 'react-redux'

import { formatPhoneNumber } from '~/utils'
import {
  Partnership,
  SteerageProvider,
  SteerageProviderDataSource,
  SteerageProviderStatus,
} from '~/components/PatientDetail/SteerageView/types'
import {
  listSteerageSteerageProvidersKey,
  useAddProviderToSteerage,
  useDeleteProviderFromSteerage,
  getNetworkPartnerDisplayName,
  listSteerageKey,
  useReviewSteerageProviders,
} from '~/api/SteerageService'
import { RecommendationStatus, SteerageProviderWaiverLevel } from '../SteerageView/types'
import UpdateProviderDetailsModal from './UpdateProviderDetailsModal'
import {
  DataCurationUsagePayload,
  EditProviderAndAvailabilityPayload,
  logEvent,
  SteerageAddProviderFromSearchPayload,
  SteerageRemoveProviderFromSearchPayload,
} from '~/utils/events'
import { UpdateProviderAvailabilityModal } from './UpdateProviderAvailabilityModal'
import Loader from '~/components/Loader'
import { SpecialtyGroupSearchCategory } from './ProviderSearch'
import {
  ProviderListItemReasonModal,
  ProviderListItemReasonModalHandle,
} from './ProviderListItemReasonModal'
import { queueNotification } from '~/redux/actions/notifications'

const StyledRating = styled(Rating)(({ theme }) => ({
  '& .MuiRating-iconFilled': {
    color: theme.palette.grey[600],
  },
}))

const useStyles = makeStyles()(theme => {
  return {
    recommendationText: {
      fontWeight: 500,
      fontSize: 'small',
      color: theme.palette.secondary.main,
      marginTop: '2px',
    },
    soft: {
      color: theme.palette.secondary.main,
      whiteSpace: 'normal',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
    },
    bold: {
      fontWeight: 'bold',
    },
    searchResult: {
      padding: '0px',
    },
    availabilityDays: {
      marginLeft: '10px',
      fontSize: 'medium',
    },
    availabilitySpacer: {
      minHeight: '20px',
    },
    availabilityIcon: {
      marginBottom: '-6px',
    },
    addAvailabilityLink: {
      fontWeight: 'normal',
      fontSize: 'medium',
      textDecorationLine: 'underline',
      color: 'black',
      '&:hover': {
        color: theme.palette.primary.main,
        textDecorationLine: 'underline',
      },
    },
    menuIcon: {
      padding: '0px',
    },
    partnerAgreementType: {
      color: theme.palette.secondary.main,
      fontWeight: 'bold',
      marginRight: '4px',
      marginBottom: '15px',
      backgroundColor: 'transparent',
    },
    recommendationIcon: {
      color: theme.palette.secondary.main,
    },
  }
})

const editProviderModalStyle = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: '24px',
  width: '490px',
}

const editAvailabilityModalStyle = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: '16px',
  width: '490px',
}

// These will eventually be published from the API directly
export interface ProviderDetailWithAdditionalData extends ProviderDetail {
  fireflyRecommendationFactors?: FireflyRecommendationFactors
  languages?: string[] | null
  gender?: string | null
  recommendationStatus?: string | null
  recommendationReasonLabels?: string | null
  location?: ProviderLocation[]
  compositeScore?: number | null
  partnership?: Partnership
}

export interface FireflyRecommendationFactors {
  averageRatingOutOfFive?: number | null
  costScoreOutOfFive?: number | null
  numberOfRatings?: number | null
  qualityScoreOutOfFive?: number | null
}

export interface ProviderListItemProps {
  provider: ProviderDetailWithAdditionalData
  locationType: string | undefined
  style?: any
  npi?: string | null | undefined
  providerName?: string | null | undefined
  facilityName?: string | null | undefined
  steerageID?: number | null
  steerageProviderList?: Array<SteerageProvider>
  states?: Array<any>
  specialtyGroupSearchCategory: SpecialtyGroupSearchCategory | null
  isProviderSearch: boolean | null
  indexInResults: number
  searchRequestId: number | null
  isSystemSuggested?: boolean
  steerageProvider?: SteerageProvider
}

export const extractLocationDetails = (locations: ProviderLocation[]): any => {
  const location = locations && locations.length >= 1 ? locations[0] : null

  var phone =
    location && location?.phoneNumbers
      ? location.phoneNumbers.find(e => e?.details && e.details === 'primary')?.phone
      : null
  phone = formatPhoneNumber(phone)
  const faxDetails = location?.faxNumbers?.filter(e => e?.details && e.details === 'primary')
  const faxNumbers = faxDetails?.map(faxDetail => formatPhoneNumber(faxDetail.fax)) || []
  const address = location?.addressDetails
  const distance = location?.distance
  const name = location?.name
  const addedOn = location?.availability?.addedOn
  const daysTillNextAvailability = location?.availability?.daysTillNextAvailability
  const networkPartnerAgreementType = location?.networkPartnerAgreementType
  const doesProviderExist = location?.availability?.doesProviderExist
  return [
    name,
    address,
    distance,
    phone,
    faxNumbers,
    location?.locationInNetwork == true,
    addedOn,
    daysTillNextAvailability,
    networkPartnerAgreementType,
    doesProviderExist,
  ]
}

export const ProviderListItem: FC<ProviderListItemProps> = props => {
  const [approved, setApproved] = useState(false)
  const [hide, setHide] = useState(false)
  const dispatch = useDispatch()
  const client = useQueryClient()
  const { classes } = useStyles()
  const [modalOpen, setModalOpen] = useState<boolean>(false)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const costScore = props?.provider?.fireflyRecommendationFactors?.costScoreOutOfFive
  let invertedCostScore: number | undefined = undefined

  if (costScore != null) {
    // cost ratings range from 1 - 5
    // a cost rating of 1 indicates a "costly" provider and should be
    // represented by $$$$$ (five dollar icons)
    // a cost rating of 5 indicates a "low cost" provider and should be
    // represented by $ (one dollar icon)
    invertedCostScore = Math.floor(6 - costScore)
  }
  const qualityScore = props?.provider?.fireflyRecommendationFactors?.qualityScoreOutOfFive
  const numberOfRatings = props?.provider?.fireflyRecommendationFactors?.numberOfRatings
  const averageRating = props?.provider?.fireflyRecommendationFactors?.averageRatingOutOfFive
  const recommendationStatus = props?.provider?.recommendationStatus
  const recommendationReasonLabels = props?.provider?.recommendationReasonLabels

  const hasLocation = Boolean(props?.provider?.locations && props?.provider?.locations?.length >= 1)
  const locationConfidenceScore = hasLocation
    ? props?.provider?.locations[0]?.confidence ?? null
    : null
  const isVerified = hasLocation
    ? (props?.provider?.locations && props?.provider?.locations[0]?.isVerified) ?? false
    : false
  const partnerName = hasLocation ? props?.provider?.locations[0]?.partnerName ?? null : null
  const [
    locationName,
    address,
    distance,
    phone,
    faxNumbers,
    locationInNetwork,
    addedOn,
    daysTillNextAvailability,
    networkPartnerAgreementType,
    doesProviderExist,
  ] = extractLocationDetails(props?.provider?.locations ?? null)

  const isUnknownRecommendation =
    recommendationStatus == null ||
    recommendationStatus == 'NO_RATING' ||
    recommendationStatus == 'NEUTRAL'
  const isRecommended = recommendationStatus == 'RECOMMENDED'
  const isNotRecommended = recommendationStatus == 'NOT_RECOMMENDED'

  // Use the search term specialty group to show as department in the data curation model
  let specialtyGroupSearchedTerm: SpecialtyGroup | null = null
  if (props?.specialtyGroupSearchCategory) {
    specialtyGroupSearchedTerm = {
      id: props?.specialtyGroupSearchCategory?.id,
      label: props?.specialtyGroupSearchCategory?.label,
      createdAt: null,
      updatedAt: null,
    }
  }

  // For opening and closing the edit provider modal
  const [openProviderDetails, setOpenProviderDetails] = useState(false)
  const handleEditProviderDetailsOpen = () => {
    setOpenProviderDetails(true)
    // Log the opening of the edit contact details modal to full story
    logEvent<EditProviderAndAvailabilityPayload>('EDIT_PROVIDER_DETAILS_BUTTON_CLICK', {
      providerNpi: props?.npi ?? null,
      careOrganisationName: props?.facilityName ?? null,
      steerageId: props?.steerageID,
      isEditedInDirectory: true,
      isProviderSearch: props?.npi ? true : false,
    })
  }
  const handleEditProviderDetailsClose = () => {
    setOpenProviderDetails(false)
    handleClose()
  }

  const handleAvailabilityModalOpen = () => {
    setModalOpen(!modalOpen)
    // Log the opening of the edit Availability modal to full story
    logEvent<EditProviderAndAvailabilityPayload>('EDIT_AVAILABILITY_BUTTON_CLICK', {
      providerNpi: props?.npi ?? null,
      careOrganisationName: props?.facilityName ?? null,
      steerageId: props?.steerageID,
      isEditedInDirectory: true,
      isProviderSearch: props?.npi ? true : false,
    })
  }

  const { isLoading: isAddingProvider, mutateAsync: handleAddProviderToSteerage } =
    useAddProviderToSteerage()
  const { isLoading: isDeletingProvider, mutateAsync: handleDeleteSteerageProvider } =
    useDeleteProviderFromSteerage()

  const handleAvailabilityModal = () => {
    setModalOpen(!modalOpen)
    handleClose()
  }

  const handleUserClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const steerageProviderMatchingID = props.steerageProviderList?.find(steerageProvider => {
    // The unique identifier is sent back as the construction of the NPI & Location unique keys
    // for Providers and only the Location unique keys for facilities. We save this as the source
    // identifier, when we add from this widget.
    if (
      props.provider.uniqueIdentifier == steerageProvider.sourceIdentifier &&
      steerageProvider.status !== SteerageProviderStatus.Rejected
    )
      return true
    return false
  })?.id

  const deleteProvider = providerID => {
    // calling the delete api for deleting provider from the care pass
    const payload = {
      steerageId: props.steerageID,
      providerId: providerID,
    }
    handleDeleteSteerageProvider(payload).then(async () => {
      // invalidate queries for fetching the care pass
      await client.cancelQueries([listSteerageKey, props.steerageID])
      await client.invalidateQueries([listSteerageKey, props.steerageID])
      await client.cancelQueries([listSteerageSteerageProvidersKey, props.steerageID])
      await client.invalidateQueries([listSteerageSteerageProvidersKey, props.steerageID])
      if (props.steerageID != null) {
        logEvent<SteerageRemoveProviderFromSearchPayload>(
          'STEERAGE_REMOVE_PROVIDER_FROM_STEERAGE_VIA_SEARCH',
          {
            steerageId: props.steerageID,
            positionOfProvider: props.indexInResults,
            steerageProviderId: providerID,
          }
        )
      }
    })
  }

  const addProviderToSteerage = async () => {
    // Get exception reason from the modal if the provider is unknown or not recommended
    let exception: string | null = null
    if (isNotRecommended || isUnknownRecommendation) {
      exception = (await providerReasonRef.current?.chooseReason()) ?? null
      if (exception === null) {
        console.log('User cancelled the steerage provider exception modal')
        return
      }
    }

    var state =
      props.states && address?.state
        ? props.states.find(stateDetail => stateDetail.abbreviation === address.state)?.id
        : null

    const payload = {
      steerageId: props.steerageID,
      firstName: props.provider.firstName,
      middleName: props.provider.middleName,
      lastName: props.provider.lastName,
      npi: props.provider.npi,
      specialtyList:
        props.provider.specialties != null && props.provider.specialties.length != 0
          ? props.provider.specialties
          : null,
      careOrganizationName: props.provider.npi ? locationName : props.provider.name,
      addressLine1: address?.addressLine1,
      addressLine2: address?.addressLine2,
      city: address?.city,
      state_id: state,
      zipCode: address?.zip,
      phone: phone?.replace(/\D/g, ''),
      fax: faxNumbers[0]?.replace(/\D/g, ''),
      dataSource: SteerageProviderDataSource.Ribbon,
      sourceIdentifier: props.provider.uniqueIdentifier,
      networkPartnerAgreementType: networkPartnerAgreementType,
      specialtyGroup: props?.specialtyGroupSearchCategory?.id ?? null,
      compositeScore: props?.provider?.compositeScore ?? null,
      costScore: costScore,
      qualityScore: qualityScore,
      numberOfRating: numberOfRatings,
      averageRating: averageRating,
      recommendationReason: recommendationReasonLabels
        ? recommendationReasonLabels.split(',').map(reason => {
            return reason.trim()
          })
        : null,
      searchRequest: props?.searchRequestId,
      languages: props?.provider?.languages,
      locationConfidenceScore: locationConfidenceScore,
      distance: distance,
    }

    // Add reason for exception if it's not null
    if (exception) payload['reasonForException'] = exception

    payload['hasVerifiedContactDetails'] = isVerified

    if (props.provider.recommendationStatus) {
      const recommendationStatusIndex = Object.values(RecommendationStatus).indexOf(
        props.provider.recommendationStatus as unknown as RecommendationStatus
      )
      payload['recommendationStatus'] = Object.keys(RecommendationStatus)[recommendationStatusIndex]
    }

    // Add waiver level on below conditions
    // 1. If only npi is present for provider then mark it as "Provider Specific"
    // 2. IF only careOrganizationName is present for provider then mark as "Entire Practice"
    // 3. If both careOrganizationName and npi are present for provider then keep it blank
    if (payload['npi'] && !payload['careOrganizationName']) {
      const indexOfProvider = Object.values(SteerageProviderWaiverLevel).indexOf(
        SteerageProviderWaiverLevel.Provider as unknown as SteerageProviderWaiverLevel
      )
      const provider = Object.keys(SteerageProviderWaiverLevel)[indexOfProvider]
      payload['waiverLevel'] = provider
    }
    if (!payload['npi'] && payload['careOrganizationName']) {
      const indexOfFacility = Object.values(SteerageProviderWaiverLevel).indexOf(
        SteerageProviderWaiverLevel.Facility as unknown as SteerageProviderWaiverLevel
      )
      const facility = Object.keys(SteerageProviderWaiverLevel)[indexOfFacility]
      payload['waiverLevel'] = facility
    }
    if (payload['npi'] && payload['careOrganizationName']) payload['waiverLevel'] = ''

    handleAddProviderToSteerage(payload).then(async storageResult => {
      // invalidate queries for refetching the providers
      await client.cancelQueries([listSteerageKey, props.steerageID])
      await client.invalidateQueries([listSteerageKey, props.steerageID])
      await client.cancelQueries([listSteerageSteerageProvidersKey, props.steerageID])
      await client.invalidateQueries([listSteerageSteerageProvidersKey, props.steerageID])
      if (props.steerageID != null) {
        logEvent<SteerageAddProviderFromSearchPayload>(
          'STEERAGE_ADD_PROVIDER_TO_STEERAGE_VIA_SEARCH',
          {
            steerageId: props.steerageID,
            positionOfProvider: props.indexInResults,
            steerageProviderId: storageResult.id,
          }
        )
        logEvent<DataCurationUsagePayload>('DATA_CURATION_USAGE', {
          steerageId: props.steerageID,
          steerageProviderId: storageResult.id,
          isAvailabilityPresent: addedOn ? true : false,
          isContactDetailsVerified: isVerified ? true : false,
          isProviderSearch: props?.isProviderSearch ? true : false,
        })
      }
    })
  }

  const providerReasonRef = useRef<ProviderListItemReasonModalHandle>(null)

  const { mutateAsync: handleReviewSuggestion, isLoading: reviewSuggestionLoading } =
    useReviewSteerageProviders(props.steerageID, {
      onMutate: payload => {
        setApproved(payload.approve)
        setTimeout(() => {
          setHide(true)
        }, 1000)
      },
      onError: () => {
        setApproved(false)
        setHide(false)
        dispatch(
          queueNotification({
            variant: 'error',
            message: 'Failed to review the suggestion',
          })
        )
      },
    })

  const handleAcceptSuggestion = () =>
    // Asserting that id is a number here because the UI responsible doesn't exist otherwise
    handleReviewSuggestion({ providers: [props.steerageProvider?.id!], approve: true })

  const handleDismissSuggestion = async () => {
    // Get exception reason from the modal if the provider is unknown or not recommended
    let exception: string | null = null
    exception = (await providerReasonRef.current?.chooseReason()) ?? null
    if (exception === null) {
      console.log('User cancelled the steerage provider exception modal')
      return
    }
    handleReviewSuggestion({
      // Asserting that id is a number here because the UI responsible doesn't exist otherwise
      providers: [props.steerageProvider?.id!],
      approve: false,
      reasonForRejection: exception,
    })
  }

  return (
    <Box style={props.style}>
      <ProviderListItemReasonModal
        ref={providerReasonRef}
        isProviderSearch={props.isProviderSearch}
        isSystemSuggested={props.isSystemSuggested}
        providers={[props.provider]}
      />
      <Collapse in={!hide}>
        <Box p={2}>
          {isAddingProvider || isDeletingProvider ? <Loader /> : null}
          <Divider />
          <Modal
            open={openProviderDetails}
            onClose={handleEditProviderDetailsClose}
            disableEscapeKeyDown
            disableScrollLock
            hideBackdrop
          >
            <Box sx={editProviderModalStyle}>
              <UpdateProviderDetailsModal
                provider={props.provider}
                specialtyGroups={specialtyGroupSearchedTerm ? [specialtyGroupSearchedTerm] : null}
                handleEditProviderDetailsClose={() => handleEditProviderDetailsClose()}
                steerageId={props?.steerageID}
                isProviderSearch={props.isProviderSearch}
                isEditedInDirectory={true}
              />
            </Box>
          </Modal>

          <Box>
            <Modal
              open={modalOpen}
              onClose={handleAvailabilityModal}
              disableEscapeKeyDown
              disableScrollLock
              hideBackdrop
            >
              <Box sx={editAvailabilityModalStyle}>
                <UpdateProviderAvailabilityModal
                  provider={props.provider}
                  handleCloseAvailabilityModal={() => handleAvailabilityModal()}
                  isProviderSearch={props.isProviderSearch}
                  steerageId={props?.steerageID}
                  isEditedInDirectory={true}
                  specialtyGroups={specialtyGroupSearchedTerm ? [specialtyGroupSearchedTerm] : null}
                />
              </Box>
            </Modal>
          </Box>

          <Grid container alignItems="flex-start" direction="row">
            {props.steerageID && !props.isSystemSuggested ? (
              <Grid item xs={1}>
                <Box py={5} mt={2}>
                  {steerageProviderMatchingID ? (
                    <IconButton
                      disabled={isDeletingProvider || isAddingProvider}
                      onClick={() => deleteProvider(steerageProviderMatchingID)}
                    >
                      <Bookmark color="primary" />
                    </IconButton>
                  ) : (
                    <IconButton
                      disabled={isDeletingProvider || isAddingProvider}
                      onClick={() => addProviderToSteerage()}
                    >
                      <BookmarkBorder />
                    </IconButton>
                  )}
                </Box>
              </Grid>
            ) : null}
            <Grid item flex={1}>
              <Box pl={1}>
                {props.isSystemSuggested ? (
                  <Grid
                    container
                    columnSpacing={1}
                    sx={{ bgcolor: approved ? colors.grey[50] : colors.blue[50], py: 1, mb: 1 }}
                  >
                    <Grid
                      item
                      alignItems="center"
                      display="flex"
                      flex={1}
                      sx={{ color: theme => theme.palette.grey[600] }}
                    >
                      <Typography sx={{ ml: 1, my: 1 }} variant="subtitle2">
                        System Suggested
                      </Typography>
                      <AutoAwesome sx={{ mx: 1 }} color="inherit" fontSize="small" />
                      <Typography variant="caption">{recommendationReasonLabels}</Typography>
                    </Grid>
                    {approved ? (
                      <Grid
                        item
                        alignItems="center"
                        display="flex"
                        sx={{ color: theme => theme.palette.grey[600] }}
                      >
                        <Check color="inherit" />
                        <Typography variant="subtitle2" sx={{ mr: 2 }}>
                          Added
                        </Typography>
                      </Grid>
                    ) : (
                      <>
                        <Grid item>
                          <Button
                            variant="outlined"
                            onClick={handleAcceptSuggestion}
                            disabled={reviewSuggestionLoading}
                          >
                            Accept
                          </Button>
                        </Grid>
                        <Grid item>
                          <Button
                            sx={{ mr: 1 }}
                            variant="outlined"
                            onClick={handleDismissSuggestion}
                            disabled={reviewSuggestionLoading}
                          >
                            Dismiss
                          </Button>
                        </Grid>
                      </>
                    )}
                  </Grid>
                ) : (
                  <Box display="flex" justifyContent="space-between" alignItems="flex-end" my={2}>
                    <Box display="flex">
                      {isRecommended && (
                        <Box display="flex">
                          <ThumbUpIcon fontSize="small" style={{ color: '#00B580' }} />
                          <Box display="flex" ml={1}>
                            <Typography variant="subtitle2" fontWeight="600">
                              {recommendationStatus}
                            </Typography>
                          </Box>
                        </Box>
                      )}
                      {isNotRecommended && (
                        <Box display="flex">
                          <ThumbDownIcon fontSize="small" style={{ color: '#EB0000' }} />
                          <Box display="flex" ml={1}>
                            <Typography variant="subtitle2" fontWeight="600">
                              {recommendationStatus}
                            </Typography>
                          </Box>
                        </Box>
                      )}
                      {isUnknownRecommendation && (
                        <Box display="flex" className={classes.recommendationIcon}>
                          <RemoveCircleOutlineIcon fontSize="small" />
                          <Box display="flex" ml={1}>
                            <Typography variant="subtitle2" fontWeight="600">
                              Unknown Recommendation
                            </Typography>
                          </Box>
                        </Box>
                      )}
                      {recommendationReasonLabels && (
                        <Box className={classes.recommendationText} ml={1.5}>
                          {recommendationReasonLabels}
                        </Box>
                      )}
                    </Box>
                    <Box display="flex">
                      {locationConfidenceScore && (
                        <>
                          {/* // If location confidence score is 1,2 then show as low if greater then 2 then high*/}
                          {locationConfidenceScore > 2 ? (
                            <Box display="flex" ml={1}>
                              <Box display="flex" className={classes.soft}>
                                <SignalCellular4BarOutlinedIcon />
                              </Box>
                              <Box
                                display="flex"
                                className={classes.recommendationText}
                                alignItems="flex-end"
                                ml={1}
                              >
                                High Certainty
                              </Box>
                            </Box>
                          ) : (
                            <Box display="flex" ml={1}>
                              <Box
                                display="flex"
                                className={classes.soft}
                                style={{ verticalAlign: 'text-bottom' }}
                              >
                                <SignalCellular1BarOutlinedIcon />
                              </Box>
                              <Box
                                display="flex"
                                className={classes.recommendationText}
                                alignItems="flex-end"
                                ml={1}
                              >
                                Low Certainty
                              </Box>
                            </Box>
                          )}
                        </>
                      )}
                    </Box>
                  </Box>
                )}
                <Box display="flex" alignItems="baseline" justifyContent="space-between">
                  <Box>
                    <Box display="flex" alignItems="center" justifyContent="space-between">
                      <Typography variant="h6"> {props?.provider?.name}</Typography>
                      {props?.provider?.providerType == 'In Home' ? (
                        <Box display="flex">
                          <Box
                            display="flex"
                            className={classes.soft}
                            style={{ verticalAlign: 'text-bottom' }}
                            ml={1}
                          >
                            <RoofingIcon fontSize="small" />
                          </Box>
                          <Box display="flex" className={classes.recommendationText} ml={0.25}>
                            {props?.provider?.providerType}
                          </Box>
                        </Box>
                      ) : props?.provider?.providerType == 'At Home Test Kit' ? (
                        <Box display="flex">
                          <Box
                            display="flex"
                            className={classes.soft}
                            style={{ verticalAlign: 'text-bottom' }}
                            ml={1}
                          >
                            <InventoryIcon fontSize="small" />
                          </Box>
                          <Box display="flex" className={classes.recommendationText} ml={0.25}>
                            {props?.provider?.providerType}
                          </Box>
                        </Box>
                      ) : props?.provider?.providerType == 'Virtual' ? (
                        <Box display="flex">
                          <Box
                            display="flex"
                            className={classes.soft}
                            style={{ verticalAlign: 'text-bottom' }}
                            ml={1}
                          >
                            <PhonelinkRingIcon fontSize="small" />
                          </Box>
                          <Box display="flex" className={classes.recommendationText} ml={0.25}>
                            {props?.provider?.providerType}
                          </Box>
                        </Box>
                      ) : (
                        distance != null && (
                          <Box display="flex">
                            <Box
                              display="flex"
                              className={classes.soft}
                              style={{ verticalAlign: 'text-bottom' }}
                              ml={1}
                            >
                              <PlaceIcon fontSize="small" />
                            </Box>
                            <Box display="flex" className={classes.recommendationText} ml={0.25}>
                              {distance} Miles
                            </Box>
                          </Box>
                        )
                      )}
                    </Box>
                  </Box>
                  {props.isSystemSuggested ? null : (
                    <>
                      <IconButton
                        className={classes.menuIcon}
                        color="primary"
                        onClick={handleUserClick}
                      >
                        <MoreVertIcon />
                      </IconButton>
                      <Menu
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        MenuListProps={{
                          disablePadding: true,
                        }}
                      >
                        <MenuItem onClick={handleEditProviderDetailsOpen}>Edit provider</MenuItem>
                        <MenuItem onClick={handleAvailabilityModalOpen}>Edit availability</MenuItem>
                      </Menu>
                    </>
                  )}
                </Box>
                {props?.provider?.npi && (
                  <Typography variant="body1" className={classes.soft}>
                    NPI {props?.provider?.npi}
                  </Typography>
                )}
              </Box>
              <Box mt={2} pl={1}>
                <Grid container alignItems="flex-start" direction="row">
                  <Grid item xs={4}>
                    <Box mb={1}>
                      {(props.isProviderSearch || props.isSystemSuggested) &&
                        locationName &&
                        locationName != props?.provider?.name && (
                          // for facility search the name of the location is the same as
                          // as the name of the location and gets hoisted to
                          // the name element shown along with the distance
                          <Typography variant="body1" className={classes.bold}>
                            {locationName}
                          </Typography>
                        )}
                      <Typography variant="body1">{address?.street} </Typography>
                      <Typography variant="body1">
                        {address?.city}
                        {address?.city && address?.state ? ', ' : null}
                        {address?.state} {address?.zip}{' '}
                      </Typography>{' '}
                      {phone && <Typography variant="body1">Ph: {phone}</Typography>}
                      {faxNumbers && faxNumbers.length > 0 && faxNumbers[0].trim() !== '' && (
                        <Typography variant="body1">Fax: {faxNumbers.join(', ')}</Typography>
                      )}
                    </Box>
                    {isVerified && (
                      <Box display="flex">
                        <Box display="flex" alignItems="end">
                          <VerifiedUserIcon fontSize="small" />
                        </Box>
                        <Typography
                          variant="subtitle2"
                          display="flex"
                          alignItems="flex-end"
                          ml={0.5}
                        >
                          Phone/Fax Verified
                        </Typography>
                      </Box>
                    )}
                    {networkPartnerAgreementType && (
                      <Grid container alignItems="flex-start">
                        <Box mt={1}>
                          <PermContactCalendar fontSize="small" />
                        </Box>
                        <Box mt={1} ml={1}>
                          <Typography variant="subtitle2">
                            {getNetworkPartnerDisplayName(
                              networkPartnerAgreementType,
                              props?.provider?.partnership?.partnershipType
                            )}
                          </Typography>
                        </Box>
                      </Grid>
                    )}
                    {partnerName && partnerName != locationName && (
                      <Box>
                        <Typography variant="subtitle2">{'(' + partnerName + ')'}</Typography>
                      </Box>
                    )}
                  </Grid>
                  <Grid item xs={4}>
                    <Box mb={1} pl={1} className={classes.searchResult}>
                      {props?.provider?.locationTypes && (
                        <Box mb={1}>
                          <Typography className={classes.soft}>
                            {props?.provider?.locationTypes?.sort().join(', ')}
                          </Typography>
                        </Box>
                      )}
                      <Box mb={1}>
                        <Typography className={classes.soft}>
                          {props?.provider?.specialties?.join(', ')}
                        </Typography>
                      </Box>
                      <Box mb={1}>
                        <Typography className={classes.soft}>
                          {props?.provider?.languages?.join(', ')}
                        </Typography>
                      </Box>
                      <Box mb={1}>
                        <Typography className={classes.soft}>{props?.provider?.gender}</Typography>
                      </Box>
                    </Box>
                  </Grid>
                  <Grid item xs={4} alignItems="flex-start">
                    <Grid container>
                      {invertedCostScore != null && (
                        <Grid container spacing={1}>
                          <Grid item xs={6}>
                            <Typography component="legend">Cost</Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <StyledRating
                              name="read-only"
                              value={invertedCostScore}
                              size="small"
                              readOnly
                              icon={<AttachMoneyRoundedIcon fontSize="inherit" />}
                              emptyIcon={<Box></Box>}
                            />
                          </Grid>
                        </Grid>
                      )}
                      {qualityScore != null && (
                        <Grid container spacing={1}>
                          <Grid item xs={6}>
                            <Typography component="legend">Quality</Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <StyledRating
                              name="read-only"
                              value={qualityScore}
                              size="small"
                              readOnly
                            />
                          </Grid>
                        </Grid>
                      )}
                      {numberOfRatings != null && averageRating != null && (
                        <Grid container spacing={1}>
                          <Grid item xs={6}>
                            <Typography component="legend">Ratings ({numberOfRatings})</Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <StyledRating
                              name="read-only"
                              value={averageRating}
                              size="small"
                              readOnly
                            />
                          </Grid>
                        </Grid>
                      )}

                      {(invertedCostScore != null ||
                        qualityScore != null ||
                        (numberOfRatings != null && averageRating != null)) && (
                        <Grid container spacing={1}>
                          <Grid item xs={12}>
                            <Box className={classes.availabilitySpacer}> </Box>
                          </Grid>
                        </Grid>
                      )}

                      {daysTillNextAvailability != null && doesProviderExist != false ? (
                        <Grid container spacing={1}>
                          <Grid item xs={12}>
                            <Typography component="legend">
                              <CalendarTodayIcon className={classes.availabilityIcon} />{' '}
                              {'Booking out ' + daysTillNextAvailability + ' days'}
                            </Typography>
                            <Typography component="legend">
                              {addedOn
                                ? '(verified ' + format(parse(addedOn), 'MMM D, YYYY') + ')'
                                : null}
                            </Typography>
                          </Grid>
                        </Grid>
                      ) : doesProviderExist == false ? (
                        <Grid container spacing={1}>
                          <Grid item xs={12}>
                            <Typography component="legend">
                              <NotInterestedIcon className={classes.availabilityIcon} /> Not
                              accepting patients
                            </Typography>
                            <Typography component="legend">
                              {addedOn
                                ? '(verified ' + format(parse(addedOn), 'MMM D, YYYY') + ')'
                                : null}
                            </Typography>
                          </Grid>
                        </Grid>
                      ) : props.isSystemSuggested ? null : (
                        <Grid container spacing={1}>
                          <Grid item xs={12}>
                            <Button
                              component="span"
                              onClick={handleAvailabilityModalOpen}
                              className={classes.addAvailabilityLink}
                            >
                              + Add availability
                            </Button>
                          </Grid>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
          {(props.npi || props.facilityName || props.providerName) && !locationInNetwork && (
            <Alert severity="warning">Provider may be out of network.</Alert>
          )}
        </Box>
      </Collapse>
    </Box>
  )
}
