import { ContentEntry, ContentEntryCollection, NormalizedIncludes, mapIncludes } from './utils'
import { createContext, useCallback, useEffect, useMemo, useState } from 'react'
import type { FC } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { apiClient } from '~/api/rest'
import { educationSlice } from '~/redux/slices/education'
import { queueNotification } from '~/redux/actions/notifications'
import { usePromiseState } from '@fireflyhealth/core'

/**
 * Breaking out a recreation of `useMutation` from `usePromiseState` so we can share
 * state setters downstream. This is useful for mapping linked entries returned by search.
 */
const useEducationMutation = () => {
  const focus = useSelector(state => state.education.inFocus.id) ?? undefined
  const focusedType = useSelector(state => state.education.inFocus.type) ?? undefined
  const me = useSelector(state => state.me.id)
  const dispatch = useDispatch()
  const state = usePromiseState<ContentEntryCollection>({ initialLoading: true })
  const handler = useCallback(async () => {
    try {
      state.setLoading(true)
      state.setError(null)
      let res
      if (focusedType == 'worklist') {
        res = await apiClient.rest.get<ContentEntryCollection>(
          `/v1/lucian/providers/${me}/education/`,
          { worklistId: focus }
        )
      } else if (focusedType == 'case') {
        res = await apiClient.rest.get<ContentEntryCollection>(
          `/v1/lucian/providers/${me}/education/`,
          { caseId: focus }
        )
      } else {
        res = await apiClient.rest.get<ContentEntryCollection>(
          `/v1/lucian/providers/${me}/education/`,
          { patientId: focus }
        )
      }
      state.setResult(res)
    } catch (e) {
      state.setError(e)
      dispatch(queueNotification({ variant: 'error', message: 'Failed to fetch content' }))
    } finally {
      state.setLoading(false)
    }
  }, [focus, me])
  return {
    handler,
    ...state,
  }
}

export interface EducationContextShape extends ReturnType<typeof useEducationMutation> {
  handleClose: () => void
  includes: NormalizedIncludes | null
  page: ContentEntry | null
  returnToIndex: () => void
  setPage: (entry: ContentEntry) => void
  showFeedback: boolean
  setShowFeedback: (show: boolean) => void
}

export const EducationContext = createContext<EducationContextShape>({} as EducationContextShape)

export const EducationModalProvider: FC = ({ children }) => {
  const [page, setPage] = useState<ContentEntry | null>(null)
  const [showFeedback, setShowFeedback] = useState(false)
  const dispatch = useDispatch()

  const returnToIndex = useCallback(() => setPage(null), [])

  const handleClose = useCallback(() => {
    dispatch(educationSlice.actions.close())
  }, [])

  // Fetch matching content for user
  const initResults = useEducationMutation()

  // Return to index view and close search when fetching new content
  useEffect(() => {
    if (initResults.loading) returnToIndex()
  }, [initResults.loading])

  // Fetch content and track activity when modal opens
  useEffect(() => {
    initResults.handler()
  }, [])

  // Include normalized "includes" in context
  const includes = useMemo(() => mapIncludes(initResults.result ?? {}), [initResults.result])
  return (
    <EducationContext.Provider
      value={{
        handleClose,
        includes,
        page,
        setPage,
        setShowFeedback,
        showFeedback,
        returnToIndex,
        ...initResults,
      }}
    >
      {children}
    </EducationContext.Provider>
  )
}
