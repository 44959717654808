import React, { createContext, useContext, useEffect, useRef, useState } from 'react'
import ZoomVideo, { MediaDevice, VideoClient } from '@zoom/videosdk'
import { Appointment } from '@fireflyhealth/core'
import { Box } from '@mui/material'

import { logger } from '~/utils/logger'
import { SessionPreCall } from './SessionPreCall'
import { SessionCall } from './SessionCall'
import { SessionHeader } from './SessionHeader'

interface SessionProps {
  appointment: Appointment
  sessionName: string
  token: string
  modal: boolean
}

interface SessionContext {
  // Client State
  appointment: Appointment
  client: typeof VideoClient | null
  clientError: boolean
  modal: boolean
  screen: 'precall' | 'call'
  sessionName: string
  token: string
  // Hardware State
  activeCamera: MediaDevice | null
  activeMicrophone: MediaDevice | null
  activeSpeaker: MediaDevice | null
  cameras: MediaDevice[]
  isMuted: boolean
  isAudioOn: boolean
  isVideoOn: boolean
  microphones: MediaDevice[]
  speakers: MediaDevice[]
  // DOM Refs
  containerRef: React.RefObject<HTMLDivElement>
  // Methods
  setActiveCameraId: (id: string) => void
  setActiveMicrophoneId: (id: string) => void
  setActiveSpeakerId: (id: string) => void
  setCameras: (cameras: MediaDevice[]) => void
  setIsMuted: (isMuted: boolean) => void
  setIsAudioOn: (isAudioOn: boolean) => void
  setIsVideoOn: (isVideoOn: boolean) => void
  setMicrophones: (microphones: MediaDevice[]) => void
  setScreen: (screen: 'precall' | 'call') => void
  setSpeakers: (speakers: MediaDevice[]) => void
}

const ZoomSessionContext = createContext({} as SessionContext)

export const useZoomSession = (): SessionContext => useContext(ZoomSessionContext)

export const SessionProvider: React.FC<SessionProps> = props => {
  /**
   * Client State
   */
  const [screen, setScreen] = useState<'precall' | 'call'>('precall')
  const [client, setClient] = useState<typeof VideoClient | null>(null)
  const [clientError, setClientError] = useState(false)

  /**
   * Hardware State
   */
  const [isMuted, setIsMuted] = useState(false)
  const [isAudioOn, setIsAudioOn] = useState(false)
  const [isVideoOn, setIsVideoOn] = useState(true)
  const [cameras, setCameras] = useState<MediaDevice[]>([])
  const [activeCameraId, setActiveCameraId] = useState<string | null>(null)
  const activeCamera = cameras.find(c => c.deviceId === activeCameraId) ?? null
  const [microphones, setMicrophones] = useState<MediaDevice[]>([])
  const [activeMicrophoneId, setActiveMicrophoneId] = useState<string | null>(null)
  const activeMicrophone = microphones.find(c => c.deviceId === activeMicrophoneId) ?? null
  const [speakers, setSpeakers] = useState<MediaDevice[]>([])
  const [activeSpeakerId, setActiveSpeakerId] = useState<string | null>(null)
  const activeSpeaker = speakers.find(c => c.deviceId === activeSpeakerId) ?? null

  /**
   * DOM Refs
   */
  const containerRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    try {
      setClient(ZoomVideo.createClient())
    } catch (e) {
      logger.warn('Zoom CDN Script has not loaded yet', e)
      setClientError(true)
    }
  }, [])
  useEffect(() => {
    if (client) {
      client
        ?.init('EN', 'CDN', {
          enforceMultipleVideos: false,
          webEndpoint: 'zoom.us',
          stayAwake: true,
        })
        .catch(e => {
          logger.warn('Failed to initialize Zoom', e)
          setClientError(true)
        })
    }
  }, [client])

  if (clientError) return <div>Zoom Error</div>

  return (
    <ZoomSessionContext.Provider
      value={{
        // Client State
        appointment: props.appointment,
        client,
        clientError,
        modal: props.modal,
        screen,
        sessionName: props.sessionName,
        token: props.token,
        // Hardware State
        activeCamera,
        activeMicrophone,
        activeSpeaker,
        cameras,
        isMuted,
        isAudioOn,
        isVideoOn,
        microphones,
        speakers,
        // DOM Refs
        containerRef,
        // Methods
        setActiveCameraId,
        setActiveMicrophoneId,
        setActiveSpeakerId,
        setCameras,
        setIsMuted,
        setIsAudioOn,
        setIsVideoOn,
        setMicrophones,
        setScreen,
        setSpeakers,
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          width: '100%',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          bgcolor: 'white',
        }}
        ref={containerRef}
      >
        <SessionHeader />
        {screen === 'precall' ? <SessionPreCall /> : null}
        {screen === 'call' ? <SessionCall /> : null}
      </Box>
    </ZoomSessionContext.Provider>
  )
}
