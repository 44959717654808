import { Box } from '@mui/material'

import Loader from '~/components/Loader'
import { useSteerage } from '~/api/SteerageService'
import { Steerage } from './types'
import SteerageView from './SteerageView'
import { SteerageViewV3 } from './SteerageViewV3/SteerageViewV3'
import { useFeatureSwitch } from '~/utils/useFeatureSwitch'

const SteerageRelatedView = ({ model }: { model: Pick<Steerage, 'id' | 'activeCaseId'> }) => {
  return <SteerageEditor id={model.id} activeCaseId={model.activeCaseId ?? null} />
}
// Helper for fetching the full Steerage prior to rendering the edit form.
const SteerageEditor: React.FC<{
  id: Steerage['id']
  activeCaseId: number | null
}> = ({ id, activeCaseId }) => {
  const { data: steerage, isLoading } = useSteerage(id)
  if (steerage) steerage.activeCaseId = activeCaseId

  const priorAuthCaseMergeSwitch = useFeatureSwitch('referral.mergePriorAuthCases')?.active

  if (isLoading || !steerage) {
    return <Loader inline={true} />
  }

  // We infer that this is a care/care+coverage user if they have a referral and show the V3 view
  if (
    steerage.hasReferral ||
    (steerage.hasWaiver && !steerage.waiver?.priorAuthorization) ||
    (priorAuthCaseMergeSwitch && steerage.hasWaiver && steerage.waiver?.priorAuthorization)
  ) {
    return <SteerageViewV3 steerageId={id} />
  }

  // Prior Auth cases see the old steerage view unless switch on
  return steerage ? <SteerageView steerage={steerage} /> : <Box></Box>
}
export default SteerageRelatedView
