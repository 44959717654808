import { FC, useState, useRef } from 'react'
import { Box, Button, Typography, TextField, makeStyles } from '@material-ui/core'
import { PatientUser } from '@fireflyhealth/core'

import { FFModal } from '~/components/FFModal/FFModal'
import usePatientDetail from '../utils/usePatientDetail'
import {
  useUpdateEmail,
  useTriggerOtp,
  useOtpVerification,
} from '~/components/PatientDetail/EditPatient/utils'

interface EmailChangeModelProps {
  handleClose: () => void
  open: boolean
  patient: PatientUser
}

export const EmailChangeModel: FC<EmailChangeModelProps> = props => {
  const styles = useStyles()
  const [emailState, setEmailState] = useState({ title: 'Change Member Email', page: 'send_otp' })
  const [error, setError] = useState(false)
  const { id } = usePatientDetail()
  const emailRef = useRef<HTMLInputElement>(null)
  const otpRef = useRef<HTMLInputElement>(null)
  const { handler: sendOtpHandler, loading: sendOtpLoading } = useTriggerOtp()
  const { handler: verifyOtpHandler, loading: verifyOtpLoading } = useOtpVerification()
  const { handler: saveEmailHandler, loading: saveEmailLoading } = useUpdateEmail()

  const SendOTP = () => {
    return (
      <Box>
        <Box display="flex" flexDirection="column" px={4} py={6}>
          <Typography variant="h6">{emailState.title}</Typography>
          <Box mt={3} mb={2}>
            <Typography>
              For security, we have to confirm the member’s identity before updating their email
              address. Here’s what happens:
            </Typography>
          </Box>
          <Typography>1. We text a security code to the member’s phone number on file</Typography>
          <Typography>2. Member reads code back to us over the phone</Typography>
          <Typography>3. We ask the member for their new email address</Typography>
          <Typography>4. We automatically send member a verification email</Typography>
          <Typography>
            5. Member follows the link in the verification email to verify their new email address
          </Typography>
        </Box>
        <Box display="flex" justifyContent="flex-end" px={4} py={6}>
          <Box mr={1}>
            <Button disabled={sendOtpLoading} onClick={props.handleClose} color="primary">
              Cancel
            </Button>
          </Box>
          <Button
            onClick={() => sendOtpHandler(id, setEmailState, setError)}
            disabled={sendOtpLoading}
            color="primary"
            variant="outlined"
          >
            Text Code to Member
          </Button>
        </Box>
      </Box>
    )
  }

  const VerifyOTP = () => {
    return (
      <Box>
        <Box display="flex" flexDirection="column" py={6} px={4}>
          <Typography variant="h6">{emailState.title}</Typography>
          <Box mt={3} mb={2}>
            <Typography>
              {`We texted a 6-digit security code to ${props.patient.phoneNumber}. It will arrive shortly. Ask the member to read it back to you and enter it here.`}
            </Typography>
          </Box>
          <TextField
            fullWidth
            label="Enter 6-digit code"
            error={error}
            defaultValue={otpRef.current?.value}
            helperText={error ? 'Please check the entered OTP' : ''}
            className={styles.textField}
            inputRef={otpRef}
            variant="outlined"
            onChange={e => {
              if (otpRef && otpRef.current) otpRef.current.value = e.target.value
            }}
          />
          <Box mt={3} mb={2} display="flex" justifyContent="flex-start" alignItems="center">
            <Typography>Member didn’t receive a code?</Typography>
            <Button
              disabled={verifyOtpLoading}
              onClick={() => sendOtpHandler(id, setEmailState, setError)}
              color="primary"
              style={{ fontSize: '1.5rem' }}
            >
              Resend
            </Button>
          </Box>
        </Box>
        <Box display="flex" justifyContent="flex-end" px={4} py={6}>
          <Box mr={1}>
            <Button disabled={verifyOtpLoading} onClick={props.handleClose} color="primary">
              Cancel
            </Button>
          </Box>
          <Button
            onClick={() => verifyOtpHandler(id, otpRef.current?.value, setEmailState, setError)}
            disabled={verifyOtpLoading}
            color="primary"
            variant="outlined"
          >
            Continue
          </Button>
        </Box>
      </Box>
    )
  }

  const UpdateEmail = () => {
    return (
      <Box>
        <Box display="flex" flexDirection="column" py={6} px={4}>
          <Typography variant="h6">{emailState.title}</Typography>
          <Box mt={3} mb={2}>
            <Typography>
              The security code is correct. Ask the member for their new email address and enter it
              here.
            </Typography>
          </Box>
          <TextField
            fullWidth
            label="New email address"
            defaultValue={emailRef.current?.value}
            error={error}
            helperText={error ? 'Invalid Email' : ''}
            className={styles.textField}
            inputRef={emailRef}
            variant="outlined"
            onChange={e => {
              if (emailRef && emailRef.current) emailRef.current.value = e.target.value
            }}
          />
        </Box>
        <Box display="flex" justifyContent="flex-end" py={6} px={4}>
          <Box mr={1}>
            <Button disabled={saveEmailLoading} onClick={props.handleClose} color="primary">
              Cancel
            </Button>
          </Box>
          <Button
            onClick={() => {
              saveEmailHandler(props.patient.id, emailRef.current?.value, setEmailState, setError)
            }}
            disabled={saveEmailLoading}
            color="primary"
            variant="outlined"
          >
            Save
          </Button>
        </Box>
      </Box>
    )
  }

  const Success = () => {
    return (
      <Box>
        <Box display="flex" flexDirection="column" py={6} px={4}>
          <Typography variant="h6">{emailState.title}</Typography>
          <Box mt={3} mb={2}>
            <Typography>
              We sent a verification email to the member. Ask them to check that they received it
              and to follow the link inside to verify their email address.
            </Typography>
          </Box>
          <Box display="flex" justifyContent="flex-end" py={6} px={4}>
            <Button
              disabled={saveEmailLoading}
              onClick={props.handleClose}
              color="primary"
              variant="outlined"
            >
              Close
            </Button>
          </Box>
        </Box>
      </Box>
    )
  }

  const EmailChangeModelBody = props => {
    const page = props.page
    switch (page) {
      case 'send_otp':
        return <SendOTP />
      case 'verify_otp':
        return <VerifyOTP />
      case 'update_email':
        return <UpdateEmail />
      case 'success':
        return <Success />
      default:
        return <SendOTP />
    }
  }

  return (
    <FFModal open={props.open}>
      <EmailChangeModelBody page={emailState.page} />
    </FFModal>
  )
}
const useStyles = makeStyles({
  textField: {
    '&:hover span': {
      opacity: 1,
    },
  },
  copy: {
    opacity: 0,
  },
})

export default EmailChangeModel
