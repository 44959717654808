import { BaseResource } from '@fireflyhealth/core'
import { ICase } from './Case'

export enum PhoneCallDirectionEnum {
  INBOUND = 'INBOUND',
  OUTBOUND = 'OUTBOUND',
}

export interface IPhoneCall extends BaseResource {
  id: number
  user: number | null
  person: number | null
  direction: 'INBOUND' | 'OUTBOUND'
  calledAt: string
  createdBy: number
  disposition?: number
  callEndedAt?: string
  externalCallId?: string
  contactPhoneNumber?: string
  cases: ICase[]
  recordingUrl?: string
  notes?: string
}

export interface IPhoneCallDisposition {
  id: number
  title: string
}
