import React, { useEffect, useState } from 'react'

import { FormSubmission } from '@fireflyhealth/core'
import { Box } from '@mui/material'
import Loader from '~/components/Loader'
import { useAuth0 } from '@auth0/auth0-react'

export interface Props {
  formSubmission: FormSubmission
}

/*
Displays a FormSubmission using a WebView of our forms engine app
*/
const EmbeddedFormSubmission: React.FC<Props> = props => {
  const [session, setSession] = useState<null | { accessToken: string }>(null)
  const { getAccessTokenSilently } = useAuth0()
  const [iFrameRef, setIFrameRef] = useState<HTMLIFrameElement | null>(null)

  useEffect(() => {
    getAccessTokenSilently().then(token => setSession({ accessToken: token }))
  }, [])

  const webViewUrlBase = process.env.REACT_APP_FIREFLY_FORMS_UI_URL as string

  const formId = props.formSubmission.form.id
  const submissionId = props.formSubmission.id
  const webViewUrl = `${webViewUrlBase}/${formId}/responses/${submissionId}`

  const injectToken = () => {
    if (!session) return
    iFrameRef?.contentWindow?.postMessage(
      { type: 'setToken', body: { token: session.accessToken, isProvider: true } },
      webViewUrlBase
    )
  }

  return (
    <Box flex={1} overflow="auto">
      {session?.accessToken ? (
        <iframe
          ref={setIFrameRef}
          title="Form Submission"
          id="form-submission"
          width="100%"
          height={window.innerHeight * 0.8}
          scrolling="yes"
          frameBorder="0"
          style={{ padding: 10 }}
          onLoadCapture={injectToken}
          src={webViewUrl}
        />
      ) : (
        <Loader inline />
      )}
    </Box>
  )
}

export default EmbeddedFormSubmission
