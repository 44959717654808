import PubNub, { MessageEvent } from 'pubnub'
import { DefaultRootState, batch } from 'react-redux'
import { normalize, schema } from 'normalizr'
import * as ChatService from '~/api/ChatService'
import { PUBNUB_PUBLISH_KEY, PUBNUB_SUBSCRIBE_KEY } from '~/utils/config'
import { queueNotification } from '../../actions/notifications'
import { patientsSlice } from '../patients'
import {
  disconnectFromPubNub,
  IPubNubPatientMetadata,
  PresenceSignalMessage,
  PresenceSignalTypeCodeMap,
  providerInboxChannel,
} from '~/utils/pubnub'
import { getThreads } from '~/api/ChatService'
import chatSlice from './slice'
import { getDefaultPatientThread } from '~/utils/chat'
import { findSelectedPodIdsFromMap } from '~/screens/Messages'
import { logger } from '~/utils/logger'
import { v4 as uuidv4 } from 'uuid'

export const initPubnub = (me, authKey) => async (dispatch, getState) => {
  console.log('initPubnub for userId', me.id)

  const state: DefaultRootState = getState()

  const pubnub = new PubNub({
    publishKey: PUBNUB_PUBLISH_KEY,
    subscribeKey: PUBNUB_SUBSCRIBE_KEY as string,
    ssl: true,
    authKey: authKey,
    userId: me.id.toString(),
  })

  const resubscribe = () => {
    const channels = [providerInboxChannel(me)]
    pubnub.unsubscribeAll()
    pubnub.subscribe({
      channels,
      withPresence: true,
    })
  }

  resubscribe()

  pubnub.addListener({
    message: message => {
      dispatch(processNewMessageAndFetchPatient(message))
    },
    signal: async signalEvent => {
      // Publisher is the provider sender ID sending us the update, formatted like "12"
      const providerUserId = signalEvent.publisher
      const message = signalEvent.message as PresenceSignalMessage
      if (message.st === PresenceSignalTypeCodeMap.MESSAGE_REFILTER) {
        if (providerUserId === me.id) return
        logger.info('[MessageRefilter] init', message)
        const patientId = message.p
        const personId = message.pe as number
        const threadUid = getDefaultPatientThread(patientId)

        // A message has been updated with information which could affect our message filters
        // e.g. has been recategorized from Operational to Clinical
        // Reload the thread so that we get thread metadata (e.g. lastReadAt timestamp)
        // as well as the updated message data
        //
        // Load the patient if needed (we need this to display the thread)
        const patientIsLoaded = !!state.patients.byId[patientId]
        if (!patientIsLoaded) {
          await dispatch(patientsSlice.thunks.getPatient(patientId))
        }
        const response = await ChatService.getChatMsgPhoneCallHistory(threadUid, personId)
        await dispatch(chatSlice.actions.loadThreadHistorySuccess(response))
        const actionPayload = {
          uuid: uuidv4(),
          patientId: response.thread.patient,
        }
        logger.info('[MessageRefilter] calling refilterThread with', actionPayload)
        dispatch(chatSlice.actions.refilterThread(actionPayload))
        return
      }
      // This is a presence signal
      dispatch(
        chatSlice.actions.setPresenceState({
          providerUserId,
          presenceState: {
            viewing: message.v,
            typing: message.t,
            patientId: message.p,
          },
        })
      )
    },
    status: status => {
      if (status.category === 'PNNetworkUpCategory') {
        resubscribe()
      } else if (status.category === 'PNNetworkDownCategory') {
        console.error('PubNub PNNetworkDownCategory status', status)
        dispatch(
          queueNotification({
            message: 'Received unexpected messaging failure (PNNetworkDownCategory)',
            variant: 'error',
          })
        )
      }
    },
  })

  // When window closes
  window.addEventListener('beforeunload', e => {
    disconnectFromPubNub(pubnub, me)
  })

  dispatch(chatSlice.actions.setPubnubClient(pubnub))
}

const delay = (ms: number) => {
  return new Promise(resolve => setTimeout(resolve, ms))
}

// Tests if an incoming real-time message can be ignored or not
// The persisted message can always be loaded later via the API
export const incomingMessageEventCanBeIgnored = (params: {
  patientMetadata: IPubNubPatientMetadata
  state: DefaultRootState
  patientId: string
}): boolean => {
  const { patientMetadata, state, patientId } = params
  const currentFilterParams = state.chat.currentFilterParams
  const currentPodIds = findSelectedPodIdsFromMap(currentFilterParams?.selectedPodIdsMap || {})

  // If viewing this patient, always load the message
  const viewingState = state.chat.viewing
  const myId = state.me.id
  if (viewingState?.[myId] == parseInt(patientId)) return false

  if (currentPodIds.length == 0) return false

  // Filtering for Care Pod ID 0 is code for "patients without a care pod"
  // so this message should be filtered out otherwise
  if (patientMetadata.pod_ids.length == 0 && !currentPodIds.includes('0')) {
    console.log(
      'processNewMessageAndFetchPatient: Care Pod filters applied for unassigned patients, but incoming message did not match; Ignoring'
    )
    return true
  }

  // If no overlap between 2) the care pods I'm filtering for and 2) this patient's care pods
  if (
    patientMetadata!.pod_ids.length > 0 &&
    !currentPodIds.some(value => patientMetadata!.pod_ids.includes(parseInt(value)))
  ) {
    console.log(
      'processNewMessageAndFetchPatient: Care Pod filters applied, but incoming message did not match; Ignoring'
    )
    return true
  }

  return false
}

export const processNewMessageAndFetchPatient =
  (messageEvent: MessageEvent) => async (dispatch, getState) => {
    const messageUid = messageEvent.message.uid
    const state: DefaultRootState = getState()
    let users = state.users.byId
    const patientId = messageEvent.message.channel.split('.')[1]
    let stateOfResidence = users[patientId]?.person?.insuranceInfo?.addresses?.[0]?.state
    let patientMetadata: IPubNubPatientMetadata | null = null

    // We need some patient data to power the messages UX
    // The message payload may include patient metadata coming from PubNub
    if (messageEvent.message.patientData) {
      patientMetadata = messageEvent.message.patientData as IPubNubPatientMetadata
    }

    logger.info('[processNewMessageAndFetchPatient] init', {
      messageUid,
      patientMetadata,
    })

    if (
      patientMetadata &&
      incomingMessageEventCanBeIgnored({ patientMetadata, state, patientId })
    ) {
      logger.info('[processNewMessageAndFetchPatient] ignoring', {
        messageUid,
      })
      return
    }

    if (messageEvent.message.sender === Number(patientId))
      // If patient sent message: mark thread read and set pubnubMessageUnread True
      // If provider sent message: mark thread as read and set pubnubMessageUnread False
      // If Luci sent message: keep thread same state as previous
      dispatch(chatSlice.actions.setThreadUnread(patientId))
    else if (messageEvent.message.sender !== Number(process.env.REACT_APP_FIREFLY_BOT_ID))
      dispatch(
        chatSlice.actions.setThreadRead({
          patient: patientId,
          clinician: messageEvent.message.sender,
        })
      )

    if (patientMetadata) {
      stateOfResidence = patientMetadata.address_state
    } else if (stateOfResidence === undefined) {
      // If patientMetadata is not present, e.g. in local environments not receiving message payloads from PubNub
      // we need to fetch the patient data now
      //
      // TODO(martin): This delay is a hack. Patient welcome messages are currently sent
      // in the post_save signal, and the Pubnub notification could arrive before
      // patient is saved to the database. Wait 3 seconds before firing a request to
      // fetch new patient.
      await delay(3000)
      await dispatch(patientsSlice.thunks.getPatient(patientId))
      users = getState().users.byId
      stateOfResidence = users[patientId]?.person?.insuranceInfo?.addresses?.[0]?.state
    }

    const userId = state.me.id

    // Load just this new message
    // The full chat history can be loaded later, e.g. after clicking into the patient
    dispatch(chatSlice.actions.loadNewPubNubMessage({ messageEvent, patientMetadata, userId }))
  }

const userSchema = new schema.Entity('users')

const threadSchema = new schema.Entity(
  'threads',
  {
    patientMeta: userSchema,
  },
  { idAttribute: 'patient' }
)

const threadListSchema = new schema.Array(threadSchema)

export const loadBatchHistory = (params: Parameters<typeof getThreads>[0]) => async dispatch => {
  try {
    const now = Date.now()
    dispatch(chatSlice.actions.initBatchHistoryRequest({ now }))
    const { threads, count } = await ChatService.getThreads(params)
    const normalized = normalize(Object.values(threads), threadListSchema)

    batch(() => {
      dispatch(
        chatSlice.actions.loadBatchHistorySuccess({
          patientThreads: threads,
          count,
          params,
          timestamp: now,
        })
      )

      // Put metadata for normalized users in user cache.
      if (normalized.entities.users) {
        dispatch(patientsSlice.actions.getPatientsSuccess(Object.values(normalized.entities.users)))
      }
    })
  } catch (e) {
    console.error(e)
  }
}

export const loadThreadHistory = (threadUid, personId) => async dispatch => {
  try {
    const response = await ChatService.getChatMsgPhoneCallHistory(threadUid, personId)
    dispatch(chatSlice.actions.loadThreadHistorySuccess(response))
  } catch (e) {
    console.error(e)
  }
}

export interface UpdateChatReadParams {
  patient: number
  clinician: number
  currentReadAt?: string | null
}
export const markThreadRead = (payload: UpdateChatReadParams) => async dispatch => {
  // Optimistically mark thread as read
  batch(() => {
    dispatch(chatSlice.actions.setThreadRead(payload))
  })
  try {
    const uid = getDefaultPatientThread(payload.patient)
    ChatService.markThreadRead(uid, new Date().toISOString(), payload.clinician)
  } catch (e) {
    if (e instanceof Error) {
      console.error(e)
    }

    batch(() => {
      // Revert to previous state
      dispatch(
        chatSlice.actions.updateThread({
          patient: payload.patient,
          lastReadAt: payload.currentReadAt,
        })
      )
      dispatch(queueNotification({ message: 'Failed to mark thread read', variant: 'error' }))
    })
  }
}
export const markThreadUnread =
  (patient: number, currentReadAt: string | null) => async dispatch => {
    // Optimistically mark thread as unread
    try {
      const uid = getDefaultPatientThread(patient)
      ChatService.markThreadUnread(uid)
      batch(() => {
        dispatch(chatSlice.actions.setThreadUnread(patient))
      })
    } catch (e) {
      if (e instanceof Error) {
        console.error(e)
      }

      // Revert to previous state
      batch(() => {
        dispatch(chatSlice.actions.updateThread({ patient, lastReadAt: currentReadAt }))
        dispatch(queueNotification({ message: 'Failed to mark thread unread', variant: 'error' }))
      })
    }
  }

export const loadThreadMoreHistory = (patient, cursor) => async dispatch => {
  try {
    const response = await ChatService.getThreadHistoryFromCursor(cursor)
    dispatch(
      chatSlice.actions.loadThreadMoreHistorySuccess({
        patient,
        ...response,
      })
    )
  } catch (e) {
    console.error(e)
  }
}

export const loadThreadHistoryAt =
  (threadUid, msgId, numBefore, numAfter, searchResultIndex) => async dispatch => {
    try {
      const response = await ChatService.getChatThreadHistoryAt(
        threadUid,
        msgId,
        numBefore,
        numAfter
      )
      if (numBefore && numAfter) {
        dispatch(chatSlice.actions.loadThreadHistoryAtSuccess({ threadUid, data: response }))
      } else if (numBefore) {
        dispatch(chatSlice.actions.loadThreadBeforeHistoryAtSuccess({ data: response }))
      } else {
        dispatch(chatSlice.actions.loadThreadAfterHistoryAtSuccess({ data: response }))
      }
      if (searchResultIndex !== null) {
        dispatch(chatSlice.actions.setSearchResultIndex(searchResultIndex))
      }
    } catch (e) {
      dispatch(
        queueNotification({
          variant: 'error',
          message: 'Failed to get search results.',
        })
      )
    }
  }

export const loadMessageSearchResults = (threadUid, searchQuery) => async dispatch => {
  try {
    dispatch(chatSlice.actions.clearHistoricalMessages())
    dispatch(chatSlice.actions.setSearchResultIndex(null))
    const response = await ChatService.getMessageSearchResults(threadUid, searchQuery)
    dispatch(
      chatSlice.actions.loadMessageSearchResultsSuccess({
        data: response,
        threadUid,
        searchQuery,
      })
    )
  } catch (e) {
    console.error(e)
  }
}
