import { AssigneeGroup } from '@fireflyhealth/core'
import { GET_ASSIGNEE_GROUPS, GET_ASSIGNEE_GROUPS_ERROR } from '../actions/actionTypes'

const INITIAL_STATE: AssigneeGroup[] = []

const assigneeGroups = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_ASSIGNEE_GROUPS:
      return action.assigneeGroups as AssigneeGroup[]
    case GET_ASSIGNEE_GROUPS_ERROR:
      return {
        ...state,
        error: action.error,
      }
    default:
      return state
  }
}

export default assigneeGroups
