import type { FC } from 'react'
import { Box, Button, Typography } from '@material-ui/core'
import { useMutation } from '@fireflyhealth/core'
import { useDispatch } from 'react-redux'
import { apiClient } from '~/api/rest'
import { FFModal } from '~/components/FFModal/FFModal'
import { queueNotification } from '~/redux/actions/notifications'
import usePatientDetail from '../utils/usePatientDetail'

interface PhoneNumberChangeModalProps {
  handleClose: () => void
  open?: boolean
}

export const PhoneNumberChangeModal: FC<PhoneNumberChangeModalProps> = props => {
  const { id } = usePatientDetail()
  const dispatch = useDispatch()
  const { handler, loading } = useMutation(async () => {
    try {
      await apiClient.rest.post(`/user/${id}/phone/change/request/`)
      dispatch(queueNotification({ message: 'Phone number change email sent', variant: 'success' }))
      props.handleClose()
    } catch (e) {
      if (e instanceof Error) {
        console.error(e)
      }
      dispatch(queueNotification({ message: 'Failed to send', variant: 'error' }))
    }
  })

  return (
    <FFModal open>
      <Box>
        <Box display="flex" flexDirection="column" py={6} px={4}>
          <Box>
            <Typography variant="h6">Change Member’s Phone Number</Typography>
            <Box mt={3} mb={2}>
              <Typography>
                For security reasons, members can’t directly change their number. We can’t either.
                Instead, we email them a link to do this using multi-factor authentication (MFA) .
              </Typography>
            </Box>
            <Typography>
              They’ll receive the email within 5 minutes. When they click the link, they’ll verify
              their identity and enter their new number.
            </Typography>
          </Box>
          <Box mt={4}>
            <Box display="flex" justifyContent="flex-end">
              <Box mr={1}>
                <Button disabled={loading} onClick={props.handleClose} color="secondary">
                  Cancel
                </Button>
              </Box>
              <Button onClick={handler} disabled={loading} color="primary" variant="contained">
                Send
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </FFModal>
  )
}

export default PhoneNumberChangeModal
