import type { FC } from 'react'
import { PatientUser } from '@fireflyhealth/core'
import ProviderSearch from '~/components/PatientDetail/ProviderSearch/ProviderSearch'
import { useProviderSearch } from './utils'
import Loader from '~/components/Loader'

export interface ProviderSearchTabProps {
  patient: PatientUser
}

const ProviderSearchTab: FC<ProviderSearchTabProps> = props => {
  const { isLoading } = useProviderSearch()
  if (isLoading) return <Loader />
  return <ProviderSearch person={props?.patient.person} />
}

export default ProviderSearchTab
