import { Grid, MenuItem, Select } from '@mui/material'
import ClearIcon from '@mui/icons-material/Clear'
import { Delete as DeleteIcon } from '@mui/icons-material'
import { differenceInDays, format } from 'date-fns'
import Moment from 'moment'
import {
  Box,
  Button,
  IconButton,
  Typography,
  Radio,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Checkbox,
} from '@mui/material'
import DateAndRelativeDateInput from '~/components/Careplans/DateAndRelativeDateInput'
import { useEffect, useState } from 'react'
import { makeStyles } from 'tss-react/mui'
import { ProviderDetailWithAdditionalData } from './ProviderListItem'
import { listSteerageSteerageProvidersKey } from '~/api/SteerageService'

import { EditProviderAndAvailabilityPayload, logEvent } from '~/utils/events'
import { UpdateCuratedProvider, useEditCuratedProvider } from '~/api/UpdateProviderDetails'
import { SpecialtyGroup } from '@fireflyhealth/core'
import { useQueryClient } from 'react-query'

const DATE_FORMAT = 'YYYY-MM-DD'

export interface IProviderAvailabilityModalProps {
  provider: ProviderDetailWithAdditionalData | null
  handleCloseAvailabilityModal: () => void
  isProviderSearch: Boolean | null
  steerageId?: number | null
  isEditedInDirectory?: boolean
  specialtyGroups: SpecialtyGroup[] | null
}

const dateOffset = (dateStr: string): number => {
  const from = new Date()
  from.setHours(0, 0, 0, 0)

  const to = Moment(dateStr).toDate()
  to.setHours(0, 0, 0, 0)

  const dayDifference = differenceInDays(to, from)
  const absDayDifference = Math.abs(dayDifference)

  return absDayDifference
}

const useStyles = makeStyles()(theme => {
  return {
    modalTitle: {
      fontWeight: 500,
      fontSize: '20px',
      marginLeft: '12px',
    },
    subTitle: {
      fontSize: '16px',
      marginTop: '-4px',
      marginLeft: '12px',
    },
    selectElementStyle: {
      maxHeight: '25px',
      marginLeft: '4px',
      marginRight: '5px',
    },
  }
})

export const UpdateProviderAvailabilityModal = (props: IProviderAvailabilityModalProps) => {
  const client = useQueryClient()
  const { classes } = useStyles()

  const { mutateAsync: handleEditCuratedProvider } = useEditCuratedProvider()
  const [locationAvailabilitySelected, setLocationAvailability] = useState<boolean>(false)
  const [specialtyGroupId, setSpecialtyGroupId] = useState<number>(0)
  const [availabilityDate, setAvailabilityDate] = useState<string>('')
  const [enableSave, setEnableSave] = useState<boolean>(false)
  const [enableDelete, setEnableDelete] = useState<boolean>(false)
  const [isSpecialtyGroupSelected, setSelectedSpecialtyGroupFlag] = useState<boolean>(false)
  const [isOptionSelected, setOptionSelected] = useState<boolean>(false)
  const location =
    props?.provider?.locations && props?.provider?.locations.length >= 1
      ? props?.provider?.locations[0]
      : null
  const providerName = props?.provider?.name ?? location?.name
  const careOrgName = props?.provider?.npi ? location?.name : props?.provider?.name
  const specialtyGroups = props?.specialtyGroups
  const address = location?.addressDetails

  const [doesProviderExist, setDoesProviderExist] = useState<boolean>(
    location?.availability?.doesProviderExist ?? true
  )
  const [clearAvailability, setClearAvailability] = useState<boolean>(false)

  const createProviderAvailability = async (
    daysTillNextAvailability: number | null,
    addedOn: string | null,
    specialtyGroupId: number,
    doesProviderExist: boolean | null
  ) => {
    const curatedProviderPayload: UpdateCuratedProvider = {
      npi: props?.provider?.npi ?? null,
      careOrgName: careOrgName ?? null,
      addressLine1: address?.addressLine1 ?? null,
      addressLine2: address?.addressLine2 ? address?.addressLine2 : null,
      city: address?.city ?? null,
      state: address?.state ?? null,
      zipCode: address?.zip ?? null,
      availability: {
        numberOfDaysTillNextAvailability: daysTillNextAvailability,
        addedAt: addedOn,
        specialtyGroup: specialtyGroupId == 0 ? null : specialtyGroupId,
        doesProviderExist: clearAvailability ? true : doesProviderExist ?? null,
      },
    }
    handleEditCuratedProvider(curatedProviderPayload).then(async () => {
      if (props?.steerageId) {
        // invalidate queries for refetching the providers
        await client.cancelQueries([listSteerageSteerageProvidersKey, props?.steerageId])
        await client.invalidateQueries([listSteerageSteerageProvidersKey, props?.steerageId])
      }
      // Log availability updated for provider at a location
      logEvent<EditProviderAndAvailabilityPayload>('UPDATED_AVAILABILITY_PROVIDER_AT_A_LOCATION', {
        providerNpi: props?.provider?.npi,
        steerageId: props?.steerageId,
        isEditedInDirectory: props?.isEditedInDirectory,
        doesProviderExist: doesProviderExist ?? null,
        isClearingAvailability: !daysTillNextAvailability && !addedOn ? true : false,
        isProviderSearch: true,
      })
    })
  }

  const createCareOrgAvailability = async (
    daysTillNextAvailability: number | null,
    addedOn: string | null,
    specialtyGroupId: number,
    doesProviderExist: boolean | null
  ) => {
    // Dual write the curated provider availability information
    const curatedProviderPayload: UpdateCuratedProvider = {
      npi: props?.provider?.npi ?? null,
      careOrgName: careOrgName ?? null,
      addressLine1: address?.addressLine1 ?? null,
      addressLine2: address?.addressLine2 ? address?.addressLine2 : null,
      city: address?.city ?? null,
      state: address?.state ?? null,
      zipCode: address?.zip ?? null,
      availability: {
        numberOfDaysTillNextAvailability: daysTillNextAvailability,
        addedAt: addedOn,
        specialtyGroup: specialtyGroupId == 0 ? null : specialtyGroupId,
        doesProviderExist: clearAvailability ? true : doesProviderExist ?? null,
      },
    }
    handleEditCuratedProvider(curatedProviderPayload).then(async () => {
      if (props?.steerageId) {
        // invalidate queries for refetching the providers
        await client.cancelQueries([listSteerageSteerageProvidersKey, props?.steerageId])
        await client.invalidateQueries([listSteerageSteerageProvidersKey, props?.steerageId])
      }
      if (specialtyGroupId == 0) {
        // Log availability updated for all providers at a location
        logEvent<EditProviderAndAvailabilityPayload>(
          'UPDATED_AVAILABILITY_ALL_PROVIDER_AT_A_LOCATION',
          {
            careOrganisationName: location?.name,
            steerageId: props?.steerageId,
            isEditedInDirectory: props?.isEditedInDirectory,
            doesProviderExist: doesProviderExist ?? null,
            isClearingAvailability: !daysTillNextAvailability && !addedOn ? true : false,
            isProviderSearch: false,
          }
        )
      } else {
        // Log availability updated for all providers at a department
        logEvent<EditProviderAndAvailabilityPayload>(
          'UPDATED_AVAILABILITY_ALL_PROVIDER_AT_A_DEPARTMENT',
          {
            careOrganisationName: location?.name,
            steerageId: props?.steerageId,
            isEditedInDirectory: props?.isEditedInDirectory,
            doesProviderExist: doesProviderExist ?? null,
            isClearingAvailability: !daysTillNextAvailability && !addedOn ? true : false,
            isProviderSearch: props?.provider?.npi ? true : false,
          }
        )
      }
    })
  }

  useEffect(() => {
    // If options are selected then enable delete button
    if (isOptionSelected == true) {
      setEnableDelete(true)
    }

    // validate the required fields are not empty
    if (doesProviderExist == false && isOptionSelected == true) {
      // set specialty group if there is a single specialty group
      if (
        locationAvailabilitySelected &&
        specialtyGroups?.length == 1 &&
        isSpecialtyGroupSelected
      ) {
        setSpecialtyGroupId(specialtyGroups[0].id)
      }
      setEnableSave(true)
      return
    }

    if (availabilityDate != '' && isOptionSelected == true) {
      if (locationAvailabilitySelected && specialtyGroups && isSpecialtyGroupSelected) {
        // set specialty group if there is a single specialty group
        if (specialtyGroups.length == 1) {
          setSpecialtyGroupId(specialtyGroups[0].id)
        } else if (specialtyGroups.length > 1 && specialtyGroupId == 0) {
          // enable save only when user selects a specialty group
          setEnableSave(false)
          return
        }
      }
      setEnableSave(true)
    } else {
      setEnableSave(false)
    }
  }, [
    availabilityDate,
    locationAvailabilitySelected,
    isOptionSelected,
    isSpecialtyGroupSelected,
    specialtyGroupId,
    doesProviderExist,
  ])

  const handleSave = (daysTillNextAvailability: number | null, addedOn: string | null) => {
    if (locationAvailabilitySelected) {
      createCareOrgAvailability(
        daysTillNextAvailability,
        addedOn,
        specialtyGroupId,
        doesProviderExist ?? null
      )
    } else {
      createProviderAvailability(
        daysTillNextAvailability,
        addedOn,
        specialtyGroupId,
        doesProviderExist ?? null
      )
    }
    props.handleCloseAvailabilityModal()
  }

  const practitionerLabel = 'Only ' + providerName + ' at this location'
  const facilityLabel = 'All providers at this location'
  const primaryEntityLabel = props.isProviderSearch == true ? practitionerLabel : facilityLabel

  const practitionerSearchAllProviderLabel = 'All providers at this location'
  const selectSpecialtyLabel = 'selected specialty'

  const addSpecialtyEditingOption =
    props.isProviderSearch == true &&
    careOrgName != null &&
    specialtyGroups != null &&
    specialtyGroups.length > 0

  const handleAvailabilityChoice = event => {
    if (isOptionSelected == false) {
      setOptionSelected(true)
    }
    if (event.target.value == practitionerLabel) {
      setLocationAvailability(false)
      return
    }
    if (event.target.value.toString() == selectSpecialtyLabel.toString()) {
      setSelectedSpecialtyGroupFlag(true)
    }

    // if all providers are selected set the specialty group to null or zero
    if (event.target.value == practitionerSearchAllProviderLabel) {
      setSelectedSpecialtyGroupFlag(false)
      setSpecialtyGroupId(0)
    }

    setLocationAvailability(true)
  }

  const handleNotAcceptingPatientsOnchange = event => {
    setDoesProviderExist(!event.target.checked)

    if (doesProviderExist) {
      // disable and clear the availability input
      setAvailabilityDate('')
    }
  }

  const handleClearAvailabilityModal = () => {
    setClearAvailability(true)
    // Log clear availability button click
    logEvent<EditProviderAndAvailabilityPayload>('OPEN_MODAL_FOR_CLEARING_AVAILABILITY_CLICK', {
      providerNpi: props?.provider?.npi,
      steerageId: props?.steerageId,
      isEditedInDirectory: props?.isEditedInDirectory,
      doesProviderExist: null,
      isClearingAvailability: true,
      isProviderSearch: props?.provider?.npi ? true : false,
    })
  }
  return (
    <Box>
      {!clearAvailability && (
        <>
          <Box display="flex" justifyContent="space-between">
            <Typography className={classes.modalTitle}>Edit Availability</Typography>
            <Box>
              <IconButton
                style={{ padding: '0px' }}
                onClick={handleClearAvailabilityModal}
                size="small"
              >
                <DeleteIcon />
              </IconButton>
              <IconButton
                style={{ padding: '5px' }}
                onClick={props.handleCloseAvailabilityModal}
                size="small"
              >
                <ClearIcon />
              </IconButton>
            </Box>
          </Box>
          <Typography className={classes.subTitle} mr={4}>
            {providerName}
          </Typography>
          <Box mt={1} m={2}>
            <Typography>Next available appointment</Typography>
            <DateAndRelativeDateInput
              value={availabilityDate}
              onChange={dateString => setAvailabilityDate(dateString)}
              disabled={!doesProviderExist}
              minDate={Moment().format('YYYY-MM-DD')}
            />
          </Box>
          <Box m={2}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={!doesProviderExist}
                  onChange={handleNotAcceptingPatientsOnchange}
                  name="doesProviderExist"
                />
              }
              label="Provider doesn’t exist at this location"
            />
          </Box>
        </>
      )}
      <Box m={2} mt={3}>
        <FormControl>
          {!clearAvailability ? (
            <Typography>Edit availability for:</Typography>
          ) : (
            <Box display="flex" justifyContent="space-between">
              <Typography sx={{ fontWeight: 500 }}>Delete existing availability for:</Typography>
              <Box ml={2}>
                <IconButton
                  style={{ padding: '5px' }}
                  onClick={props.handleCloseAvailabilityModal}
                  size="small"
                >
                  <ClearIcon />
                </IconButton>
              </Box>
            </Box>
          )}
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            name="radio-buttons-group"
            onChange={handleAvailabilityChoice}
          >
            {/* Add a primary-edition option, which is either the provider for provider
                search or all providers at the location for facility search. Everyone
                gets a first option! */}
            <FormControlLabel
              value={primaryEntityLabel}
              control={<Radio />}
              label={primaryEntityLabel}
            />
            {/* Add a Specialty-editing option, if possible */}
            {addSpecialtyEditingOption ? (
              <FormControlLabel
                value={selectSpecialtyLabel}
                control={<Radio />}
                label={
                  <Grid container direction={'row'} spacing={0.5}>
                    <Grid item>All</Grid>
                    {specialtyGroups.length > 1 ? (
                      <Grid item>
                        <Select
                          variant="standard"
                          className={classes.selectElementStyle}
                          onChange={e => setSpecialtyGroupId(e.target.value as number)}
                        >
                          {specialtyGroups.map((group, i) => (
                            <MenuItem value={group.id} key={i}>
                              {group.label}
                            </MenuItem>
                          ))}
                        </Select>
                      </Grid>
                    ) : (
                      <Grid item>{specialtyGroups[0].label}</Grid>
                    )}
                    <Grid item>providers</Grid>
                    <Grid item> at</Grid>
                    <Grid item> this</Grid>
                    <Grid item> location</Grid>
                  </Grid>
                }
              />
            ) : (
              ''
            )}
          </RadioGroup>
        </FormControl>
      </Box>
      {clearAvailability ? (
        <Box my={1} mx={2} display="flex" justifyContent="flex-end">
          <Button onClick={() => setClearAvailability(false)}>
            <Typography>Cancel</Typography>
          </Button>
          <Button
            variant="contained"
            color="primary"
            disabled={!enableDelete}
            onClick={() => handleSave(null, null)}
          >
            <Typography>Delete</Typography>
          </Button>
        </Box>
      ) : (
        <Box my={1} mx={2} display="flex" justifyContent="flex-end">
          <Button onClick={props.handleCloseAvailabilityModal}>
            <Typography>Cancel</Typography>
          </Button>
          <Button
            variant="contained"
            color="primary"
            disabled={!enableSave}
            onClick={() =>
              handleSave(dateOffset(availabilityDate), format(new Date(), DATE_FORMAT))
            }
          >
            <Typography>Save</Typography>
          </Button>
        </Box>
      )}
    </Box>
  )
}
