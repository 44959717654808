import { useState } from 'react'
import type { FC } from 'react'
import Collapse from '@material-ui/core/Collapse'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { useTheme } from '@material-ui/core'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'
import ExpandMore from '@material-ui/icons/ExpandMore'
import ExpandLess from '@material-ui/icons/ExpandLess'
import Typography from '@material-ui/core/Typography'
import FileUpload from '../../Chat/ChatMessageUpload/FileUpload'
import axiosRequest from '~/utils/axios'
import Loader from '../../Loader'
import FFModal from '~/components/FFModal/FFModal'
import { queueNotification } from '~/redux/actions/notifications'
import { batch, useDispatch } from 'react-redux'
import { patientsSlice } from '~/redux/slices/patients'

interface EditPatientInsuranceCardProps {
  // TODO: Update js-core to 0.0.25
  patient: any
}

export const EditPatientInsuranceCard: FC<EditPatientInsuranceCardProps> = props => {
  const styles = useStyles()
  const dispatch = useDispatch()
  const { palette } = useTheme()
  const [insuranceFrontImage, setInsuranceFrontImage] = useState<any>(
    props.patient.person?.insuranceInfo?.imageFront
  )
  const [insuranceBackImage, setInsuranceBackImage] = useState<any>(
    props.patient.person?.insuranceInfo?.imageBack
  )
  const [uploadedInsuranceFrontImage, setUploadedInsuranceFrontImage] = useState<any>(null)
  const [uploadedInsuranceBackImage, setUploadedInsuranceBackImage] = useState<any>(null)
  const [uploadKey, setUploadKey] = useState<number>(0)
  const [isInsuranceFrontImageChanged, setIsInsuranceFrontImageChanged] = useState<boolean>(false)
  const [isInsuranceBackImageChanged, setIsInsuranceBackImageChanged] = useState<boolean>(false)
  const [isUpdatingInsuranceImage, setIsUpdatingInsuranceImage] = useState<boolean>(false)
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false)
  const [visible, setVisible] = useState<boolean>(false)
  const uploadFile = async () => {
    setIsUpdatingInsuranceImage(true)
    let user: any
    try {
      const formData = new FormData()
      if (
        uploadedInsuranceFrontImage != null &&
        uploadedInsuranceFrontImage !== insuranceFrontImage
      ) {
        formData.append('image_front', (uploadedInsuranceFrontImage as any)!.data)
      }
      if (uploadedInsuranceBackImage != null && uploadedInsuranceBackImage !== insuranceBackImage) {
        formData.append('image_back', (uploadedInsuranceBackImage as any)!.data)
      }
      user = await axiosRequest(
        'POST',
        'multipart/formdata',
        true,
        `/user/${props.patient.id}/coverage/submit_insurance_info/`,
        formData
      )
      // Update the images
      setInsuranceFrontImage(user.data.person.insuranceInfo.imageFront)
      setInsuranceBackImage(user.data.person.insuranceInfo.imageBack)
      batch(() => {
        dispatch(patientsSlice.thunks.getPatient(props.patient.id))
        dispatch(
          queueNotification({
            variant: 'success',
            message: 'Updated insurance card',
          })
        )
      })
    } catch (e) {
      console.error(e)
      dispatch(
        queueNotification({
          variant: 'error',
          message: `Failed to update insurance card`,
        })
      )
    } finally {
      // reset the values for the upload widget
      setUploadedInsuranceFrontImage(null)
      setUploadedInsuranceBackImage(null)
      setIsInsuranceFrontImageChanged(false)
      setIsInsuranceBackImageChanged(false)
      setUploadKey(uploadKey + 1)
      setIsUpdatingInsuranceImage(false)
      setIsModalOpen(false)
    }
  }
  const openModal = () => {
    setIsModalOpen(true)
  }
  return (
    <>
      <Box display="flex" alignItems="center">
        <Typography variant="h6" color="inherit">
          Insurance Card
        </Typography>
        <Box ml={1}>
          <IconButton onClick={() => setVisible(!visible)}>
            {visible ? <ExpandLess /> : <ExpandMore />}
          </IconButton>
        </Box>
      </Box>
      {
        <Collapse in={visible}>
          <Box display="flex" mt={1} flexDirection="column">
            <Box display="flex" mt={1} mb={2}>
              <Box
                key="front"
                flex={1}
                p={1}
                mr="1"
                border={`0.1rem solid ${palette.grey[200]}`}
                borderRadius={8}
              >
                <Typography>Insurance Card Front</Typography>
                {insuranceFrontImage && (
                  <a href={insuranceFrontImage} target="_blank" rel="noopener noreferrer">
                    <img
                      className={styles.image}
                      src={insuranceFrontImage}
                      alt="Insurance Card Front"
                    />
                  </a>
                )}
                <Box>
                  <FileUpload
                    file={uploadedInsuranceFrontImage}
                    key={uploadKey}
                    handleFile={fileWithType => {
                      setIsInsuranceFrontImageChanged(true)
                      setUploadedInsuranceFrontImage(fileWithType)
                    }}
                    handleClear={() => {
                      setIsInsuranceFrontImageChanged(false)
                      setInsuranceFrontImage(props.patient.person?.insuranceInfo?.imageFront)
                      setUploadedInsuranceFrontImage(null)
                      setUploadKey(uploadKey + 1)
                    }}
                    supportedImageTypes={['image/jpeg', 'image/png', 'image/tiff']}
                    supportedDocTypes={[]}
                    supportMessage="Supports JPG, PNG, or TIFF"
                  />
                  {isInsuranceFrontImageChanged && uploadedInsuranceFrontImage && (
                    <Typography>{(uploadedInsuranceFrontImage as any)!.data.name}</Typography>
                  )}
                </Box>
              </Box>
              <Box
                key="back"
                flex={1}
                p={1}
                mr="0"
                border={`0.1rem solid ${palette.grey[200]}`}
                borderRadius={8}
              >
                <Typography>Insurance Card Back</Typography>
                {insuranceBackImage && (
                  <a href={insuranceBackImage} target="_blank" rel="noopener noreferrer">
                    <img
                      className={styles.image}
                      src={insuranceBackImage}
                      alt="Insurance Card Back"
                    />
                  </a>
                )}
                <Box>
                  <FileUpload
                    file={uploadedInsuranceBackImage}
                    key={uploadKey}
                    handleFile={fileWithType => {
                      setIsInsuranceBackImageChanged(true)
                      setUploadedInsuranceBackImage(fileWithType)
                    }}
                    handleClear={() => {
                      setIsInsuranceBackImageChanged(false)
                      setInsuranceBackImage(props.patient.person?.insuranceInfo?.imageBack)
                      setUploadedInsuranceBackImage(null)
                      setUploadKey(uploadKey + 1)
                    }}
                    supportedImageTypes={['image/jpeg', 'image/png', 'image/tiff']}
                    supportedDocTypes={[]}
                    supportMessage="Supports JPG, PNG, or TIFF"
                  />
                  {isInsuranceBackImageChanged && uploadedInsuranceBackImage && (
                    <Typography>{(uploadedInsuranceBackImage as any)!.data.name}</Typography>
                  )}
                </Box>
              </Box>
            </Box>
            <Grid container spacing={1} justify="flex-end">
              <Button
                color="primary"
                onClick={openModal}
                variant="outlined"
                disabled={
                  !isInsuranceFrontImageChanged ||
                  !isInsuranceBackImageChanged ||
                  isUpdatingInsuranceImage
                }
              >
                {isUpdatingInsuranceImage ? <Loader inline /> : 'Update'}
              </Button>
            </Grid>
            {!isModalOpen ? null : (
              <FFModal
                open={true}
                header={
                  <Box m={2}>
                    <Typography variant="h5">Update Insurance Card Images</Typography>
                  </Box>
                }
                footer={
                  <Box m={2} display="flex" justifyContent="flex-end">
                    <Button
                      onClick={() => setIsModalOpen(false)}
                      disabled={isUpdatingInsuranceImage}
                    >
                      Cancel
                    </Button>
                  </Box>
                }
              >
                <>
                  <Box m={2}>
                    <Typography>
                      Are you sure you want to update the insurance cards? After the update,
                      eligibility checks will automatically run on the new images.
                    </Typography>
                  </Box>
                  <Box m={2}>
                    <Button
                      onClick={uploadFile}
                      disabled={isUpdatingInsuranceImage}
                      variant="contained"
                    >
                      Yes, Update
                    </Button>
                  </Box>
                </>
              </FFModal>
            )}
          </Box>
        </Collapse>
      }
    </>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  image: {
    width: '100%',
    height: 'auto',
    borderRadius: theme.spacing(),
  },
}))

export default EditPatientInsuranceCard
