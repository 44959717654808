import { useEffect, useState } from 'react'
import type { FC } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Box, Button, Typography } from '@material-ui/core'
import { User, usePromise } from '@fireflyhealth/core'
import { Formik } from 'formik'
import Select from 'react-select'
import Loader from '~/components/Loader'
import { getSettings, updateSettings } from '~/redux/slices/settings/actions'
import { apiClient } from '~/api/rest'
import CheckBox from '../CheckBox/CheckBox'
import { queueNotification } from '~/redux/actions/notifications'

const EMPTY_CHOICE = {
  firstName: 'No',
  lastName: 'Guide',
  id: '', // value prop can't be null
}

const getGuideValue = guide => guide.id
const getGuideLabel = guide => `${guide.firstName} ${guide.lastName}`

export const OnboardingGuideSettings: FC<any> = () => {
  const [onboardingGuides, setOnboardingGuides] = useState<User[]>([])
  const [notificationSettings, setNotificationSettings] = useState({})
  const settingsState = useSelector((state: any) => state.settings)
  const dispatch = useDispatch()

  const { initialized: settingStateInitialized, serverSettings } = settingsState

  const { loading } = usePromise(async () => {
    try {
      dispatch(getSettings())
      const guides = await apiClient.settings.getOnboardingGuides()
      guides.unshift(EMPTY_CHOICE)
      setOnboardingGuides(guides)
    } catch (e) {
      console.error(e)
    }
  })

  useEffect(() => {
    if (serverSettings.onboardingGuideNotifications) {
      setNotificationSettings(serverSettings.onboardingGuideNotifications)
    }
  }, [serverSettings.onboardingGuideNotifications])

  const onSubmitOnboardingGuide = ({ onboardingGuide }) => {
    dispatch(
      updateSettings({
        onboardingGuide: onboardingGuide ? onboardingGuide.id : null,
      })
    )
  }

  const toggleNotificationSetting = key => {
    setNotificationSettings({
      ...notificationSettings,
      [key]: {
        ...notificationSettings[key],
        value: !notificationSettings[key].value,
      },
    })
  }

  const onSubmitNotificationSettings = async () => {
    try {
      const newSettings = {}
      Object.keys(notificationSettings).map(key => {
        newSettings[notificationSettings[key].key] = !!notificationSettings[key].value
      })
      dispatch(updateSettings({ onboarding_guide_notifications: newSettings }))
      dispatch(
        queueNotification({
          variant: 'success',
          message: 'Notification settings updated',
        })
      )
    } catch {
      dispatch(
        queueNotification({
          variant: 'error',
          message: 'Error setting notification settings',
        })
      )
    }
  }

  if (loading || !settingStateInitialized || !notificationSettings) return <Loader />

  return (
    <>
      <Box m={2}>
        <Typography variant="h6">Onboarding Guide</Typography>
        <Formik initialValues={serverSettings} onSubmit={onSubmitOnboardingGuide}>
          {formik => (
            <form onSubmit={formik.handleSubmit}>
              <Box my={1}>
                <Select
                  name="onboardingGuide"
                  options={onboardingGuides}
                  getOptionLabel={getGuideLabel}
                  getOptionValue={getGuideValue}
                  onChange={value => formik.setFieldValue('onboardingGuide', value)}
                  value={formik.values.onboardingGuide ?? EMPTY_CHOICE}
                />
              </Box>
              <Box my={1}>
                <Button type="submit" color="primary" variant="contained">
                  Save Onboarding Guide
                </Button>
              </Box>
            </form>
          )}
        </Formik>
      </Box>
      <Box m={2}>
        <Typography variant="h6">Notification Settings</Typography>
        {Object.keys(notificationSettings).map(key => {
          return (
            <Box key={key} display="flex">
              <CheckBox
                value={notificationSettings[key].value}
                onChecked={() => toggleNotificationSetting(key)}
              />
              <Typography>{notificationSettings[key].description}</Typography>
            </Box>
          )
        })}
        <Box my={1}>
          <Button
            type="submit"
            color="primary"
            variant="contained"
            onClick={onSubmitNotificationSettings}
          >
            Save Notification Settings
          </Button>
        </Box>
      </Box>
    </>
  )
}

export default OnboardingGuideSettings
