import { apiClient, partialJsonParamSerializer } from '~/api/rest'
import { useQuery, useMutation } from '~/components/Providers/ApiProvider'
import { useQueryClient } from 'react-query'
import { AssigneeGroup } from '@fireflyhealth/core'
import { setDueDateToEOD } from '~/utils/date'
import { WorkUnit } from '../models/WorkUnit'
import { WorklistWorkUnit } from '../models/PatientTodoItem'
import { useMemo } from 'react'
import { keyBy } from 'lodash'

export const getWorkLists = async () => {
  const data = await apiClient.rest.get(`/worklists/`)
  return data
}

export const listWorkListKey = 'listWorkList'
export const useWorkLists = () => {
  const { data, isLoading } = useQuery(
    [listWorkListKey],
    () => getWorkLists(),
    {},
    {
      error: 'Failed to fetch worklists',
    }
  )
  return { result: data, isLoading }
}

export const useWorkListsById = () => {
  const { result: workLists, isLoading } = useWorkLists()
  const result = useMemo(() => {
    return keyBy(workLists, workList => workList.id)
  }, [workLists, isLoading])

  return { result, isLoading }
}

async function listAssigneeGroup(): Promise<AssigneeGroup[]> {
  const data = await apiClient.rest.get<AssigneeGroup[]>('/user/assignee-group/')
  return data
}

const listAssigneeGroupKey = 'listAssigneeGroup'
export const useListAssigneeGroup = () => {
  const { data, isLoading } = useQuery(
    [listAssigneeGroupKey],
    listAssigneeGroup,
    {
      staleTime: Infinity,
    },
    {
      error: 'Failed to fetch Assignee groups',
    }
  )

  return { result: data || [], isLoading }
}

export const getWorkUnitItems = async (worklistId: number | undefined, options: any) => {
  if (worklistId) {
    const { data } = await apiClient.rest.http.request({
      url: `/providers/me/work_units/worklists/${worklistId}/`,
      method: 'GET',
      params: options,
      paramsSerializer: partialJsonParamSerializer('filters', 'sort'),
    })

    const workUnitData = data.results ? (data.results as WorkUnit[]) : []
    let workUnits: WorklistWorkUnit[] = workUnitData.map(workUnit => {
      return {
        ...workUnit,
        worklist: worklistId,
        id: workUnit.contentObject.id,
        pk: workUnit.contentObject.id,
      }
    })
    return {
      items: workUnits,
      count: data.count || 0,
    }
  }
}

export const getWorkUnitItemsKey = 'getWorkUnitItems'
export const useWorkUnitItems = (id: number | undefined, options: any) => {
  const { data, isLoading } = useQuery(
    [getWorkUnitItemsKey, id, options],
    () => getWorkUnitItems(id, options),
    {},
    {
      error: 'Failed to fetch worklists',
    }
  )
  return { result: data, isLoading }
}

const updateWorkUnitItem = (workUnit: Partial<WorklistWorkUnit>) => {
  return apiClient.rest.patch<WorkUnit>(
    `/providers/me/work_units/${workUnit.id}/${workUnit.contentType}/`,
    {
      dueDate: workUnit.dueDate ? setDueDateToEOD(workUnit.dueDate) : undefined,
      ...(workUnit.owner ? { owner: workUnit.owner } : { ownerGroup: workUnit.ownerGroup }),
      action: workUnit.action,
    }
  )
}

export const useUpdateWorkUnitItem = () => {
  const client = useQueryClient()

  return useMutation(
    updateWorkUnitItem,
    {
      onSuccess: async () => {
        await client.cancelQueries([getWorkUnitItemsKey])
        await client.invalidateQueries([getWorkUnitItemsKey])
      },
    },
    { error: 'Failed to update work unit' }
  )
}
