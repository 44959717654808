import { keyBy } from 'lodash/fp'
import { createSlice } from '@reduxjs/toolkit'
import { CrudResource } from '@fireflyhealth/core'

import { queueNotification } from '~/redux/actions/notifications'

export interface CrudSliceState<T> {
  byId: {
    [key: number]: T
  }
  loadedAll: boolean
}

interface CrudSliceOptions<T, S> {
  // Transform to apply to API data before storing in state.
  transform?: (data: T) => S

  // Any extra reducers supplied to the slice creator.
  extraReducers?: any
}

export const crudSlice = <T, S = T>(
  name,
  resource: CrudResource<T>,
  options: CrudSliceOptions<T, S> = {}
) => {
  const transform = options.transform || ((x: T) => x as any as S) // TODO: Hack and needs better typing.

  const slice = createSlice({
    name,
    initialState: { byId: {}, loadedAll: false } as CrudSliceState<S>,
    reducers: {
      getAllSuccess: (state, { payload }) => {
        state.byId = keyBy('id', payload)
        state.loadedAll = true
      },
      getSuccess: (state, { payload }) => {
        state.byId[payload.id] = payload
      },
      createSuccess: (state, { payload }) => {
        state.byId[payload.id] = payload
      },
      updateSuccess: (state, { payload }) => {
        state.byId[payload.id] = payload
      },
      deleteSuccess: (state, { payload: id }) => {
        if (state.byId[id]) {
          delete state.byId[id]
        }
      },
    },
    extraReducers: options.extraReducers || {},
  })

  const thunks = {
    getAll: (params?: any) => {
      return async dispatch => {
        try {
          let data = await resource.getAll(params)

          // TODO: This is a hack around endpoints which use pagination.
          if ((data as any).results) data = (data as any).results

          const transformedData = data.map(transform)
          return dispatch(slice.actions.getAllSuccess(transformedData))
        } catch (error) {
          console.log(error)
          dispatch(
            queueNotification({
              variant: 'error',
              message: `${name}: Error fetching all.`,
            })
          )
        }
      }
    },
    get: (id: number | string) => {
      return async dispatch => {
        try {
          const data = await resource.get(id)
          return dispatch(slice.actions.getSuccess(data))
        } catch (error) {
          console.log(error)
          dispatch(
            queueNotification({
              variant: 'error',
              message: `${name}: Error fetching ${id}`,
            })
          )
        }
      }
    },
    create: (data: T) => {
      return async dispatch => {
        try {
          const createdData = await resource.create(data)
          return dispatch(slice.actions.createSuccess(transform(createdData)))
        } catch (error) {
          console.log(error)
          dispatch(
            queueNotification({
              variant: 'error',
              message: `${name}: Error creating.`,
            })
          )
        }
      }
    },
    update: (id: number | string, data: Partial<T>) => {
      return async dispatch => {
        try {
          const createdData = await resource.update(id, data)

          return dispatch(slice.actions.updateSuccess(transform(createdData)))
        } catch (error) {
          console.log(error)
          dispatch(
            queueNotification({
              variant: 'error',
              message: `${name}: Error updating ${id}.`,
            })
          )
        }
      }
    },

    delete: (id: number | string) => {
      return async dispatch => {
        try {
          await resource.delete(id)
          return dispatch(slice.actions.deleteSuccess(id))
        } catch (error) {
          console.log(error)
          dispatch(
            queueNotification({
              variant: 'error',
              message: `${name}: Error deleting ${id}.`,
            })
          )
        }
      }
    },
  }

  return Object.assign(slice, { thunks })
}
