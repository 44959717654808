import { useEffect } from 'react'
import { Box, Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { apiClient } from '~/api/rest'
import { useMutation } from '@fireflyhealth/core'
import { useDispatch } from 'react-redux'
import { queueNotification } from '~/redux/actions/notifications'
import { ITask } from '~/models/Task'
import Moment from 'moment'

interface ICreateFromTemplateActionsProps {
  closeModal: () => void
  patient: any
  me: any
  handleModalAction: (any) => void
  tasks: ITask[]
  readyToSaveFromTemplateId: null | number
  saving: boolean
  setSaving: (arg0: boolean) => void
  allTemplateTasksComplete: boolean
  title: string
  notes: string
  previewData: any
}

const CreateFromTemplateActions = (props: ICreateFromTemplateActionsProps) => {
  const {
    tasks,
    readyToSaveFromTemplateId,
    saving,
    setSaving,
    allTemplateTasksComplete,
    previewData,
  } = props

  const dispatch = useDispatch()

  const {
    handler: assignTemplate,
    loading: assignTemplateIsLoading,
    error: assignTemplateError,
    result: createdCarePlan,
  } = useMutation(tasks =>
    apiClient.rest.post(`/care-plan/templates/${readyToSaveFromTemplateId}/assign/`, {
      user_id: props.patient.id,
      tasks,
      title: props.title,
      notes: props.notes,
    })
  )

  useEffect(() => {
    if (assignTemplateError) {
      dispatch(queueNotification({ variant: 'error', message: assignTemplateError.data?.error }))
    }
  }, [assignTemplateError])

  useEffect(() => {
    if (createdCarePlan) {
      props.handleModalAction(createdCarePlan)
      dispatch(queueNotification({ variant: 'success', message: 'Created care plan' }))
      props.closeModal()
    }
  }, [createdCarePlan])

  useEffect(() => {
    setSaving(Boolean(assignTemplateIsLoading))
  }, [assignTemplateIsLoading])

  const saveDisabled = () =>
    saving || assignTemplateIsLoading || !readyToSaveFromTemplateId || !allTemplateTasksComplete

  const handleSave = async () => {
    if (saveDisabled()) return
    const tasksPayload = tasks.map(task => {
      return {
        ...task,
        dueDate: Moment(task.dueDate, 'YYYY-MM-DD').format(),
      }
    })
    try {
      if (!previewData.taskPreviews || !previewData.taskPreviews.length) await assignTemplate([])
      else await assignTemplate(tasksPayload)
    } catch (e) {
      console.error(e)
    }
  }
  const classes = useStyles()

  return (
    <Box m={2} display="flex" justifyContent="flex-end">
      <Button
        onClick={props.closeModal}
        disabled={saving}
        className={`${classes.formSubmissionButton} ${classes.formSubmissionCancel}`}
      >
        Cancel
      </Button>
      <Button
        disabled={saveDisabled()}
        className={`${classes.formSubmissionButton} ${classes.formSubmissionSave}`}
        color="primary"
        variant="contained"
        onClick={handleSave}
      >
        Save
      </Button>
    </Box>
  )
}

const useStyles = makeStyles(theme => ({
  formSubmissionButton: {
    paddingLeft: '1em',
    paddingRight: '1em',
  },
  formSubmissionCancel: {
    marginRight: theme.spacing(),
  },
  formSubmissionSave: {
    boxShadow: 'none',
  },
}))

export default CreateFromTemplateActions
