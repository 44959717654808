import { useEffect, useState } from 'react'
import type { FC } from 'react'
import {
  Box,
  Typography,
  Grid,
  Chip,
  Switch,
  TextField,
  List,
  ListItem,
  ListSubheader,
  ListItemText,
} from '@mui/material'
import Moment from 'moment'
import { useFormik } from 'formik'
import EditField from '~/components/Generic/EditField'
import Loader from '~/components/Loader'
import Autocomplete from '@mui/material/Autocomplete'
import { Service, SpecialtyDropdownOption, Steerage, SteerageProviderStatus } from './types'
import {
  useSpecialtyGroups,
  useLocationTypes,
  useEditSteerage,
  useCarePassServiceCategories,
  EditSteerageInterface,
  useSteerageProviders,
  useToggleSteerageLock,
  ToggleSteerageLockInterface,
  useEditInsuranceAuthProvider,
  useAddInsuranceAuthProvider,
  useServices,
} from '~/api/SteerageService'
import SteerageReadOnlyView from './SteerageReadOnlyView'
import InsuranceAuthProviderForm from './InsuranceAuthProviderForm'
import { EditInsuranceAuthProviderInterface } from '../../../api/SteerageService'
import SteerageProviderList from './SteerageProviderList'
import { logEvent, SteerageEventPayload } from '~/utils/events'
import ServiceAutocomplete, { ServiceValueType } from './LastMileServiceAutocomplete'

export interface SteerageProps {
  steerage: Steerage
}

export const getSteerageType = (steerage: Steerage) => {
  // We need to show the type of steerage
  if (steerage.isEffectivelyReferralAndWaiver) return 'Referral w/Care Pass'
  if (steerage.isEffectivelyReferralOnly) return 'Referral'
  if (steerage.isEffectivelyWaiverOnly) return 'Care Pass'
  return ''
}

const SteerageView: FC<SteerageProps> = props => {
  const { data: steerageProviderList } = useSteerageProviders(props.steerage.id)
  const { data: serviceCategories } = useCarePassServiceCategories(props.steerage.person)
  const { data: specialtyGroups } = useSpecialtyGroups()
  const { data: locationTypes } = useLocationTypes()
  const { data: lastMileServices } = useServices()
  const [fullSpecialtyList, setFullSpecialtyList] = useState<SpecialtyDropdownOption[]>([])
  const { isLoading: isEditingSteerage, mutateAsync: handleEditSteerage } = useEditSteerage()
  const { isLoading: isTogglingSteerage, mutateAsync: toggleSteerageLock } = useToggleSteerageLock()
  const {
    isLoading: isEditingInsuranceAuthProvider,
    mutateAsync: handleEditInsuranceAuthProvider,
  } = useEditInsuranceAuthProvider()
  const { isLoading: isAddingInsuranceAuthProvider, mutateAsync: handleAddInsuranceAuthProvider } =
    useAddInsuranceAuthProvider()
  const [errorEffectiveThrough, setErrorEffectiveThrough] = useState<boolean>(false)
  const [errorServiceQuantity, setErrorServiceQuantity] = useState<boolean>(false)
  const [hasSteerageFormValid, setHasSteerageFormValid] = useState<boolean>(false)

  // For displaying and storing last mile service category
  const [lastMileServicesList, setLastMileServicesList] = useState<Service[]>([])
  const [selectedLastMileServices, setSelectedLastMileServices] = useState<Service[]>(
    props?.steerage?.referral?.lastMileServices ?? []
  )
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const isReferralPresent = props.steerage.hasReferral
  const isCarePassPresent = props.steerage.hasWaiver
  const isEffectivelyReferralOnly = props.steerage.isEffectivelyReferralOnly
  const isEffectivelyWaiverOnly = props.steerage.isEffectivelyWaiverOnly
  const isEffectivelyReferralAndWaiver = props.steerage.isEffectivelyReferralAndWaiver

  // When there are providers automatically added to a Steerage, they should
  // only render in this list if they have been approved by a navigator.
  const filteredSteerageProviderList = (steerageProviderList ?? []).filter(p => {
    return p.status === SteerageProviderStatus.Accepted
  })

  useEffect(() => {
    setIsLoading(isEditingSteerage || isTogglingSteerage)
  }, [isEditingSteerage, isTogglingSteerage])

  useEffect(() => {
    // If the care pass is changed then set error as blank
    setErrorEffectiveThrough(false)
    setErrorServiceQuantity(false)
  }, [props.steerage])

  useEffect(() => {
    if (lastMileServices) {
      setLastMileServicesList(lastMileServices)
    }
  }, [lastMileServices])

  useEffect(() => {
    setHasSteerageFormValid(IsSteerageFormValid(formik.values))
  }, [selectedLastMileServices])

  useEffect(() => {
    // log when the steerage case is opened
    logEvent<SteerageEventPayload>('STEERAGE_CASE_ENTRY', {
      steerageId: props.steerage.id,
    })
    return () => {
      // log when user navigates away from the steerage case
      logEvent<SteerageEventPayload>('STEERAGE_CASE_EXIT', {
        steerageId: props.steerage.id,
      })
    }
  }, [])

  // The Specialty dropdown in the UI is created as a merged list of two different possible
  // input sources: SpecialtyGroup or LocationType. We handle this by creating an array,
  // fullSpecialtyList, which holds all possible options for the dropdown, each of which
  // holding not only the label that should show to the user, but which of these two Source
  // Objects it comes from and the ID. Within the Form, the autocomplete's value is a simple
  // string, which is the label of the selection. Upon save, we match that value back to one
  // of the options in the full list. Below, we populate the full list of options.
  useEffect(() => {
    if (!locationTypes) {
      return
    }
    if (!specialtyGroups) {
      return
    }
    let allOptions: SpecialtyDropdownOption[] = []
    for (const specialtyGroup of specialtyGroups) {
      const option: SpecialtyDropdownOption = {
        id: specialtyGroup.id,
        label: specialtyGroup.label,
        source: 'SpecialtyGroup',
      }
      allOptions.push(option)
    }
    for (const locationType of locationTypes) {
      // There are some Location Types that duplicate Specialties. From an analytics perspective,
      // we don't care which is utilized for selection, so we are arbitrarily going to use the
      // Specialty version. This is necessary so that the list doesn't have two elements with the
      // same Label.
      const labelExists = allOptions.find(option => option.label == locationType.label)
      if (labelExists) {
        continue
      }
      const option: SpecialtyDropdownOption = {
        id: locationType.id,
        label: locationType.label,
        source: 'LocationType',
      }
      allOptions.push(option)
    }
    allOptions.sort((a, b) => a.label.localeCompare(b.label))
    setFullSpecialtyList(allOptions)
  }, [locationTypes, specialtyGroups])

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      description: props.steerage.description ?? '',
      effectiveFrom: props.steerage.effectiveFrom ? props.steerage.effectiveFrom : '',
      effectiveThrough: props.steerage.effectiveThrough ? props.steerage.effectiveThrough : '',
      isWaiverIncluded: props.steerage.isWaiverIncluded ?? true,
      insuranceAuthorizationRequired:
        props.steerage.referral?.insuranceAuthorizationRequired ?? false,
      insuranceAuthorizationStatus:
        props.steerage.referral?.referralInsuranceAuthorization?.status ?? '',
      insuranceAuthorizationNumber:
        props.steerage.referral?.referralInsuranceAuthorization?.authorizationNumber ?? '',
      referralInsuranceAuthUnstructuredIcdCode:
        props.steerage.referral?.referralInsuranceAuthUnstructuredIcdCode?.icdCodeData ?? '',
      referralInsuranceAuthIcdCodes: props.steerage.referral?.referralInsuranceAuthIcdCodes,
      isLastMile: props.steerage.referral?.isLastMile ?? false,
      leakageReason: props.steerage.referral?.leakageReason ?? '',
      requestType: props.steerage.requestType ?? '',
      schedulingDate: props.steerage.schedulingDate ?? '',
      serviceQuantityUnit: props.steerage.serviceQuantity?.unit ?? '',
      serviceQuantityValue: props.steerage.serviceQuantity?.value ?? '',
      specialtyListOption:
        fullSpecialtyList?.find(
          specialtyOption =>
            (specialtyOption?.id == props.steerage.specialtyGroup &&
              specialtyOption.source == 'SpecialtyGroup') ||
            (specialtyOption?.id == props.steerage.locationType &&
              specialtyOption.source == 'LocationType')
        )?.label ?? '',
      // this field is used only for manually created care passes
      // which can have only one service category
      serviceCategory:
        props.steerage.waiver?.priorAuthorization == null
          ? props.steerage.waiver?.serviceCategories?.[0]?.label
          : null,
      useReferralProvider:
        props?.steerage?.referral?.referralInsuranceAuthorization?.useReferralProvider ?? true,
      typeOfVisit: props.steerage.typeOfVisit ?? '',
      visitNotePriority: props.steerage.referral?.visitNotePriority ?? '',
    },
    onSubmit: async values => {
      // validate the care pass data only when care pass is not locked
      if (!validateOnSaveDetails() && !props?.steerage?.isLocked) return

      let selectedSpecialtyOption = fullSpecialtyList?.find(
        specialtyOption => specialtyOption?.label == values.specialtyListOption
      )

      let payload: EditSteerageInterface = {
        effectiveFrom: values.effectiveFrom != '' ? values.effectiveFrom : null,
        effectiveThrough: values.effectiveThrough != '' ? values.effectiveThrough : null,
        description: values.description ?? null,
        isWaiverIncluded: values['isWaiverIncluded'],
        requestType: values.requestType ?? null,
        schedulingDate: values.schedulingDate != '' ? values.schedulingDate : null,
        serviceQuantity: {
          value: values['serviceQuantityValue'] != '' ? values['serviceQuantityValue'] : null,
          unit: values['serviceQuantityUnit'] != '' ? values['serviceQuantityUnit'] : null,
        },
        specialtyGroup:
          selectedSpecialtyOption && selectedSpecialtyOption.source == 'SpecialtyGroup'
            ? selectedSpecialtyOption.id
            : null,
        locationType:
          selectedSpecialtyOption && selectedSpecialtyOption.source == 'LocationType'
            ? selectedSpecialtyOption.id
            : null,
        steerageId: props.steerage.id,
        typeOfVisit: values['typeOfVisit'] ?? '',
      }

      // only add service category to payload if it is coverage only or care+coverage and care pass is included
      if (isEffectivelyWaiverOnly || isEffectivelyReferralAndWaiver) {
        let selectedServiceCategoryId = values['serviceCategory']
          ? serviceCategories?.find(
              serviceCategory => serviceCategory.label === values['serviceCategory']
            )?.id
          : null
        payload['waiver'] = {
          serviceCategoryIds: selectedServiceCategoryId ? [selectedServiceCategoryId] : [],
        }
      }

      if (isReferralPresent) {
        // If the isLastMile flag is disabled then set the selected ids and values to []
        if (!values['isLastMile']) {
          setSelectedLastMileServices([])
        }

        // filter out the last mile service ids
        let selectedLastMileServiceIds = selectedLastMileServices.map(
          selectedLastMileService => selectedLastMileService?.id
        )

        payload['referral'] = {
          insuranceAuthorizationRequired: values['insuranceAuthorizationRequired'],
          isLastMile: values['isLastMile'],
          lastMileServiceIds: values['isLastMile'] ? selectedLastMileServiceIds : [],
          visitNotePriority: values['visitNotePriority'],
          leakageReason: values['leakageReason'],
          referralInsuranceAuthorization: {
            authorizationNumber: values['insuranceAuthorizationNumber']
              ? values['insuranceAuthorizationNumber']
              : null,
            status: values['insuranceAuthorizationStatus'],
          },
          referralInsuranceAuthUnstructuredIcdCode: {
            icdCodeData: values['referralInsuranceAuthUnstructuredIcdCode'],
          },
        }
      }
      return handleEditSteerage(payload)
    },
    validate: values => {
      setHasSteerageFormValid(IsSteerageFormValid(values))
      return {}
    },
  })

  const handleLockButtonOnClick = async () => {
    // Save the form if there are changes
    if (formik.dirty) {
      await formik.submitForm()
    }
    const payload: ToggleSteerageLockInterface = {
      steerageId: props.steerage.id,
      isLocked: isLocked,
    }

    await toggleSteerageLock(payload)
  }

  const steerage = props.steerage
  const referral = props.steerage.referral
  const referralRecipient = props.steerage.referral?.referralRecipient
    ? props.steerage.referral?.referralRecipient[0]
    : null
  const insurancePayerName = referral?.person?.insuranceInfo?.insurancePayer?.name
  const insurancePlanName = referral?.person?.insuranceInfo?.insurancePlan?.name
  const insurancePlanType = referral?.person?.insuranceInfo?.planType
  const isLocked = props.steerage.isLocked != null ? props.steerage.isLocked : false

  const IsSteerageFormValid = (values): boolean => {
    // Don't validate if the steerage is locked
    if (props?.steerage?.isLocked) {
      return true
    }

    // If specialty is not selected, the form is not valid
    if (!values.specialtyListOption) {
      return false
    }

    // This method disables/enables the save details button if the data is valid/invalid
    // common validations for all cases
    // Either both unit and quantity should be present or none
    if (!values.serviceQuantityUnit && values.serviceQuantityValue) {
      return false
    }

    if (values.serviceQuantityUnit && !values.serviceQuantityValue) {
      return false
    }

    if (values.isLastMile && !selectedLastMileServices.length) {
      return false
    }

    // There are three cases
    // care only- Only referral is present so hide care pass related fields
    // coverage only- Only care pass will be present so hide referral related fields
    // care coverage - There are two cases for this
    //    1. When the care pass is included
    //    2. care pass is not included

    // The validations for the coverage and care coverage #1(where care pass is included) will be same
    if (isEffectivelyWaiverOnly || isEffectivelyReferralAndWaiver) {
      if (!values?.effectiveFrom) {
        return false
      }
      if (!values?.effectiveThrough) {
        return false
      }
      if (!values?.description) {
        return false
      }
      // If serviceCategory is empty for custom care pass(care pass without prior auth) then disable the send to flume button but
      // in-case of care pass created from prior auth then it is not required
      if (steerage?.waiver?.priorAuthorization == null && !values?.serviceCategory) {
        return false
      }
      return true
    }

    // The validations for the care-only and care coverage#2(where care pass is not included) will be same
    if (isEffectivelyReferralOnly) {
      if (!values?.description) {
        return false
      }
      return true
    }

    return true
  }

  const validateOnSaveDetails = () => {
    // validate the service quantity
    // Either both unit and value field is required or none
    if (!formik.values.serviceQuantityUnit && formik.values.serviceQuantityValue) {
      setErrorServiceQuantity(true)
      return false
    }

    if (formik.values.serviceQuantityUnit && !formik.values.serviceQuantityValue) {
      setErrorServiceQuantity(true)
      return false
    }

    // If an effective through date has been entered, validate that it is tomorrow or later
    let tomorrow = Moment().add(1, 'days').format('YYYY-MM-DD')
    if (formik.values.effectiveThrough && formik.values.effectiveThrough < tomorrow) {
      setErrorEffectiveThrough(true)
      return false
    }

    // Reset error messages to false if we made it through all those validations
    setErrorEffectiveThrough(false)
    setErrorServiceQuantity(false)
    return true
  }

  const fetchLabelForLock = () => {
    if (isEffectivelyReferralAndWaiver || isEffectivelyWaiverOnly) return 'Send to Member and Flume'
    if (isEffectivelyReferralOnly) return 'Send to Member'
    return ''
  }

  const onClickOfAddProvider = () => {
    // This is used when we click add provider button for adding steerage provider via provider search
    // if the form values of care pass are not changed then no need to update it else call submit form
    if (formik.dirty) {
      formik.handleSubmit()
    }
    logEvent<SteerageEventPayload>('STEERAGE_INITIATE_ADD_PROVIDER_WORKFLOW', {
      steerageId: props.steerage.id,
    })
  }

  const isLockingDisabled = (): boolean => {
    // disable the lock button until all required details are added
    if (!formik.values?.description) {
      return true
    }

    // should also have at least one steerage provider
    if (filteredSteerageProviderList && filteredSteerageProviderList.length < 1) return true

    // Either both unit and quantity should be present or none
    if (!formik.values.serviceQuantityUnit && formik.values.serviceQuantityValue) {
      return true
    }

    if (formik.values.serviceQuantityUnit && !formik.values.serviceQuantityValue) {
      return true
    }

    if (isEffectivelyReferralAndWaiver || isEffectivelyWaiverOnly) {
      // If serviceCategory is empty for custom care pass(care pass without prior auth) then disable the send to flume button but
      // in-case of care pass created from prior auth then it is not required
      if (steerage?.waiver?.priorAuthorization == null && !formik.values?.serviceCategory) {
        return true
      }

      // effective dates are only required in case of waiver
      if (!formik.values?.effectiveFrom) {
        return true
      }
      if (!formik.values?.effectiveThrough) {
        return true
      }
    }

    // if care pass is presents then all the steerage provider should have waiver level
    if (
      steerage?.hasActiveWaiver &&
      filteredSteerageProviderList &&
      filteredSteerageProviderList?.filter(
        provider => provider?.waiverLevel == '' || provider?.waiverLevel == null
      ).length > 0
    ) {
      return true
    }

    return false
  }

  const useReferralProviderToggle = () => {
    // check if the referral insurance auth is present for the referral, if not call the create API otherwise update
    if (props.steerage.referral && !props.steerage.referral?.referralInsuranceAuthorization) {
      let payload: EditInsuranceAuthProviderInterface = {
        referral: props.steerage.referral.id,
        useReferralProvider: !formik.values.useReferralProvider,
      }
      handleAddInsuranceAuthProvider(payload)

      return
    }

    let payload: EditInsuranceAuthProviderInterface = {
      referral: props.steerage.referral?.id ?? null,
      insuranceAuthId: props.steerage.referral?.referralInsuranceAuthorization?.id ?? null,
      useReferralProvider: !formik.values.useReferralProvider,
    }

    // calling the update provider api
    handleEditInsuranceAuthProvider(payload)
  }

  const onChangeLastMileService = (changedLastMileServices: ServiceValueType[]) => {
    // First make the selected object as empty
    setSelectedLastMileServices([])

    // This will be used for changing the values in the last mile service autocomplete field
    // and storing the last mile service values on click of save
    let updatedLastMileServices = new Array()
    changedLastMileServices?.map(selectedLastMileService => {
      lastMileServicesList.map?.(lastMileService => {
        if (lastMileService?.id == selectedLastMileService?.id) {
          updatedLastMileServices.push(lastMileService)
        }
      })
    })
    if (updatedLastMileServices) {
      setSelectedLastMileServices(updatedLastMileServices)
    }
  }

  return (
    <Box>
      {isAddingInsuranceAuthProvider ? <Loader /> : null}
      {isEditingInsuranceAuthProvider ? <Loader /> : null}
      {isLoading ? <Loader /> : null}
      <Box>
        <Typography variant="body1" style={{ fontWeight: 500, marginBottom: '15px' }}>
          {getSteerageType(steerage)}
        </Typography>
        {/* only show care pass included switch if it is care+coverage */}
        {isCarePassPresent && isReferralPresent && (
          <Grid
            container
            spacing={3}
            style={{ margin: '10px', marginBottom: '15px', marginLeft: '-5px' }}
          >
            <Switch
              name="isWaiverIncluded"
              onChange={formik.handleChange}
              edge="start"
              checked={!!formik.values?.isWaiverIncluded}
              color="primary"
              onClick={() => formik.handleSubmit()}
              disabled={isLoading}
            />
            <Box style={{ marginTop: '10px' }}>Include Care Pass</Box>
          </Grid>
        )}
        {steerage?.isLocked ? (
          <>
            <SteerageReadOnlyView steerage={steerage}></SteerageReadOnlyView>
            <Grid container spacing={3}>
              <Autocomplete
                onInputChange={(_, val) => {
                  formik.setFieldValue('specialtyListOption', val)
                  formik.setFieldTouched('specialtyListOption')
                }}
                value={formik.values.specialtyListOption}
                disabled={isLoading}
                options={fullSpecialtyList || []}
                sx={{ width: '45%', marginTop: '25px', marginLeft: '25px' }}
                freeSolo
                renderInput={params => (
                  <TextField variant="standard" {...params} label="Specialty" />
                )}
              />
            </Grid>
          </>
        ) : (
          <>
            <Grid container spacing={3}>
              <EditField
                InputLabelProps={{ shrink: true }}
                label="Description"
                type="text"
                onChange={formik.handleChange}
                value={formik.values?.description}
                name="description"
                disabled={isLoading}
                inputProps={{
                  maxLength: 50,
                }}
              />
              {/* Hide the service category field when care pass is not present */}
              {isEffectivelyWaiverOnly || isEffectivelyReferralAndWaiver ? (
                <>
                  {/* If prior auth is present then show the service category in readonly format */}
                  {steerage?.waiver?.priorAuthorization && steerage?.waiver?.serviceCategories && (
                    <EditField
                      InputLabelProps={{ shrink: true }}
                      label="Service Category"
                      disabled
                      value={steerage?.waiver?.serviceCategories
                        ?.map(function (serviceCategory) {
                          return serviceCategory.label
                        })
                        .join(', ')}
                    />
                  )}
                  {/*if waiver was not created from a prior auth: allow selection of a service category */}
                  {steerage?.waiver?.priorAuthorization == null &&
                    steerage?.waiver?.serviceCategories && (
                      <EditField
                        InputLabelProps={{ shrink: true }}
                        label="Service Category"
                        onChange={formik.handleChange}
                        value={formik.values.serviceCategory}
                        name="serviceCategory"
                        disabled={isLoading}
                        select
                        SelectProps={{ native: true }}
                      >
                        <option value=""></option>
                        {serviceCategories?.map(serviceCategory => (
                          <option key={serviceCategory['id']} value={serviceCategory['label']}>
                            {serviceCategory['label']}
                          </option>
                        ))}
                      </EditField>
                    )}
                </>
              ) : null}
            </Grid>
            <Grid container spacing={3}>
              <EditField
                InputLabelProps={{ shrink: true }}
                label="Effective From"
                type="date"
                onChange={formik.handleChange}
                value={formik.values.effectiveFrom}
                name="effectiveFrom"
                disabled={isLoading}
                // Minimum Year for selection should be 2016
                minDate={Moment('2016-01-01').format('YYYY-MM-DD')}
              />
              <EditField
                error={errorEffectiveThrough}
                helperText={
                  errorEffectiveThrough ? 'Effective through date must be tomorrow or later.' : ''
                }
                InputLabelProps={{ shrink: true }}
                label="Effective Through"
                type="date"
                onChange={formik.handleChange}
                value={formik.values.effectiveThrough}
                name="effectiveThrough"
                disabled={isLoading}
                // Minimum date for selection should be tomorrow
                minDate={Moment().add(1, 'days').format('YYYY-MM-DD')}
              />
            </Grid>
            <Grid container spacing={3}>
              <Grid container spacing={3} xs={6} style={{ marginTop: '1px', marginLeft: '1px' }}>
                <EditField
                  error={errorServiceQuantity}
                  helperText={
                    errorServiceQuantity
                      ? 'Both a quantity and unit must be specified for Service Quantity.'
                      : ''
                  }
                  InputLabelProps={{ shrink: true }}
                  label="Quantity"
                  type="number"
                  name="serviceQuantityValue"
                  onChange={formik.handleChange}
                  value={formik.values.serviceQuantityValue}
                  disabled={isLoading}
                />

                <EditField
                  error={errorServiceQuantity}
                  helperText={
                    errorServiceQuantity
                      ? 'Both a quantity and unit must be specified for Service Quantity.'
                      : ''
                  }
                  InputLabelProps={{ shrink: true }}
                  label="Unit"
                  name="serviceQuantityUnit"
                  onChange={formik.handleChange}
                  value={formik.values.serviceQuantityUnit}
                  select
                  SelectProps={{ native: true }}
                  disabled={isLoading}
                >
                  <option value=""></option>
                  <option key="days" value="days">
                    Days
                  </option>
                  <option key="units" value="units">
                    Units
                  </option>
                  <option key="visits" value="visits">
                    Visits
                  </option>
                </EditField>
              </Grid>
              <Autocomplete
                onInputChange={(_, val) => {
                  formik.setFieldValue('specialtyListOption', val)
                  formik.setFieldTouched('specialtyListOption', true, true)
                  const isUrgentCare = val === 'Urgent Care'
                  formik.setFieldValue('isLastMile', isUrgentCare)
                }}
                value={formik.values.specialtyListOption}
                disabled={isLoading}
                options={fullSpecialtyList || []}
                sx={{ width: '45%', marginTop: '25px', marginLeft: '35px' }}
                freeSolo
                renderInput={params => (
                  <TextField required variant="standard" {...params} label="Specialty" />
                )}
              />
            </Grid>
          </>
        )}

        <Grid container spacing={3} style={{ marginTop: '2px' }}>
          <EditField
            InputLabelProps={{ shrink: true }}
            label="Scheduled Date"
            type="date"
            onChange={formik.handleChange}
            value={formik.values.schedulingDate}
            name="schedulingDate"
            disabled={isLoading}
            // Minimum Year for selection should be 2016
            minDate={Moment('2016-01-01').format('YYYY-MM-DD')}
          />

          <EditField
            label="Request Type"
            select
            InputLabelProps={{ shrink: true }}
            SelectProps={{ native: true }}
            onChange={formik.handleChange}
            value={formik.values.requestType}
            name="requestType"
            disabled={isLoading}
          >
            <option value=""></option>
            <option key="broad" value="broad">
              Broad Request
            </option>
            <option key="specific" value="specific">
              Specific Request
            </option>
          </EditField>
        </Grid>

        <Grid container justifyContent="flex-end">
          <Grid item>
            <Box mt={4}>
              <Chip
                label="Save Details"
                color="primary"
                variant="outlined"
                size="medium"
                disabled={!hasSteerageFormValid || isLoading}
                style={{ fontSize: '14px', fontWeight: 500 }}
                onClick={() => formik.handleSubmit()}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box>
        {isReferralPresent && (
          <Box>
            <Typography variant="body1" style={{ fontWeight: 500, marginBottom: '15px' }}>
              Referral Details
            </Typography>
            <Grid container spacing={3}>
              <EditField
                InputLabelProps={{ shrink: true }}
                label="Referral Body"
                type="text"
                value={referral?.body}
                disabled={true}
                multiline
              />
              <EditField
                InputLabelProps={{ shrink: true }}
                label="To"
                type="text"
                value={referralRecipient?.referredTo}
                disabled={true}
                multiline
              />
            </Grid>
            <Grid
              container
              spacing={3}
              style={{ margin: '10px', marginBottom: '15px', marginLeft: '-5px' }}
            >
              <Switch
                edge="start"
                color="primary"
                onChange={formik.handleChange}
                name="isLastMile"
                checked={formik.values?.isLastMile}
                disabled={isLoading}
              />
              <Box style={{ marginTop: '10px' }}>This is a Firefly Nearby Referral</Box>
            </Grid>
            {formik.values?.isLastMile && (
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <ServiceAutocomplete
                    onChangeLastMileService={onChangeLastMileService}
                    lastMileServices={lastMileServicesList}
                    selectedLastMileServices={selectedLastMileServices}
                  />
                </Grid>
              </Grid>
            )}
            <Grid container spacing={3}>
              <EditField
                InputLabelProps={{ shrink: true }}
                label="Referral Sent"
                value={referralRecipient?.referralSentYn}
                type="text"
                disabled={true}
              />
              <EditField
                InputLabelProps={{ shrink: true }}
                value={
                  referral?.vendorCreateDate
                    ? Moment(referral?.vendorCreateDate).format('MMM DD, YYYY')
                    : null
                }
                label="Created Date"
                type="text"
                disabled={true}
              />
            </Grid>
            <Grid container spacing={3}>
              <EditField
                InputLabelProps={{ shrink: true }}
                label="Sign Off User"
                type="text"
                value={
                  referral?.signOffUser
                    ? referral?.signOffUser?.firstName + referral?.signOffUser?.lastName
                    : null
                }
                disabled={true}
              />
              <EditField
                label="Type of visit"
                select
                InputLabelProps={{ shrink: true }}
                SelectProps={{ native: true }}
                name="typeOfVisit"
                onChange={formik.handleChange}
                value={formik.values.typeOfVisit}
                disabled={isLoading}
              >
                <option value=""></option>
                <option key="In Person" value="In Person">
                  In Person
                </option>
                <option key="Virtual" value="Virtual">
                  Virtual
                </option>
                <option key="In Person Or Virtual" value="In Person Or Virtual">
                  In Person Or Virtual
                </option>
                <option key="Home" value="Home">
                  Home
                </option>
                <option key="Any" value="Any">
                  Any
                </option>
              </EditField>
            </Grid>
            <Grid container spacing={3}>
              <EditField
                label="Referral Priority"
                type="text"
                InputLabelProps={{ shrink: true }}
                SelectProps={{ native: true }}
                name="referralPriority"
                value={referral?.referralPriority}
                disabled={true}
              ></EditField>
            </Grid>
            <Grid container spacing={3}>
              <EditField
                label="Leakage Reason"
                select
                InputLabelProps={{ shrink: true }}
                SelectProps={{ native: true }}
                name="leakageReason"
                onChange={formik.handleChange}
                value={formik.values.leakageReason}
                disabled={isLoading}
              >
                <option value=""></option>
                <option key="Location" value="Location">
                  Location
                </option>
                <option key="Insurance" value="Insurance">
                  Insurance
                </option>
                <option key="Prior Relationship" value="Prior Relationship">
                  Prior Relationship
                </option>
                <option key="Availability" value="Availability">
                  Availability
                </option>
                <option key="Service Need" value="Service Need">
                  Service Need
                </option>
                <option key="Patient Preference" value="Patient Preference">
                  Patient Preference
                </option>
                <option key="Other" value="Other">
                  Other
                </option>
              </EditField>
              <EditField
                InputLabelProps={{ shrink: true }}
                label="Insurance Payer Name"
                type="text"
                value={insurancePayerName ?? null}
                disabled={true}
              />
            </Grid>

            <Grid container spacing={3}>
              <EditField
                InputLabelProps={{ shrink: true }}
                label="Insurance Plan Name"
                type="text"
                disabled={true}
                value={insurancePlanName ?? null}
              />
              <EditField
                InputLabelProps={{ shrink: true }}
                label="Insurance Plan Type"
                type="text"
                value={insurancePlanType ?? null}
                disabled={true}
              />
            </Grid>
            <Typography
              variant="body1"
              style={{ fontWeight: 500, marginBottom: '15px', marginTop: '15px' }}
            >
              Authorization Details
            </Typography>
            <Grid
              container
              spacing={3}
              style={{ margin: '10px', marginBottom: '15px', marginLeft: '-5px' }}
            >
              <Switch
                edge="start"
                color="primary"
                onChange={formik.handleChange}
                name="insuranceAuthorizationRequired"
                checked={formik.values?.insuranceAuthorizationRequired}
                onClick={() => formik.handleSubmit()}
                disabled={isLoading}
              />
              <Box style={{ marginTop: '10px' }}>Insurance Authorization is required</Box>
            </Grid>
            {formik.values?.insuranceAuthorizationRequired ? (
              <>
                <Grid container spacing={3} style={{ marginBottom: '10px' }}>
                  <List style={{ margin: '10px' }}>
                    <ListSubheader style={{ lineHeight: '24px' }}>ICD-10 Code(s)</ListSubheader>
                    {formik?.values?.referralInsuranceAuthIcdCodes?.map((icdcode, i) => {
                      return (
                        <ListItem
                          key={i}
                          style={{ lineHeight: '15px', paddingTop: '0px', paddingBottom: '0px' }}
                        >
                          <ListItemText primary={icdcode?.code + ' ' + icdcode?.description} />
                        </ListItem>
                      )
                    })}
                  </List>
                </Grid>
                <Grid container spacing={3}>
                  <EditField
                    label="Insurance Approval / Authorization Status"
                    select
                    InputLabelProps={{ shrink: true }}
                    SelectProps={{ native: true }}
                    onChange={formik.handleChange}
                    name="insuranceAuthorizationStatus"
                    value={formik.values.insuranceAuthorizationStatus}
                    disabled={isLoading}
                  >
                    <option value=""></option>
                    <option key="Not Started" value="Not Started">
                      Not Started
                    </option>
                    <option key="Not Required" value="Not Required">
                      Not Required
                    </option>
                    <option key="In Progress" value="In Progress">
                      In Progress
                    </option>
                    <option key="Complete" value="Complete">
                      Complete
                    </option>
                    <option key="Accepted" value="Accepted">
                      Accepted
                    </option>
                    <option key="Fixed" value="Fixed">
                      Fixed
                    </option>
                  </EditField>
                  <EditField
                    InputLabelProps={{ shrink: true }}
                    label="Authorization Number"
                    type="text"
                    onChange={formik.handleChange}
                    name="insuranceAuthorizationNumber"
                    value={formik?.values?.insuranceAuthorizationNumber}
                    disabled={isLoading}
                  />
                </Grid>
                <Grid
                  container
                  spacing={3}
                  style={{
                    margin: '15px',
                    marginLeft: '-5px',
                  }}
                >
                  <Switch
                    edge="start"
                    color="primary"
                    disabled={isLoading}
                    onChange={formik.handleChange}
                    name="useReferralProvider"
                    checked={formik.values.useReferralProvider}
                    onClick={useReferralProviderToggle}
                  />
                  <Box style={{ marginTop: '10px' }}>Use the provider on the referral</Box>
                </Grid>
                {formik.values.useReferralProvider == false ? (
                  <InsuranceAuthProviderForm steerage={steerage} />
                ) : null}
              </>
            ) : null}

            <Grid container justifyContent="flex-end">
              <Grid item>
                <Box mt={4}>
                  <Chip
                    label="Save Details"
                    color="primary"
                    variant="outlined"
                    size="medium"
                    style={{ fontSize: '14px', fontWeight: 500 }}
                    onClick={() => formik.handleSubmit()}
                    disabled={!hasSteerageFormValid || isLoading}
                  />
                </Box>
              </Grid>
            </Grid>
          </Box>
        )}
        <SteerageProviderList
          steerage={steerage}
          steerageProviders={filteredSteerageProviderList}
          onClickOfAddProvider={onClickOfAddProvider}
          steerageDescription={formik.values.description}
        />
        <Grid container justifyContent="flex-end">
          <Grid item>
            <Box mt={4}>
              <Chip
                label="Save Details"
                color="primary"
                variant="outlined"
                size="medium"
                style={{ fontSize: '14px', fontWeight: 500 }}
                onClick={() => formik.handleSubmit()}
                disabled={!hasSteerageFormValid || isLoading}
              />
              &nbsp;&nbsp;
              {!isLocked && (
                <Chip
                  label={fetchLabelForLock()}
                  onClick={handleLockButtonOnClick}
                  color="primary"
                  variant="outlined"
                  size="medium"
                  style={{ fontSize: '14px', fontWeight: 500 }}
                  disabled={isLoading || isLockingDisabled()}
                />
              )}{' '}
              {isLocked && (
                <Chip
                  onClick={handleLockButtonOnClick}
                  color="primary"
                  variant="outlined"
                  size="medium"
                  style={{ fontSize: '14px', fontWeight: 500 }}
                  label="Reopen"
                  disabled={isLoading}
                />
              )}
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  )
}
export default SteerageView
