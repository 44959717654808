import { Box, Typography, Grid, Card } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import type { FC } from 'react'
import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined'
import { SearchAvailability } from '@fireflyhealth/core'

const useStyles = makeStyles()(theme => {
  return {
    errorCard: {
      backgroundColor: '#FFF2E3',
    },
    errorText: {
      color: '#613510',
    },
    boldErrorText: {
      fontWeight: 'bold',
      color: '#613510',
    },
  }
})

export interface SearchAvailableProps {
  searchAvailability: SearchAvailability | null
}

export const SearchNotAvailable: FC<SearchAvailableProps> = props => {
  const { classes } = useStyles()
  return (
    <Card className={classes.errorCard}>
      <Box p={2}>
        <Grid container alignItems="center" spacing={2}>
          <Grid item>
            <Box color="warning.main">
              <ReportProblemOutlinedIcon color="inherit" />
            </Box>
          </Grid>
          <Grid item>
            <Typography variant="body1" className={classes.boldErrorText}>
              Search not available
            </Typography>
            <Typography variant="body1" className={classes.errorText}>
              {props.searchAvailability?.error}
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </Card>
  )
}
