import { Box, Typography, IconButton, Button } from '@mui/material'
import { FC, useRef, useState } from 'react'
import symptom_icon from '~/assets/images/symptom_icon.svg'
import { components } from 'react-select'
import debounce from 'debounce-promise'
import AsyncSelect from 'react-select/async'
import { VisitReason } from '~/api/AppointmentService'
import { GlobalHotKeys } from 'react-hotkeys'
import { Search, Close } from '@mui/icons-material'
import Fuse from 'fuse.js'

export type SymptomItem = {
  id?: number
  label: string
}

type PrimaryConcernProps = {
  setIsLoading: (e: boolean) => void
  isLoading: boolean
  setSymptomsSelected: (e: SymptomItem[]) => void
  symptomsSelected: SymptomItem[]
  setOtherSymptomsSelected: (e: string[]) => void
  otherSymptomsSelected: string[]
  setVisitReason: (value: VisitReason | null) => void
  patientId: number
  allSymptoms: any[]
  setAllSymptoms: (e: any[]) => void
  categories: any[]
}
const Control = props => {
  return (
    <components.Control {...props}>
      <Search style={{ marginLeft: 8, color: '#7b8f9a' }} />
      {props.children}
    </components.Control>
  )
}

const fuseOptions = {
  isCaseSensitive: false,
  distance: 100,
  findAllMatches: false,
  includeMatches: false,
  includeScore: false,
  location: 0,
  minMatchCharLength: 0,
  shouldSort: true,
  threshold: 0.6,
  useExtendedSearch: false,
  keys: ['label'],
}

const makeSelectOptions = symptoms =>
  symptoms.map(symptom => ({
    ...symptom,
    value: symptom.id,
    label: `${symptom.label}`,
  }))
const SEARCH_DEBOUNCE_TIME = 300

const PrimaryConcern: FC<PrimaryConcernProps> = ({
  isLoading,
  otherSymptomsSelected,
  setOtherSymptomsSelected,
  symptomsSelected,
  setSymptomsSelected,
  allSymptoms,
  categories,
}) => {
  const selectRef = useRef<any>(null)
  const [selectableSymptoms, setSelectableSymptoms] = useState<SymptomItem[]>(allSymptoms)

  const hotkeys = {
    FOCUS_SEARCH: event => {
      event.preventDefault()
      if (selectRef && selectRef.current) {
        selectRef.current.focus()
      }
    },
  }
  const debouncedSearch = debounce(
    async searchTermInput => {
      let filteredSymptoms = selectableSymptoms.filter(symptom => {
        //if no input then return
        if (searchTermInput === '') {
          return symptom
        }
        //return the symptom which contain user input
        else {
          return symptom.label.toLowerCase().includes(searchTermInput)
        }
      })
      filteredSymptoms.push({ label: searchTermInput })

      const options = makeSelectOptions(filteredSymptoms)

      const fuse = new Fuse(options, fuseOptions)
      return fuse.search(searchTermInput)
    },
    SEARCH_DEBOUNCE_TIME,
    { leading: true }
  )

  let removeSymptom = e => {
    let selectedSymptoms =
      symptomsSelected &&
      symptomsSelected.filter(item => {
        return item.label !== e
      })
    setSymptomsSelected(selectedSymptoms)
    let otherSymptoms =
      otherSymptomsSelected &&
      otherSymptomsSelected.filter(item => {
        return item !== e
      })
    setOtherSymptomsSelected(otherSymptoms)
    selectableSymptoms.push({ label: e })
    setSelectableSymptoms(selectableSymptoms)
  }

  let handleSymptomSelection = async e => {
    // Show only remaining symptoms, to avoid selection of same symptom again
    const filtered = selectableSymptoms.filter(obj => {
      if (obj.label !== e.label) {
        return obj
      }
    })
    setSelectableSymptoms(filtered)
    if (e.id) {
      setSymptomsSelected([{ id: e.id, label: e.label }, ...symptomsSelected])
    } else {
      setOtherSymptomsSelected([e.label, ...otherSymptomsSelected])
    }
  }

  const loadOptions = input => {
    return debouncedSearch(input)
  }

  let symptomsList: string[] = symptomsSelected.map(({ label }) => label)
  if (otherSymptomsSelected.length) {
    symptomsList = symptomsList.concat(otherSymptomsSelected)
  }

  return (
    <>
      {!isLoading ? (
        <>
          <GlobalHotKeys handlers={hotkeys} />
          <Box sx={{ display: 'flex' }} mx={2} my={2}>
            <Box sx={{ display: 'inline-flex' }}>
              <AsyncSelect
                value={null}
                onChange={e => {
                  handleSymptomSelection(e)
                }}
                placeholder="Add symptom or condition"
                components={{ Control }}
                styles={{
                  control: (provided, state) => ({
                    ...provided,
                    display: 'inline-flex',
                    width: 500,
                    height: 40,
                    borderRadius: 5,
                  }),
                  indicatorSeparator: () => ({ display: 'none' }),
                  dropdownIndicator: () => ({ display: 'none' }),
                }}
                defaultOptions={[
                  {
                    options: makeSelectOptions(selectableSymptoms),
                  },
                ]}
                loadOptions={loadOptions}
                openMenuOnClick
                openMenuOnFocus
                tabSelectsValue={false}
                ref={selectRef}
              />
            </Box>
          </Box>
          {symptomsList.length ? (
            <>
              {symptomsList.map(symptom => {
                return (
                  <Box
                    key={symptom}
                    mx={2}
                    my={2}
                    display={'flex'}
                    alignItems={'center'}
                    justifyContent={'space-between'}
                  >
                    <Box justifyContent={'left'} display={'flex'} alignItems={'center'}>
                      <img
                        src={symptom_icon}
                        alt="Symptom"
                        style={{ width: 24, height: 24, marginRight: 8 }}
                      />
                      <Typography
                        sx={{
                          fontWeight: '400',
                          fontFamily: 'Roboto',
                          fontSize: 16,
                        }}
                      >
                        {symptom}
                      </Typography>
                    </Box>
                    <Box display={'flex'} justifyContent={'flex-end'} alignItems={'center'}>
                      <IconButton
                        onClick={() => {
                          removeSymptom(symptom)
                        }}
                        size="small"
                      >
                        <Close />
                      </IconButton>
                    </Box>
                  </Box>
                )
              })}
              <Box marginLeft={2} marginBottom={3}>
                <Typography
                  sx={{
                    fontWeight: '400',
                    fontFamily: 'Roboto',
                    fontSize: 14,
                    color: '#697077',
                  }}
                >
                  Search again to add multiple concerns
                </Typography>
              </Box>
            </>
          ) : (
            categories.map(category => (
              <Box key={category.id} my={2} mx={2}>
                <Typography
                  sx={{ fontWeight: '600', color: '#697077', fontFamily: 'Roboto', fontSize: 14 }}
                >
                  {category.label}
                </Typography>
                {category.symptoms.map(symptom => {
                  return (
                    <Box
                      key={symptom.id}
                      my={1}
                      display={'flex'}
                      alignItems={'center'}
                      justifyContent={'left'}
                    >
                      <img
                        src={symptom_icon}
                        alt="Symptom"
                        style={{ width: 24, height: 24, marginRight: 6 }}
                      />
                      <Button
                        color="inherit"
                        onClick={() => {
                          handleSymptomSelection(symptom)
                        }}
                      >
                        <Typography
                          sx={{
                            fontWeight: '400',
                            fontFamily: 'Roboto',
                            fontSize: 16,
                          }}
                        >
                          {symptom.label}
                        </Typography>
                      </Button>
                    </Box>
                  )
                })}
              </Box>
            ))
          )}
        </>
      ) : null}
    </>
  )
}

export default PrimaryConcern
