import { useSelector } from 'react-redux'
import keyBy from 'lodash/fp/keyBy'
import { ProviderUser } from '@fireflyhealth/core'
import { Avatar, Box, Tooltip } from '@mui/material'
import { makeStyles } from 'tss-react/mui'

interface Props {
  patientId: number
}

const CurrentProfileViewers: React.FC<Props> = props => {
  const { classes } = useStyles()
  const me = useSelector(state => state.me)
  const providers = useSelector(state => state.providers)
  const providersById = keyBy('id', providers)
  const viewingState = useSelector(state => state.chat.viewing)
  const currentlyViewingThisPatient = Object.keys(viewingState).filter(
    providerId => viewingState[providerId] == props.patientId && providerId != me.id.toString()
  )
  if (currentlyViewingThisPatient.length == 0) return null

  const providersToShow = currentlyViewingThisPatient.reduce((arr, el) => {
    const provider = providersById[el]
    if (provider) {
      arr.push(provider)
    }
    return arr
  }, [] as ProviderUser[])

  const providerBubbles: JSX.Element[] = []

  for (let index = 0; index < providersToShow.length; index++) {
    const provider = providersToShow[index]

    if (index === 2 && providersToShow.length > 3) {
      const remainingProviders = providersToShow.slice(2)
      let remainingNames = ''
      for (let nameIndex = 0; nameIndex < remainingProviders.length - 1; nameIndex++) {
        const remainingProvider = remainingProviders[nameIndex]
        remainingNames += `${remainingProvider.firstName} ${remainingProvider.lastName}, `
      }
      const lastProvider = providersToShow[providersToShow.length - 1]
      remainingNames += `& ${lastProvider.firstName} ${lastProvider.lastName}`
      providerBubbles.push(
        <Tooltip title={remainingNames} aria-label="add" key={index}>
          <Avatar className={classes.otherProviderAvatars}>+{remainingProviders.length}</Avatar>
        </Tooltip>
      )
      break
    }

    const fullName = provider.firstName + ' ' + provider.lastName
    const initials = `${provider.firstName.slice(0, 1)}${provider.lastName.slice(0, 1)}`

    providerBubbles.push(
      <Tooltip title={fullName} aria-label="add" key={index}>
        <Avatar className={classes.otherProviderAvatars}>{initials}</Avatar>
      </Tooltip>
    )
  }

  return (
    <Box display="flex" alignItems="center">
      <Box display="flex">{providerBubbles}</Box>
    </Box>
  )
}

const useStyles = makeStyles()(theme => ({
  otherProviderAvatars: {
    border: `.1rem solid ${theme.palette.primary.main}`,
    backgroundColor: theme.palette.primary.lighter,
    color: theme.palette.text.primary,
    fontSize: '1.5rem',
  },
}))

export default CurrentProfileViewers
