import type { FC } from 'react'
import PriorAuthorizationList from '~/components/PatientDetail/PriorAuthorization/PriorAuthorizationList'
import { Person } from '@fireflyhealth/core'

export interface PriorAuthorizationProps {
  // Note that the Tabs correctly pass in a PERSON even though the attribute
  // is named for the vestigial PATIENT.
  patient: Person
}

const PriorAuthorization: FC<PriorAuthorizationProps> = props => {
  return <PriorAuthorizationList person={props.patient} />
}

export default PriorAuthorization
