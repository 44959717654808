import { PatientUser } from '@fireflyhealth/core'
import { PatientTodo } from '~/models/PatientTodoItem'
import { Worklist } from '~/redux/slices/worklists'
import { Box } from '@mui/material'
import { FC, useState } from 'react'
import { PatientTodoTaskComponents } from './PatientCategorizedTodos'
import ToDoHeader from './ToDoHeader'
export const ToDoItem: FC<{
  parentId: number
  todoContent: PatientTodo
  patient: PatientUser
  selectedWorklist: Worklist | undefined
  expandCase: boolean
  isClosed?: boolean
  disableEdit?: boolean
  setDisableEdit: any
  parentCategory: string
  contentType: string | number | undefined
}> = props => {
  const {
    parentId,
    todoContent,
    selectedWorklist,
    patient,
    expandCase,
    isClosed,
    disableEdit,
    setDisableEdit,
    parentCategory,
    contentType,
  } = props
  const [isTodoTaskUpdating, setIsTodoTaskUpdating] = useState<boolean>(false)
  const [isTodoNoteUpdating, setIsTodoNoteUpdating] = useState<boolean>(false)

  return (
    <Box
      key={`todo_${contentType}_${parentId}`}
      sx={{
        backgroundColor: '#FFF',
        border: '1px solid #E0E0E0',
        boxShadow:
          '0px 3px 2px -2px rgba(0, 0, 0, 0.07), 0px 2px 3px rgba(0, 0, 0, 0.08), 0px 1px 4px rgba(0, 0, 0, 0.07);',
        borderRadius: '4px',
        overflow: 'hidden',
        '&:not(:first-child)': {
          marginTop: 2,
        },
      }}
    >
      <ToDoHeader
        parentId={parentId}
        todoContent={todoContent}
        patient={patient}
        selectedWorklist={selectedWorklist}
        expandCase={expandCase ?? false}
        isClosed={isClosed ?? false}
        disableEdit={disableEdit ?? false}
        parentCategory={parentCategory}
        isTodoTaskUpdating={isTodoTaskUpdating}
        isTodoNoteUpdating={isTodoNoteUpdating}
      >
        <PatientTodoTaskComponents
          parentId={parentId}
          patient={patient}
          todo={todoContent}
          worklist={selectedWorklist}
          isClosed={isClosed ?? false}
          setDisableEdit={setDisableEdit}
          setIsTodoTaskUpdating={setIsTodoTaskUpdating}
          setIsTodoNoteUpdating={setIsTodoNoteUpdating}
        />
      </ToDoHeader>
    </Box>
  )
}
