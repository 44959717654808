import { InsuranceInfo, PatientUser, Coverage, CrudResource } from '@fireflyhealth/core'
import { batch } from 'react-redux'

import { apiClient } from '~/api/rest'
import { queueNotification } from '~/redux/actions/notifications'
import { getPatientRequest, getPatientsRequest } from '~/requests/getPatients'
import { patientsSlice, PatientInsurancePayload, PatientInsuranceAddressPayload } from './'

type RecursivePartial<T> = {
  [P in keyof T]?: RecursivePartial<T[P]>
}

export const patientsResource = new CrudResource<any>(apiClient.rest, '/user/')

export const getPatient = patientId => {
  return async dispatch => {
    try {
      const response: any = await getPatientRequest(patientId)
      if (!response.data.isPatient) throw new Error('User is not a patient')
      else {
        response.data.firstName = response.data.person?.firstName
        response.data.lastName = response.data.person?.lastName
      }
      return dispatch(patientsSlice.actions.getPatientSuccess(response.data))
    } catch (error) {
      if (error instanceof Error) {
        console.log(error)
        dispatch(patientsSlice.actions.getPatientError())
        const err = new Error(
          `'Problem fetching patient for id: ${patientId}. Error: ${error.message}`
        )
        if (error instanceof Error) {
          err.stack = error.stack
        }
        throw err
      }
    }
  }
}

export const getPatients = () => {
  return async dispatch => {
    try {
      const response: any = await getPatientsRequest()
      return dispatch(patientsSlice.actions.getPatientsSuccess(response.data))
    } catch (error) {
      console.log(error)
      return dispatch(patientsSlice.actions.getPatientsError())
    }
  }
}

export const updatePatient = (payload: RecursivePartial<PatientUser>) => async dispatch => {
  try {
    const patient = await apiClient.rest.patch(`/user/${payload.id}/`, payload)
    dispatch(patientsSlice.actions.getPatientSuccess(patient))
    dispatch(
      queueNotification({
        variant: 'success',
        message: 'Patient info updated',
      })
    )
  } catch (e) {
    console.warn(e)
    dispatch(queueNotification({ variant: 'error', message: 'Problem updating patient info' }))
    throw new Error('Problem updating patient info')
  }
}

export const updatePatientInsuranceAddress =
  (payload: PatientInsuranceAddressPayload) => async dispatch => {
    try {
      const insurance = await apiClient.rest.patch<InsuranceInfo>(
        `/insurance/member/${payload.insuranceInfo.id}`,
        payload.insuranceInfo
      )
      await dispatch(
        patientsSlice.actions.updatePatientInsuranceSuccess({
          insuranceInfo: insurance,
          patientId: payload.patientId,
        })
      )
      dispatch(
        queueNotification({
          variant: 'success',
          message: 'Patient address updated',
        })
      )
    } catch (e) {
      console.log(e)
      dispatch(queueNotification({ variant: 'error', message: 'Problem updating patient address' }))
      throw new Error('Problem updating patient address')
    }
  }

export const updatePatientInsurance = (payload: PatientInsurancePayload) => async dispatch => {
  try {
    // TODO: Implement, determine if response fits into redux similarly to current action
    // const response = await apiClient.rest.post(
    //   `/user/${payload.patientId}/coverage/submit_insurance_info/`,
    //   payload.insuranceInfo
    // )
    // console.log('Response:', response)
    const insurance = await apiClient.rest.patch<InsuranceInfo>(
      `/insurance/member/${payload.insuranceInfo.id}`,
      payload.insuranceInfo
    )
    await dispatch(
      patientsSlice.actions.updatePatientInsuranceSuccess({
        insuranceInfo: insurance,
        patientId: payload.patientId,
      })
    )
    dispatch(
      queueNotification({
        variant: 'success',
        message: 'Patient insurance info updated',
      })
    )
  } catch (e) {
    console.log(e)
    dispatch(
      queueNotification({ variant: 'error', message: 'Problem updating patient insurance info' })
    )
    throw new Error('Problem updating patient insurance info')
  }
}

export const updatePatientInsuranceCoverage =
  (payload: Coverage, patientId: number) => async dispatch => {
    try {
      const patient = await apiClient.rest.post<PatientUser>(
        `/user/${patientId}/coverage/set_status/`,
        payload
      )
      batch(() => {
        dispatch(patientsSlice.actions.getPatientSuccess(patient))
        dispatch(
          queueNotification({
            variant: 'success',
            message: 'Patient insurance coverage status updated',
          })
        )
      })
    } catch (e) {
      console.log(e)
      dispatch(
        queueNotification({
          variant: 'error',
          message: 'Problem updating patient insurance coverage status',
        })
      )
      throw new Error('Problem updating patient insurance coverage status')
    }
  }

export const updatePatientUrgentVisitInfo =
  (patientId: number, urgentVisit: boolean) => async dispatch => {
    try {
      const patient = await apiClient.rest.patch(`/user/${patientId}/`, { urgentVisit })
      dispatch(patientsSlice.actions.getPatientSuccess(patient))
      dispatch(
        queueNotification({
          variant: 'success',
          message: 'Urgent visit info updated',
        })
      )
    } catch (e) {
      console.warn(e)
      dispatch(
        queueNotification({ variant: 'error', message: 'Problem updating urgent visit info' })
      )
      throw new Error('Problem updating urgent visit info')
    }
  }
