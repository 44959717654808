import type { FC } from 'react'
import { useMutation } from '@fireflyhealth/core'
import { useAuth0 } from '@auth0/auth0-react'
import { Box, Button, Fade, Grid, Grow, makeStyles, Typography } from '@material-ui/core'
import Loader from '~/components/Loader'

import STARBURST from '~/assets/images/starburst.png'
import LOGO from '~/assets/images/logo_full.png'
import { Button as GenericButton } from '~/components/Generic/Button'
import { useLocalStorage } from '~/components/Providers/LocalStorageProvider'
import { logger } from '~/utils/logger'
import { AUTH0_ORG_LOGIN_ENABLED } from '~/utils/config'

export const Login: FC = () => {
  const auth0 = useAuth0()
  const [_, setOrgId] = useLocalStorage('organizationId')
  const auth0Login = orgId => {
    // Use Auth0 appState to persist state
    // so that after authentication, we can redirect to the last known location
    // Previous location is stored in the "next" URL query param
    const nextUrl = (location.search.split('?next=')?.[1] as string) || '/messages'
    const organization = AUTH0_ORG_LOGIN_ENABLED ? orgId : undefined
    setOrgId(organization)
    logger.debug(
      '[Session] auth0Login with organization ID',
      orgId,
      'isOrganizationLoginEnabled',
      AUTH0_ORG_LOGIN_ENABLED
    )
    return auth0.loginWithRedirect({
      appState: { next: decodeURI(nextUrl) },
      organization: organization,
    })
  }
  const { handler, loading } = useMutation(() => auth0Login(process.env.REACT_APP_AUTH0_ORG_ID))
  const { handler: partnerHandler, loading: partnerLoading } = useMutation(() =>
    auth0Login(process.env.REACT_APP_AUTH0_PARTNER_ORG_ID)
  )
  const classes = useStyles()

  return (
    <Box
      position="absolute"
      height="100%"
      width="100%"
      display="flex"
      alignItems="center"
      justifyContent="center"
      className={classes.bg}
    >
      <Grid item xs={11} sm={5} md={3} lg={2}>
        <Grow in>
          <Fade in>
            <Box mt={-5}>
              <Box mb={3} display="flex" justifyContent="center">
                <img src={LOGO} alt="Logo" width="100%" />
              </Box>
              <Button
                disabled={auth0.isLoading || loading}
                data-testid="login-button"
                variant="contained"
                fullWidth
                onClick={handler}
                className={classes.loginButton}
              >
                {auth0.isLoading || loading ? <Loader inline /> : 'LOG IN'}
              </Button>
              {AUTH0_ORG_LOGIN_ENABLED && (
                <Box mt={1}>
                  <Typography variant="body2" align="center">
                    DFCI partner providers,&nbsp;
                    <GenericButton
                      onClick={partnerHandler}
                      disabled={auth0.isLoading || partnerLoading}
                    >
                      <Typography variant="body2" color="primary">
                        log in here
                      </Typography>
                    </GenericButton>
                  </Typography>
                </Box>
              )}
            </Box>
          </Fade>
        </Grow>
      </Grid>
    </Box>
  )
}

const useStyles = makeStyles(theme => ({
  bg: {
    background: `url(${STARBURST}) left bottom no-repeat`,
    backgroundSize: '25%',
  },
  loginButton: {
    background: theme.palette.navy[500],
    color: theme.palette.common.white,
    '&:hover': {
      background: theme.palette.navy[900],
    },
  },
}))

export default Login
