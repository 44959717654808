import React from 'react'
import { Box, Divider, ThemeProvider, Typography } from '@mui/material'
import { createTheme } from '@mui/material/styles'
import { DEFAULT_THEME, mui5theme } from '~/components/Providers/ThemeProvider/mui5theme'
import { useSteerage } from '~/api/SteerageService'
import { ReferralDetails } from './ReferralDetails'
import { ReferralRequestV2 } from './ReferralRequestV2'
import { ReferralDetailsV2 } from './ReferralDetailsV2'
import { useFeatureSwitch } from '~/utils/useFeatureSwitch'
import PriorAuthorizationRelatedView from '../../PriorAuthorization/PriorAuthorizationRelatedView'

/**
 * Parent view for V3 of the SteerageView
 */
export const SteerageViewV3: React.FC<{ steerageId: number }> = props => {
  const { data: steerage, isLoading: steerageIsLoading } = useSteerage(props.steerageId)
  const showReferralDetails = steerage?.segment !== null

  /**
   * Powering read-only views
   * Referral request is disabled based on locked status *and* segmentation
   * Referral details is based only on locked status
   */
  const readOnlyRequest =
    Boolean(steerage?.isLocked) ||
    Boolean(steerage?.wasElationLetterCreatedFromLucian) ||
    steerage?.segment !== null
  const readOnlyDetails = !!steerage?.isLocked

  /**
   * Temporary creating a V2 version for the ReferralDetails so that Prior Auth sections will be behind a switch
   * Remove V2 version when removing the switch
   */
  const is_prior_auth_merge_active = useFeatureSwitch('referral.mergePriorAuthCases')?.active

  return (
    <>
      {!steerageIsLoading && steerage && (
        <Box>
          <Divider sx={{ borderColor: t => t.palette.grey['400'] }} />
          <Typography align="center" variant="subtitle2" my={1}>
            Status: {steerage.status}
          </Typography>
          <Divider sx={{ borderColor: t => t.palette.grey['400'] }} />
          {is_prior_auth_merge_active && steerage.waiver && steerage.waiver.priorAuthorization ? (
            <Box key={`prior_auth_${steerage.waiver.priorAuthorization.pk}`} mt={4} ml={1}>
              <PriorAuthorizationRelatedView model={steerage.waiver.priorAuthorization} />
            </Box>
          ) : null}
          <ReadOnlyWrapper enabled={readOnlyRequest}>
            <ReferralRequestV2 steerage={steerage} />
          </ReadOnlyWrapper>
          {showReferralDetails ? (
            <ReadOnlyWrapper enabled={readOnlyDetails}>
              {is_prior_auth_merge_active ? (
                <ReferralDetailsV2 steerageId={steerage.id} personId={steerage?.person} />
              ) : (
                <ReferralDetails steerageId={steerage.id} personId={steerage?.person} />
              )}
            </ReadOnlyWrapper>
          ) : null}
        </Box>
      )}
    </>
  )
}

/**
 * This theme is used to override the default MUI theme for the SteerageView.
 * This powers the read-only view so that we can achieve the look we're after
 * instead of duplicating a lot of code to render inputs as Typography components.
 */
const steerageTheme = createTheme({
  ...mui5theme,
  components: {
    ...mui5theme.components,
    MuiCheckbox: {
      styleOverrides: {
        root: {
          '&.Mui-disabled': {
            color: DEFAULT_THEME.palette.grey[500],
          },
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        label: {
          '&.Mui-disabled': {
            color: DEFAULT_THEME.palette.grey[700],
          },
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          '&.Mui-disabled': {
            color: DEFAULT_THEME.palette.text.primary,
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          '&.Mui-disabled': {
            color: DEFAULT_THEME.palette.grey[700],
          },
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        input: {
          '&.Mui-disabled': {
            '-webkit-text-fill-color': 'inherit!important',
            color: DEFAULT_THEME.palette.text.primary,
          },
        },
        root: {
          '&.Mui-disabled': {
            '&:before': {
              border: 0,
            },
          },
        },
      },
    },
  },
})

const ReadOnlyWrapper: React.FC<{ enabled: boolean }> = props => {
  if (props.enabled) return <ThemeProvider theme={steerageTheme}>{props.children}</ThemeProvider>
  return <>{props.children}</>
}
