import { usePromise as useCorePromise } from '@fireflyhealth/core'

import { UseMutationConfig, useResolvedPromise } from './useResolvedPromise'

/**
 * Re-exports usePromise from js-core with Lucian-specific
 * side effects via useResolvedPromise. This includes some code duplication
 * to get the types to match what's in the js- core
 */
const DEFAULT_CONFIG = { initialLoading: true }
export function usePromise<P, E = any>(
  getPromise: () => Promise<P>,
  config: UseMutationConfig = DEFAULT_CONFIG
) {
  const promise = useCorePromise<P, E>(getPromise, config)
  useResolvedPromise(promise, { ...DEFAULT_CONFIG, ...config })
  return promise
}
