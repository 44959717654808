import { apiClient } from '~/api/rest'
import { useMutation, useQuery } from '~/components/Providers/ApiProvider'
import { InsuranceAddress, InsuranceInfo, Person } from '@fireflyhealth/core'

type RecursivePartial<T> = {
  [P in keyof T]?: RecursivePartial<T[P]>
}

export interface PartialInsuranceInfo {
  insuranceInfo: any
}

export interface PatientInsuranceAddressPayload {
  insuranceInfo: { id: number | undefined; addresses: InsuranceAddress[] }
}
/**
 * updatePerson
 * @param payload: includes id, firstName, lastName, dob, preferredName,
    employer, gender, pronouns, requestAuthForPhi, phoneNumber, email
 * @returns updated person
 */
function updatePerson(payload: RecursivePartial<Person>): Promise<Person> {
  return apiClient.rest.patch<Person>(`/providers/me/user/people/${payload.id}/`, payload)
}

export const useUpdatePerson = () => {
  return useMutation(
    updatePerson,
    {
      onSuccess: async () => {},
    },
    { success: 'Patient info updated', error: 'Failed to update patient' }
  )
}

const getPerson = async (personId: number): Promise<Person> => {
  const data = await apiClient.rest.get<Person>(`/providers/me/user/people/${personId}`)
  return data
}

const getPersonById = 'getPersonById'
export const useGetPerson = (variables: { personId: number }) => {
  const { personId } = variables
  return useQuery(
    [getPersonById, personId],
    () => getPerson(personId),
    {},
    {
      error: 'Failed to fetch person',
    }
  )
}

/**
 * @param payload: includes firstName, lastName, dob, preferredName,
   gender, sex, pronouns, requestAuthForPhi, phoneNumber and email,
 * @returns newly added person
 */
function addNewMember(payload: RecursivePartial<Person>): Promise<Person> {
  return apiClient.rest.post<Person>(`/providers/me/user/person/`, {
    ...payload,
  })
}

export const useAddNewMember = () => {
  return useMutation(
    addNewMember,
    {
      onSuccess: () => {},
    },
    { success: 'Member added successfully', error: 'Failed to add new member' }
  )
}

function createOrUpdateInsurance(payload: PartialInsuranceInfo): Promise<InsuranceInfo> {
  if (payload.insuranceInfo.id != null) {
    return apiClient.rest.patch<InsuranceInfo>(
      `/insurance/member/${payload.insuranceInfo.id}`,
      payload.insuranceInfo
    )
  } else {
    return apiClient.rest.post<InsuranceInfo>(`/insurance/member/`, { ...payload.insuranceInfo })
  }
}

export const useCreateOrUpdateInsurance = () => {
  return useMutation(
    createOrUpdateInsurance,
    {
      onSuccess: async () => {},
    },
    { success: 'Patient Insurance info updated', error: 'Failed to update patient Insurance' }
  )
}

function createOrUpdateInsuranceAddress(
  payload: PatientInsuranceAddressPayload
): Promise<InsuranceInfo> {
  if (payload.insuranceInfo.id != null) {
    return apiClient.rest.patch<InsuranceInfo>(
      `/insurance/member/${payload.insuranceInfo.id}`,
      payload.insuranceInfo
    )
  } else {
    return apiClient.rest.post<InsuranceInfo>(`/insurance/member/`, { ...payload.insuranceInfo })
  }
}

export const useCreateOrUpdateInsuranceAddress = () => {
  return useMutation(
    createOrUpdateInsuranceAddress,
    {
      onSuccess: async () => {},
    },
    { success: 'Patient Insurance info updated', error: 'Failed to update patient Insurance' }
  )
}
