import type { FC } from 'react'
import { Person } from '@fireflyhealth/core'
import ProviderSearch from '~/components/PatientDetail/ProviderSearch/ProviderSearch'
import { useProviderSearch } from '~/components/PatientDetail/ProviderSearch/utils'
import Loader from '~/components/Loader'

export interface ProviderSearchTabProps {
  // Note that the Tabs correctly pass in a PERSON even though the attribute
  // is named for the vestigial PATIENT.
  patient: Person
}

const ProviderSearchTab: FC<ProviderSearchTabProps> = props => {
  const { isLoading } = useProviderSearch()
  if (isLoading) return <Loader />
  return <ProviderSearch person={props?.patient} />
}

export default ProviderSearchTab
