import { CrudResource, PatientUser, Person, PhysicianUser } from '@fireflyhealth/core'
import { apiClient } from '~/api/rest'

class NoteResource extends CrudResource<Note> {}

export interface Note {
  id?: number
  createdBy?: PhysicianUser | number
  content: string
  createdAt?: string
  metadata?: { [key: string]: any }
  patient?: PatientUser | number
  noteType?: string
  contentType?: string
  objectId?: number
  updatedAt?: string
  person?: Person | number
}

export default new NoteResource(apiClient.rest, '/notes/')
