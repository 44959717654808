import { createSlice, PayloadAction, Dictionary } from '@reduxjs/toolkit'
import { PatientUser } from '@fireflyhealth/core'
import { keyBy, merge } from 'lodash/fp'

import { patientsSlice, PatientInsurancePayload } from './patients'
import { GET_PROVIDERS_SUCCESS } from '../actions/actionTypes'

export interface UsersState {
  byId: Dictionary<PatientUser>
}

export const getUserById = (users: UsersState, id: number | undefined) =>
  id ? users.byId[id] : undefined

const usersSlice = createSlice({
  name: 'users',
  initialState: { byId: {} } as UsersState,
  reducers: {},
  extraReducers: {
    [patientsSlice.actions.getPatientsSuccess.toString()]: (
      state,
      { payload: patients }: PayloadAction<PatientUser[]>
    ) => {
      state.byId = merge(keyBy('id', patients), state.byId)
    },
    [patientsSlice.actions.getPatientSuccess.toString()]: (
      state,
      { payload: patient }: PayloadAction<PatientUser>
    ) => {
      state.byId[patient.id] = patient
    },
    [patientsSlice.actions.updatePatientInsuranceSuccess.toString()]: (
      state,
      { payload }: PayloadAction<PatientInsurancePayload>
    ) => {
      const user = state.byId[payload.patientId]
      if (user && user.isPatient) {
        user.person.insuranceInfo = payload.insuranceInfo
      }
      state.byId[payload.patientId] = user
    },
    [GET_PROVIDERS_SUCCESS]: (state, { providers }) => {
      state.byId = { ...state.byId, ...keyBy('id', providers) }
    },
  },
})

export default usersSlice
