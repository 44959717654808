import { AutoAwesome as AutoAwesomeIcon } from '@mui/icons-material'
import { FolderOpen as FolderIcon } from '@mui/icons-material'
import { Shortcut as ForwardIcon } from '@mui/icons-material'

import qs from 'query-string'
import * as MUI from '@mui/material'

import { IconButton, Menu, MenuItem, Tooltip, Typography, Button } from '@mui/material'
import { Box, Chip } from '@mui/material'
import { MoreVert as MoreVertIcon } from '@mui/icons-material'

import Moment from 'moment'
import { useContext, useEffect, useState } from 'react'
import type { FC } from 'react'
import { ICase, ICaseCategory, ICaseRelation, caseIsClosed, caseIsOpen } from '~/models/Case'
import { PatientUser } from '~/types'
import { useDispatch, useSelector } from 'react-redux'

import {
  useAddCase,
  useCaseCategories,
  useCaseCategoriesById,
  useCases,
  useTaskCollectionPreviewMutation,
  useUpdateCase,
} from '~/api/CaseService'
import { useUpdatePhoneCall } from '~/api/PhoneCallService'
import {
  chatMessageText,
  findMessageIdentifier,
  instanceOfPhoneCall,
  useMessageCaseInfo,
  useRefreshDefaultThread,
} from './utils'
import chatSlice from '~/redux/slices/chat/slice'
import { isClinical, isOperational, isUrgent } from '~/utils/chat'
import { useHistory } from 'react-router-dom'
import { CaseOrCategory, CaseWithTitle, Grouped, isCase } from './TriageCaseOrCategorySelect/types'
import { format } from 'date-fns'
import { getMessageCaseRelation } from '../Cases/utils'
import TriageCaseCategorySelect from './TriageCaseOrCategorySelect/Select'
import { ITask, VIEWABLE_TASK_FIELDS } from '~/models/Task'
import { pick } from 'lodash'
import { IChatMessage, Message } from './Chat'
import { useUpdateChatMessage, IChatMessageV2Messagetags } from '~/api/ChatService'
import { useMutation } from '../Providers/ApiProvider'
import { UpdateMessageType, UpdateMessageUrgency, logEvent } from '~/utils/events'
import { apiClient } from '~/api/rest'
import FFModal from '../FFModal/FFModal'
import { PresenceSignalTypeCodeMap, providerInboxChannel } from '~/utils/pubnub'
import { SelectedTodoContext } from '~/utils/useManageSelectedTodo'

interface Props {
  patient: PatientUser
  message: Message
  menuIsOpen: boolean
  menuAnchorEl: null | HTMLElement
  setMenuAnchorEl: (element: null | HTMLElement) => void
  startMultiSelect: () => void
  setIsSelectingMultiple: Function
  setIsLoading: Function
  isLastMessage: boolean
}

const getMessageType = (isClinicalMessage: boolean) => {
  return isClinicalMessage ? 'clinical' : 'operational'
}

const getMessageUrgency = (isUrgentMessage: boolean) => {
  return isUrgentMessage ? 'urgent' : 'not urgent'
}

const triggerThreadRefiltering = ({ me, pubNub, patient }) => {
  // Broadcast the change to other Lucian clients so that they can re-check the message
  // against their filters
  // This is what allows a message to show up as recategorized from Clinical => Operational
  // without a user refreshing their Operational-filtered messages
  if (pubNub) {
    pubNub.signal(
      {
        channel: providerInboxChannel(me),
        message: {
          st: PresenceSignalTypeCodeMap.MESSAGE_REFILTER,
          p: patient.id,
          pe: patient.person.id,
        },
      },
      status => {
        if (status.error) {
          console.error('Error in useToggleMessageBucket', status)
        }
      }
    )
  }
}

const useToggleMessageBucket = (me: any, patient) => {
  const { mutateAsync: handleUpdateChatMessage } = useUpdateChatMessage()
  const role = me.providerFields?.internalRole ? me.providerFields?.internalRole : null
  const pubNub = useSelector(state => state.chat?.pubnub)

  return useMutation(
    (message: IChatMessage) => {
      const isClinical = !isOperational(message)
      let tags: IChatMessageV2Messagetags[] = []
      for (const tag of message.messageTags ?? []) {
        if (tag.tag !== 'clinical' && tag.tag !== 'operational') {
          tags.push({
            messagetag: tag,
          })
        }
      }
      tags.push({
        messagetag: { tag: getMessageType(!isClinical) },
      })
      return handleUpdateChatMessage({
        uid: message.uid,
        chatmessagev2_messagetags: tags,
      })
    },
    {
      onSuccess: (_data, message) => {
        logEvent<UpdateMessageType>('UPDATED_MESSAGE_TYPE_FOR_CHAT_MESSAGE', {
          messageUid: message.uid,
          previousMessageType: getMessageType(!isOperational(message)),
          revisedMessageType: getMessageType(!isClinical),
          userRoleTitle: role,
        })
        triggerThreadRefiltering({ me, pubNub, patient })
      },
    }
  )
}

const useToggleMessageUrgency = (me: any, patient) => {
  const { mutateAsync: handleUpdateChatMessage } = useUpdateChatMessage()
  const role = me.providerFields?.internalRole ? me.providerFields?.internalRole : null
  const pubNub = useSelector(state => state.chat?.pubnub)

  return useMutation(
    (message: IChatMessage) => {
      const isUrgentMessage = !isUrgent(message)
      return handleUpdateChatMessage({
        uid: message.uid,
        is_urgent_message: isUrgentMessage,
      })
    },
    {
      onSuccess: (_data, message) => {
        logEvent<UpdateMessageUrgency>('UPDATED_MESSAGE_URGENCY_FOR_CHAT_MESSAGE', {
          messageUid: message.uid,
          previousMessageUrgency: getMessageUrgency(isUrgent(message)),
          revisedMessageUrgency: getMessageUrgency(!isUrgent(message)),
          userRoleTitle: role,
        })
        triggerThreadRefiltering({ me, pubNub, patient })
      },
    }
  )
}

// Candidates for linking are:
// - NOT currently linked to the message
// - NOT currently linked to a care plan
// - Open (as determined by status category)
// - Closed (as determined by status category) and updated in the past day
//
// Show most recently updated cases first.
const findCaseOptions = (
  allCases: ICase[],
  caseInfo: MessageIdToCaseInfoDict,
  message: Message,
  filterCarePlanCases: boolean
) =>
  allCases
    ?.filter(caseObject => {
      // Is this message already linked to this case?
      if ((caseInfo[message.id] || []).find(info => info.caseId == caseObject.id)) {
        return false
      }

      if (filterCarePlanCases) {
        if (caseObject.relations.some(relation => relation.contentType == 'careplan')) {
          // Is it linked to a care plan?
          return false
        }
      }

      if (caseIsOpen(caseObject)) {
        return true
      }

      // Closed within the past day?
      if (Moment().diff(Moment(caseObject.updatedAt), 'days') <= 1) {
        return true
      }

      return false
    })
    .sort((a, b) => Moment(b.updatedAt).diff(Moment(a.updatedAt))) || []

const getBucketLabel = (message: Message): 'Operational' | 'Clinical' | null =>
  isOperational(message) ? 'Operational' : isClinical(message) ? 'Clinical' : null

const BucketChip: FC<{ message: Message }> = props => {
  // Display information about a message's "bucket"
  // Operational vs. Clinical

  let bucket = isOperational(props.message)
    ? 'Operational'
    : isClinical(props.message)
    ? 'Clinical'
    : null
  if (!bucket) return null

  return (
    <Chip
      icon={<AutoAwesomeIcon />}
      label={bucket}
      variant="outlined"
      size="small"
      sx={{
        color: 'secondary.dark',
        borderColor: 'transparent',
        backgroundColor: 'transparent',
      }}
    />
  )
}

interface MessageIdToCaseInfoDict {
  [messageId: number]: { caseId: number; categoryId: number }[]
}

const DeleteChatMessageModal: FC<{
  messageText: string
  loading: boolean
  setModalOpen: Function
  modalOpen: boolean
  onModalConfirm: () => void
}> = ({ messageText, loading, setModalOpen, modalOpen, onModalConfirm }) => {
  let abbreviatedMessageText = messageText.substring(0, 27)

  if (abbreviatedMessageText.length < messageText.length) {
    abbreviatedMessageText += '...'
  }

  if (!modalOpen) return null

  return (
    <FFModal
      open={true}
      header={
        <Box m={2}>
          <Typography variant="h5">
            Delete Message: <i>{abbreviatedMessageText}</i>
          </Typography>
        </Box>
      }
      footer={
        <Box m={2} display="flex" justifyContent="flex-end">
          <Button onClick={() => setModalOpen(false)} disabled={loading}>
            Cancel
          </Button>
        </Box>
      }
    >
      <>
        <Box m={2}>
          <Typography>
            Are you sure you want to delete this message? The patient may have already received a
            notification about your message.
          </Typography>
        </Box>
        <Box m={2}>
          <Button onClick={onModalConfirm} disabled={loading} variant="contained">
            Yes, Delete
          </Button>
        </Box>
      </>
    </FFModal>
  )
}

const MessageActions: FC<Props> = props => {
  // Message information and controls for the actions a user can take on a message
  // e.g. triaging to Clinical vs. Operational, adding to a Case
  const { message, menuAnchorEl, setMenuAnchorEl, menuIsOpen } = props
  const me = useSelector(state => state.me)
  const clinicians = useSelector(state => state.providers)
  const senderId = 'sender' in message ? message.sender : null
  const sender = clinicians.find(c => c.id == senderId)
  const mine = me.id === senderId
  const isEvent = !instanceOfPhoneCall(message) && message.event

  // To display Case information, we need:
  // 1) Case Category data
  // 2) A mapping of Case IDs mapped to their relations
  // 3) This message's id/type, to check if it is included in any Cases' relations
  const { data: patientCases } = useCases({
    personId: props.patient.person.id,
  })
  const { messageIdToCaseInfo } = useMessageCaseInfo(props.patient.id, props.patient.person.id)
  const dispatch = useDispatch()
  const { mutate: toggleMessageBucket, data: messageBucketUpdatedData } = useToggleMessageBucket(
    me,
    props.patient
  )
  const { mutate: toggleMessageUrgency, data: messageUrgencyUpdatedData } = useToggleMessageUrgency(
    me,
    props.patient
  )

  const closeMenu = () => setMenuAnchorEl(null)

  const [inlineTriage, setInlineTriage] = useState<boolean>(false)
  const messageIdentifier = findMessageIdentifier(props.message)

  const { result: caseCategoriesById } = useCaseCategoriesById()
  const { result: categories } = useCaseCategories()
  const { mutateAsync: handleUpdatePhoneCall } = useUpdatePhoneCall(
    props.patient.id,
    props.patient.person.id,
    true
  )

  const caseOptionsNew: CaseWithTitle[] = findCaseOptions(
    patientCases || [],
    messageIdToCaseInfo,
    props.message,
    false
  ).map(c => ({ ...c, title: caseCategoriesById[c.category]?.title }))

  const recentCases: Grouped<CaseWithTitle>[] = caseOptionsNew
    .slice(0, 3)
    .map(c => ({ ...c, group: 'Recently Updated' }))

  const openCases: Grouped<CaseWithTitle>[] = caseOptionsNew
    .slice(3)
    .filter(c => !caseIsClosed(c))
    .map(c => ({ ...c, group: 'Other Open Cases' }))

  const groupedCategories: Grouped<ICaseCategory>[] = categories.map(c => ({
    ...c,
    group: c.parent || 'Uncategorized',
    title: '# ' + c.title,
  }))

  const groupSort = (arr: Grouped<CaseOrCategory>[], priorityMap: { [key: string]: number }) => {
    return [...arr].sort((a, b) => {
      const akey = a.group
      const bkey = b.group

      if (priorityMap[akey] !== undefined && priorityMap[bkey] !== undefined) {
        return priorityMap[akey] - priorityMap[bkey]
      } else if (priorityMap[akey] !== undefined) {
        return -1
      } else if (priorityMap[bkey] !== undefined) {
        return 1
      }

      return a.group.localeCompare(b.group)
    })
  }

  const triageCaseOrCategorySelectOptions = groupSort(
    [...recentCases, ...openCases, ...groupedCategories],
    {
      'Recently Updated': 0,
      'Other Open Cases': 1,
    }
  )

  const setMessageId = (patientId: number, uid: string, id: number) =>
    dispatch(chatSlice.actions.setMessageId({ patientId, uid, id }))

  const taskCollectionPreviewMutation = useTaskCollectionPreviewMutation()
  const dueDate = format(new Date(), 'YYYY-MM-DD')
  const ownerGroup = me.assigneeGroup?.id

  const { mutateAsync: handleUpdatePatientCase } = useUpdateCase()
  const { mutateAsync: handleAddPatientCase } = useAddCase()
  const { setSelectedCaseId } = useContext(SelectedTodoContext)

  const history = useHistory()

  const handleTriageMessage = async (selectedOption: CaseOrCategory) => {
    props.setIsLoading(true)
    if (isCase(selectedOption)) {
      let relations = [...selectedOption.relations]
      if (messageIdentifier) {
        const relation: ICaseRelation = await getMessageCaseRelation(
          messageIdentifier,
          props.patient.id,
          setMessageId
        )
        relations.push(relation)
      }
      // [UserGroupCleanUp]: Backend has implemented a dual write where it expects either an owner or an owner_group.
      // Below line will be removed after the backend changes where we start sending only the owner_group and not both.
      delete selectedOption.owner
      await handleUpdatePatientCase({
        ...selectedOption,
        tasks: selectedOption.tasks.map(t => pick(t, ...VIEWABLE_TASK_FIELDS) as ITask),
        relations: relations,
      })
      props.setIsLoading(false)
    } else {
      // Triaging to a Case Category?
      // First, create a new Case + any Task Collection Tasks
      var tasks: ITask[] = []
      if (selectedOption?.taskCollectionId) {
        const taskData = await taskCollectionPreviewMutation.mutateAsync({
          caseCategoryId: selectedOption.id,
          userId: props.patient.id,
          me: me.id,
          meAssigneeGroup: me.assigneeGroup!.id,
        })

        if (taskData) tasks = taskData.taskPreviews
      }

      let relations: ICaseRelation[] = []
      if (messageIdentifier) {
        const relation: ICaseRelation = await getMessageCaseRelation(
          messageIdentifier,
          props.patient.id,
          setMessageId
        )
        relations = [relation]
      }

      const addedCase = await handleAddPatientCase({
        user: props.patient.id,
        person: props.patient.person.id,
        category: selectedOption.id,
        tasks: tasks,
        relations: relations,
        description: selectedOption?.description || '',
        notes: selectedOption?.notes || '',
        dueDate: dueDate != '' ? dueDate : undefined,
        ownerGroup: ownerGroup ? ownerGroup : undefined,
        isProposed: undefined,
        createdBy: '',
      })

      handleAfterLinkCase(addedCase)
    }
  }

  const handleAfterLinkCase = (caseObj: ICase) => {
    if (caseObj) {
      setInlineTriage(false)
      props.setIsLoading(false)
      setSelectedCaseId(caseObj.id, { type: 'add' })
    }
  }

  const handleToggleMessageBucket = () => {
    closeMenu()
    if (message.kind != 'phonecall') toggleMessageBucket(message)
  }

  const handleToggleMessageUrgency = () => {
    closeMenu()
    if (message.kind != 'phonecall') toggleMessageUrgency(message)
  }
  const handleToggleSelectMultiple = () => {
    closeMenu()
    const existingQS = qs.parse(location.search)
    delete existingQS['caseId']
    delete existingQS['action']
    history.push({
      search: qs.stringify(existingQS),
    })
    props.setIsSelectingMultiple(current => !current)
  }

  useEffect(() => {
    if (messageBucketUpdatedData) {
      dispatch(chatSlice.actions.setMessageTags(messageBucketUpdatedData))
    }
  }, [messageBucketUpdatedData])

  useEffect(() => {
    if (messageUrgencyUpdatedData) {
      dispatch(chatSlice.actions.setMessageUrgency(messageUrgencyUpdatedData))
    }
  }, [messageUrgencyUpdatedData])

  useEffect(() => {
    if (!props.menuIsOpen) setInlineTriage(false)
  }, [props.menuIsOpen])

  const handleRemovePhoneCall = (phoneCallId: number) => {
    closeMenu()
    handleUpdatePhoneCall({
      id: phoneCallId,
      user: null,
      person: null,
    })
  }

  const tasksByMessageId = useSelector(state => state.tasks.tasksByMessageId)
  const messageTasks = tasksByMessageId[props.message.id]

  const canDeleteMessage =
    mine &&
    props.isLastMessage &&
    !instanceOfPhoneCall(props.message) &&
    (messageTasks || []).length == 0

  const [deleteModalOpen, setDeleteModalOpen] = useState<boolean>(false)
  const refreshDefaultThread = useRefreshDefaultThread(props.patient)

  const { mutate: deleteMutate, isLoading: deleteIsLoading } = useMutation(
    () =>
      apiClient.rest.delete(`/chat/v2/chat_message/${(props.message as IChatMessage).uid}/delete/`),
    {},
    { success: 'Message deleted', error: 'Failed to delete message' }
  )

  const handleDelete = async () => {
    try {
      await deleteMutate()
      await refreshDefaultThread()
    } catch (e) {
      console.error(e)
    } finally {
      setDeleteModalOpen(false)
    }
  }

  const shouldShowBucket = !!getBucketLabel(message) && !messageIdToCaseInfo[message.id]
  // Find the Case linked to the Message
  // If there are multiple Cases, don't select one
  const singleLinkedCase = messageIdToCaseInfo[message.id]
    ? messageIdToCaseInfo[message.id].length == 1
      ? messageIdToCaseInfo[message.id][0]
      : null
    : null

  const menuItems = (() => {
    const items: JSX.Element[] = []

    if (!isEvent) {
      if (message.kind === 'phonecall') {
        if ((messageIdToCaseInfo[message.id] || []).length === 0 && message.createdBy == me.id) {
          items.push(
            <MenuItem onClick={() => handleRemovePhoneCall(message.id)}>Remove phone call</MenuItem>
          )
        }
      } else {
        if (shouldShowBucket) {
          items.push(
            <MenuItem onClick={() => handleToggleMessageBucket()}>
              Mark as {isClinical(message) ? '"Operational"' : '"Clinical"'}
            </MenuItem>
          )
        }
        items.push(
          <MenuItem onClick={() => handleToggleMessageUrgency()}>
            {isUrgent(message) ? 'Remove "Urgent" tag' : 'Tag as "Urgent"'}
          </MenuItem>
        )
        items.push(<MenuItem onClick={handleToggleSelectMultiple}>Select multiple</MenuItem>)
      }
    }

    if (canDeleteMessage) {
      items.push(
        <MenuItem
          onClick={() => {
            closeMenu()
            setDeleteModalOpen(true)
          }}
        >
          Delete
        </MenuItem>
      )
    }

    return items.map((item, i) => ({ ...item, key: i }))
  })()

  const handleInlineTriageClick = () => {
    setInlineTriage(true)
  }

  const createForwardTask = () => {
    if (!singleLinkedCase) {
      console.error('createForwardTask no singleLinkedCase')
      return
    }
    const caseObj = patientCases?.find(caseObj => caseObj.id == singleLinkedCase.caseId)
    if (!caseObj) {
      console.error('createForwardTask no caseObj')
      return
    }
    setSelectedCaseId(singleLinkedCase.caseId, {
      type: 'createForwardTask',
      message: message,
      ownerGroup: caseObj.ownerGroup,
      timestamp: Date.now(),
    })
  }

  return (
    <>
      {inlineTriage ? (
        <Box
          display="flex"
          alignItems="center"
          flexWrap="wrap"
          position="relative"
          top=".7rem"
          width={'250px'}
          sx={{
            '& .MuiAutocomplete-root': {
              width: 'inherit',
            },
            '& .MuiAutocomplete-root .MuiAutocomplete-inputRoot .MuiAutocomplete-input': {
              width: 'inherit',
            },
          }}
        >
          <TriageCaseCategorySelect
            onChange={handleTriageMessage}
            options={triageCaseOrCategorySelectOptions}
            onBlur={() => setInlineTriage(false)}
          />
        </Box>
      ) : (
        <Box display="flex" position="relative" flexGrow={1} alignItems="center">
          {shouldShowBucket ? <BucketChip message={message} /> : null}
          {isEvent ? null : (
            <Box sx={{ cursor: 'pointer' }}>
              <Tooltip title="Case">
                <Chip
                  icon={<FolderIcon style={{ color: MUI.colors.blue[500] }} />}
                  variant={'outlined'}
                  size="small"
                  onClick={handleInlineTriageClick}
                  sx={{
                    color: 'primary.main',
                    borderColor: 'transparent',
                    backgroundColor: 'transparent',
                    '& .MuiChip-icon': {
                      marginRight: -2,
                    },
                  }}
                />
              </Tooltip>
              {singleLinkedCase ? (
                <Tooltip title="Forward">
                  <Chip
                    icon={<ForwardIcon style={{ color: MUI.colors.blue[500] }} />}
                    variant={'outlined'}
                    size="small"
                    onClick={createForwardTask}
                    sx={{
                      color: 'primary.main',
                      borderColor: 'transparent',
                      backgroundColor: 'transparent',
                      '& .MuiChip-icon': {
                        marginRight: -2,
                      },
                    }}
                  />
                </Tooltip>
              ) : null}
            </Box>
          )}
          {menuItems.length ? (
            <IconButton
              size="small"
              title="More..."
              onClick={event => setMenuAnchorEl(event.currentTarget)}
            >
              <MoreVertIcon />
            </IconButton>
          ) : null}

          <Menu
            anchorEl={menuAnchorEl}
            open={menuIsOpen}
            onClose={() => closeMenu()}
            PaperProps={{
              sx: { minWidth: '100px', filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))' },
            }}
          >
            {menuItems}
          </Menu>
        </Box>
      )}
      {canDeleteMessage ? (
        <DeleteChatMessageModal
          onModalConfirm={handleDelete}
          modalOpen={deleteModalOpen}
          setModalOpen={setDeleteModalOpen}
          messageText={chatMessageText(message, sender)}
          loading={deleteIsLoading}
        />
      ) : null}
    </>
  )
}

export default MessageActions
