import { ICarePlan } from './CarePlan'
import { ICase } from './Case'
import { ITodoItem } from './TodoItem'
import Moment from 'moment'

interface IBaseTask {
  owner?: number
  ownerGroup?: number
  createdBy?: number
  createdAt?: string
  completedOn?: number
  dueDate: string | Date
  id?: number
  isComplete: boolean
  completedBy?: number
  parentId?: number | null
  patient?: number
  person?: number
  patientMeta?: any
  title: string
  priority: number
  taskTemplatePk?: number // which template was this task built from?
  isForwardingMessage?: boolean
}

interface IChatMessageV2Task extends IBaseTask {
  contentType: 'chatmessagev2'
  contentObject?: {}
}

interface ICarePlanTask extends IBaseTask {
  contentType: 'careplan'
  contentObject?: Pick<
    ICarePlan,
    'id' | 'createdAt' | 'createdBy' | 'updatedAt' | 'updatedBy' | 'title' | 'patient'
  >
}

interface ICaseTask extends IBaseTask {
  contentType: 'case'
  contentObject?: Pick<
    ICase,
    | 'id'
    | 'updatedAt'
    | 'user'
    | 'category'
    | 'description'
    | 'relations'
    | 'actions'
    | 'action'
    | 'status'
    | 'autoComplete'
    | 'isProposed'
    | 'createdBy'
  >
}

interface IFormSubmissionTask extends IBaseTask {
  contentType: 'formsubmission'
  contentObject?: {} // TODO
}

interface IInsuranceMemberInfoTask extends IBaseTask {
  contentType: 'insurancememberinfo'
  contentObject?: {}
}

interface IUndefinedTask extends IBaseTask {
  contentType?: undefined
  contentObject?: {}
}

interface IZeroTask extends IBaseTask {
  contentType: 0 // TODO: 0 is an artifact of a bug in CreateTask
  contentObject?: {}
}

export type ITask =
  | IChatMessageV2Task
  | ICarePlanTask
  | ICaseTask
  | IFormSubmissionTask
  | IInsuranceMemberInfoTask
  | IUndefinedTask
  | IZeroTask

const ValidContentType = [
  'careplan',
  'case',
  'formsubmission',
  'insurancememberinfo',
  'chatmessagev2',
]

interface IUnvalidatedRelation {
  contentType: string
  objectId: number
  contentObject: any
}

interface IValidRelation extends IUnvalidatedRelation {
  contentType: 'careplan' | 'case' | 'formsubmission' | 'insurancememberinfo' | 'chatmessagev2'
}

function isValidRelation(relation: IUnvalidatedRelation): relation is IValidRelation {
  return ValidContentType.includes(relation.contentType)
}

// IServerTask is the task payload as returned by the API, before we have enhanced it using CreateTask.
export type IServerTask = Pick<
  ITask,
  | 'owner'
  | 'ownerGroup'
  | 'createdBy'
  | 'completedBy'
  | 'completedOn'
  | 'createdAt'
  | 'id'
  | 'isComplete'
  | 'patient'
  | 'patientMeta'
  | 'person'
  | 'title'
  | 'priority'
> & {
  dueDate: string // Server can't send a Date
  relations: IUnvalidatedRelation[]
}

export interface UpdateTaskParent {
  taskId: number
  oldObjectId: number
  newObjectId: number
  oldContentType: string | number | undefined
  newContentType?: string | number | undefined
}

export const CreateTask = ({
  owner,
  ownerGroup,
  createdBy,
  completedBy,
  completedOn,
  createdAt,
  dueDate,
  id,
  isComplete,
  patient,
  patientMeta,
  person,
  title,
  relations,
  priority,
}: IServerTask): ITask => {
  // TODO: Ensure that all tasks come back with valid content types.
  // Filter out relations with invalid content types
  const validRelations = relations.filter(isValidRelation)
  // Dates are passed down as UTC midnight datetimes from server. Slice dates to YYYY-MM-DD
  // TODO: While Task.due_date on the backend allows `null=True` and `blank=True`, in reality there are zero null or
  // blank due_date values as of today.
  // const formattedDueDate = dueDate ? Moment(dueDate.slice(0, 10)) : undefined
  const formattedDueDate = Moment(dueDate.slice(0, 10))

  return {
    owner,
    ownerGroup,
    createdBy,
    completedBy,
    completedOn,
    createdAt,
    // TODO: Handle when due_date is null?
    // dueDate: formattedDueDate ? formattedDueDate.toDate() : null,
    dueDate: formattedDueDate.toDate(),
    id,
    isComplete,
    parentId: validRelations.length ? validRelations[0].objectId : null,
    patient,
    patientMeta,
    person,
    title,
    contentType: validRelations.length && validRelations[0].contentType,
    priority,
    contentObject: validRelations.length ? validRelations[0].contentObject : {},
  }
}

export const getTodoItemFromTask = (task: ITask): ITodoItem => {
  return {
    owner: task.owner,
    ownerGroup: task.ownerGroup,
    createdBy: task.createdBy,
    dueDate: new Date(task.dueDate),
    id: task.id!,
    isComplete: task.isComplete,
    patient: task.patient,
    priority: task.priority,
    subContentType: task.contentType!,
    contentType: 'task',
    contentObject: task,
  }
}

export const CreateMockTask = (payload: any = {}) =>
  CreateTask({
    owner: payload.owner || 0,
    ownerGroup: payload.ownerGroup || 0,
    createdBy: payload.createdBy || 0,
    completedBy: payload.completedBy || 0,
    completedOn: payload.completedOn || Moment().format(),
    createdAt: payload.createdAt || Moment().format(),
    dueDate: payload.dueDate || Moment().format(),
    id: payload.id || 0,
    isComplete: payload.isComplete || false,
    patient: payload.patient || 0,
    patientMeta: payload.patientMeta || {},
    title: payload.title || 'TEST TASK TITLE',
    relations: payload.relations || [{ contentType: 'chatmessagev2' }],
    priority: payload.priority || 0,
  })

export const VIEWABLE_TASK_FIELDS = [
  'id',
  'title',
  'dueDate',
  'ownerGroup',
  'priority',
  'isComplete',
]
