import { ProviderUser } from '@fireflyhealth/core'
import {
  GET_PROVIDERS_SUCCESS,
  GET_PROVIDERS_ERROR,
  UPDATE_PROVIDER_PRACTICING_STATES,
} from '../actions/actionTypes'

const INITIAL_STATE: ProviderUser[] = []

const providers = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_PROVIDERS_SUCCESS:
      return action.providers as ProviderUser[]
    case GET_PROVIDERS_ERROR:
      return [...state]
    case UPDATE_PROVIDER_PRACTICING_STATES:
      return state.map(provider => {
        if (provider.id == action.payload.id) {
          return {
            ...provider,
            providerFields: {
              ...provider.providerFields,
              practicingStates: action.payload.practicingStates,
            },
          }
        } else {
          return provider
        }
      })
    default:
      return state
  }
}

export default providers
