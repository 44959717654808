import {
  Add as AddIcon,
  Clear as ClearIcon,
  ArrowDropDown as ArrowDropDownIcon,
  ArrowRight as ArrowRightIcon,
  AutoAwesome as AutoAwesomeIcon,
  ExpandMore as ExpandMoreIcon,
  Face as FaceIcon,
  List as ListIcon,
} from '@mui/icons-material'
import {
  Autocomplete,
  Badge,
  Box,
  Button,
  Grid,
  IconButton,
  LinearProgress,
  Link,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from '@mui/material'

import { IInboxWorkUnitTask, InboxWorkUnit } from '~/models/InboxWorkUnit'
import React, { useContext, useEffect, useMemo, useState } from 'react'
import { Transition, TransitionGroup } from 'react-transition-group'
import { groupBy, keyBy } from 'lodash'
import { useAddTask, useUpdateTask } from '~/api/TaskService'
import { useHistory, useLocation } from 'react-router'
import { useInbox } from '~/api/InboxService'

import AddTaskEditor from '~/components/Tasks/TaskEditor'
import CheckBox from '~/components/CheckBox/CheckBox'
import { ErrorBoundary } from 'react-error-boundary'
import ErrorScreen from '~/components/ErrorScreen'
import FFElation from '~/components/Generic/FFElation'
import { IStateMachineFields } from '~/models/StateMachine'
import { ITask } from '~/models/Task'
import InterfaceContent from '~/components/Interface/InterfaceContent'
import Moment from 'moment'
import NextActionDate from '~/components/WorkUnit/NextActionDate'
import PriorityIcon from '~/components/Generic/PriorityIcon'
import { Link as RouterLink } from 'react-router-dom'
import UserGroupSelect from '~/components/Generic/UserGroupSelect'
import classNames from 'classnames'
import { format } from 'date-fns'
import { getDueDateLabel } from '~/components/Tasks/utils'
import lodash from 'lodash'
import { makeStyles } from 'tss-react/mui'
import qs from 'query-string'
import { useLocalStorage } from '~/components/Providers/LocalStorageProvider'
import { useSelector } from 'react-redux'
import { useUpdateCase } from '~/api/CaseService'
import { ICaseTag, workunitCompleteStates } from '~/models/Case'
import { usePodFilterOptions } from '~/utils/usePodFilterOptions'
import TodoTypeSelect, { SelectedTypeFilters } from './TodoTypeSelect'
import { useGroupOptions } from '~/utils/useGroupOptions'
import CaseTag from '~/components/PatientCategorizedTodos/CaseTag'
import { InboxRowSelectedPayload, logEvent } from '~/utils/events'

const useStyles = makeStyles<void, 'showOnRowHover'>()((theme, _params, classes) => ({
  inboxRowExit: {
    transform: 'translateX(100%)',
    // Roughly following https://material.io/design/motion/speed.html#easing
    transition: 'transform 200ms cubic-bezier(0.4, 0.0, 1, 1)',
    // Transitioning a table row to collapse is tricky. Row height is determined
    // by the contents, which don't have max height set by default.
    //
    // When the animation begins, set the max height to 100% of the viewport
    // height, on the assumption that most rows will fit within the viewport,
    // and scrolling will be limited within the short duration of the animation.
    '*': {
      '@keyframes shrink': {
        '0%': {
          maxHeight: '100vh',
        },
        '100%': {
          maxHeight: 0,
        },
      },
      animation: 'shrink 200ms cubic-bezier(0.4, 0.0, 0.2, 1) 200ms',
    },
  },
  sparkle: {
    '@keyframes sparkle': {
      '0%': {
        opacity: 0,
      },
      '5%': {
        opacity: 1,
      },
      '100%': {
        opacity: 0,
      },
    },
    animation: 'sparkle 6s linear 0s infinite normal',
    ':nth-of-type(1)': {
      animationDelay: '1s',
    },
    ':nth-of-type(2)': {
      animationDelay: '2s',
    },
    ':nth-of-type(3)': {
      animationDelay: '3s',
    },
    ':nth-of-type(4)': {
      animationDelay: '4s',
    },
    ':nth-of-type(5)': {
      animationDelay: '5s',
    },
  },
  expandLink: {
    '&:hover': {
      cursor: 'pointer',
      color: theme.palette.primary.main,
    },
  },
  row: {
    [`&:hover .${classes.showOnRowHover}`]: {
      opacity: 1,
      transition: '100ms linear opacity',
    },
  },
  showOnRowHover: {
    opacity: 0,
    transition: '100ms linear opacity',
  },
  machineGeneratedIcon: {
    color: 'black',
    paddingLeft: '0.5rem',
    paddingTop: '0.3rem',
    paddingRight: '2rem',
    fontSize: '1.6rem',
    '&:hover': {
      cursor: 'default',
      backgroundColor: 'transparent',
    },
  },
}))

// Context for passing down filter state.
const Context = React.createContext<{
  filteredOwnerId?: string
  filteredOwnerGroupIds?: number[]
}>({})

interface PodFilterOption {
  name: string
  id: number
}

const Inbox = () => {
  const { classes } = useStyles()
  const me = useSelector(state => state.me)

  const [showAdvancedFilter, setShowAdvancedFilter] = useState<boolean>(false)
  const [showOldView, setShowOldView] = useLocalStorage('showOldView', 'no')
  const [ownerGroupIds, setOwnerGroupIds] = useQueryParam<number[] | undefined>(
    'ownerGroupIds',
    me.assigneeGroup != undefined && showOldView === 'yes' ? [me.assigneeGroup.id] : undefined,
    {
      parse: value => (value as string | undefined)?.split(',').map(param => Number(param)),
      stringify: value => value?.join(',') || null,
    }
  )
  const [ownerId, setOwnerId] = useQueryParam<number | undefined>(
    'ownerId',
    showOldView === 'yes' ? undefined : me.id
  )
  const [podIds, setPodIds] = useQueryParam<number[] | undefined>('podIds', undefined, {
    parse: value => (value as string | undefined)?.split(',').map(param => Number(param)),
    stringify: value => value?.join(',') || null,
  })
  const [dueDateFilter, setDueDateFilter] = useQueryParam<NextActionDateFilterOption | undefined>(
    'nextActionDate',
    'Today'
  )
  const dueDateRange = dueDateFilter ? NEXT_ACTION_DATE_FILTER_TO_RANGE[dueDateFilter] : undefined
  const [typeFilters, setTypeFilters] = useQueryParam<SelectedTypeFilters>('types', undefined, {
    parse: value => (value ? JSON.parse(value as string) : undefined),
    stringify: value => JSON.stringify(value),
  })
  const [page, setPage] = useState(0)
  const [pageSizePref, setPageSizePref] = useLocalStorage('inbox.pageSize')
  const pageSize = pageSizePref ? parseInt(pageSizePref) : 25

  const { data, isLoading } = useInbox({
    ownerGroupIds: ownerGroupIds ? ownerGroupIds : undefined,
    ownerIds: ownerId ? [ownerId] : undefined,
    podIds,
    dueDateRange: dueDateRange,
    carePlanAutocreatedFromIds: typeFilters
      ? typeFilters.filter(filter => filter.type === 'Care Plan').map(template => template.id)
      : undefined,
    caseCategoryIds: typeFilters
      ? typeFilters.filter(filter => filter.type === 'Case Category').map(category => category.id)
      : undefined,
    limit: pageSize,
    offset: page * pageSize,
  })
  let podFilterOptions = usePodFilterOptions()
  let isNoCareTeam = podFilterOptions?.some(podFilterOption => {
    return podFilterOption.id === 0
  })
  if (!isNoCareTeam || !podFilterOptions || podFilterOptions.length == 0) {
    podFilterOptions.push({
      id: 0,
      name: 'No Care Team',
    })
  }
  // On updation of search params, reset the current page to 0
  useEffect(() => {
    setPage(0)
  }, [dueDateRange, typeFilters, ownerGroupIds, ownerId, podIds])

  // Whenever the search parameters change, update the key in local storage so
  // that they remain "sticky".
  const location = useLocation()
  const [__, setInboxParams] = useLocalStorage('inboxParams')
  useEffect(() => {
    setInboxParams(location.search)
    if (showOldView === 'yes') setOwnerId(undefined)
  }, [setInboxParams, location.search, ownerId])
  const handleView = () => {
    if (showOldView == 'yes') {
      setOwnerId(me.id)
      setOwnerGroupIds(undefined)
    } else {
      setOwnerId(undefined)
      setOwnerGroupIds(me.assigneeGroup != undefined ? [me.assigneeGroup.id] : undefined)
      setShowAdvancedFilter(false)
    }
    showOldView == 'yes' ? setShowOldView('no') : setShowOldView('yes')
  }
  const handleAdvanceFilter = () => {
    if (showOldView === 'no' && showAdvancedFilter) {
      setOwnerId(me.id)
      setOwnerGroupIds(undefined)
    }
    setShowAdvancedFilter(!showAdvancedFilter)
  }

  return (
    <InterfaceContent>
      {isLoading && <LinearProgress />}
      <ErrorBoundary FallbackComponent={ErrorScreen}>
        <Box sx={{ display: 'flex', padding: 2 }}>
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
              {showOldView == 'yes' && (
                <Grid item xs={3}>
                  <OwnerGroupFilter
                    value={ownerGroupIds}
                    onChange={value => setOwnerGroupIds(value)}
                    label="Assigned to"
                  />
                </Grid>
              )}
              {showOldView == 'yes' && (
                <Grid item xs={3}>
                  <PodFilter
                    value={podIds}
                    onChange={value => setPodIds(value)}
                    podFilterOptions={podFilterOptions}
                  />
                </Grid>
              )}
              <Grid item xs={showOldView == 'no' ? 2 : 3}>
                <NextActionDateFilter
                  value={dueDateFilter}
                  onChange={value => setDueDateFilter(value)}
                />
              </Grid>
              <Grid item xs={showOldView == 'no' ? 2 : 3}>
                <TodoTypeSelect
                  data={data}
                  currentSelections={typeFilters}
                  onClose={selected =>
                    setTypeFilters(
                      (selected || []).map(option => ({
                        type: option.type,
                        id: option.id,
                        label: option.label,
                      })) ?? []
                    )
                  }
                />
              </Grid>
              {showAdvancedFilter && (
                <Grid item xs={2}>
                  <ViewAsFilter value={ownerId} onChange={value => setOwnerId(value ?? me.id)} />
                </Grid>
              )}
              {showAdvancedFilter && (
                <Grid item xs={2}>
                  <OwnerGroupFilter
                    value={ownerGroupIds}
                    onChange={value => setOwnerGroupIds(value)}
                    label="Groups"
                    groupOnly
                  />
                </Grid>
              )}
              {showAdvancedFilter && (
                <Grid item xs={2}>
                  <PodFilter
                    value={podIds}
                    onChange={value => setPodIds(value)}
                    podFilterOptions={podFilterOptions}
                  />
                </Grid>
              )}
            </Grid>
          </Box>
          {showOldView == 'no' && (
            <Box>
              <IconButton color="secondary" onClick={handleAdvanceFilter}>
                {showAdvancedFilter ? (
                  <ClearIcon color="inherit" sx={{ fontSize: '1.4rem', fontWeight: 500 }} />
                ) : (
                  <AddIcon color="inherit" sx={{ fontSize: '1.4rem', fontWeight: 500 }} />
                )}
                <Typography sx={{ fontSize: '1.4rem', fontWeight: 500 }}>
                  {showAdvancedFilter ? 'Clear' : 'Show'} advanced filters
                </Typography>
              </IconButton>
            </Box>
          )}
        </Box>
        <Box marginLeft={1}>
          <IconButton color="primary" onClick={handleView}>
            <Typography sx={{ fontSize: '1.4rem', fontWeight: 500 }}>
              Switch to the {showOldView == 'yes' ? 'new' : 'old'} filters
            </Typography>
          </IconButton>
        </Box>
        <Box sx={{ display: 'flex', height: '100%' }}>
          <Box sx={{ flexGrow: 1 }}>
            {!isLoading &&
            ((ownerGroupIds?.length === 1 && ownerGroupIds[0] === me.assigneeGroup?.id) ||
              (ownerId && ownerId === me.id)) &&
            data?.results?.length === 0 ? (
              <Grid container justifyContent="center" alignItems="center" height="100%">
                <Grid item>
                  <Sparkle>
                    <Typography sx={{ fontWeight: 'bold' }}>Nice work!</Typography>
                  </Sparkle>
                </Grid>
              </Grid>
            ) : (
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TablePagination
                        count={data?.count ?? 0}
                        page={page}
                        rowsPerPage={pageSize}
                        onPageChange={(_event, newPage) => {
                          setPage(newPage)
                        }}
                        onRowsPerPageChange={event => {
                          setPageSizePref(event.target.value)
                        }}
                      />
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <Context.Provider
                      value={{
                        ...{ filteredOwnerId: ownerId ? ownerId : undefined },
                        ...{ filteredOwnerGroupIds: ownerGroupIds },
                      }}
                    >
                      <TransitionGroup component={null}>
                        {data?.results?.map((result, i) => (
                          // We want to animate rows as they exit to make the
                          // transition less jarring. Material UI provides
                          // transition components, but these can only take a single
                          // child, and each InboxRow is potentially two TableRows
                          // (the second for the collapsible content). DOM
                          // validation prohibits anything but a TableRow from being
                          // a child of a TableBody.
                          //
                          // To get around these constraints, we fall back to using
                          // the bare Transition component, and pass down props to
                          // each TableRow.
                          <Transition
                            key={`${result.contentType}-${result.objectId}`}
                            timeout={400}
                          >
                            {status => (
                              <InboxRow
                                TableRowProps={{
                                  className:
                                    status === 'exiting' || status === 'exited'
                                      ? classes.inboxRowExit
                                      : undefined,
                                  sx: {
                                    borderTop: 'solid',
                                    borderWidth: '.15rem',
                                    borderColor: 'grey.100',
                                  },
                                }}
                                workUnit={result}
                                dueDateRange={dueDateRange}
                                positionalInfo={{
                                  pagePosition: i,
                                  absolutePosition: page * pageSize + i,
                                }}
                              />
                            )}
                          </Transition>
                        ))}
                      </TransitionGroup>
                    </Context.Provider>
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </Box>
        </Box>
      </ErrorBoundary>
    </InterfaceContent>
  )
}

// Helper for using query params as state.
export const useQueryParam = <T extends unknown = qs.ParsedQuery[keyof qs.ParsedQuery]>(
  param: string,
  defaultValue?: T,
  {
    parse,
    stringify,
  }: {
    parse: (value: qs.ParsedQuery[keyof qs.ParsedQuery]) => T
    stringify: (value: T) => qs.ParsedQuery[keyof qs.ParsedQuery]
  } = {
    parse: value => value as T,
    stringify: value => value as qs.ParsedQuery[keyof qs.ParsedQuery],
  }
): [T | undefined, (newValue: T) => void] => {
  const history = useHistory()
  const location = useLocation()
  const params = qs.parse(location.search)
  const value = parse(params[param])

  const [state, setState] = useState(value !== undefined ? value : defaultValue)

  return [
    state,
    newValue => {
      setState(newValue)
      history.push(
        qs.stringifyUrl({
          url: location.pathname,
          query: { ...params, [param]: stringify(newValue) },
        })
      )
    },
  ]
}

export interface AssigneeGroupOption {
  label: string
  name: string
  id?: number // the Assignee Group id
  cssClass?: string
}

const OwnerGroupFilter = ({
  value,
  onChange,
  label,
  cssClass,
  groupOnly,
}: {
  value?: number[]
  onChange: (value?: number[]) => void
  label?: string
  cssClass?: string
  groupOnly?: boolean
}) => {
  const preferences = useSelector(state => state.preferences)
  const providers = useSelector(state => state.providers)
  const assigneeGroups = useSelector(state => state.assigneeGroups)

  const { groupedOptions } = useMemo(() => {
    const providerTypeByID = providers.reduce((lookup, provider) => {
      if (provider.providerFields?.practicingStates) {
        lookup[provider.id] = 'Clinicians'
        return lookup
      }

      lookup[provider.id] = 'Other'
      return lookup
    }, {})

    const grouped = groupBy(providers, provider => providerTypeByID[provider.id])
    const groupedClinicians = grouped.Clinicians
      ? grouped.Clinicians.map(provider => ({
          label: 'Clinical',
          name: `${provider.assigneeGroup?.name}`,
          value: provider.assigneeGroup?.id,
          practicingStates: provider.providerFields?.practicingStates
            ?.map(location => location.abbreviation)
            .join(', '),
        }))
      : []
    const groupedOther = grouped.Other
      ? grouped.Other.map(provider => ({
          label: 'Other',
          name: `${provider.assigneeGroup?.name}`,
          value: provider.assigneeGroup?.id,
          practicingStates: provider.providerFields?.practicingStates
            ?.map(location => location.abbreviation)
            .join(', '),
        }))
      : []

    const orderedGroupOfUsers = [...(assigneeGroups || [])]?.sort((a, b) =>
      a.name.localeCompare(b.name)
    )
    const groupedGroupOfUsers = orderedGroupOfUsers
      ? orderedGroupOfUsers.map(group => ({
          label: 'GROUPS',
          name: `${group.name}`,
          value: group.id,
          practicingStates: '',
        }))
      : []
    let groupedOptions
    if (groupOnly) {
      groupedOptions = groupedGroupOfUsers
    } else {
      groupedOptions = groupedClinicians
      groupedOptions = groupedOptions.concat(groupedOther, groupedGroupOfUsers)
    }

    return { groupedOptions }
  }, [providers, preferences, assigneeGroups])

  const providerGroupsByID = keyBy(groupedOptions, 'value')

  return (
    <Autocomplete
      size="small"
      multiple
      options={groupedOptions}
      groupBy={provider => provider.label}
      getOptionLabel={provider => provider.name}
      filterSelectedOptions
      renderInput={params => <TextField {...params} label={label} />}
      isOptionEqualToValue={(option, value) => option.value == value.value}
      classes={{
        input: cssClass ? cssClass : '',
        option: cssClass ? cssClass : '',
      }}
      sx={{
        '.MuiInputLabel-root.MuiFormLabel-root': {
          fontSize: cssClass ? '1.4rem' : '1.6rem',
          zIndex: cssClass ? '0' : '1',
        },
      }}
      renderOption={(props, provider) => (
        <li {...props} key={provider.value}>
          <span>
            {provider.name || 'Unknown Provider'}
            {provider.practicingStates && (
              <Typography display="inline" color="secondary" className={cssClass ? cssClass : ''}>
                {'  '}
                {provider.practicingStates}
              </Typography>
            )}
          </span>
        </li>
      )}
      value={value?.map(id => providerGroupsByID[id] || { id }) ?? []}
      onChange={(_, selected) => {
        onChange(selected.map(provider => (provider.value ? provider.value : 0)))
      }}
      disableCloseOnSelect
    />
  )
}

const PodFilter = ({
  value,
  onChange,
  podFilterOptions,
}: {
  value?: number[]
  onChange: (value?: number[]) => void
  podFilterOptions?: PodFilterOption[]
}) => {
  const podsByID = useMemo(() => keyBy(podFilterOptions, 'id'), [podFilterOptions])
  return (
    <Autocomplete
      size="small"
      multiple
      options={podFilterOptions ? podFilterOptions : []}
      getOptionLabel={pod => `${pod.name}`}
      isOptionEqualToValue={(option, value) => option.id == value.id}
      filterSelectedOptions
      renderInput={params => <TextField {...params} label={'Care pods'} />}
      renderOption={(props, pod) => (
        <li {...props}>
          <span>{pod.name}</span>
        </li>
      )}
      value={value?.map(id => podsByID[id] || { id }) ?? []}
      onChange={(_, selected) => {
        onChange(selected?.map(pod => pod.id) ?? [])
      }}
      disableCloseOnSelect
    />
  )
}

const NEXT_ACTION_DATE_FILTER_TO_RANGE = {
  Today: {
    end: Moment().endOf('day').toDate(),
  },
  'Next 7 Days': {
    start: Moment().startOf('day').toDate(),
    end: Moment().add('7', 'days').endOf('day').toDate(),
  },
  Overdue: {
    end: Moment().subtract(1, 'days').endOf('day').toDate(),
  },
}

type NextActionDateFilterOption = keyof typeof NEXT_ACTION_DATE_FILTER_TO_RANGE

const NextActionDateFilter = ({
  value,
  onChange,
}: {
  value?: NextActionDateFilterOption
  onChange: (value?: NextActionDateFilterOption) => void
}) => {
  return (
    <Autocomplete<NextActionDateFilterOption>
      size="small"
      options={Object.keys(NEXT_ACTION_DATE_FILTER_TO_RANGE) as NextActionDateFilterOption[]}
      value={value}
      renderInput={params => <TextField {...params} label="Next action date" />}
      onChange={(_, selected) => {
        onChange(selected ?? undefined)
      }}
    />
  )
}

const ViewAsFilter = ({
  value,
  onChange,
}: {
  value?: number
  onChange: (value?: number) => void
}) => {
  const preferences = useSelector(state => state.preferences)
  const providers = useSelector(state => state.providers)

  const { groupedOptions } = useMemo(() => {
    const providerTypeByID = providers.reduce((lookup, provider) => {
      if (provider.providerFields?.practicingStates) {
        lookup[provider.id] = 'Clinicians'
        return lookup
      }

      lookup[provider.id] = 'Other'
      return lookup
    }, {})

    const grouped = groupBy(providers, provider => providerTypeByID[provider.id])
    const groupedClinicians = grouped.Clinicians
      ? grouped.Clinicians.map(provider => ({
          label: 'Clinical',
          name: `${provider.assigneeGroup?.name}`,
          value: provider.id,
          practicingStates: provider.providerFields?.practicingStates
            ?.map(location => location.abbreviation)
            .join(', '),
        }))
      : []
    const groupedOther = grouped.Other
      ? grouped.Other.map(provider => ({
          label: 'Other',
          name: `${provider.assigneeGroup?.name}`,
          value: provider.id,
          practicingStates: provider.providerFields?.practicingStates
            ?.map(location => location.abbreviation)
            .join(', '),
        }))
      : []

    let groupedOptions = groupedClinicians
    groupedOptions = groupedOptions.concat(groupedOther)

    return { groupedOptions }
  }, [providers, preferences])

  const providerGroupsByID = keyBy(groupedOptions, 'value')
  return (
    <Autocomplete
      size="small"
      options={groupedOptions}
      isOptionEqualToValue={(option, value) => option.value == value.value}
      groupBy={provider => provider.label}
      getOptionLabel={provider => provider.name}
      value={value ? providerGroupsByID[value] : undefined}
      renderInput={params => <TextField {...params} label="View as" />}
      renderOption={(props, provider) => (
        <li {...props} key={provider.value}>
          <span>
            {provider.name || 'Unknown Provider'}
            {provider.practicingStates && (
              <Typography display="inline" color="secondary">
                {'  '}
                {provider.practicingStates}
              </Typography>
            )}
          </span>
        </li>
      )}
      onChange={(_, selected) => {
        onChange(selected ? selected.value : undefined)
      }}
    />
  )
}

const InboxRow = ({
  TableRowProps,
  workUnit,
  dueDateRange,
  positionalInfo,
}: {
  TableRowProps?: React.ComponentProps<typeof TableRow>
  workUnit: InboxWorkUnit
  dueDateRange?: {
    start?: Date
    end: Date
  }
  positionalInfo: {
    pagePosition: number
    absolutePosition: number
  }
}) => {
  const logInboxItemSelection = (urlType: InboxRowSelectedPayload['urlType']) => {
    // Analytics tracking to understand how users are intereacting with items in the Inbox
    // e.g. how far down on the page are they scrolling to pick up work?
    logEvent<InboxRowSelectedPayload>('INBOX_ROW_SELECTED', {
      ...positionalInfo,
      urlType,
      contentType: workUnit.contentType || '',
      contentObjectId: workUnit.objectId,
    })
  }

  switch (workUnit.contentType) {
    case 'careplan': {
      return (
        <CarePlanInboxRow
          TableRowProps={TableRowProps}
          carePlan={workUnit}
          dueDateRange={dueDateRange}
          onClickCapture={logInboxItemSelection}
        />
      )
    }
    case 'case': {
      return (
        <CaseInboxRow
          TableRowProps={TableRowProps}
          case={workUnit}
          dueDateRange={dueDateRange}
          onClickCapture={logInboxItemSelection}
        />
      )
    }
    case 'insurancememberinfo': {
      return (
        <InsuranceMemberInfoInboxRow
          TableRowProps={TableRowProps}
          insuranceMemberInfo={workUnit}
          dueDateRange={dueDateRange}
          onClickCapture={logInboxItemSelection}
        />
      )
    }
  }

  // Unknown content type!
  return (
    <InboxRowContent
      person={{
        firstName: 'Unknown',
        lastName: 'Unknown',
      }}
      title="Unknown type"
      subtitle={`Please report in #firefly-support that ${workUnit.contentType} ID ${workUnit.objectId} is not appearing properly in the inbox`}
      dueDate={workUnit.dueDate}
      dueDateRange={dueDateRange}
      canChangeOwner={false}
      canAddTask={false}
    />
  )
}

const CarePlanInboxRow = ({
  TableRowProps,
  carePlan,
  dueDateRange,
  onClickCapture,
}: {
  TableRowProps?: React.ComponentProps<typeof TableRow>
  carePlan: Extract<InboxWorkUnit, { contentType: 'careplan' }>
  dueDateRange?: {
    start?: Date
    end: Date
  }
  onClickCapture: InboxRowContentProps['onClickCapture']
}) => {
  return (
    <InboxRowContent
      TableRowProps={TableRowProps}
      person={carePlan.contentObject.patient}
      // TODO: this could go to the /todos tab and deep-link to the Care Plan using the `careplanId` query param
      url={`/patients/${carePlan.contentObject.patient.id}/careplans`}
      urlType="profile_care_plans"
      title={carePlan.contentObject.title}
      dueDate={carePlan.dueDate}
      tasks={carePlan.items}
      dueDateRange={dueDateRange}
      canChangeOwner={false}
      canAddTask={false}
      onClickCapture={onClickCapture}
    />
  )
}

const CaseInboxRow = (props: {
  TableRowProps?: React.ComponentProps<typeof TableRow>
  case: Extract<InboxWorkUnit, { contentType: 'case' }>
  dueDateRange?: {
    start?: Date
    end: Date
  }
  onClickCapture: InboxRowContentProps['onClickCapture']
}) => {
  const { mutateAsync: handleUpdateCase, isLoading } = useUpdateCase()

  const { mutateAsync: handleUpdateCaseStatus, isLoading: isLoadingStatus } = useUpdateCase()

  return (
    <InboxRowContent
      TableRowProps={props.TableRowProps}
      person={props.case.contentObject.person}
      url={`/people/${props.case.contentObject.person.id}/todos?caseId=${props.case.objectId}`}
      urlType="case"
      ownerGroup={props.case.contentObject.ownerGroup}
      title={props.case.contentObject.category.title}
      subtitle={props.case.contentObject.description}
      status={props.case.contentObject.status}
      actions={props.case.contentObject.actions}
      statusCategories={props.case.contentObject.statesWithCategories}
      isProposed={props.case.contentObject.isProposed}
      createdBy={props.case.contentObject.createdBy}
      onAction={action => {
        handleUpdateCaseStatus({
          id: props.case.objectId,
          action,
        })
      }}
      dueDate={props.case.contentObject.dueDate}
      onChangeDueDate={dueDate => {
        handleUpdateCase({
          id: props.case.objectId,
          dueDate,
        })
      }}
      onChangeOwnerGroup={ownerGroup => {
        handleUpdateCase({
          id: props.case.objectId,
          ownerGroup,
        })
      }}
      tasks={props.case.items}
      loading={isLoading}
      loadingStatus={isLoadingStatus}
      dueDateRange={props.dueDateRange}
      canChangeOwner={true}
      canAddTask={true}
      taskRelation={{
        content_type: 'case',
        object_id: props.case.objectId,
      }}
      tags={props.case.contentObject.caseTags}
      onClickCapture={props.onClickCapture}
    />
  )
}

const InsuranceMemberInfoInboxRow = ({
  TableRowProps,
  insuranceMemberInfo,
  dueDateRange,
  onClickCapture,
}: {
  TableRowProps?: React.ComponentProps<typeof TableRow>
  insuranceMemberInfo: Extract<InboxWorkUnit, { contentType: 'insurancememberinfo' }>
  dueDateRange?: {
    start?: Date
    end: Date
  }
  onClickCapture: InboxRowContentProps['onClickCapture']
}) => {
  return (
    <InboxRowContent
      TableRowProps={TableRowProps}
      person={insuranceMemberInfo.contentObject.person}
      url={`/people/${insuranceMemberInfo.contentObject.person.id}/profile`}
      urlType="profile"
      title="Insurance Upload"
      dueDate={insuranceMemberInfo.dueDate}
      tasks={insuranceMemberInfo.items}
      dueDateRange={dueDateRange}
      canChangeOwner={false}
      canAddTask={false}
      onClickCapture={onClickCapture}
    />
  )
}

const InboxTaskRow = ({ task, careTeam }: { task: IInboxWorkUnitTask; careTeam?: number[] }) => {
  const [isChecked, setIsChecked] = useState(task.contentObject.isComplete)

  const { isLoading, mutate: handleUpdateTask } = useUpdateTask()

  const onChangeDueDate = dueDate => {
    const formattedDueDate = dueDate ? Moment(dueDate).format() : undefined
    handleUpdateTask({
      id: task.contentObject.id,
      dueDate: formattedDueDate,
    } as ITask)
  }

  const { selectedCaseOwnerGroupOption = null, flatOptions } = useGroupOptions(
    task.contentObject.ownerGroup?.id || null,
    false,
    null,
    careTeam,
    false
  )

  return (
    <TableRow>
      <TableCell></TableCell>
      <TableCell>
        <Grid container flexWrap="nowrap">
          <Grid item>
            <CheckBox
              disabled={isLoading}
              value={isChecked}
              onChecked={async value => {
                setIsChecked(value)
                try {
                  await handleUpdateTask({
                    id: task.contentObject.id,
                    isComplete: value,
                  } as ITask)
                } catch (e) {
                  setIsChecked(!value)
                }
              }}
            ></CheckBox>
          </Grid>
          <Grid item container flexDirection="column" justifyContent="flex-start">
            <Grid item>
              <Box sx={{ display: 'inline-flex' }}>
                <Box sx={{ marginRight: '0.5rem' }}>
                  {task.contentObject.priority ? (
                    <PriorityIcon level={task.contentObject.priority} variant="view" />
                  ) : null}
                </Box>
                <Typography display="inline">{task.contentObject.title}</Typography>
              </Box>
            </Grid>
            <Grid item>
              <Typography color="secondary">
                Created {getDueDateLabel(Moment(task.contentObject.createdAt).toDate())}
                {task.contentObject.createdBy
                  ? ` by ${task.contentObject.createdBy.firstName} ${task.contentObject.createdBy.lastName}`
                  : null}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </TableCell>
      <TableCell align="right" sx={{ verticalAlign: 'top' }}>
        <Grid container justifyContent="flex-start" flexDirection="column">
          <Grid item>
            <UserGroupSelect
              selectedValue={selectedCaseOwnerGroupOption}
              options={flatOptions}
              onChange={ownerGroupOption => {
                const ownerGroup = ownerGroupOption.value
                handleUpdateTask({
                  id: task.contentObject.id,
                  ownerGroup,
                } as ITask)
              }}
              detailed
              disabled={isLoading}
            />
          </Grid>
        </Grid>
      </TableCell>
      <TableCell></TableCell>
      <TableCell sx={{ verticalAlign: 'top' }}>
        <Grid container>
          <Grid item>
            <NextActionDate
              date={task.contentObject.dueDate}
              editable={true}
              onChange={onChangeDueDate}
            />
          </Grid>
        </Grid>
      </TableCell>
      <TableCell></TableCell>
    </TableRow>
  )
}

const ConditionalLink: React.FC<{
  to?: string
  urlType?: InboxRowContentProps['urlType']
  onClickCapture: InboxRowContentProps['onClickCapture']
}> = ({ to, children, urlType, onClickCapture }) => {
  if (!to) {
    return <>{children}</>
  }

  return (
    <Link
      component={RouterLink}
      sx={{
        textDecoration: 'none',
        cursor: 'pointer',
        transition: '100ms linear color',
        color: 'inherit',
        '&:hover': {
          color: 'primary.main',
        },
      }}
      to={to}
      onClickCapture={_event => (onClickCapture ? onClickCapture(urlType) : undefined)}
    >
      {children}
    </Link>
  )
}

interface InboxRowContentProps {
  TableRowProps?: React.ComponentProps<typeof TableRow>
  person: {
    id?: number
    firstName: string | null
    lastName: string | null
    elationUrl?: string | null
    careTeam?: number[]
    userId?: number
  }
  url?: string
  urlType?: InboxRowSelectedPayload['urlType']
  owner?: {
    id: number
  } | null
  ownerGroup?: {
    id: number
  } | null
  title: string
  subtitle?: string
  status?: IStateMachineFields['status']
  actions?: IStateMachineFields['actions']
  statusCategories?: IStateMachineFields['statesWithCategories']
  isProposed?: boolean | null
  createdBy?: string
  onAction?: (action: IStateMachineFields['action']) => void
  dueDate?: InboxWorkUnit['dueDate']
  onChangeDueDate?: (newDate: string) => void
  onChangeOwnerGroup?: (newOwnerGroup: number) => void
  tasks?: IInboxWorkUnitTask[]
  loading?: boolean
  loadingStatus?: boolean
  dueDateRange?: {
    start?: Date
    end: Date
  }
  canChangeOwner: boolean
  canAddTask: boolean
  taskRelation?: {
    content_type: string
    object_id: number
  }
  tags?: ICaseTag[]
  onClickCapture?: (urlType: InboxRowContentProps['urlType']) => void
}

const InboxRowContent = ({
  TableRowProps,
  person,
  url,
  urlType,
  ownerGroup,
  title,
  subtitle,
  status,
  actions,
  statusCategories,
  isProposed,
  onAction,
  dueDate,
  onChangeDueDate,
  onChangeOwnerGroup,
  tasks,
  loading,
  loadingStatus,
  dueDateRange,
  canChangeOwner,
  canAddTask,
  taskRelation,
  tags,
  onClickCapture,
}: InboxRowContentProps) => {
  const { classes } = useStyles()

  const { filteredOwnerGroupIds } = useContext(Context)
  const { filteredOwnerId } = useContext(Context)

  const { isLoading, mutate: handleCreateTask } = useAddTask()

  const me = useSelector<any, any>(state => state.me)

  const newTaskData = {
    title: '',
    dueDate: format(new Date(), 'YYYY-MM-DD'),
    ...{ ownerGroup: me.assigneeGroup?.id },
    priority: 0,
    isComplete: false,
  }

  const [addingTask, setAddingTask] = useState<boolean>(false)
  const [newTask, setNewTask] = useState<ITask>(newTaskData)

  const [isOpen, setIsOpen] = useState(Boolean(tasks?.length))
  const [showAllIncomplete, setShowAllIncomplete] = useState(false)

  const incompleteTasks = tasks?.filter(task => task.contentObject.isComplete == false)
  const sortedIncompleteTasks = lodash.sortBy(incompleteTasks, [
    function (task) {
      return task.contentObject.dueDate
    },
  ])

  const availableActions =
    // If there are incomplete tasks, don't allow moving the parent to a completed state
    incompleteTasks && statusCategories && incompleteTasks.length > 0
      ? actions?.filter(
          action =>
            !workunitCompleteStates.includes(
              statusCategories?.find(
                stateWithCategory => stateWithCategory['state']['name'] == action.dest
              )?.category
            )
        )
      : actions

  const handleAddTask = async () => {
    const payload = {
      title: newTask.title,
      ...{ ownerGroup: newTask.ownerGroup },
      dueDate: Moment(newTask.dueDate).format(),
      priority: newTask.priority,
      person: person.id,
      patient: person.userId,
      relations: [taskRelation],
      isComplete: false,
    }
    handleCreateTask(payload)
    setAddingTask(!addingTask)
    setNewTask(newTaskData)
  }

  const isTaskHidden = (task: IInboxWorkUnitTask) => {
    // If we are not filtering by any owner, show all incomplete tasks.
    if (!filteredOwnerGroupIds?.length && !filteredOwnerId) {
      return false
    }
    // Only show tasks due in the due date filter range
    const taskDueDate = new Date(task.contentObject.dueDate)
    // Some due date rilter range does not have a start date
    const isTaskDueDateAfterStartFilter =
      !dueDateRange?.start || (dueDateRange?.start && taskDueDate >= dueDateRange.start)
    const isTaskInDateRange =
      (isTaskDueDateAfterStartFilter && dueDateRange?.end && taskDueDate <= dueDateRange.end) ||
      !dueDateRange
    if (!isTaskInDateRange) return true

    // User/group filters
    if (
      task.contentObject.ownerGroup?.id &&
      filteredOwnerGroupIds &&
      filteredOwnerGroupIds?.includes(task.contentObject.ownerGroup.id)
    ) {
      return false
    }
    if (
      task.contentObject.owner?.id &&
      filteredOwnerId &&
      parseInt(filteredOwnerId) === task.contentObject.owner.id
    ) {
      return false
    }

    return true
  }

  const numHiddenTasks = incompleteTasks?.filter(task => isTaskHidden(task)).length
  const isMachineGenerated = isProposed

  const { selectedCaseOwnerGroupOption, flatOptions } = useGroupOptions(
    ownerGroup?.id || null,
    false,
    null,
    person?.careTeam,
    !canChangeOwner
  )

  return (
    <>
      <TableRow {...TableRowProps} className={classNames(classes.row, TableRowProps?.className)}>
        <TableCell sx={{ verticalAlign: 'top', minWidth: '20vw' }}>
          <Grid container flexDirection="column" justifyContent="flex-start" marginBottom={-1}>
            <Grid item>
              <ConditionalLink
                to={url}
                onClickCapture={_event => (onClickCapture ? onClickCapture(urlType) : undefined)}
              >
                <Typography sx={{ fontWeight: 'bold' }}>
                  {person.firstName} {person.lastName}
                </Typography>
              </ConditionalLink>
            </Grid>
            <Grid item container className={classes.showOnRowHover}>
              {url ? (
                <Grid item>
                  <Link
                    component={RouterLink}
                    to={url}
                    onClickCapture={_event =>
                      onClickCapture ? onClickCapture(urlType) : undefined
                    }
                    sx={{
                      textDecoration: 'none',
                      cursor: 'pointer',
                      transition: '100ms linear color',
                      color: 'inherit',
                      '&:hover': {
                        color: 'primary.main',
                      },
                    }}
                  >
                    <FaceIcon fontSize="inherit" titleAccess="Go to patient" />
                    <Typography display="inline" title="Go to patient" variant="caption">
                      Go to patient
                    </Typography>
                  </Link>
                </Grid>
              ) : null}
              {person?.elationUrl ? (
                <Grid item ml={2}>
                  <Link
                    href={person.elationUrl}
                    onClickCapture={_event =>
                      onClickCapture ? onClickCapture('elation') : undefined
                    }
                    target="blank"
                    sx={{
                      textDecoration: 'none',
                      cursor: 'pointer',
                      transition: '100ms linear color',
                      color: 'inherit',
                      '&:hover': {
                        color: 'primary.main',
                      },
                    }}
                  >
                    <FFElation titleAccess="Open in Elation" />
                    <Typography display="inline" title="Open in Elation" variant="caption">
                      Open in Elation
                    </Typography>
                  </Link>
                </Grid>
              ) : null}
            </Grid>
          </Grid>
        </TableCell>
        <TableCell sx={{ verticalAlign: 'top', width: '100%' }}>
          <Grid container flexDirection="column" justifyContent="flex-start">
            <Grid item>
              <Typography sx={{ fontWeight: 'bold' }}>
                {title}{' '}
                {isMachineGenerated ? (
                  <IconButton
                    className={classes.machineGeneratedIcon}
                    style={{
                      opacity: '1',
                    }}
                  >
                    <AutoAwesomeIcon fontSize="inherit" />
                  </IconButton>
                ) : null}
              </Typography>
            </Grid>
            <Grid item>
              <Typography color="secondary">{subtitle}</Typography>
              {tags &&
                tags.length > 0 &&
                tags?.map(caseTag => {
                  return <CaseTag key={String(caseTag.id)} tag={caseTag.tag} />
                })}
            </Grid>
          </Grid>
        </TableCell>
        <TableCell align="right" sx={{ verticalAlign: 'top', minWidth: '20vw' }}>
          <Grid container justifyContent="flex-start" flexDirection="column">
            <Grid item>
              <UserGroupSelect
                selectedValue={selectedCaseOwnerGroupOption || null}
                options={flatOptions}
                onChange={ownerGroupOption => {
                  const ownerGroup = ownerGroupOption.value
                  if (ownerGroup) onChangeOwnerGroup?.(ownerGroup)
                }}
                disabled={!canChangeOwner || loading}
                detailed
              />
            </Grid>
          </Grid>
        </TableCell>
        <TableCell align="right" sx={{ verticalAlign: 'top' }}>
          <Grid container justifyContent="flex-end">
            <Grid item>
              {isMachineGenerated ? (
                <Typography>Machine-Generated</Typography>
              ) : availableActions ? (
                <Autocomplete
                  size="small"
                  disableClearable={true}
                  style={{ width: 180 }}
                  autoHighlight
                  value={{
                    dest: status!,
                  }}
                  options={availableActions || []}
                  getOptionLabel={option => {
                    // `option` could be the option to represent the current value
                    // in which case we only know the current status
                    // or it could be a state transition
                    // in which case the trigger name is what we want to show
                    return option.trigger || status!
                  }}
                  isOptionEqualToValue={(option, _value) => !!option.trigger}
                  onChange={(_event, newValue) => {
                    onAction?.(newValue.trigger!)
                  }}
                  renderInput={params => (
                    <TextField
                      {...params}
                      size="small"
                      sx={{
                        '& fieldset': { border: 'none' },
                        '&': {
                          backgroundColor: '#f5f5f5',
                          borderRadius: '10px',
                        },
                      }}
                    />
                  )}
                  disabled={loadingStatus}
                />
              ) : (
                status ?? null
              )}
            </Grid>
          </Grid>
        </TableCell>
        <TableCell sx={{ verticalAlign: 'top' }}>
          <Grid container>
            <Grid item>
              <NextActionDate
                date={dueDate}
                editable={Boolean(onChangeDueDate)}
                onChange={onChangeDueDate}
              />
            </Grid>
          </Grid>
        </TableCell>
        <TableCell align="right" sx={{ verticalAlign: 'top' }}>
          <Grid container justifyContent="flex-end">
            <Grid item>
              <IconButton
                onClick={() => {
                  setIsOpen(!isOpen)
                }}
              >
                {isOpen ? (
                  <ArrowDropDownIcon color={incompleteTasks?.length ? 'primary' : undefined} />
                ) : (
                  <ArrowRightIcon color={incompleteTasks?.length ? 'primary' : undefined} />
                )}
                <Badge badgeContent={incompleteTasks?.length ?? null} color="primary">
                  <ListIcon color={incompleteTasks?.length ? 'primary' : undefined} />
                </Badge>
              </IconButton>
            </Grid>
          </Grid>
        </TableCell>
      </TableRow>
      {isOpen
        ? sortedIncompleteTasks
            ?.filter(task => showAllIncomplete || !isTaskHidden(task))
            .map(task => (
              <InboxTaskRow key={task.objectId} task={task} careTeam={person?.careTeam} />
            ))
        : null}
      {isOpen && addingTask ? (
        <TableRow className={classNames(classes.row, TableRowProps?.className)}>
          <TableCell sx={{ verticalAlign: 'top', minWidth: '20vw' }} />
          <TableCell sx={{ verticalAlign: 'top', width: '100%' }} colSpan={2}>
            <Box width="93%" margin="1rem 0 2rem 2rem">
              <Paper>
                <Box p={2}>
                  <Box marginTop={1} marginBottom={4}>
                    <AddTaskEditor
                      disabled={isLoading}
                      task={newTask}
                      patient={{ person }}
                      onChange={task => {
                        setNewTask(task)
                      }}
                      canAssignToPatient={false}
                    />
                  </Box>
                  <Box mt={2} display="flex" flex={1} justifyContent="flex-end">
                    <Box ml={1}>
                      <Button disabled={isLoading} onClick={() => setAddingTask(!addingTask)}>
                        Cancel
                      </Button>
                      <Button
                        disabled={isLoading || !newTask.title}
                        variant="contained"
                        color="primary"
                        onClick={() => {
                          handleAddTask()
                        }}
                      >
                        <Typography variant="button">Add Task</Typography>
                      </Button>
                    </Box>
                  </Box>
                </Box>
              </Paper>
            </Box>
          </TableCell>
        </TableRow>
      ) : null}
      {isOpen && !addingTask ? (
        <TableRow className={classNames(classes.row, TableRowProps?.className)}>
          <TableCell sx={{ verticalAlign: 'top', minWidth: '20vw' }} />
          <TableCell sx={{ verticalAlign: 'top', width: '100%' }}>
            <Grid
              container
              flexDirection="row"
              justifyContent="flex-start"
              alignItems="flex-end"
              spacing="10"
            >
              {canAddTask ? (
                <Grid item>
                  <Link
                    onClick={() => {
                      setAddingTask(true)
                    }}
                    color="secondary"
                    underline="none"
                    className={classes.expandLink}
                  >
                    <Typography variant="body2" fontWeight="bold">
                      <AddIcon fontSize="inherit" />
                      Add Task
                    </Typography>
                  </Link>
                </Grid>
              ) : null}
              {numHiddenTasks ? (
                <Grid item>
                  <Link
                    onClick={() => {
                      setShowAllIncomplete(!showAllIncomplete)
                    }}
                    color="secondary"
                    underline="none"
                    className={classes.expandLink}
                  >
                    <Typography variant="body2" fontWeight="bold">
                      <ExpandMoreIcon fontSize="inherit" />
                      {showAllIncomplete ? 'Hide' : 'Show'} all incomplete ({numHiddenTasks})
                    </Typography>
                  </Link>
                </Grid>
              ) : null}
            </Grid>
          </TableCell>
        </TableRow>
      ) : null}
    </>
  )
}

// Very important component...should evoke the feeling of having a
// sparkling-clean inbox.
const Sparkle: React.FC<{}> = ({ children }) => {
  const { classes } = useStyles()

  const RADIUS = 100

  return (
    <Box style={{ position: 'relative' }}>
      {Array.from(Array(5).keys()).map((_, i) => (
        <Box
          key={i}
          className={classes.sparkle}
          style={{
            position: 'absolute',
            // Attempting to distribute sparkles uniformly on a circle.
            // See https://stackoverflow.com/questions/5837572/generate-a-random-point-within-a-circle-uniformly
            transform: `translate(${
              RADIUS * Math.sqrt(Math.random()) * Math.cos(Math.random() * 2 * Math.PI)
            }px, ${RADIUS * Math.sqrt(Math.random()) * Math.sin(Math.random() * 2 * Math.PI)}px)`,
          }}
        >
          ✨
        </Box>
      ))}
      <>{children}</>
    </Box>
  )
}

export default Inbox
