import { useQuery } from 'react-query'

import { apiClient } from '~/api/rest'
import { SpecialtyDropdownOption } from '../types'
import { getServices, getLocationTypesBasedOnProgram } from '~/api/SteerageService'

/**
 * Loads all dependencies for the Referral Details section of the Steerage View.
 * Caches indefinitely and performs the necessary mutations to get specialty groups
 * and location types into a single array.
 */
export const useReferralDetails = person_id =>
  useQuery(
    ['referralDetails'],
    async () => {
      return Promise.all([
        getLocationTypesBasedOnProgram(person_id),
        apiClient.fact.getSpecialtyGroups(),
        getServices(),
      ])
    },
    {
      select: ([locationTypes, specialtyGroups, lastMileServices]) => {
        if (!locationTypes) return
        if (!specialtyGroups) return

        let allOptions: SpecialtyDropdownOption[] = []
        for (const specialtyGroup of specialtyGroups) {
          const option: SpecialtyDropdownOption = {
            id: specialtyGroup.id,
            label: specialtyGroup.label,
            source: 'SpecialtyGroup',
            services: specialtyGroup?.services,
          }
          allOptions.push(option)
        }
        for (const locationType of locationTypes) {
          // There are some Location Types that duplicate Specialties. From an analytics perspective,
          // we don't care which is utilized for selection, so we are arbitrarily going to use the
          // Specialty version. This is necessary so that the list doesn't have two elements with the
          // same Label.
          const labelExists = allOptions.find(option => option.label == locationType.label)
          if (labelExists) {
            continue
          }
          const option: SpecialtyDropdownOption = {
            id: locationType.id,
            label: locationType.label,
            source: 'LocationType',
            services: locationType?.services,
          }
          allOptions.push(option)
        }
        allOptions.sort((a, b) => a.label.localeCompare(b.label))
        return {
          specialtiesAndLocationTypes: allOptions,
          lastMileServices,
        }
      },
      staleTime: Infinity,
    }
  )
