import { useState, useEffect } from 'react'
import { useQueryClient } from 'react-query'
import type { FC } from 'react'
import { useHistory } from 'react-router'
import { apiClient } from '~/api/rest'
import { useLocation } from 'react-router-dom'
import { Box, Grid, Divider, Button, Radio, Typography } from '@mui/material'
import SteerageProviderForm from './SteerageProviderForm'
import Moment from 'moment'
import { Steerage, SteerageProvider } from './types'
import { logEvent, SteerageEventPayload } from '~/utils/events'
import SteerageProviderListItem from './SteerageProviderListItem'
import Loader from '~/components/Loader'
import { useStates, useUnselectSteerageProvider } from '../../../api/SteerageService'
import { listSteerageSteerageProvidersKey } from '~/api/SteerageService'
import MemberSelectedView, { selectedProvider } from './MemberSelectedView'

interface SteerageProps {
  steerage: Steerage
  steerageProviders?: Array<SteerageProvider> | null | undefined
  onClickOfAddProvider: () => void
  steerageDescription: string | null
}

/**
 * @deprecated
 * // TODO: Refactor to useReasonForException
 */
const getReasonForExceptionProvider = (): Promise<string[][]> => {
  return apiClient.rest.get(`/referral/steerage/reason-for-exception/?reason=manual`)
}

const SteerageProviderList: FC<SteerageProps> = props => {
  const steerage = props?.steerage
  const client = useQueryClient()
  const location = useLocation()
  const history = useHistory()
  const [showAddProviderForm, setShowAddProviderForm] = useState(false)
  const [showEditProviderForm, setShowEditProviderForm] = useState<number | null>()
  const [validReasonsForException, setValidReasonsForException] = useState<string[][]>([])
  const [memberSelectedProvider, setMemberSelectedProvider] = useState<number | null>(
    selectedProvider(steerage?.steerageProviders)?.id ?? null
  )
  const { data: states } = useStates()

  const handleExceptionOnClick = event => {
    // show the create form and hide the edit form
    setShowAddProviderForm(showAddProviderForm == true ? false : true)
    setShowEditProviderForm(null) // setting value to null for hiding the edit form
    logEvent<SteerageEventPayload>('STEERAGE_ADD_EXCEPTION', {
      steerageId: props.steerage.id,
    })
  }

  const hideAddUpdateProviderForm = event => {
    setShowAddProviderForm(false)
    setShowEditProviderForm(null)
  }

  // Disable the add exception button if there are providers under review
  const isAddExceptionDisabled =
    props?.steerage?.steerageProviders?.some(provider => provider.status === 'under_review') ??
    false

  //API's for selecting/unselecting the steerage provider on behalf of a member
  const { isLoading: isUnselectingProvider, mutateAsync: handleUnselectingSteerageProvider } =
    useUnselectSteerageProvider()

  // populating the reason for exception list for provider
  useEffect(() => {
    if (validReasonsForException.length <= 0) {
      getReasonForExceptionProvider()
        .then(result => {
          if (result) setValidReasonsForException(result)
        })
        .catch(e => {
          console.error(`Failed to load Reason for exception list: Exception:${e}`)
        })
    }
  }, [])
  const addProviderViaSearch = () => {
    props?.onClickOfAddProvider()
    const path = location.pathname
    const basePath = path.substring(0, path.lastIndexOf('/'))

    // Redirect it to provider search and passing Case ID, so that we can link back to case
    // patients/<user_id>/todos?caseId=<case_id>.
    const description =
      props?.steerageDescription ||
      (steerage.createdAt && Moment(steerage.createdAt).format('MM/DD/YYYY'))
    const steerageId = steerage ? steerage?.id : null

    const searchParams = new URLSearchParams()
    searchParams.set('caseDescription', description ?? '')
    searchParams.set('steerage_id', steerageId?.toString() ?? '')

    if (steerage.specialtyGroup) {
      searchParams.set('specialty_group', steerage.specialtyGroup.toString())
    }

    if (steerage?.cases != null && steerage.cases.length > 0) {
      const caseId = steerage?.activeCaseId || null
      searchParams.set('caseId', caseId?.toString() ?? '')
    }
    history.push(`${basePath}/providersearch?` + searchParams.toString())
  }

  const handleMemberSelectedProviderChange = () => {
    let payload = {
      steerageId: props?.steerage?.id ?? null,
      providerId: null,
    }
    setMemberSelectedProvider(null)
    // unselecting the steerage provider
    handleUnselectingSteerageProvider(payload).then(async () => {
      // invalidate queries for refetching the providers
      await client.cancelQueries([listSteerageSteerageProvidersKey, props?.steerage?.id])
      await client.invalidateQueries([listSteerageSteerageProvidersKey, props?.steerage?.id])
    })
  }

  return (
    <Box mt={3}>
      <Typography variant="body1" sx={{ fontWeight: 500 }}>
        Providers
      </Typography>
      {props?.steerageProviders && props?.steerageProviders?.length > 0 && (
        <Box>
          <Typography variant="body2" sx={{ fontWeight: 500, my: 1 }}>
            Added
          </Typography>
          <Divider />
        </Box>
      )}
      {props?.steerageProviders?.map(provider => (
        <SteerageProviderListItem
          key={provider?.id}
          steerage={steerage}
          provider={provider}
          setShowEditProviderForm={setShowEditProviderForm}
          showEditProviderForm={showEditProviderForm ?? null}
          showAddProviderForm={showAddProviderForm}
          setShowAddProviderForm={setShowAddProviderForm}
          validReasonsForException={validReasonsForException}
          memberSelectedProvider={memberSelectedProvider}
          setMemberSelectedProvider={setMemberSelectedProvider}
        />
      ))}
      {/* Only show the radio buttons when it is locked and length of provider is greater then one */}
      {steerage?.isLocked &&
        steerage?.steerageProviders &&
        steerage?.steerageProviders?.length > 1 && (
          <Box mt={1} mb={1}>
            {isUnselectingProvider ? <Loader /> : null}
            <Grid container>
              <Grid item xs={1}>
                <Radio
                  checked={memberSelectedProvider ? false : true}
                  value={null}
                  name="radio-buttons"
                  onChange={handleMemberSelectedProviderChange}
                />
              </Grid>
              <Grid item xs={11}>
                <Box mt={1}>No provider is selected</Box>
              </Grid>
            </Grid>
            <Divider />
          </Box>
        )}
      {!steerage?.isLocked && showAddProviderForm && (
        <Box mt={2} fontWeight="400">
          <SteerageProviderForm
            steerage={props?.steerage}
            steerageProviderCreateForm={true}
            steerageProvider={null}
            onCancel={hideAddUpdateProviderForm}
            reasonForExceptionList={validReasonsForException}
            states={states ?? []}
          />
        </Box>
      )}

      {!steerage?.isLocked && (
        <Grid container spacing={3}>
          <Grid item>
            <Box ml={-1}>
              <Button component="span" title="Add Provider" onClick={addProviderViaSearch}>
                + Add providers
              </Button>
            </Box>
            <Box ml={-1}>
              <Button
                component="span"
                title="Add Exception"
                disabled={isAddExceptionDisabled}
                onClick={handleExceptionOnClick}
              >
                + Add exception
              </Button>
            </Box>
          </Grid>
        </Grid>
      )}
      {steerage?.isLocked && props?.steerageProviders && (
        <MemberSelectedView
          steerageProviders={props?.steerageProviders}
          steerage={props.steerage}
        />
      )}
    </Box>
  )
}
export default SteerageProviderList
