import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { queueNotification } from '~/redux/actions/notifications'
import { ClinicHours } from '@fireflyhealth/core'
import {
  Button,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material'

import { apiClient } from '~/api/rest'
import { makeStyles } from 'tss-react/mui'

const HEADER_COLUMNS = [
  { id: 'dayOfWeek', label: 'Day' },
  { id: 'fromHour', label: 'Open Time' },
  { id: 'toHour', label: 'Close Time' },
]

const SORTER = {
  monday: 1,
  tuesday: 2,
  wednesday: 3,
  thursday: 4,
  friday: 5,
  saturday: 6,
  sunday: 7,
}

const ClinicHoursSettings = () => {
  const [hours, setHours] = useState<any>(null)
  const dispatch = useDispatch()
  const { classes } = useStyles()
  const me = useSelector(state => state.me)
  const tenantKey = me.providerFields?.tenantKey
  const clinicHoursResource = apiClient.resource<ClinicHours>(
    `/clinic-hours/v3/?tenant_id=${tenantKey}`
  )

  useEffect(() => {
    const fetchHours = async () => {
      const data = await clinicHoursResource.getAll()
      data.sort(function sortByDay(a, b) {
        const day1 = a.dayOfWeek.toLowerCase()
        const day2 = b.dayOfWeek.toLowerCase()
        return SORTER[day1] - SORTER[day2]
      })
      setHours(data)
    }
    fetchHours()
  }, [])

  const updateHours = (index, key, value) => {
    const newHours = [...hours]
    newHours[index][key] = value
    setHours(newHours)
  }

  const submitHours = async index => {
    if (hours[index].fromHour > hours[index].toHour) {
      dispatch(
        queueNotification({
          variant: 'error',
          message: 'Open time cannot be later than Close time',
        })
      )
    } else {
      try {
        await apiClient.rest.patch(`/clinic-hours/set/${hours[index].dayOfWeek}/`, {
          fromHour: hours[index].fromHour,
          toHour: hours[index].toHour,
        })
        dispatch(
          queueNotification({
            variant: 'success',
            message: 'Saved clinic hours',
          })
        )
      } catch {
        dispatch(
          queueNotification({
            variant: 'error',
            message: 'Failed to save clinic hours',
          })
        )
      }
    }
  }

  if (hours == null) {
    return <CircularProgress />
  }

  return (
    <div className={classes.container}>
      <Typography variant="h6">Practice Hours</Typography>
      <Table id="empanelment-table">
        <TableHead>
          <TableRow>
            {HEADER_COLUMNS.map(headerColumn => (
              <TableCell className={classes.cell} key={headerColumn.id}>
                {headerColumn.label}
              </TableCell>
            ))}
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {hours.map((hoursOfDay, index) => (
            <TableRow key={index}>
              <TableCell>
                <Typography>{hoursOfDay.dayOfWeek}</Typography>
              </TableCell>
              <TableCell>
                <TextField
                  type="time"
                  value={hoursOfDay.fromHour}
                  onChange={e => updateHours(index, 'fromHour', e.target.value)}
                />
              </TableCell>
              <TableCell>
                <TextField
                  type="time"
                  value={hoursOfDay.toHour}
                  onChange={e => updateHours(index, 'toHour', e.target.value)}
                />
              </TableCell>
              <TableCell>
                <Button
                  type="submit"
                  color="primary"
                  variant="contained"
                  onClick={() => submitHours(index)}
                >
                  Save
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  )
}

const useStyles = makeStyles()(theme => ({
  cell: {
    paddingTop: '1.5em',
    paddingBottom: '1.5em',
    paddingLeft: '1em',
    paddingRight: '1em!important',
  },
  container: {
    padding: theme.spacing(2),
  },
  selectWrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  select: {
    width: '25rem',
    margin: 5,
  },
}))

export default ClinicHoursSettings
