import type { FC } from 'react'
import { Typography, Box } from '@mui/material'
import Moment from 'moment'
import { Steerage } from './types'

interface SteerageProps {
  steerage: Steerage
}

const SteerageReadOnlyView: FC<SteerageProps> = props => {
  const steerage = props?.steerage ? props?.steerage : null
  return (
    <Box>
      <Typography>{steerage?.description}</Typography>
      {steerage?.isEffectivelyWaiverOnly || steerage?.isEffectivelyReferralAndWaiver ? (
        <Typography>
          {steerage?.waiver?.serviceCategories
            ?.map(function (serviceCategory) {
              return serviceCategory.label
            })
            .join(', ')}
        </Typography>
      ) : null}
      <Typography>
        {steerage?.effectiveFrom && Moment(steerage?.effectiveFrom).format('MM/DD/YYYY')}
        {steerage?.effectiveFrom && steerage?.effectiveThrough ? ' - ' : null}
        {steerage?.effectiveThrough && Moment(steerage?.effectiveThrough).format('MM/DD/YYYY')}
      </Typography>
      <Typography>
        {steerage?.serviceQuantity && steerage?.serviceQuantity?.value}{' '}
        {steerage?.serviceQuantity && steerage?.serviceQuantity?.unit}
      </Typography>
    </Box>
  )
}

export default SteerageReadOnlyView
