import type { FC } from 'react'
import { useState, useEffect } from 'react'
import { useQueryClient } from 'react-query'
import {
  Alert,
  Box,
  Typography,
  Button,
  Modal,
  IconButton,
  TextField,
  Menu,
  MenuItem,
} from '@mui/material'
import ClearIcon from '@mui/icons-material/Clear'
import { makeStyles } from 'tss-react/mui'
import InsertCommentOutlinedIcon from '@mui/icons-material/InsertCommentOutlined'
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined'
import Loader from '~/components/Loader'
import {
  PersonPreferenceCreateUpdateInterface,
  getPersonPreferenceKey,
  useCreatePersonPreference,
  useFetchPersonPreference,
  useUpdatePersonPreference,
} from '~/api/SteerageService'

export interface PersonPreferenceProps {
  personId: number
  showTitle: boolean
}

const useStyles = makeStyles()(theme => {
  return {
    modalTitle: {
      fontWeight: 500,
      fontSize: '20px',
      lineHeight: '16px',
    },
    saveButton: {
      borderRadius: '28px',
    },
    deleteCancelButton: {
      fontWeight: 500,
    },
    deleteSubText: {
      fontWeight: 400,
    },
    cancelButton: {
      fontWeight: 600,
      marginRight: '10px',
    },
  }
})
const modalStyle = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 514,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
}

const PersonPreference: FC<PersonPreferenceProps> = props => {
  const { classes } = useStyles()
  const client = useQueryClient()
  const [preference, setPreference] = useState<string>('')
  //API for fetching person preference
  const { isLoading: isFetchingPersonPreference, data: personPreferences } =
    useFetchPersonPreference(props?.personId)
  // API for creating person preference
  const { isLoading: isAddingPersonPreference, mutateAsync: handleCreatePersonPreference } =
    useCreatePersonPreference()
  // API for editing person preference
  const { isLoading: isUpdatingPersonPreference, mutateAsync: handleUpdatePersonPreference } =
    useUpdatePersonPreference()
  const [personPreference] = personPreferences ?? []

  // Handling the Create/Update Person Preference Modal
  const [open, setOpen] = useState(false)
  const handleCreateUpdateModalOpen = () => setOpen(true)
  const handleCreateUpdateModalClose = () => {
    setOpen(false)
    setPreference(personPreference?.preference ?? '')
    // close the menu in case open
    handleMenuClose()
  }

  // Handling the delete Person Preference Modal
  const [openDeleteModal, setOpenDeleteModal] = useState(false)
  const handleDeleteModalOpen = () => setOpenDeleteModal(true)
  const handleDeleteModalClose = () => {
    setOpenDeleteModal(false)
    // Close the menu
    handleMenuClose()
  }

  useEffect(() => {
    setPreference(personPreference?.preference ?? '')
  }, [personPreference])

  const handleCreateUpdateModal = () => {
    // First create payload
    const payload: PersonPreferenceCreateUpdateInterface = {
      person: props?.personId,
      preference: preference,
    }

    // Check if we are creating person preference
    if (!personPreference) {
      // Call the API to create person preferences
      handleCreatePersonPreference(payload).then(async () => {
        // invalidate queries for refetching the preferences
        await client.cancelQueries([getPersonPreferenceKey, props.personId])
        await client.invalidateQueries([getPersonPreferenceKey, props.personId])
      })
      // Close the modal
      handleCreateUpdateModalClose()
      return
    }

    // Else Update the person preference
    payload['personPreferenceId'] = personPreference.id
    // Call the API to update person preferences
    handleUpdatePersonPreference(payload).then(async () => {
      // invalidate queries for refetching the preferences
      await client.cancelQueries([getPersonPreferenceKey, props.personId])
      await client.invalidateQueries([getPersonPreferenceKey, props.personId])
    })
    // Close the modal
    handleCreateUpdateModalClose()
    // close the menu
    handleMenuClose()
    return
  }

  const handleDeletePersonPreferenceModal = () => {
    // First create payload
    const payload: PersonPreferenceCreateUpdateInterface = {
      person: props?.personId,
      preference: null,
      personPreferenceId: personPreference.id,
    }

    // Call the API to update person preferences with preference as null
    handleUpdatePersonPreference(payload).then(async () => {
      // invalidate queries for refetching the preferences
      await client.cancelQueries([getPersonPreferenceKey, props.personId])
      await client.invalidateQueries([getPersonPreferenceKey, props.personId])
    })
    // Close the modal
    handleDeleteModalClose()
    // Close the menu
    handleMenuClose()
    return
  }

  // for opening and closing menu icon
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const openMenu = Boolean(anchorEl)
  const handleMenuClose = () => {
    setAnchorEl(null)
  }
  const handleMenuClick = event => {
    setAnchorEl(event.currentTarget)
  }

  return (
    <Box>
      {isFetchingPersonPreference || isAddingPersonPreference || isUpdatingPersonPreference ? (
        <Loader />
      ) : null}
      {props?.showTitle && (
        <Typography variant="body1" style={{ fontWeight: 500 }}>
          Member Preferences
        </Typography>
      )}
      {personPreference?.preference ? (
        <Alert
          iconMapping={{
            info: <InsertCommentOutlinedIcon fontSize="inherit" />,
          }}
          action={
            <Box mt={-1}>
              <IconButton onClick={handleMenuClick}>
                <MoreVertOutlinedIcon />
              </IconButton>
            </Box>
          }
          severity="info"
        >
          {personPreference?.preference}
        </Alert>
      ) : (
        <Box sx={{ ml: -1 }}>
          <Button component="span" onClick={handleCreateUpdateModalOpen}>
            + Add member preferences
          </Button>
        </Box>
      )}
      <Modal open={open} onClose={handleCreateUpdateModalClose}>
        <Box sx={modalStyle}>
          <Box display="flex" justifyContent="space-between" mb={3}>
            <Typography className={classes.modalTitle}>Update member preferences</Typography>
            <Box mt={-1}>
              <IconButton size="small" onClick={handleCreateUpdateModalClose}>
                <ClearIcon />
              </IconButton>
            </Box>
          </Box>
          <TextField
            id="standard-multiline-flexible"
            label="Navigation Preferences"
            multiline
            variant="standard"
            helperText="e.g. addresses other than home, provider gender, last mile locations"
            inputProps={{ maxLength: 300 }}
            value={preference}
            onChange={e => setPreference(e.target.value)}
            fullWidth
          />
          <Box mt={5} display="flex" justifyContent="flex-end">
            <Button onClick={handleCreateUpdateModalClose}>
              <Typography className={classes.cancelButton}>Cancel</Typography>
            </Button>
            <Button
              onClick={handleCreateUpdateModal}
              variant="contained"
              color="primary"
              className={classes.saveButton}
            >
              <Typography>Save and Update Preferences</Typography>
            </Button>
          </Box>
        </Box>
      </Modal>
      <Modal open={openDeleteModal} onClose={handleDeleteModalClose}>
        <Box sx={modalStyle}>
          <Box display="flex" justifyContent="space-between" mb={3}>
            <Typography className={classes.modalTitle}>Delete Member Preference?</Typography>
          </Box>
          <Box display="flex" justifyContent="space-between" mb={3}>
            <Typography className={classes.deleteSubText}>
              This will completely remove all logged preferences from the member’s profile.{' '}
            </Typography>
          </Box>
          <Box mt={3} mb={-2} display="flex" justifyContent="flex-end">
            <Button onClick={handleDeleteModalClose}>
              <Typography className={classes.deleteCancelButton}>CANCEL</Typography>
            </Button>
            <Button onClick={handleDeletePersonPreferenceModal}>
              <Typography className={classes.deleteCancelButton}>DELETE PREFERENCE</Typography>
            </Button>
          </Box>
        </Box>
      </Modal>
      <Menu
        elevation={1}
        anchorEl={anchorEl}
        open={openMenu}
        onClose={handleMenuClose}
        MenuListProps={{
          disablePadding: true,
        }}
      >
        <MenuItem onClick={handleCreateUpdateModalOpen}>Edit</MenuItem>
        <MenuItem onClick={handleDeleteModalOpen}>Delete</MenuItem>
      </Menu>
    </Box>
  )
}

export default PersonPreference
