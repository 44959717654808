import { keyBy } from 'lodash'
import { useMemo } from 'react'
import { useDispatch } from 'react-redux'
import { apiClient } from '~/api/rest'
import { useMutation, useQuery } from '~/components/Providers/ApiProvider'
import {
  AppointmentType,
  Schedule,
  IngestionJobCreate,
  IngestionJob,
  IngestionJobWithImpact,
  IngestionJobStatus,
  StagingShift,
  Shift,
  StagingShiftException,
  ShiftException,
  VisitMixCreate,
  CalendarEvent,
} from '~/components/Settings/ProviderShift/utils'
import { queueNotification } from '~/redux/actions/notifications'

const scheduleIngest = async (): Promise<void> => {
  await apiClient.rest.post(`/schedule/ingest/`, {})
  return
}

export const useScheduleIngest = () => {
  const dispatch = useDispatch()

  return useMutation(
    scheduleIngest,
    {
      onSuccess: async () => {
        dispatch(
          queueNotification({
            variant: 'success',
            message: 'Initiated schedule refresh',
          })
        )
      },
      onError: async () => {
        dispatch(
          queueNotification({
            variant: 'error',
            message: 'Failed to refresh schedule data',
          })
        )
      },
    },
    { success: 'Initiated schedule refresh', error: 'Failed to refresh schedule data' }
  )
}

export const getSchedules = async () => {
  const data = await apiClient.rest.get<Schedule[]>('/user/providers/schedules/')
  return data
}

export const getAppointmentTypes = async () => {
  const data = await apiClient.rest.get<AppointmentType[]>('/schedule/appointment-types/')
  return data
}

export const getSchedulesForProvider = async (providerId: number) => {
  const data = await apiClient.rest.get<Schedule[]>(`/user/providers/${providerId}/schedule/`)
  return data
}

const createIngestionJob = async (payload: IngestionJobCreate): Promise<IngestionJob> => {
  return apiClient.rest.post<IngestionJob>('/schedule/drafts/', payload)
}

const getIngestionJob = async (ingestionJobId: number): Promise<IngestionJobWithImpact> => {
  return apiClient.rest.get<IngestionJobWithImpact>(`/schedule/drafts/${ingestionJobId}/`)
}

export const useGetIngestionJob = () => {
  return useMutation(
    getIngestionJob,
    {},
    { success: 'Updated ingestion job', error: 'Failed to update ingestion job' }
  )
}

export const useCreateIngestionJob = () => {
  return useMutation(
    createIngestionJob,
    {},
    { success: 'Created ingestion job', error: 'Failed to create ingestion job' }
  )
}

const updateIngestionJobStatus = async (
  payload: IngestionJobStatus
): Promise<IngestionJobStatus> => {
  return apiClient.rest.patch<IngestionJobStatus>(`/schedule/drafts/status/${payload.id}/`, payload)
}

export const useUpdateIngestionJobStatus = () => {
  return useMutation(
    updateIngestionJobStatus,
    {},
    { success: 'Updated ingestion job', error: 'Failed to update ingestion job' }
  )
}

const getAppointmentTypesKey = 'getAppointmentTypes'
export const useAppointmentType = () => {
  const { data, isLoading } = useQuery(
    [getAppointmentTypesKey],
    getAppointmentTypes,
    {
      staleTime: Infinity,
    },
    {
      error: 'Failed to fetch appointment type',
    }
  )

  return { result: data || [], isLoading }
}

export const useAppointmentTypeByReason = () => {
  const { result: appointmentTypes, isLoading } = useAppointmentType()
  const result = useMemo(() => {
    return keyBy(appointmentTypes, appointmentType => appointmentType.name)
  }, [appointmentTypes, isLoading])

  return { result, isLoading }
}

function createOrUpdateStagingShift(payload: StagingShift): Promise<StagingShift> {
  if (payload.id != null && payload.id > 0) {
    return apiClient.rest.patch<StagingShift>(`/schedule/staging-shifts/${payload.id}/`, payload)
  } else {
    return apiClient.rest.post<StagingShift>(`/schedule/staging-shifts/`, payload)
  }
}

export const useCreateOrUpdateStagingShift = () => {
  return useMutation(
    createOrUpdateStagingShift,
    {},
    { success: 'Shift info updated', error: 'Failed to update shift info' }
  )
}

function deleteStagingShift(payload: Shift): Promise<StagingShift> {
  return apiClient.rest.delete<StagingShift>(`/schedule/staging-shifts/${payload.id}/`)
}

export const useDeleteStagingShift = () => {
  return useMutation(
    deleteStagingShift,
    {},
    { success: 'Shift info updated', error: 'Failed to update shift info' }
  )
}

function createOrUpdateStagingShiftException(
  payload: StagingShiftException
): Promise<StagingShiftException> {
  if (payload.id != null && payload.id > 0) {
    return apiClient.rest.patch<StagingShiftException>(
      `/schedule/staging-shift-exceptions/${payload.id}/`,
      payload
    )
  } else {
    return apiClient.rest.post<StagingShiftException>(
      `/schedule/staging-shift-exceptions/`,
      payload
    )
  }
}

export const useCreateOrUpdateStagingShiftException = () => {
  return useMutation(
    createOrUpdateStagingShiftException,
    {},
    { success: 'Shift exception info updated', error: 'Failed to update shift exception info' }
  )
}

function deleteStagingShiftException(payload: ShiftException): Promise<StagingShiftException> {
  return apiClient.rest.delete<StagingShiftException>(
    `/schedule/staging-shift-exceptions/${payload.id}/`
  )
}

export const useDeleteStagingShiftException = () => {
  return useMutation(
    deleteStagingShiftException,
    {},
    { success: 'Shift exception info updated', error: 'Failed to update shift exception info' }
  )
}

function createOrUpdateVisitMix(payload: VisitMixCreate): Promise<VisitMixCreate> {
  if (payload.id != null) {
    return apiClient.rest.patch<VisitMixCreate>(
      `/schedule/visit-mix-ratios/${payload.id}/`,
      payload
    )
  } else {
    return apiClient.rest.post<VisitMixCreate>(`/schedule/visit-mix-ratios/`, payload)
  }
}

export const useCreateOrUpdateVisitMix = () => {
  return useMutation(createOrUpdateVisitMix)
}

const stripMilliseconds = (date: Date) => date.toISOString().replace(/\.\d+/, '')

export const listEventsForProvider = (providerId: number, start: Date, end: Date) => {
  return apiClient.rest.get<CalendarEvent[]>(
    `/schedule/events/${providerId}/?start_date=${stripMilliseconds(
      start
    )}&end_date=${stripMilliseconds(end)}`
  )
}

const updateVisitMix = async (providerId: number): Promise<void> => {
  return apiClient.rest.post(`/schedule/${providerId}/visit-mix-consume/`)
}

export const useUpdateVisitMix = () => {
  return useMutation(
    updateVisitMix,
    {},
    { success: 'Updated visit mix ratios', error: 'Failed to update visit mix ratios' }
  )
}
