import { CrudResource } from '@fireflyhealth/core'
import { apiClient } from '~/api/rest'

import { crudSlice } from './crud'
import { CreateTask, ITask } from '~/models/Task'
import { UPDATE_TASK } from '../actions/actionTypes'

const tasksResource = new CrudResource<ITask>(apiClient.rest, '/task/v2/')

const tasksSlice = crudSlice<any, ITask>('tasks', tasksResource, {
  transform: CreateTask,
  extraReducers: {
    [UPDATE_TASK]: (state, action) => {
      state.byId[action.task.id] = action.task
    },
  },
})

export default tasksSlice
