import { useEffect } from 'react'
import type { FC } from 'react'
import { useMutation, usePromise } from '@fireflyhealth/core'
import { PictureAsPdf } from '@material-ui/icons'
import { Box, makeStyles, Typography, useTheme } from '@material-ui/core'
import { Skeleton } from '@material-ui/lab'
import CircularProgress from '@material-ui/core/CircularProgress'
import { useDispatch } from 'react-redux'
import cx from 'classnames'

import { apiClient } from '~/api/rest'
import { queueNotification } from '~/redux/actions/notifications'

interface ChatMessageDocumentProps {
  id: number
  user: { id: number }
}

export const ChatMessageDocument: FC<ChatMessageDocumentProps> = ({ id, user }) => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const { palette } = useTheme()
  const { result } = usePromise(async () =>
    apiClient.rest.get(`/documents/providers/me/patients/${user.id}/files/${id}/stub/`)
  )

  const download = useMutation(async () => {
    const res = await apiClient.rest.get(
      `/documents/providers/me/patients/${user.id}/files/${result.id}/`
    )
    window.open(res.file, '_blank')
  })

  useEffect(() => {
    if (download.error) {
      dispatch(
        queueNotification({
          message: 'You do not have permission to view this file',
          variant: 'error',
        })
      )
    }
  }, [download.error])
  return (
    <Box
      my={1}
      px={2}
      py={1}
      borderRadius={16}
      border={`0.1rem solid ${palette.grey[200]}`}
      display="flex"
      alignItems="center"
    >
      {download.loading ? (
        <CircularProgress size={24} color="primary" />
      ) : (
        <PictureAsPdf color="primary" />
      )}
      <Box ml={1}>
        {result ? (
          <Box
            component="button"
            className={cx({ [classes.button]: !download.error })}
            onClick={() => {
              // Only render button clickable when there are no errors
              if (!download.loading && !download.error) {
                download.handler()
              }
            }}
            bgcolor="#FFF"
            padding={0}
            border={0}
          >
            <Typography>{result.title}</Typography>
          </Box>
        ) : (
          <Typography>
            <Skeleton animation="wave" variant="rect" height="1em" width="16em" />
          </Typography>
        )}
      </Box>
    </Box>
  )
}

const useStyles = makeStyles(() => ({ button: { cursor: 'pointer' } }))

export default ChatMessageDocument
