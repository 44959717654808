import React, { createContext, useCallback, useContext, useEffect, useMemo, useRef } from 'react'
import { RestClient } from '@fireflyhealth/core'
import { useDispatch, useSelector } from 'react-redux'
import { useQuery } from 'react-query'
import jwt_decode from 'jwt-decode'
import Loader from '~/components/Loader'
import { queueNotification } from '~/redux/actions/notifications'
import { getAccessToken } from '~/api/zusTokenExchage'

interface ZusSessionHandlerContext {
  zusApi: RestClient
  accessToken: string | undefined
}

// @ts-ignore
const ZusSessionContext = createContext<ZusSessionHandlerContext>({})

// Export hook for convenience
export const useZusSessionHandler = () => useContext(ZusSessionContext)

export const ZusSessionProvider: React.FC = props => {
  const me = useSelector(state => state.me)
  const dispatch = useDispatch()
  const timeoutIdRef = useRef<NodeJS.Timeout>()
  const updateToken = () => getAccessToken(me.providerFields?.tenantKey)
  const { data, error, isLoading, refetch } = useQuery(['zusTokenData'], () => updateToken(), {
    staleTime: Infinity,
    select: data => ({
      accessToken: data.data.access_token,
      accessTokenExpiration: (Date.now() + data.data.expires_in * 1000).toString(),
    }),
  })

  const getToken = useCallback(() => {
    return data?.accessToken ? data.accessToken : ''
  }, [data?.accessToken])

  const zusApi = useMemo(() => {
    const zusUrl = process.env.REACT_APP_ZUS_API_URL as string
    return new RestClient({
      url: zusUrl,
      getToken: () => Promise.resolve(getToken()),
      log: (message, params = '', error) => {
        console.log(
          '%cAPI',
          `font-size: 9px;background-color: ${
            error ? 'red' : 'green'
          };color: white;padding: 0.35em 0.5em 0.2em;border-radius: 0.25em;`,
          message,
          params
        )
      },
    })
  }, [getToken])

  /* Timer needs to start as soon as we have the timeoutIdRef reference. Refresh should not actually refresh the 
  token since we already have a token and the expiration should be set further in advance. */
  useEffect(() => {
    if (timeoutIdRef) refreshToken()
  }, [timeoutIdRef])

  /**
   * refreshToken
   * This function refresh token every 1 minute before it is set to expire
   * If logout stop refreshing token(getSessionToken)
   */
  const refreshToken = async () => {
    if (timeoutIdRef.current) clearTimeout(timeoutIdRef.current)
    const token = getToken()
    if (token) {
      try {
        // Refresh token if it's about to expire
        const decodedToken: any = jwt_decode(token)
        let remainingTimeInSeconds = decodedToken.exp - Math.floor(Date.now() / 1000)
        if (remainingTimeInSeconds <= 60) {
          refetch()
          const newDecodedToken: any = jwt_decode(getToken())
          remainingTimeInSeconds = newDecodedToken.exp - Math.floor(Date.now() / 1000)
        }
        const timeBeforeNextRefreshInMilliseconds = (remainingTimeInSeconds - 60) * 1000
        timeoutIdRef.current = setTimeout(refreshToken, timeBeforeNextRefreshInMilliseconds)
      } catch (e) {
        console.error(e)
      }
    }
  }

  const renderChildren = () => {
    if (isLoading) return <Loader inline />
    else if (data?.accessToken) return props.children
    else if (!data?.accessToken || error) {
      console.warn('Failed to fetch Zus access token')
      dispatch(queueNotification({ variant: 'error', message: 'Failed to fetch Zus access token' }))
      return <></>
    }
  }

  return (
    <ZusSessionContext.Provider value={{ zusApi, accessToken: data?.accessToken }}>
      {renderChildren()}
    </ZusSessionContext.Provider>
  )
}

export default ZusSessionProvider
