import { useState, useEffect } from 'react'
import type { FC } from 'react'
import { Grid } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import { useDispatch } from 'react-redux'
import { apiClient } from '~/api/rest'
import { patientsSlice } from '../../../redux/slices/patients'
import { PatientUser, useMutation } from '@fireflyhealth/core'
import { EditField } from '~/components/Generic/EditField'
import PrimaryCareDischarge from './PrimaryCareDischarge'
import { useGetChurnedReasons } from '~/api/OnboardingService'

interface EditPrimaryCareProgramProps {
  patient: PatientUser
}

const useStyles = makeStyles()(theme => {
  return {
    container: {
      display: 'flex',
      marginTop: '1rem',
      justifyContent: 'space-between',
      padding: '1rem',
    },
  }
})

const RISK_SCORE = {
  high: 'High',
  intermediate: 'Intermediate',
  low: 'Low',
}

export const EditPrimaryCareProgram: FC<EditPrimaryCareProgramProps> = ({ patient }) => {
  const { data: dischargeReasons } = useGetChurnedReasons()

  const riskScore =
    patient.person?.primaryCareProgramInfo?.riskScore != null
      ? patient.person?.primaryCareProgramInfo?.riskScore
      : ''
  const dispatch = useDispatch()
  const { classes } = useStyles()
  const [loading, setLoading] = useState(false)
  const url = `/programs/primary-care/${patient.id}/pending-discharge/v2`
  const {
    result: pendingDischargeInfo,
    loading: pendingDischargeInfoLoading,
    handler: loadPendingDischargeInfo,
  } = useMutation(async () => apiClient.rest.get(url), { initialLoading: true })

  useEffect(() => {
    loadPendingDischargeInfo()
  }, [])

  const updateRiskScore = async (val: string) => {
    setLoading(true)

    try {
      await dispatch(
        patientsSlice.thunks.updatePatient({
          id: patient.id,
          person: {
            primaryCareProgramInfo: {
              riskScore: val,
            },
          },
        })
      )
    } catch (e) {
      console.error(e)
    } finally {
      setLoading(false)
    }
  }

  // Shortcut for change handlers and values
  const setValues = (name: string, value) => ({
    name,
    onChange: async e => {
      e.persist()
      const targetValue = e.target.value !== '' ? e.target.value : null
      if (name === 'riskScore') {
        await updateRiskScore(targetValue)
      }
    },
    value,
  })

  if (!patient || !patient.person) return null

  return (
    <>
      <div className={classes.container}>
        <Grid container spacing={2}>
          <EditField
            {...setValues('riskScore', riskScore)}
            label="Risk Score"
            select
            InputLabelProps={{ shrink: true }}
            SelectProps={{ native: true }}
            disabled={loading}
          >
            <option value="">(select)</option>
            {Object.entries(RISK_SCORE).map(([key, val]) => (
              <option key={key} value={key}>
                {val}
              </option>
            ))}
          </EditField>
          <Grid item xs={12}>
            <PrimaryCareDischarge
              loading={pendingDischargeInfoLoading}
              pendingDischargeInfo={pendingDischargeInfo}
              patientId={patient.id}
              loadPendingDischargeInfo={loadPendingDischargeInfo}
              dischargeReasons={dischargeReasons}
            />
          </Grid>
        </Grid>
      </div>
    </>
  )
}

export default EditPrimaryCareProgram
