import { useRef, useState } from 'react'
import type { FC, RefObject } from 'react'
import {
  Box,
  Button,
  Checkbox as MuiCheckbox,
  CheckboxProps,
  Divider,
  FormControlLabel,
  FormGroup,
  IconButton,
  TextField,
  Typography,
  useTheme,
} from '@mui/material'
import { Star, StarBorder } from '@mui/icons-material'
import { Appointment } from '@fireflyhealth/core'

import { FFModal } from '~/components/FFModal/FFModal'
import { apiClient } from '~/api/rest'

interface AppointmentRating {
  appointment: number
  comment: string | null
  rating: number | null
  audioBreakingUp: boolean
  audioWasDelayed: boolean
  callDroppedUnexpectedly: boolean
  clinicianCouldntHear: boolean
  clinicianCouldntSee: boolean
  clinicianTroubleConnecting: boolean
  patientCouldntHear: boolean
  patientCouldntSee: boolean
  patientTroubleConnecting: boolean
  videoBreakingUp: boolean
  videoWasDelayed: boolean
}

// Default all checkboxes to primary color
const Checkbox = (props: CheckboxProps) => <MuiCheckbox color="primary" {...props} />

const STAR_DESCRIPTION = {
  0: 'Very bad: care was not possible over video',
  1: 'Bad: problems significantly impacted the visit',
  2: 'Ok, There were some problems that impacted the visit',
  3: 'Good: minor glitches, hardly noticed them',
  4: 'Excellent, perfect, clear, no problems',
}

const getRefWithDefault = (ref: RefObject<HTMLInputElement>) => ref.current?.checked ?? false

interface AppointmentFeedbackProps {
  appointment: Appointment
  onClose: () => void
}

export const AppointmentFeedback: FC<AppointmentFeedbackProps> = props => {
  const { palette } = useTheme()

  const [stars, setStars] = useState<null | number>(null)
  const commentRef = useRef<HTMLTextAreaElement>(null)
  const audioBreakingUpRef = useRef<HTMLInputElement>(null)
  const audioWasDelayedRef = useRef<HTMLInputElement>(null)
  const callDroppedUnexpectedlyRef = useRef<HTMLInputElement>(null)
  const clinicianCouldntHearRef = useRef<HTMLInputElement>(null)
  const clinicianCouldntSeeRef = useRef<HTMLInputElement>(null)
  const clinicianTroubleConnectingRef = useRef<HTMLInputElement>(null)
  const patientCouldntHearRef = useRef<HTMLInputElement>(null)
  const patientCouldntSeeRef = useRef<HTMLInputElement>(null)
  const patientTroubleConnectingRef = useRef<HTMLInputElement>(null)
  const videoBreakingUpRef = useRef<HTMLInputElement>(null)
  const videoWasDelayedRef = useRef<HTMLInputElement>(null)

  const handleSubmit = () => {
    // Capture uncontrolled inputs
    const payload: AppointmentRating = {
      appointment: props.appointment?.id as number,
      comment: commentRef.current?.value ?? null,
      rating: stars === null ? null : stars + 1,
      audioBreakingUp: getRefWithDefault(audioBreakingUpRef),
      audioWasDelayed: getRefWithDefault(audioWasDelayedRef),
      callDroppedUnexpectedly: getRefWithDefault(callDroppedUnexpectedlyRef),
      clinicianCouldntHear: getRefWithDefault(clinicianCouldntHearRef),
      clinicianCouldntSee: getRefWithDefault(clinicianCouldntSeeRef),
      clinicianTroubleConnecting: getRefWithDefault(clinicianTroubleConnectingRef),
      patientCouldntHear: getRefWithDefault(patientCouldntHearRef),
      patientCouldntSee: getRefWithDefault(patientCouldntSeeRef),
      patientTroubleConnecting: getRefWithDefault(patientTroubleConnectingRef),
      videoBreakingUp: getRefWithDefault(videoBreakingUpRef),
      videoWasDelayed: getRefWithDefault(videoWasDelayedRef),
    }
    // Post API without error handling
    apiClient.rest.post('/visit-ratings/', payload).catch(console.error)
    // Leave appointment
    props.onClose()
  }
  return (
    <FFModal
      open
      header={
        <Box p={1}>
          <Typography variant="h6" align="center">
            Please rate the technical quality of this visit
          </Typography>
        </Box>
      }
      footer={
        <Box my={1} mx={2} display="flex" justifyContent="flex-end">
          <Button
            disabled={stars === null}
            variant="contained"
            color="primary"
            onClick={handleSubmit}
          >
            <Typography>Done</Typography>
          </Button>
        </Box>
      }
    >
      <Box m={2}>
        <Box display="flex" justifyContent="center">
          {Array(5)
            .fill('')
            .map((s, i) => {
              const Component = stars !== null && stars >= i ? Star : StarBorder
              return (
                <IconButton edge="start" onClick={() => setStars(stars === i ? null : i)} key={i}>
                  <Component color="primary" />
                </IconButton>
              )
            })}
        </Box>
        {stars !== null ? (
          <Box mb={2}>
            <Typography align="center" variant="subtitle2">
              {STAR_DESCRIPTION[stars]}
            </Typography>
          </Box>
        ) : null}
        {stars !== null && stars <= 2 ? (
          <Box mb={2} p={2} borderRadius={4} bgcolor={palette.grey[50]}>
            <Typography variant="h6">Audio</Typography>
            <FormGroup>
              <FormControlLabel
                control={<Checkbox inputRef={patientCouldntHearRef} />}
                label="The patient couldn't hear me"
              />
              <FormControlLabel
                control={<Checkbox inputRef={clinicianCouldntHearRef} />}
                label="I couldn't hear the patient"
              />
              <FormControlLabel
                control={<Checkbox inputRef={audioBreakingUpRef} />}
                label="Audio was breaking up"
              />
              <FormControlLabel
                control={<Checkbox inputRef={audioWasDelayedRef} />}
                label="The sound was delayed"
              />
            </FormGroup>
            <Box my={2}>
              <Divider />
            </Box>
            <Typography variant="h6">Video</Typography>
            <FormGroup>
              <FormControlLabel
                control={<Checkbox inputRef={patientCouldntSeeRef} />}
                label="The patient couldn’t see me"
              />
              <FormControlLabel
                control={<Checkbox inputRef={clinicianCouldntSeeRef} />}
                label="I couldn’t see the patient"
              />
              <FormControlLabel
                control={<Checkbox inputRef={videoBreakingUpRef} />}
                label="Video was breaking up"
              />
              <FormControlLabel
                control={<Checkbox inputRef={videoWasDelayedRef} />}
                label="Video was delayed"
              />
            </FormGroup>
            <Box my={2}>
              <Divider />
            </Box>
            <Typography variant="h6">Connection</Typography>
            <FormGroup>
              <FormControlLabel
                control={<Checkbox inputRef={patientTroubleConnectingRef} />}
                label="The patient had trouble connecting"
              />
              <FormControlLabel
                control={<Checkbox inputRef={clinicianTroubleConnectingRef} />}
                label="I had trouble connecting"
              />
              <FormControlLabel
                control={<Checkbox inputRef={callDroppedUnexpectedlyRef} />}
                label="The call dropped unexpectedly"
              />
            </FormGroup>
          </Box>
        ) : null}
        {stars !== null ? (
          <>
            <Typography align="center">Anything else you&apos;d like to add?</Typography>
            <TextField variant="outlined" fullWidth multiline inputRef={commentRef} />
          </>
        ) : null}
      </Box>
    </FFModal>
  )
}

export default AppointmentFeedback
