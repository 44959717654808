import { useInfiniteQuery } from '~/components/Providers/ApiProvider'
import { PatientTodo, PatientTodoFilters, PatientTodoTabs } from '~/models/PatientTodoItem'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { flatten } from 'lodash'
import { LimitOffsetPagination as CoreLimitOffsetPagination } from '@fireflyhealth/core'
import { apiClient } from '~/api/rest'

interface LimitOffsetPaginationWithParams<T> extends CoreLimitOffsetPagination<T> {
  next: string | null
  previous: string | null
  params: {
    isComplete: boolean
  } | null
}

function pathFromURL(url: string) {
  const u = new URL(url)
  return u.pathname + u.search
}

export const listPersonTodoKey = 'listPersonTodo'
export const usePersonTodos = (personId: number) => {
  const limit = 50

  const [tab, setTab] = useState<PatientTodoTabs>('active')
  const [filters, setFilters] = useState<PatientTodoFilters>({
    isComplete: tab === 'complete',
  })

  const initialPageParam = useMemo(
    () =>
      'contentType' in filters
        ? `/providers/me/work_units/people/${personId}/todos/?content_type=${filters.contentType}&object_id=${filters.objectId}&limit=${limit}`
        : `/providers/me/work_units/people/${personId}/todos/?is_complete=${!!filters.isComplete}&limit=${limit}&offset=0`,
    [personId, filters]
  )

  const {
    data,
    hasNextPage,
    fetchNextPage,
    hasPreviousPage,
    fetchPreviousPage,
    isLoading,
    isFetchingNextPage,
    isFetchingPreviousPage,
  } = useInfiniteQuery(
    [listPersonTodoKey, personId, initialPageParam],
    ({ pageParam = initialPageParam }) => {
      return apiClient.rest.get<LimitOffsetPaginationWithParams<PatientTodo>>(pageParam)
    },
    {
      getNextPageParam: lastPage => (lastPage.next ? pathFromURL(lastPage.next) : undefined),
      getPreviousPageParam: firstPage =>
        firstPage.previous ? pathFromURL(firstPage.previous) : undefined,
    },
    {
      error: 'Failed to fetch to dos for patient',
    }
  )

  const resetFilters = useCallback(() => {
    setFilters({
      isComplete: tab === 'complete',
    })
  }, [setFilters, tab])

  // Used when the caller sets the tab explicitly, which should also update the filters and trigger a new query.
  const handleSetTab = useCallback(
    (tab: Parameters<typeof setTab>[0]) => {
      setTab(tab)
      setFilters({
        isComplete: tab === 'complete',
      })
    },
    [setTab, tab, setFilters]
  )

  // Go to the tab and page containing the given object.
  const goTo = useCallback(
    (identifier: Pick<PatientTodo, 'contentType' | 'objectId'>) => {
      if (!identifier.contentType || !identifier.objectId) {
        return
      }

      setFilters({ contentType: identifier.contentType, objectId: identifier.objectId })
    },
    [setFilters]
  )

  // When we go to a specific object, there is a period of time where we don't know the tab we should be on. Once we
  // get the data back, set the correct tab.
  useEffect(() => {
    if (!('contentType' in filters)) {
      return
    }

    const isComplete = data?.pages[0]?.params?.isComplete
    setTab(isComplete ? 'complete' : 'active')
  }, [filters, data, setTab])

  return {
    data: flatten(data?.pages.map(page => page.results)),
    tab,
    setTab: handleSetTab,
    hasNextPage,
    fetchNextPage,
    hasPreviousPage,
    fetchPreviousPage,
    goTo,
    isLoading,
    isFetchingNextPage,
    isFetchingPreviousPage,
    resetFilters,
  }
}
