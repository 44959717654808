import { useState } from 'react'
import type { FC } from 'react'
import { PatientUser } from '@fireflyhealth/core'
import { Button, Box, Checkbox, FormGroup, FormControlLabel, Grid, Typography } from '@mui/material'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import { useFormik } from 'formik'

import EditField from '../../Generic/EditField'
import EditPersonFields from '~/components/PersonDetail/EditPerson/EditPersonFields'
import PhoneNumberChangeModal from './PhoneNumberChangeModal'
import { ValueSetter } from '~/types'
import EmailChangeModel from './EmailChangeModel'

interface EditPatientFormProps {
  patient: PatientUser
  loading: boolean
  handleSubmit: (values: PatientUser) => void
}

export const EditPatientForm: FC<EditPatientFormProps> = props => {
  const [showPhoneNumberChangeModal, setShowPhoneNumberChangeModal] = useState(false)
  const [showEmailChangeModel, setShowEmailChangeModel] = useState(false)

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: props.patient,
    onSubmit: props.handleSubmit,
  })

  const {
    firstName,
    lastName,
    email,
    person: { dob, preferredName, sex, employeeIdentifier, employer, gender, pronouns },
    phoneNumber,
  } = formik.values

  // Shortcut for change handlers and values
  const setValues: ValueSetter = (name, value) => ({
    name,
    onChange: e => {
      e.persist()
      formik.handleChange(e)
      formik.setFieldTouched(name, true, false)
    },
    disabled: props.loading,
    value,
  })

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <EditPersonFields
          employeeIdentifier={employeeIdentifier}
          firstName={firstName}
          lastName={lastName}
          dob={dob}
          preferredName={preferredName}
          employer={employer}
          gender={gender}
          sex={sex}
          pronouns={pronouns}
          loading={props.loading}
          isUser={true}
          setValues={setValues}
          setFieldValue={formik.setFieldValue}
          setFieldTouched={formik.setFieldTouched}
        />
        <Grid mt={1} container spacing={2}>
          <EditField
            {...setValues('email', email)}
            InputProps={{ readOnly: true }}
            onClick={() => setShowEmailChangeModel(true)}
            label="Email"
            variant="outlined"
            showChangeButton={true}
          />

          <EditField
            {...setValues('phoneNumber', phoneNumber)}
            InputProps={{ readOnly: true }}
            onClick={() => {
              setShowPhoneNumberChangeModal(true)
            }}
            label="Phone Number"
            variant="outlined"
            showChangeButton={true}
            disabled={true}
          />
        </Grid>

        <Box display="flex" justifyContent="flex-end" alignItems="center" mt={3}>
          {props.patient.person.requestAuthForPhi ? <VisibilityOff /> : <Visibility />}

          <Box ml={1}>
            <Typography variant="body2">
              Patient is {props.patient.person.requestAuthForPhi ? '' : 'not '} VIP
            </Typography>
          </Box>
        </Box>

        <Box display="flex" justifyContent="flex-end" my={3}>
          {formik.dirty && (
            <Box mr={3}>
              <Button onClick={() => formik.resetForm()}>Cancel Patient Detail Changes</Button>
            </Box>
          )}

          <Button type="submit" variant="outlined" color="primary">
            Save Patient Details
          </Button>
        </Box>
      </form>

      <Box>
        {showPhoneNumberChangeModal && (
          <PhoneNumberChangeModal
            handleClose={() => setShowPhoneNumberChangeModal(false)}
            open={showPhoneNumberChangeModal}
          />
        )}
      </Box>
      <Box>
        {showEmailChangeModel && (
          // @ts-ignore
          <EmailChangeModel
            handleClose={() => setShowEmailChangeModel(false)}
            open={showEmailChangeModel}
            patient={props.patient}
          />
        )}
      </Box>
    </>
  )
}

export default EditPatientForm

type Factor = 'email' | 'phone' | 'insurance' | 'address'

interface FactorGroup {
  label: string
  options: FactorOption[]
}

interface FactorOption {
  name: Factor
  label: string
}

type FactorVerificationLookup = {
  [key in Factor]?: boolean
}

export const VerifyIdentity = (props: {
  factors: FactorGroup[]
  verifiedFactors: FactorVerificationLookup
  onChange: (factor: FactorOption, nextValue: boolean) => void
}) => {
  const { factors, verifiedFactors, onChange } = props

  return (
    <>
      <Typography paragraph>
        Select the two factors used to confirm the patient&apos;s identity.
      </Typography>
      <Typography paragraph>
        {factors.map(group => {
          return (
            <>
              <Typography paragraph>
                {group.label}
                <FormGroup>
                  {group.options.map((factor, i) => (
                    <FormControlLabel
                      key={i}
                      control={
                        <Checkbox
                          checked={!!verifiedFactors[factor.name]}
                          onChange={e => onChange(factor, e.target.checked)}
                          name={factor.name}
                          color="primary"
                        />
                      }
                      label={factor.label}
                    />
                  ))}
                </FormGroup>
              </Typography>
            </>
          )
        })}
      </Typography>
      <Typography paragraph>
        If you cannot confirm the patient&apos;s identity, reach out to #firefly-support and
        @member-guide for help.
      </Typography>
    </>
  )
}
