import { Box, IconButton, Typography } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import screenfull from 'screenfull'
import { Close, Fullscreen } from '@mui/icons-material'
import Moment from 'moment'
import { useDispatch } from 'react-redux'

import { leaveVideoAppointment } from '~/redux/actions/appointments'
import { AppointmentFeedback } from '../AppointmentFeedback'
import { useZoomSession } from './SessionProvider'
import logo from './zoom.png'
import { useCallback, useEffect, useState } from 'react'
import { useFeatureFlag } from '~/utils/useFeatureFlag'
import AppointmentVisitFeedback from '../AppointmentVisitFeedback'

export const SessionHeader: React.FC = () => {
  const [showFeedbackModal, setShowFeedbackModal] = useState(false)
  const [feedbackModalDisplayed, setFeedbackModalDisplayed] = useState(false)
  const { classes } = useStyles()
  const dispatch = useDispatch()
  const isPostVisitSurvey: boolean = useFeatureFlag('postVisitSurvey')

  const { containerRef, appointment, modal, setScreen } = useZoomSession()

  const listener = useCallback(
    (event: BeforeUnloadEvent) => {
      if (!feedbackModalDisplayed) setShowFeedbackModal(true)
      if (!showFeedbackModal) setFeedbackModalDisplayed(true)
      event.preventDefault()
    },
    [setShowFeedbackModal, showFeedbackModal]
  )

  useEffect(() => {
    window.addEventListener('beforeunload', listener)

    // Remove listener when component is unmounted.
    return () => {
      window.removeEventListener('beforeunload', listener)
    }
  }, [listener])

  // Appointment description and timing
  const duration = Moment.duration(appointment?.duration).as('m')
  const start = Moment(appointment?.start).format('h:mm')
  const end = Moment(appointment?.start).add(duration, 'minutes').format('h:mmA')
  const title = appointment?.description.length
    ? `${start} - ${end} | ${appointment?.description}`
    : `${start} - ${end}`
  return (
    <Box
      sx={theme => ({
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        background: theme.palette.common.white,
      })}
      id="VIDEO_VISIT_MODAL"
    >
      <Box sx={{ overflow: 'hidden', whiteSpace: 'nowrap', px: 2 }}>
        <Typography
          sx={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            fontSize: '1.5rem !important',
          }}
        >
          {title}
        </Typography>
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <img src={logo} className={classes.logo} alt="Zoom" />
        <IconButton
          onClick={() => {
            if (screenfull.isEnabled) screenfull.toggle(containerRef.current ?? undefined)
          }}
        >
          <Fullscreen />
        </IconButton>
        {modal ? (
          <IconButton
            onClick={() => {
              setScreen('precall')
              setShowFeedbackModal(true)
            }}
          >
            <Close />
          </IconButton>
        ) : null}
        {showFeedbackModal ? (
          isPostVisitSurvey ? (
            <AppointmentVisitFeedback
              onClose={() => {
                setShowFeedbackModal(false)
                dispatch(leaveVideoAppointment())
              }}
              appointment={appointment}
            />
          ) : (
            <AppointmentFeedback
              onClose={() => {
                setShowFeedbackModal(false)
                dispatch(leaveVideoAppointment())
              }}
              appointment={appointment}
            />
          )
        ) : null}
      </Box>
    </Box>
  )
}

const useStyles = makeStyles()(theme => ({
  logo: {
    height: '1em',
  },
  canvas: {
    display: 'block',
    position: 'relative',
    zIndex: 100,
  },
  video: {
    height: 125,
    display: 'block',
    position: 'relative',
    zIndex: 100,
  },
}))
