import React, { useMemo } from 'react'
import {
  Box,
  Grid,
  Button,
  CircularProgress,
  Typography,
  Switch,
  Alert,
  Link,
  FormGroup,
  FormControlLabel,
  BoxProps,
  TextField,
  MenuItem,
  FormControl,
  FormHelperText,
  InputLabel,
  Select,
} from '@mui/material'
import Moment from 'moment'
import * as yup from 'yup'
import ScienceOutlinedIcon from '@mui/icons-material/ScienceOutlined'
import { grey } from '@mui/material/colors'
import { useFormik } from 'formik'

import {
  EditSteerageInterface,
  ReferralInsuranceAuthorizationStatuses,
  useCarePassServiceCategories,
  useEditSteerage,
  useSteerage,
  useSteerageProviders,
  useToggleSteerageLock,
} from '~/api/SteerageService'
import { SteerageEventPayload, TalonSearchPayload, logEvent } from '~/utils/events'
import EditField from '~/components/Generic/EditField'
import SteerageProviderList from '../SteerageProviderList'
import { SteerageSegment, Steerage, SteerageProviderStatus } from '../types'
import InsuranceAuthProviderForm from '../InsuranceAuthProviderForm'
import { useGetPerson } from '~/api/PersonService'

const SCHEMA = yup.object().shape({
  effectiveThrough: yup
    .date()
    .min(Moment().add(1, 'days').format('YYYY-MM-DD'), 'Must be after today'),
  serviceQuantityValue: yup.number().min(1, 'Must be greater than 0'),
})

export const ReferralDetailsV2: React.FC<{
  steerageId: number
  personId: number | null
}> = props => {
  const { data: steerage, isLoading: steerageIsLoading } = useSteerage(props.steerageId)
  const { data: steerageProviderList, isLoading: steerageProvidersListIsLoading } =
    useSteerageProviders(props.steerageId)
  const { isLoading: isTogglingSteerageLoading, mutateAsync: toggleSteerageLock } =
    useToggleSteerageLock()
  const editSteerage = useEditSteerage()
  const isLocked = Boolean(steerage?.isLocked)
  const isEffectivelyWaiverOnly = steerage?.isEffectivelyWaiverOnly
  const isEffectivelyReferralAndWaiver = steerage?.isEffectivelyReferralAndWaiver
  const { data: serviceCategories, isLoading: serviceCategoriesIsLoading } =
    useCarePassServiceCategories(props?.personId)

  // When there are providers automatically added to a Steerage, they should
  // only render in this list if they have been approved by a navigator.
  const filteredSteerageProviderList = (steerageProviderList ?? []).filter(p => {
    return p.status === SteerageProviderStatus.Accepted
  })

  const isLoading =
    steerageIsLoading ||
    isTogglingSteerageLoading ||
    steerageProvidersListIsLoading ||
    editSteerage.isLoading ||
    serviceCategoriesIsLoading

  const isDisabled = isLoading || isLocked

  /**
   * The scheduling date should be disabled under the following conditions:
   * 1. There are no accepted Steerage Providers added to the Steerage
   * 2. There's more than one Steerage Provider but none of them have been selected
   */
  const noProvidersAreAdded = filteredSteerageProviderList.length === 0
  const noProviderIsSelected =
    filteredSteerageProviderList.length > 1 &&
    filteredSteerageProviderList.every(p => p.memberSelectedAt === null)
  const isSchedulingDateDisabled = isDisabled || noProvidersAreAdded || noProviderIsSelected

  const hasPriorAuth = Boolean(
    steerage?.waiver?.priorAuthorization && steerage?.waiver?.serviceCategories
  )

  const handleLockButtonOnClick = async () => {
    onFormSubmit(formik.values, true)
  }

  const onClickOfAddProvider = () => {
    // This is used when we click add provider button for adding steerage provider via provider search
    // if the form values of care pass are not changed then no need to update it else call submit form
    if (formik.dirty) {
      formik.handleSubmit()
    }
    logEvent<SteerageEventPayload>('STEERAGE_INITIATE_ADD_PROVIDER_WORKFLOW', {
      steerageId: props.steerageId,
    })
  }

  let labelForLock = 'Send to Member'
  if (steerage?.recommendationSentAt) labelForLock = 'Resend to Member'
  if (isEffectivelyReferralAndWaiver || isEffectivelyWaiverOnly) labelForLock += ' and Flume'

  // Read-only information at the top of the section
  const { data: person } = useGetPerson({ personId: props?.personId! })
  const planType = person?.insuranceInfo?.planType?.toUpperCase() ?? 'None'
  const planName = person?.insuranceInfo?.insurancePlan?.name ?? 'None'
  const payerName = person?.insuranceInfo?.insurancePayer?.name ?? 'None'
  const pcpName = person?.insuranceInfo?.pcpName ?? 'None'

  // Prepopulate insuranceAuthorizationRequired so it renders as a string
  let initialInsuranceAuthorizationRequired: null | string = null
  if (steerage?.referral?.insuranceAuthorizationRequired === true) {
    initialInsuranceAuthorizationRequired = 'YES'
  }
  if (steerage?.referral?.insuranceAuthorizationRequired === false) {
    initialInsuranceAuthorizationRequired = 'NO'
  }

  // Prepopulate PriorAuthRequired so it renders as a string
  let initialPriorAuthRequired: null | string = null
  if (steerage?.waiver?.priorAuthRequired === true) {
    initialPriorAuthRequired = 'YES'
  }
  if (steerage?.waiver?.priorAuthRequired === false) {
    initialPriorAuthRequired = 'NO'
  }

  let defaultServiceCategory: string | null = null
  if (steerage?.waiver?.serviceCategories?.length) {
    defaultServiceCategory = steerage.waiver.serviceCategories[0].label
  }

  const formik = useFormik({
    validationSchema: SCHEMA,
    initialValues: {
      schedulingDate: steerage?.schedulingDate ?? '',
      useReferralProvider:
        steerage?.referral?.referralInsuranceAuthorization?.useReferralProvider ?? true,
      authorizationNumber:
        steerage?.referral?.referralInsuranceAuthorization?.authorizationNumber ?? '',
      priorAuthOriginatorSystemId: steerage?.waiver?.priorAuthOriginatorSystemId ?? '',
      insuranceAuthorizationRequired: initialInsuranceAuthorizationRequired,
      priorAuthRequired: initialPriorAuthRequired,
      insuranceAuthorizationStatus:
        steerage?.referral?.referralInsuranceAuthorization?.status ?? '',
      requestedByMember: steerage?.requestedByMember ?? false,
      requestType: steerage?.requestType ?? 'broad',
      preferredProviderOrFacility: steerage?.preferredProviderOrFacility ?? '',
      memberPreferredProvider: steerage?.memberRequestedData?.providerName ?? '',

      description: steerage?.description ?? '',
      serviceQuantityUnit: steerage?.serviceQuantity?.unit ?? '',
      serviceQuantityValue: steerage?.serviceQuantity?.value ?? '',
      serviceCategory: defaultServiceCategory,
      effectiveFrom: steerage?.effectiveFrom ?? '',
      effectiveThrough: steerage?.effectiveThrough ?? '',
      isWaiverIncluded: steerage?.isWaiverIncluded ?? true,
    },
    onSubmit: values => {
      onFormSubmit(values, false)
    },
  })

  const onFormSubmit = (values, shouldLock: boolean) => {
    // Case 1.
    // The form is Not Dirty and shouldLock the steerage is True - It means navigator tried to lock/unlock steerage
    // Then just lock or unlock the steeatge
    if (!formik.dirty && shouldLock) {
      toggleSteerageLock({
        steerageId: props.steerageId,
        isLocked,
      })
      return
    }

    // Case 2
    // When the steerage form is dirty and shouldLock is False - It means navigator tried to save steerage details
    // Then just save the steeatge details
    let authorizationNumber: string | null = values.authorizationNumber
    let authorizationStatus: ReferralInsuranceAuthorizationStatuses | null =
      values.insuranceAuthorizationStatus
    const insuranceAuthRequired = values.insuranceAuthorizationRequired === 'YES' ? true : false
    if (!insuranceAuthRequired) {
      authorizationNumber = null
      authorizationStatus = null
    }
    let payload: EditSteerageInterface = {
      description: values.description,
      steerageId: props.steerageId,
      schedulingDate: values.schedulingDate != '' ? values.schedulingDate : null,
    }
    // If care only, add the referral insurance details to the payload
    if (!steerage?.hasWaiver) {
      // @ts-ignore
      payload['referral'] = {
        insuranceAuthorizationRequired: insuranceAuthRequired,
        // TODO: Update this type to be Partial<ReferralInsuranceAuthorization>
        // @ts-ignore
        referralInsuranceAuthorization: {
          authorizationNumber,
          useReferralProvider: values.useReferralProvider,
          status: authorizationStatus,
        },
      }
    } else {
      const selectedServiceCategoryId = values.serviceCategory
        ? serviceCategories?.find(
            serviceCategory => serviceCategory.label === values['serviceCategory']
          )?.id
        : null
      payload['serviceQuantity'] = {
        value: values['serviceQuantityValue'] || null,
        unit: values['serviceQuantityUnit'] || null,
      }
      payload['effectiveFrom'] = values.effectiveFrom || null
      payload['effectiveThrough'] = values.effectiveThrough || null
      payload['isWaiverIncluded'] = values.isWaiverIncluded
      payload['waiver'] = {
        // This should never really be an empty array since a service category will always be mapped to
        // an ID unless the Prior Auth returns an empty list for service categories (this should not
        // realistically happen).
        serviceCategoryIds: selectedServiceCategoryId != null ? [selectedServiceCategoryId] : [],
      }

      if (values.priorAuthRequired != null) {
        const priorAuthRequired = values.priorAuthRequired === 'YES' ? true : false
        let originatorId: string | null = values.priorAuthOriginatorSystemId
        if (!priorAuthRequired) {
          originatorId = null
        }
        payload['waiver']['priorAuthRequired'] = priorAuthRequired
        payload['waiver']['priorAuthOriginatorSystemId'] = originatorId
      }
    }
    editSteerage
      // TODO: Update this type to be Partial<Referral>
      // @ts-ignore
      .mutateAsync(payload, {
        // Use new initial values to reset the form
        onSuccess: () => formik.resetForm(),
      })
      .then(async () => {
        // Case 3
        // When the Form is dirty and shouldLock is True - It means navigator has updated the steerage details
        // and tried to lock the steerage at the same time
        // The first save the steerage details and then lock the steerage
        if (shouldLock) {
          toggleSteerageLock({
            steerageId: props.steerageId,
            isLocked,
          })
        }
      })
  }

  const isLockingDisabled: boolean = useMemo(() => {
    // disable the lock button until all required details are added
    if (!steerage?.description && !formik.values.description) {
      return true
    }

    // should also have at least one steerage provider
    if (filteredSteerageProviderList && filteredSteerageProviderList.length < 1) return true

    // Either both unit and quantity should be present or none
    if (
      !formik.values.serviceQuantityUnit &&
      formik.values.serviceQuantityValue &&
      steerage?.hasActiveWaiver
    ) {
      return true
    }

    if (
      formik.values.serviceQuantityUnit &&
      !formik.values.serviceQuantityValue &&
      steerage?.hasActiveWaiver
    ) {
      return true
    }

    // Care Pass Checks -> If Care Pass Toggle is On
    if (isEffectivelyReferralAndWaiver || isEffectivelyWaiverOnly) {
      // If serviceCategory is empty for custom care pass then disable the send to flume button
      if (!formik.values?.serviceCategory) {
        return true
      }

      // effective dates are only required in case of waiver
      if (!formik.values?.effectiveFrom) {
        return true
      }
      if (!formik.values?.effectiveThrough) {
        return true
      }
    }
    // if care pass is presents then all the steerage provider should have waiver level
    if (
      steerage?.hasActiveWaiver &&
      filteredSteerageProviderList &&
      filteredSteerageProviderList?.filter(
        provider => provider?.waiverLevel == '' || provider?.waiverLevel == null
      ).length > 0
    ) {
      return true
    }

    // If a waiver is added to steerage, whether it's active or not, we'll need it to
    // link to the prior auth, so ensure prior auth variables are set
    if (
      steerage?.waiver &&
      steerage?.waiver.priorAuthRequired === null &&
      !formik.values.priorAuthRequired
    ) {
      return true
    }
    // Originator ID should be present if PA is required
    if (
      steerage?.waiver &&
      formik.values.priorAuthRequired === 'YES' &&
      !formik.values.priorAuthOriginatorSystemId
    ) {
      return true
    }

    // Care only checks -> make sure insurance auth details are present
    if (
      !steerage?.hasWaiver &&
      steerage?.referral?.insuranceAuthorizationRequired === null &&
      !formik.values.insuranceAuthorizationRequired
    ) {
      return true
    }

    return false
  }, [formik.values, steerage, filteredSteerageProviderList])

  return (
    <Box my={1} mx={1}>
      <form
        onSubmit={e => {
          formik.handleSubmit()
          // Prevent the page from refreshing
          e.preventDefault()
        }}
      >
        <Box my={2}>
          <Typography fontSize={18} fontWeight="500">
            Referral Details
          </Typography>
        </Box>
        <MemberFacingBlock>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                disabled={isDisabled}
                fullWidth
                required
                label="Description"
                name="description"
                onChange={formik.handleChange}
                value={formik.values.description}
                variant="standard"
              />
            </Grid>
          </Grid>
        </MemberFacingBlock>
        {steerage && (steerage.segment == SteerageSegment.COMMODITY || steerage.segment == null) && (
          <Box display="flex" flexDirection="column">
            <Alert
              severity="info"
              style={{ display: 'flex', width: '45%' }}
              iconMapping={{
                info: <ScienceOutlinedIcon />,
              }}
              sx={{ '& .MuiAlert-message': { display: 'flex' }, mt: 1 }}
            >
              <Typography variant="body1" style={{ fontWeight: 500 }}>
                {' '}
                Use{' '}
                <Link
                  href={
                    'https://www.mymedicalshopper.com/support-dashboard/firefly?et=supportDashboardEligibility'
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={() =>
                    logEvent<TalonSearchPayload>('TALON_SEARCH', {
                      steerageId: steerage?.id,
                    })
                  }
                >
                  Talon Provider Search
                </Link>
              </Typography>
            </Alert>
          </Box>
        )}
        {steerage || isLoading ? (
          <Box>
            <SteerageProviderList
              steerage={steerage as Steerage}
              steerageProviders={filteredSteerageProviderList}
              onClickOfAddProvider={onClickOfAddProvider}
              steerageDescription={steerage?.description ?? ''}
            />
            {steerage?.hasWaiver ? (
              <Box>
                <Typography sx={{ fontWeight: 500 }}>Care Pass</Typography>
                <Grid container mt={1} mb={1}>
                  {steerage?.hasReferral && (
                    <Box ml={1}>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Switch
                              name="isWaiverIncluded"
                              onChange={formik.handleChange}
                              onClick={() => formik.handleSubmit()}
                              edge="start"
                              checked={!!formik.values?.isWaiverIncluded}
                              color="primary"
                              disabled={isDisabled || hasPriorAuth}
                            />
                          }
                          label="Attach Care Pass"
                        />
                      </FormGroup>
                    </Box>
                  )}
                </Grid>
                {steerage?.hasActiveWaiver ? (
                  <Box>
                    {/* If prior auth is present then show the service category in readonly format */}
                    {hasPriorAuth ? (
                      <Grid container spacing={2}>
                        <EditField
                          InputLabelProps={{ shrink: true }}
                          label="Service Category"
                          disabled
                          value={steerage?.waiver?.serviceCategories
                            ?.map(function (serviceCategory) {
                              return serviceCategory.label
                            })
                            .join(', ')}
                        />
                      </Grid>
                    ) : (
                      <Grid container spacing={2}>
                        <EditField
                          InputLabelProps={{ shrink: true }}
                          label="Service Category"
                          onChange={formik.handleChange}
                          value={formik.values.serviceCategory}
                          name="serviceCategory"
                          disabled={isDisabled}
                          select
                          SelectProps={{ native: true }}
                          // required
                        >
                          <option value=""></option>
                          {serviceCategories?.map(serviceCategory => (
                            <option key={serviceCategory['id']} value={serviceCategory['label']}>
                              {serviceCategory['label']}
                            </option>
                          ))}
                        </EditField>
                      </Grid>
                    )}
                    <Grid container spacing={2} mt={1}>
                      <EditField
                        InputLabelProps={{ shrink: true }}
                        label="Effective From"
                        type="date"
                        onChange={formik.handleChange}
                        value={formik.values.effectiveFrom}
                        name="effectiveFrom"
                        disabled={isDisabled}
                        // Minimum Year for selection should be 2016
                        minDate={Moment('2016-01-01').format('YYYY-MM-DD')}
                      />
                      <EditField
                        error={Boolean(formik.errors.effectiveThrough)}
                        helperText={formik.errors.effectiveThrough}
                        InputLabelProps={{ shrink: true }}
                        label="Effective Through"
                        type="date"
                        onChange={formik.handleChange}
                        value={formik.values.effectiveThrough}
                        name="effectiveThrough"
                        disabled={isDisabled}
                        // Minimum date for selection should be tomorrow
                        minDate={Moment().add(1, 'days').format('YYYY-MM-DD')}
                      />
                    </Grid>
                    <Grid container spacing={2} mt={1} xs={6}>
                      <EditField
                        required={!!formik.values.serviceQuantityUnit}
                        helperText={formik.errors.serviceQuantityValue}
                        error={Boolean(formik.errors.serviceQuantityValue)}
                        InputLabelProps={{ shrink: true }}
                        label="Quantity"
                        type="number"
                        name="serviceQuantityValue"
                        onChange={formik.handleChange}
                        value={formik.values.serviceQuantityValue}
                        disabled={isDisabled}
                      />

                      <EditField
                        required={!!formik.values.serviceQuantityValue}
                        InputLabelProps={{ shrink: true }}
                        label="Unit"
                        name="serviceQuantityUnit"
                        onChange={formik.handleChange}
                        value={formik.values.serviceQuantityUnit}
                        select
                        SelectProps={{ native: true }}
                        disabled={isDisabled}
                      >
                        <option value=""></option>
                        <option key="days" value="days">
                          Days
                        </option>
                        <option key="units" value="units">
                          Units
                        </option>
                        <option key="visits" value="visits">
                          Visits
                        </option>
                      </EditField>
                    </Grid>
                  </Box>
                ) : null}
              </Box>
            ) : null}
            {!steerage?.hasWaiver ? (
              <Grid container rowSpacing={steerage?.isLocked ? 1 : 3}>
                <Grid item xs={12}>
                  <Typography fontWeight="500">Insurance Information</Typography>
                </Grid>
                <Grid item xs={6}>
                  <FormHelperText>Payer Name</FormHelperText>
                  <Typography>{payerName}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <FormHelperText>Plan Name</FormHelperText>
                  <Typography>{planName}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <FormHelperText>Plan Type</FormHelperText>
                  <Typography>{planType}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <FormHelperText>Insurance PCP</FormHelperText>
                  <Typography>{pcpName}</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography fontWeight="500">Insurance Authorization</Typography>
                </Grid>
                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <InputLabel required sx={{ ml: -2 }}>
                      Authorization required
                    </InputLabel>
                    <Select
                      label="Authorization Required"
                      disabled={isLoading || isDisabled}
                      onChange={e => {
                        formik.handleChange({
                          target: {
                            name: 'insuranceAuthorizationRequired',
                            value: e.target.value,
                          } as any,
                        } as React.ChangeEvent)
                      }}
                      value={formik.values.insuranceAuthorizationRequired}
                      name="insuranceAuthorizationRequired"
                      variant="standard"
                      fullWidth
                      required
                    >
                      <MenuItem value="YES">Yes</MenuItem>
                      <MenuItem value="NO">No</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                {formik.values.insuranceAuthorizationRequired === 'YES' ? (
                  <>
                    <Grid item xs={12}>
                      <FormHelperText>ICD-10 Code(s)</FormHelperText>
                      {!steerage?.referral?.referralInsuranceAuthIcdCodes?.length ? (
                        <Typography color="GrayText">No codes attached</Typography>
                      ) : null}
                      {steerage?.referral?.referralInsuranceAuthIcdCodes?.map(icdcode => {
                        return (
                          <Typography mb={0.5} key={icdcode.code}>
                            {icdcode?.code + ' ' + icdcode?.description}
                          </Typography>
                        )
                      })}
                    </Grid>
                    <Grid item xs={6} pr={1}>
                      <FormControl fullWidth>
                        <InputLabel sx={{ ml: -2 }}>Authorization status</InputLabel>
                        <Select
                          label="Authorization Status"
                          disabled={isLoading || isDisabled}
                          onChange={e => {
                            formik.handleChange({
                              target: {
                                name: 'insuranceAuthorizationStatus',
                                value: e.target.value,
                              } as any,
                            } as React.ChangeEvent)
                          }}
                          value={formik.values.insuranceAuthorizationStatus}
                          name="insuranceAuthorizationStatus"
                          variant="standard"
                          fullWidth
                        >
                          {Object.values(ReferralInsuranceAuthorizationStatuses).map(status => (
                            <MenuItem value={status} key={status}>
                              {status}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        disabled={isLoading || isDisabled}
                        fullWidth
                        label="Authorization Number"
                        name="authorizationNumber"
                        onChange={formik.handleChange}
                        value={formik.values.authorizationNumber}
                        variant="standard"
                        required={
                          formik.values.insuranceAuthorizationStatus ===
                          ReferralInsuranceAuthorizationStatuses.accepted
                            ? true
                            : false
                        }
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Switch
                              disabled={isLoading || isDisabled}
                              checked={formik.values.useReferralProvider}
                              name="useReferralProvider"
                              onChange={formik.handleChange}
                            />
                          }
                          label="Use the provider on the referral"
                        />
                      </FormGroup>
                    </Grid>
                    {formik.values.useReferralProvider == false ? (
                      <Grid item xs={12}>
                        <InsuranceAuthProviderForm steerage={steerage!} />
                      </Grid>
                    ) : null}
                  </>
                ) : null}
              </Grid>
            ) : (
              <Grid container rowSpacing={steerage?.isLocked ? 1 : 3} columnSpacing={2}>
                <Grid item xs={12} mt={1}>
                  <Typography fontWeight="500">Firefly Prior Authorization</Typography>
                </Grid>
                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <InputLabel required sx={{ ml: -2 }}>
                      Authorization required
                    </InputLabel>
                    <Select
                      label="Authorization Required"
                      disabled={isLoading || isDisabled}
                      onChange={e => {
                        formik.handleChange({
                          target: {
                            name: 'priorAuthRequired',
                            value: e.target.value,
                          } as any,
                        } as React.ChangeEvent)
                      }}
                      value={formik.values.priorAuthRequired}
                      name="priorAuthRequired"
                      variant="standard"
                      fullWidth
                      required
                    >
                      <MenuItem value="YES">Yes</MenuItem>
                      <MenuItem value="NO">No</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                {formik.values.priorAuthRequired === 'YES' ? (
                  <Grid item xs={6}>
                    <TextField
                      disabled={isLoading || isDisabled}
                      fullWidth
                      label="Originator ID"
                      name="priorAuthOriginatorSystemId"
                      onChange={formik.handleChange}
                      value={formik.values.priorAuthOriginatorSystemId}
                      variant="standard"
                    />
                  </Grid>
                ) : null}
              </Grid>
            )}
            <MemberFacingBlock>
              <Grid container spacing={2}>
                <EditField
                  InputLabelProps={{ shrink: true }}
                  disabled={isSchedulingDateDisabled}
                  label="Scheduled date"
                  type="date"
                  name="schedulingDate"
                  minDate={Moment('2016-01-01').format('YYYY-MM-DD')}
                  value={formik.values.schedulingDate}
                  onChange={formik.handleChange}
                />
              </Grid>
            </MemberFacingBlock>
            <Grid container mt={1} alignItems="center" justifyContent="flex-end">
              <Grid item>
                <Box display="flex" alignItems="center">
                  {!isLocked ? (
                    <Button
                      variant="text"
                      type="submit"
                      disabled={isDisabled}
                      endIcon={isLoading ? <CircularProgress size="1em" color="inherit" /> : null}
                      sx={{ marginRight: 1 }}
                    >
                      Save as Draft
                    </Button>
                  ) : null}
                  {!isLocked && (
                    <Button
                      onClick={handleLockButtonOnClick}
                      variant="outlined"
                      disabled={isLoading || isLockingDisabled}
                    >
                      {labelForLock}
                    </Button>
                  )}
                  {isLocked && (
                    <Button
                      onClick={handleLockButtonOnClick}
                      variant="outlined"
                      disabled={isLoading}
                      endIcon={isLoading ? <CircularProgress size="1em" color="inherit" /> : null}
                    >
                      Unlock
                    </Button>
                  )}
                </Box>
              </Grid>
            </Grid>
          </Box>
        ) : null}
      </form>
    </Box>
  )
}

const MemberFacingBlock: React.FC<BoxProps> = props => {
  const { children, ...rest } = props
  return (
    <Box {...rest} sx={{ bgcolor: grey['200'], borderRadius: 2, mx: -2, my: 2, p: 2 }}>
      <Typography variant="subtitle2" fontWeight="500" color={grey['700']}>
        Member-Facing
      </Typography>
      {children}
    </Box>
  )
}
