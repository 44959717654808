import { useEffect, useState } from 'react'
import type { FC } from 'react'
import Autocomplete from '@mui/material/Autocomplete'
import { makeStyles } from 'tss-react/mui'
import SearchIcon from '@mui/icons-material/Search'
import { InputAdornment, Box, Grid, TextField } from '@mui/material'
import { CPTCode, LocationType } from '@fireflyhealth/core'

import { LocationTypeSearchCategory, SpecialtyGroupSearchCategory } from './ProviderSearch'
import EditField from '~/components/Generic/EditField'
const useStyles = makeStyles()(theme => {
  return {
    selectAutoComplete: {
      width: '100%',
    },
  }
})
interface FacilitySearchOptionsPickerProps {
  locationTypes: LocationType[]
  onSelect: (
    locationType: LocationTypeSearchCategory | null,
    specialtyGroup: SpecialtyGroupSearchCategory | null,
    npi: string | null,
    providerName: string | null,
    gender: string | null,
    facilityName: string | null,
    procedureCode: CPTCode | null
  ) => void
  disabled: boolean
  loading: boolean
  selectedLocationType: LocationTypeSearchCategory | null
  selectedFacilityName: string | null
  setSearchRadius: (val: number) => void
}

export const FacilitySearchOptionsPicker: FC<FacilitySearchOptionsPickerProps> = (
  props: FacilitySearchOptionsPickerProps
) => {
  const [locationType, setLocationType] = useState<LocationTypeSearchCategory | null>(
    props.selectedLocationType
  )
  const [searchString, setSearchString] = useState<string>('')
  const [facilityName, setFacilityName] = useState<string | null>(props.selectedFacilityName)

  const { classes } = useStyles()

  let facilityLabels: LocationTypeSearchCategory[] = props.locationTypes?.map(locationType => {
    return {
      id: locationType.id,
      label: locationType.label,
      type: 'Facility',
      category: 'Facility',
    }
  })
  facilityLabels = facilityLabels || []

  const setSearchTerms = (event, newValue: LocationTypeSearchCategory | null) => {
    let locationTypeFromValue: LocationTypeSearchCategory | null = null
    if (newValue != null && newValue.type === 'Facility') {
      locationTypeFromValue = newValue
    }
    setLocationType(locationTypeFromValue)
    props.onSelect(locationTypeFromValue, null, null, null, null, facilityName, null)
  }

  useEffect(() => {
    props.onSelect(locationType, null, null, null, null, facilityName, null)
  }, [])

  const setFacilityNameParam = event => {
    const facilityNameFromEvent = event.target.value
    setFacilityName(facilityNameFromEvent)
    props.onSelect(locationType, null, null, null, null, facilityNameFromEvent, null)

    // set distance to 25miles if it's name search else 10miles for other searches
    facilityNameFromEvent ? props?.setSearchRadius(25) : props?.setSearchRadius(10)
  }

  return (
    <Box style={{ gap: 20 }} display="flex" width="80vh" flexDirection="column">
      <Grid item xs={6}>
        <Autocomplete
          value={locationType}
          onChange={setSearchTerms}
          inputValue={searchString}
          onInputChange={(event, newSearchString) => {
            setSearchString(newSearchString)
          }}
          id="facility-picker"
          options={facilityLabels}
          getOptionLabel={option => `${option.label}`}
          className={classes.selectAutoComplete}
          size="small"
          disabled={props.disabled}
          loading={props.loading}
          forcePopupIcon={false}
          renderOption={(props, option: LocationTypeSearchCategory) => (
            <li {...props} key={option.id}>
              {option.label}
            </li>
          )}
          renderInput={params => {
            return (
              <TextField
                {...params}
                label="Facility Type"
                variant="outlined"
                InputProps={{
                  ...params.InputProps,
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                }}
              />
            )
          }}
        />
      </Grid>
      <EditField
        InputLabelProps={{ shrink: true }}
        label="Facility Name"
        type="text"
        name="facilityName"
        value={facilityName}
        onChange={setFacilityNameParam}
        disabled={props.disabled}
      />
    </Box>
  )
}

export default FacilitySearchOptionsPicker
