import { useQuery } from 'react-query'
import { useDispatch } from 'react-redux'

import { apiClient } from '~/api/rest'
import { queueNotification } from '~/redux/actions/notifications'
import { AgreementTypeConfig, CuratedProviderType } from '../SteerageView/types'

export const useProviderSearch = () => {
  const dispatch = useDispatch()
  return useQuery(
    ['locationSearch'],
    async () => {
      const [locationTypes, specialtyGroups, cptCodes, states] = await Promise.all([
        apiClient.fact.getLocationTypes(),
        apiClient.fact.getSpecialtyGroups(),
        apiClient.cptCode.getAll({ withRibbonMappings: true }),
        apiClient.rest.get(`/states/`),
      ])
      return {
        locationTypes,
        specialtyGroups,
        cptCodes,
        states,
      }
    },
    {
      staleTime: Infinity,
      onError: () => {
        dispatch(
          queueNotification({
            message: 'Failed to load search terms',
            variant: 'error',
          })
        )
      },
    }
  )
}

export function sortingOrderForSuggestedPartners(provider): number {
  if (provider.networkPartnerAgreementType === AgreementTypeConfig.CLIENT_CONTRACT) {
    return 0
  }
  if (provider.providerType === CuratedProviderType.VIRTUAL) {
    return 1
  }
  if (provider.providerType === CuratedProviderType.AT_HOME_TEST_KIT) {
    return 2
  }
  if (provider.providerType === CuratedProviderType.IN_HOME) {
    return 3
  }
  if (provider.providerType === CuratedProviderType.IN_PERSON) {
    return 4
  }
  return 5 // Default sorting order for unknown types
}
