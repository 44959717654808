import { useCallback, useEffect, useState } from 'react'
import type { FC } from 'react'
import { useSelector } from 'react-redux'
// eslint-disable-next-line
import * as MUI from '@material-ui/core'
// eslint-disable-next-line
import * as Icons from '@material-ui/icons'
import Select, { OptionProps } from 'react-select'
import debounce from 'lodash/debounce'
import merge from 'lodash/fp/merge'

import { apiClient } from '~/api/rest'
import { useMutation } from '~/utils/useMutation'
import { ContentEntryCollection, ContentEntry, useEducationModal } from './utils'

interface EducationModalSearchProps {
  onClose: () => void
}

const Option: FC<OptionProps<ContentEntry, false, any>> = props => (
  <MUI.Typography color="secondary" component="div">
    <MUI.ListItem button onClick={props.innerProps.onClick}>
      <MUI.ListItemText>{props.data.fields.title}</MUI.ListItemText>
      <Icons.ChevronRight color="inherit" />
    </MUI.ListItem>
  </MUI.Typography>
)

export const EducationModalSearch: FC<EducationModalSearchProps> = props => {
  const [query, setQuery] = useState('')
  const [focus, setFocus] = useState(true)
  const classes = useStyles()
  const me = useSelector(state => state.me.id)
  const { setPage, setResult } = useEducationModal()

  const { result, loading, handler } = useMutation(async q => {
    const res = await apiClient.rest.get<ContentEntryCollection>(
      `/v1/lucian/providers/${me}/education/search/?filter=${q}`
    )

    // Update modal results with search includes
    setResult(prev => merge({ includes: res.includes }, prev))

    return res
  })
  const debounceSearch = useCallback(debounce(handler, 300, { leading: false, trailing: true }), [])

  // Fire debounced content search whenever user input changes
  useEffect(() => {
    if (query) {
      debounceSearch(query)
    }
  }, [query])

  const handleChange = useCallback(e => setQuery(e.target.value), [])
  const handleKeyDown = useCallback(
    e => {
      // When a user presses escape key, close search
      if (e.keyCode === 27) props.onClose()
      // When a user presses enter, fire off a new search
      if (e.keyCode === 13) {
        handler(query)
      }
    },
    [query]
  )

  const openSearchResult = useCallback((entry: ContentEntry | null) => {
    if (entry) setPage(entry)
    props.onClose()
  }, [])

  return (
    <>
      <MUI.Input
        // Considering it safe to use autofocus here because
        // this component only mounts when prompted by user input
        // eslint-disable-next-line
        autoFocus
        fullWidth
        color="secondary"
        classes={{ root: classes.input }}
        value={query}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        onBlur={() => setFocus(false)}
        onFocus={() => setFocus(true)}
      />
      <Select<ContentEntry>
        value={null}
        styles={{
          control: () => ({ backgroundColor: 'transparent' }),
          // This is to force it above list subheadings
          menu: base => ({ ...base, zIndex: 10 }),
        }}
        options={result?.items ?? []}
        isLoading={loading}
        onChange={openSearchResult}
        getOptionLabel={item => item.fields.title}
        filterOption={() => true}
        components={{
          IndicatorsContainer: () => null,
          ValueContainer: () => null,
          Input: () => null,
          Option,
        }}
        menuIsOpen={focus}
      />
    </>
  )
}

const useStyles = MUI.makeStyles(theme => ({
  input: {
    color: theme.palette.grey[200],
  },
  popper: { zIndex: 2000 },
}))

export default EducationModalSearch
