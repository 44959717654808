import { apiClient } from '~/api/rest'
import { useQuery, useMutation } from '~/components/Providers/ApiProvider'
import { IPhoneCall, IPhoneCallDisposition } from '~/models/PhoneCall'
import { LimitOffsetPagination } from '@fireflyhealth/core'
import { useQueryClient } from 'react-query'
import { useDispatch } from 'react-redux'
import chatSlice from '~/redux/slices/chat/slice'

// IClientPhoneCall represents a phone call on the client that has yet to be persisted.
export type IClientPhoneCall = Omit<
  IPhoneCall,
  | 'id'
  | 'createdAt'
  | 'updatedAt'
  | 'callEndedAt'
  | 'externalCallId'
  | 'contactPhoneNumber'
  | 'cases'
>

/**
 * addPhoneCall
 * @param payload: includes direction, phone_number, datetime and userid
 * @returns newly added phone call
 */
function addPhoneCall(payload: IClientPhoneCall): Promise<IPhoneCall> {
  return apiClient.rest.post<IPhoneCall>(`/providers/me/phone-calls/`, {
    ...payload,
  })
}

export const useAddPhoneCall = (created_by?: number) => {
  const client = useQueryClient()
  const dispatch = useDispatch()

  return useMutation(
    addPhoneCall,
    {
      onSuccess: (payload: IClientPhoneCall) => {
        if (payload.user != null) {
          if (created_by) {
            payload.createdBy = created_by
          }
          dispatch(chatSlice.actions.appendPhoneCallToChatThread(payload))
        } else {
          client.invalidateQueries([listPhoneCallsKey, payload.person])
        }
      },
    },
    { error: 'Failed to add phone call' }
  )
}

/**
 * updatePhoneCall
 * @param payload: includes id, called at, call ended at, direction, phone_number, datetime and userid
 * @returns updated phone call linked to the current patient
 */
function updatePhoneCall(
  payload: Required<Pick<IPhoneCall, 'id'>> & Partial<Omit<IPhoneCall, 'id'>>
): Promise<IPhoneCall> {
  return apiClient.rest.patch<IPhoneCall>(`/providers/me/phone-calls/${payload.id}`, payload)
}

export const useUpdatePhoneCall = (
  patientId: number | null,
  personID: number | null,
  isDeletePhoneCall: boolean
) => {
  const client = useQueryClient()
  const dispatch = useDispatch()
  return useMutation(
    updatePhoneCall,
    {
      onSuccess: async (result: IClientPhoneCall) => {
        if (patientId != null) {
          result.user = patientId
          if (isDeletePhoneCall) {
            dispatch(chatSlice.actions.deletePhoneCallFromChatThread(result))
          } else {
            dispatch(chatSlice.actions.appendPhoneCallToChatThread(result))
          }
          await client.cancelQueries([PhoneCallsKey])
          await client.invalidateQueries([PhoneCallsKey])
        }
      },
    },
    { error: 'Failed to update phone call' }
  )
}

/**
 * getPhoneCallsForPatient
 * @param patientId: user id
 * @returns filtered phone calls for patient
 */
const getPhoneCallsForPatient = async (personId: number): Promise<IPhoneCall[]> => {
  const data = await apiClient.rest.get<LimitOffsetPagination<IPhoneCall>>(
    `/providers/me/phone-calls/?person=${personId}`
  )
  return data.results || []
}

/**
 * getPhoneCallsForProvider
 * params = created_by: user id, this will be replaced with a new column once Export API is implemented
 *  user: user id for patients
 *  user_isnull: boolean, this filters phonecalls based on if user is linked
 * @returns filtered phone calls for provider
 */
const getPhoneCallsForProvider = async (params: {
  created_by?: number
  user?: number
  person_isnull?: boolean
}) => {
  const data = await apiClient.rest.get<LimitOffsetPagination<IPhoneCall>>(
    `/providers/me/phone-calls/`,
    params
  )
  return data.results || []
}

const listPhoneCallsKey = 'listPhoneCalls'
export const useGetPhoneCallsForPatient = (variables: { personId: number }) => {
  const { personId } = variables
  return useQuery(
    [listPhoneCallsKey, personId],
    () => getPhoneCallsForPatient(personId),
    {},
    {
      error: 'Failed to fetch phone calls',
    }
  )
}

const PhoneCallsKey = 'PhoneCalls'
export const useGetPhoneCallsForProvider = (params: {
  created_by?: number
  user?: number
  person?: number
  person_isnull?: boolean
}) => {
  return useQuery(
    [PhoneCallsKey, params],
    () => getPhoneCallsForProvider(params),
    {
      staleTime: 2 * 60 * 60 * 1000, // 2 minutes
    },
    {
      error: 'Failed to fetch phone calls',
    }
  )
}

async function listPhoneCallDispositions(): Promise<IPhoneCallDisposition[]> {
  const data = await apiClient.rest.get<LimitOffsetPagination<IPhoneCallDisposition>>(
    `/providers/me/phone-calls/dispositions/`
  )
  return data.results
}

const listPhoneCallDispositionsKey = 'listPhoneCallDispositions'
export const usePhoneCallDispositions = () => {
  const { data, isLoading } = useQuery(
    [listPhoneCallDispositionsKey],
    listPhoneCallDispositions,
    {
      staleTime: Infinity,
    },
    {
      error: 'Failed to fetch phone call dispositions',
    }
  )

  return { result: data || [], isLoading }
}
