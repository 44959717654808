import type { FC } from 'react'
import { Autocomplete, TextField, Checkbox as MuiCheckbox } from '@mui/material'
import {
  CheckBoxOutlineBlank as MuiIconCheckBoxOutlineBlank,
  CheckBox as MuiIconCheckBox,
} from '@mui/icons-material'
import { Service } from './types'

const checkboxIcon = <MuiIconCheckBoxOutlineBlank fontSize="small" />
const checkedCheckboxIcon = <MuiIconCheckBox fontSize="small" />

export interface ServiceValueType {
  id: number
  service: string
  category: string
}

export interface ServiceAutocompleteProps {
  onChangeLastMileService: (value: ServiceValueType[]) => void
  lastMileServices: Service[]
  selectedLastMileServices: Service[]
  disabled?: boolean
}

const ServiceAutocomplete: FC<ServiceAutocompleteProps> = props => {
  const selectedLastMileService = props?.selectedLastMileServices.map(option => {
    return {
      category: option?.category?.description,
      id: option.id,
      service: option.description == 'z Other' ? 'Other' : option.description,
    } as ServiceValueType
  })
  return (
    <Autocomplete
      disabled={props?.disabled}
      selectOnFocus
      clearOnBlur
      handleHomeEndKeys
      multiple
      disableCloseOnSelect
      filterSelectedOptions
      size="small"
      options={props?.lastMileServices?.map(
        option =>
          ({
            key: option.id,
            category: option?.category?.description,
            id: option.id,
            // We have added last mile service as "z Other" instead of "Other" because we needed to
            // sort the "Other" at the last position in the last mile service list for the category
            service: option.description == 'z Other' ? 'Other' : option.description,
          } as ServiceValueType)
      )}
      groupBy={option => option.category}
      getOptionLabel={option => option.service}
      isOptionEqualToValue={(option, value) =>
        option.category == value.category && option.id == value.id
      }
      renderInput={params => <TextField variant="standard" {...params} label={'Services'} />}
      renderOption={(props, option, { selected }) => (
        <li {...props}>
          <MuiCheckbox
            icon={checkboxIcon}
            checkedIcon={checkedCheckboxIcon}
            style={{ marginRight: 8 }}
            checked={selected}
          />
          {option.service}
        </li>
      )}
      value={selectedLastMileService}
      onChange={async (event, value) => {
        props?.onChangeLastMileService(value)
      }}
    />
  )
}

export default ServiceAutocomplete
