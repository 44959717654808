import { List, ListItemText, ListItemButton } from '@mui/material'
import { NavLink, Route } from 'react-router-dom'
import InterfaceContent from '~/components/Interface/InterfaceContent'
import OnCallSettings from '~/components/Settings/OnCallSettings'
import MacroSettings from '~/components/Settings/MacroSettings'
import OnboardingGuideSettings from '~/components/Settings/OnboardingGuideSettings'
import FormViewerSettings from '~/components/Settings/FormViewerSettings'
import FeatureSwitchSettings from '~/components/Settings/FeatureSwitchSettings'
import EmpanelmentSettings from '~/components/Settings/EmpanelmentSettings'
import ClinicHoursSettings from '~/components/Settings/ClinicHoursSettings'
import ProviderShiftSettings from '~/components/Settings/ProviderShiftSettings'
import ErrorScreen from '~/components/ErrorScreen'
import { ErrorBoundary } from 'react-error-boundary'
import { Features, BehindProviderFeatureAccessCheck } from '@fireflyhealth/core'
import { useFeatureAccess } from '~/utils/useFeatureAccess'
import PracticeSuiteInvoiceSettings from '~/components/Settings/PracticeSuiteInvoiceSettings'

const NAV_LINKS: {
  path: string
  name: string
  component: any
  enabled: boolean
  feature: Features
}[] = [
  {
    path: 'on-call',
    name: 'On-Call',
    component: OnCallSettings,
    enabled: true,
    feature: 'practiceSettings',
  },
  {
    path: 'provider-shifts',
    name: 'Provider Shifts',
    component: ProviderShiftSettings,
    enabled: true,
    feature: 'practiceSettings',
  },
  {
    path: 'clinic-hours',
    name: 'Practice Hours',
    component: ClinicHoursSettings,
    enabled: true,
    feature: 'practiceSettings',
  },
  {
    path: 'empanelment',
    name: 'State Licenses',
    component: EmpanelmentSettings,
    enabled: true,
    feature: 'practiceSettings',
  },
  {
    path: 'feature-switches',
    name: 'Feature Switches',
    component: FeatureSwitchSettings,
    enabled: true,
    feature: 'practiceSettings',
  },
  {
    path: 'form-viewer',
    name: 'Form Viewer',
    component: FormViewerSettings,
    enabled: true,
    feature: 'forms',
  },
  {
    path: 'macros',
    name: 'Macros (Beta)',
    component: MacroSettings,
    enabled: true,
    feature: 'practiceSettings',
  },
  {
    path: 'onboarding-guide',
    name: 'Onboarding Guide',
    component: OnboardingGuideSettings,
    enabled: true,
    feature: 'practiceSettings',
  },
  {
    path: 'practice-suite',
    name: 'Practice Suite Invoice Upload',
    component: PracticeSuiteInvoiceSettings,
    enabled: true,
    feature: 'billing',
  },
]

const Sidebar = (
  <List>
    {NAV_LINKS.filter(link => link.enabled).map(link => (
      <ListItemButton
        component={NavLink}
        to={`/settings/${link.path}`}
        key={link.path}
        sx={{
          '&.active': {
            color: 'primary.main',
          },
        }}
      >
        <ListItemText primary={link.name} sx={{ '& span': { fontSize: '1.8rem' } }} />
      </ListItemButton>
    ))}
  </List>
)

const Settings = () => {
  const featureAccess = useFeatureAccess()

  return (
    <InterfaceContent sidebar={Sidebar}>
      <ErrorBoundary FallbackComponent={ErrorScreen as any}>
        {NAV_LINKS.map(link => (
          <BehindProviderFeatureAccessCheck
            featureAccess={featureAccess}
            feature={link.feature}
            key={link.path}
          >
            <Route path={`/settings/${link.path}`} component={link.component} />
          </BehindProviderFeatureAccessCheck>
        ))}
      </ErrorBoundary>
    </InterfaceContent>
  )
}

export default Settings
