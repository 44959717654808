import { Add as AddIcon } from '@mui/icons-material'
import {
  Box,
  Button,
  CircularProgress,
  IconButton,
  Link as MLink,
  Menu,
  MenuItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@mui/material'
import { PatientTodo } from '~/models/PatientTodoItem'
import { useAddTask, useUpdateTask } from '~/api/TaskService'
import { useContext, useEffect, useState } from 'react'

import AddTaskEditor from '../Tasks/TaskEditor'
import CheckBox from '~/components/CheckBox/CheckBox'
import type { FC } from 'react'
import { ITask } from '~/models/Task'
import Moment from 'moment'
import { PatientUser } from '~/types'
import PriorityIcon from '../Generic/PriorityIcon'
import { Worklist } from '~/redux/slices/worklists'
import classNames from 'classnames'
import { format } from 'date-fns'
import { makeStyles } from 'tss-react/mui'
import { useSelector } from 'react-redux'
import UserGroupSelect from '../Generic/UserGroupSelect'
import NextActionDate from '../WorkUnit/NextActionDate'
import { getUserName } from '~/utils'
import { getUpdatedAtLabel } from '~/components/Tasks/utils'
import PriorityToggle from '../Generic/PriorityToggleInline'
import { useGroupOptions } from '~/utils/useGroupOptions'
import { SelectedTodoContext } from '~/utils/useManageSelectedTodo'

// Keep task title under the 255-char DB limit
const MAX_MESSAGE_LENGTH_INCLUDED_IN_FORWARD = 40

const useStyles = makeStyles<void, 'actions'>()((theme, _params, classes) => {
  return {
    container: {
      display: 'flex',
      transition: '250ms linear opacity',
      [`&:hover .${classes.actions}`]: {
        opacity: 1,
      },
    },
    containerLoading: {
      opacity: 0.5,
      pointerEvents: 'none',
    },
    cell: {
      padding: theme.spacing(0.7),
    },
    cellCheck: {
      flex: '0 1 5%',
      width: '5%!important',
      paddingLeft: theme.spacing(2),
    },
    cellNewCheck: {
      flex: '0 1 4%',
      width: '4%!important',
      paddingLeft: theme.spacing(1),
      paddingRight: 0,
    },
    cellTask: {
      flex: '0 1 45%',
      width: '45%!important',
      paddingBottom: 0,
    },
    cellNewTask: {
      flex: '0 1 40%',
      width: '40%!important',
      paddingBottom: 0,
    },
    cellOther: {
      flex: '0 1 15%',
      width: '15%!important',
    },
    cellNewOther: {
      flex: '0 1 25%',
      width: '25%!important',
    },
    cellDueDate: {
      flex: '0 1 20%',
      width: '20%!important',
    },
    cellNewDueDate: {
      flex: '0 1 15%',
      width: '15%!important',
    },
    actions: {
      display: 'flex',
      alignItems: 'center',
      margin: '.25rem 0 0.7rem',
      opacity: 0,
      transition: '100ms linear opacity',
    },
    actionsNew: {
      display: 'none',
    },
    addLink: {
      '&:hover': {
        cursor: 'pointer',
        color: theme.palette.primary.main,
        backgroundColor: 'transparent',
      },
    },
    actionsLink: {
      display: 'flex',
      alignItems: 'center',
      marginRight: theme.spacing(),
      color: theme.palette.grey[700],
      fontSize: '1.6rem',
      textDecoration: 'none',
      whiteSpace: 'nowrap',
      border: 0,
      background: 'none',
      padding: 0,
      cursor: 'pointer',
      transition: '100ms linear color',
      '&:hover': {
        color: theme.palette.primary.main,
      },
    },
    actionsLinkNew: {
      display: 'none',
    },
    actionsLinkText: {
      fontSize: '1.2rem',
      marginLeft: theme.spacing(0.5),
    },
    [`@media (max-width: ${theme.breakpoints.values.lg}px)`]: {
      actionsLinkText: {
        display: 'none',
      },
    },

    secondaryTitle: {
      paddingLeft: '.5rem',
    },
    worklistItemChip: {
      marginLeft: theme.spacing(),
      color: theme.palette.primary.main,
      background: theme.palette.grey[200],
      padding: theme.spacing(0.7, 0.7, 0.7),
    },
    worklistItemCheckboxIcon: {
      color: theme.palette.grey[300],
    },
    modal: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: '40%',
    },
    textareaBox: {
      '&::placeholder': {
        color: 'black',
      },
    },
  }
})

/** ToDoTask
 * This component renders a single task in a parent object's To Do list.
 */
const ToDoTask: FC<{
  contentType: PatientTodo['contentType']
  todoItem: PatientTodo['items'][number]['contentObject']
  patient: PatientUser
  isReadOnly?: boolean
  setDisableEdit: Function
  setIsTodoTaskUpdating: Function
}> = ({ contentType, todoItem, patient, isReadOnly, setDisableEdit, setIsTodoTaskUpdating }) => {
  const { classes } = useStyles()

  // Track if an update to isComplete is pending, so that checking the checkbox
  // feels more responsive to user input.
  const [optimisticIsComplete, setOptimisticIsComplete] = useState<boolean | null>(null)

  const { mutateAsync: handleUpdateTask, isLoading: isUpdatingTask } = useUpdateTask()

  const { mutateAsync: handleUpdateTaskAssignee, isLoading: isUpdatingTaskAssignee } =
    useUpdateTask()

  const user = useSelector(state => {
    if (todoItem?.createdBy) return state.users.byId[todoItem?.createdBy]
    else return null
  })

  const [priorityChange, setPriorityChange] = useState<boolean>(false)

  const [anchorEl, setAnchorEl] = useState<Element | null>(null)

  /**
   * toggleTaskComplete
   * handle task complete
   */
  const toggleTaskComplete = async (todoItem: PatientTodo['items'][number]['contentObject']) => {
    setDisableEdit(true)
    setIsTodoTaskUpdating(true)
    setOptimisticIsComplete(!todoItem.isComplete)
    delete todoItem.owner
    await handleUpdateTask({
      ...(todoItem as ITask),
      isComplete: !todoItem.isComplete,
    })
    setOptimisticIsComplete(null)
    setDisableEdit(false)
    setIsTodoTaskUpdating(false)
  }

  const onTaskUpdate = async (
    updatedField,
    updatedFieldValue,
    todoItem: PatientTodo['items'][number]['contentObject']
  ) => {
    setIsTodoTaskUpdating(true)
    if (updatedField == 'ownerGroup') {
      delete todoItem.owner
      await handleUpdateTaskAssignee({
        ...(todoItem as ITask),
        [updatedField]: updatedFieldValue,
      })
    } else {
      await handleUpdateTask({
        ...(todoItem as ITask),
        [updatedField]: updatedFieldValue,
      })
      setPriorityChange(false)
    }
    setIsTodoTaskUpdating(false)
  }

  const { selectedCaseOwnerGroupOption, flatOptions } = useGroupOptions(
    todoItem?.ownerGroup || null,
    contentType !== 'case',
    patient,
    patient.person.careTeam,
    todoItem.isComplete || isReadOnly
  )

  return (
    <TableRow role="button" tabIndex={0} className={classNames(classes.container)}>
      <TableCell className={classNames(classes.cellNewCheck)}>
        <Box sx={{ display: 'flex', alignItems: 'center', height: '100%', paddingTop: 2 }}>
          <CheckBox
            value={optimisticIsComplete ?? todoItem.isComplete}
            onChecked={() => toggleTaskComplete(todoItem)}
            disabled={isReadOnly}
          />
        </Box>
      </TableCell>
      <TableCell
        align="left"
        className={classNames(classes.cell, classes.cellNewTask)}
        sx={{
          '& .MuiTypography-root': {
            fontSize: '1.4rem',
          },
          '& svg': {
            borderRadius: 0,
          },
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', height: '100%' }}>
          {!priorityChange ? (
            <PriorityToggle
              priority={todoItem.priority ? todoItem.priority : 0}
              onChange={(e, _) => {
                setAnchorEl(e.currentTarget)
              }}
              disabled={isUpdatingTask || todoItem.isComplete}
            />
          ) : (
            <Box sx={{ minWidth: '26px', marginTop: '1.1rem' }}>
              <CircularProgress variant="indeterminate" color={'primary'} size={'2rem'} />
            </Box>
          )}
          <Menu
            MenuListProps={{
              disablePadding: true,
            }}
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={e => {
              setAnchorEl(null)
            }}
            sx={{ marginLeft: '-1.7rem', marginTop: '-2.5rem' }}
          >
            <MenuItem
              onClick={() => {
                setPriorityChange(true)
                setAnchorEl(null)
                onTaskUpdate('priority', 0, todoItem)
              }}
              sx={{
                marginTop: '0.5rem',
              }}
            >
              <PriorityIcon level={0} variant="view" />
              <Typography sx={{ marginTop: '0.5rem', marginLeft: '1rem' }}>Standard</Typography>
            </MenuItem>
            <MenuItem
              onClick={() => {
                setPriorityChange(true)
                setAnchorEl(null)
                onTaskUpdate('priority', 1, todoItem)
              }}
            >
              <PriorityIcon level={1} variant="view" />
              <Typography sx={{ marginTop: '0.5rem', marginLeft: '1rem' }}>High</Typography>
            </MenuItem>
            <MenuItem
              onClick={() => {
                setPriorityChange(true)
                setAnchorEl(null)
                onTaskUpdate('priority', 2, todoItem)
              }}
              sx={{
                marginBottom: '1rem',
              }}
            >
              <PriorityIcon level={2} variant="view" />
              <Typography sx={{ marginTop: '0.5rem', marginLeft: '1rem' }}>Urgent</Typography>
            </MenuItem>
          </Menu>

          <TextField
            hiddenLabel
            disabled={isReadOnly || todoItem.isComplete}
            placeholder={'+ Add title'}
            defaultValue={todoItem.title}
            multiline
            onClick={e => {
              e.stopPropagation()
            }}
            onBlur={e => {
              if (e.target.value !== '' && todoItem.title !== e.target.value) {
                onTaskUpdate('title', e.target.value, todoItem)
              }
            }}
            InputProps={{ classes: { input: classes.textareaBox } }}
            sx={{
              wordBreak: 'break-word',
              fontSize: '1.4rem',
              width: '100%',
              '& .MuiInputBase-root.MuiOutlinedInput-root': {
                padding: 0,
              },
              '& textarea': {
                padding: 0,
                paddingLeft: 1,
                paddingTop: 0.2,
                paddingBottom: 0.2,
                fontSize: '1.4rem',
                color: 'navy.600',
              },
              '& fieldset': {
                border: 'none',
              },
              '& textarea:focus': {
                border: '1px solid black',
                borderColor: 'grey.500',
                borderRadius: 1,
              },
            }}
          />
        </Box>
      </TableCell>
      <TableCell
        align="left"
        className={classNames(classes.cell, classes.cellNewOther)}
        sx={{
          '& .MuiInputBase-root.MuiOutlinedInput-root': {
            fontSize: '1.4rem',
          },
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', height: '100%' }}>
          {todoItem.ownerGroup ? (
            <UserGroupSelect
              selectedValue={selectedCaseOwnerGroupOption}
              options={flatOptions}
              onChange={async ownerGroupOption => {
                const ownerGroup = ownerGroupOption.value
                onTaskUpdate('ownerGroup', ownerGroup, todoItem)
              }}
              detailed
              patient={patient}
              disabled={todoItem.isComplete || isReadOnly || isUpdatingTaskAssignee}
            />
          ) : null}
        </Box>
      </TableCell>
      <TableCell
        align="left"
        className={classNames(classes.cell, classes.cellNewDueDate)}
        sx={{
          '& .MuiBox-root': {
            marginLeft: '0 !important',
          },
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', height: '100%' }}>
          <NextActionDate
            date={todoItem.dueDate}
            editable={!todoItem.isComplete}
            newTodos={true}
            onChange={dueDate => onTaskUpdate('dueDate', dueDate, todoItem)}
          />
        </Box>
      </TableCell>
      <TableCell align="left" className={classNames(classes.cell, classes.cellOther)}>
        {user ? (
          <Box sx={{ display: 'flex', alignItems: 'center', height: '100%' }}>
            <Box>
              <Typography
                sx={{
                  fontSize: '1.2rem',
                  color: 'grey.500',
                }}
              >
                {`Created by ` + getUserName(user)}
              </Typography>
              <Typography
                sx={{
                  fontSize: '1.2rem',
                  color: 'grey.500',
                }}
              >
                {todoItem.createdAt ? getUpdatedAtLabel(Moment(todoItem.createdAt).toDate()) : ''}
              </Typography>
            </Box>
          </Box>
        ) : null}
      </TableCell>
    </TableRow>
  )
}

/**
 * ToDoTasks
 * This component used to display all tasks in patient to do section
 */
const ToDoTasks = (props: {
  worklist: Worklist | undefined
  parentId: number
  patient: PatientUser
  todoContent: PatientTodo
  isClosed: boolean
  setDisableEdit: Function
  setIsTodoTaskUpdating: Function
}) => {
  const { parentId, patient, todoContent, isClosed, setDisableEdit, setIsTodoTaskUpdating } = props
  const me = useSelector<any, any>(state => state.me)

  const { classes } = useStyles()

  const newTaskData = {
    title: '',
    dueDate: format(new Date(), 'YYYY-MM-DD'),
    ...{ ownerGroup: me.assigneeGroup?.id },
    priority: 0,
    isComplete: false,
  }

  const [addingTask, setAddingTask] = useState<boolean>(false)
  const [newTask, setNewTask] = useState<ITask>(newTaskData)
  const [showCompleted, setShowCompleted] = useState<boolean>(false)

  const { isLoading, mutate: handleCreateTask } = useAddTask()

  const toDoItems = todoContent.items.map(item => item.contentObject)
  const contentType = todoContent.contentType
  const showExpandLink: boolean = todoContent.items.some(item => item.contentObject.isComplete)

  const handleAddTask = async () => {
    const payload = {
      ...newTask,
      dueDate: Moment(newTask.dueDate).format(),
      patient: patient.id,
      person: patient.person.id,
      relations: [
        {
          content_type: contentType,
          object_id: parentId,
        },
      ],
      isComplete: false,
    }
    setIsTodoTaskUpdating(true)
    handleCreateTask(payload)
    setAddingTask(!addingTask)
    setNewTask(newTaskData)
    setIsTodoTaskUpdating(false)
  }

  const { selectPayload, selectedCaseId } = useContext(SelectedTodoContext)

  useEffect(() => {
    if (
      !selectedCaseId ||
      selectedCaseId != parentId ||
      !selectPayload ||
      selectPayload?.type !== 'createForwardTask'
    ) {
      return
    }
    setAddingTask(true)
    let title = `RE: "${selectPayload.message.text || ''}`
    if (title.length >= MAX_MESSAGE_LENGTH_INCLUDED_IN_FORWARD) {
      const elipsis = '...'
      title = title.substring(0, MAX_MESSAGE_LENGTH_INCLUDED_IN_FORWARD - elipsis.length) + elipsis
    }
    title += '"'
    setNewTask({
      title,
      dueDate: format(new Date(), 'YYYY-MM-DD'),
      ownerGroup: selectPayload.ownerGroup,
      priority: 1,
      isComplete: false,
      isForwardingMessage: true,
    })
  }, [selectedCaseId, selectPayload])

  return (
    <>
      <TableContainer>
        <Table size="small">
          <TableHead
            sx={{
              display: toDoItems.length === 0 ? 'none' : 'table-header-group',
            }}
          >
            <TableRow
              className={classNames(classes.container, {
                [classes.containerLoading]: isLoading,
              })}
            >
              <TableCell
                align="left"
                colSpan={2}
                className={classNames(classes.cell, classes.cellNewTask)}
              >
                Task
              </TableCell>
              <TableCell className={classNames(classes.cellNewCheck)}> </TableCell>
              <TableCell align="left" className={classNames(classes.cell, classes.cellNewOther)}>
                Assigned to
              </TableCell>
              <TableCell align="left" className={classNames(classes.cell, classes.cellNewDueDate)}>
                Due
              </TableCell>
              <TableCell
                align="left"
                className={classNames(classes.cell, classes.cellOther)}
              ></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {toDoItems
              .filter(todoItem => {
                if (!showExpandLink) {
                  return true
                }

                return !todoItem.isComplete || showCompleted
              })
              .map(todoItem => {
                return (
                  <ToDoTask
                    key={`ToDoItem_${contentType}_${todoItem.id}`}
                    todoItem={todoItem}
                    patient={patient}
                    contentType={contentType}
                    // Generally, a closed WorkUnit should not have any
                    // incomplete tasks. If this does happen to occur, we can
                    // still allow any existing incomplete tasks to be
                    // completed.
                    isReadOnly={isClosed && todoItem.isComplete}
                    setDisableEdit={setDisableEdit}
                    setIsTodoTaskUpdating={setIsTodoTaskUpdating}
                  />
                )
              })}
          </TableBody>
        </Table>
      </TableContainer>
      {!addingTask && contentType !== 'worklist' && (
        <Box display="flex">
          <Box display="flex" flexGrow={1}>
            <IconButton
              disabled={isClosed}
              onClick={() => setAddingTask(!addingTask)}
              className={classes.addLink}
              color="secondary"
            >
              <AddIcon sx={{ fontSize: '1.4rem' }} />
              {isClosed ? (
                <Typography sx={{ fontSize: '1.4rem' }}>Update status to add tasks</Typography>
              ) : (
                <Typography sx={{ fontSize: '1.4rem', fontWeight: 500 }}>Add task</Typography>
              )}
            </IconButton>
          </Box>
          {showExpandLink && (
            <Box display="flex" paddingRight={2} paddingTop={2} flexGrow={1}>
              <ExpandTodoContentLink
                todoContent={todoContent}
                showCompleted={showCompleted}
                handleShowCompletedItems={newValue => setShowCompleted(newValue)}
              />
            </Box>
          )}
        </Box>
      )}
      {addingTask && (
        <Box margin="1rem 0.2rem 2rem 1rem">
          <Paper>
            <Box p={2}>
              <Box marginTop={1} marginBottom={4}>
                <AddTaskEditor
                  disabled={isLoading}
                  task={newTask}
                  patient={patient}
                  onChange={task => {
                    setNewTask(task)
                  }}
                  canAssignToPatient={contentType === 'case' ? false : true}
                />
              </Box>
              <Box mt={2} display="flex" flex={1} justifyContent="flex-end">
                <Box ml={1}>
                  <Button disabled={isLoading} onClick={() => setAddingTask(!addingTask)}>
                    Cancel
                  </Button>
                  <Button
                    disabled={isLoading || !newTask.title}
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      handleAddTask()
                    }}
                  >
                    <Typography variant="button">Add Task</Typography>
                  </Button>
                </Box>
              </Box>
            </Box>
          </Paper>
        </Box>
      )}
    </>
  )
}

const ExpandTodoContentLink = ({
  todoContent,
  showCompleted,
  handleShowCompletedItems,
}: {
  todoContent: PatientTodo
  showCompleted: boolean
  handleShowCompletedItems: (newValue: boolean) => void
}) => {
  const { classes } = useStyles()

  const numCompleted = todoContent.items.filter(item => item.contentObject.isComplete).length

  return (
    <Box fontWeight="bold">
      <MLink
        onClick={() => handleShowCompletedItems(!showCompleted)}
        color="secondary"
        className={classes.addLink}
        underline="always"
      >
        {showCompleted
          ? `HIDE ${numCompleted} COMPLETED TASKS`
          : `SHOW ${numCompleted} COMPLETED TASKS`}
      </MLink>
    </Box>
  )
}

export default ToDoTasks
