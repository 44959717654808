import { PatientUser } from '@fireflyhealth/core'
import { useSelector } from 'react-redux'
import { AssigneeGroupOption, getAssignableUserGroups } from '~/utils/userGroups'

export function useGroupOptions(
  value: number | null,
  canAssignToPatient: boolean,
  patient?: PatientUser | null,
  careTeam?: number[],
  disabled?: boolean
) {
  const providers = useSelector(state => state.providers)

  const assigneeGroups = useSelector(state => state.assigneeGroups)
  const careTeamDetails = careTeam ?? []

  const groupedOptions = getAssignableUserGroups(
    providers,
    canAssignToPatient,
    careTeamDetails,
    patient,
    assigneeGroups
  )
  const flatOptions: AssigneeGroupOption[] = []
  groupedOptions.map(category => flatOptions.push(...category.options))

  const selectedCaseOwnerGroupOption = flatOptions.find(option => option.value == value)

  return { selectedCaseOwnerGroupOption, flatOptions, groupedOptions, disabled }
}
