import { AssigneeGroup, PatientUser, ProviderUser } from '@fireflyhealth/core'
import { providerCanProvideClinicalCareToPatient } from './index'

export interface AssigneeGroupOption {
  label: string
  value: number | null // the Assignee Group id
  disabledReason?: string | null
  category: string
}

const DISABLED_REASONS = {
  out_of_state: "Not licensed in the patient's state",
  inactive: 'Inactive',
}

// Returns a list of grouped options, meant to be used in a react-select component
// Each individual option is a AssigneeGroup who a Task may be assigned to
export const getAssignableUserGroups = (
  providers: ProviderUser[],
  canAssignToPatient: boolean,
  careTeam: number[] | undefined,
  patient?: PatientUser | null,
  assigneeGroups?: AssigneeGroup[] | null
): {
  label: string
  options: AssigneeGroupOption[]
}[] => {
  const disabledReason = (userOption: ProviderUser): string | null => {
    if (patient && !providerCanProvideClinicalCareToPatient(userOption, patient)) {
      return DISABLED_REASONS['out_of_state']
    }
    if (!userOption.isActive) {
      return DISABLED_REASONS['inactive']
    }

    return null
  }

  careTeam = careTeam ?? []

  const patientCareTeam =
    careTeam.length > 0
      ? providers.filter(
          provider => !provider.testOnly && provider.isActive && careTeam!.includes(provider.id)
        )
      : []

  // Find all providers(physicians) with practicing states in patient state
  const providersInPatientState = patient
    ? providers.filter(
        provider =>
          providerCanProvideClinicalCareToPatient(provider, patient) &&
          !careTeam!.includes(provider.id) &&
          provider.assigneeGroup != null
      )
    : []

  let activeCoveringProviders = providers.filter(
    provider =>
      !provider.testOnly &&
      provider.isActive &&
      !careTeam!.includes(provider.id) &&
      provider.assigneeGroup != null
  )
  if (patient) {
    activeCoveringProviders = activeCoveringProviders.filter(
      provider =>
        !providerCanProvideClinicalCareToPatient(provider, patient) &&
        provider.assigneeGroup != null
    )
  }

  const inactiveProviders = providers.filter(
    provider => (provider.testOnly || !provider.isActive) && provider.assigneeGroup != null
  )

  const groupOfUsers = assigneeGroups?.filter(group => group.isGroup == true)

  const orderedPatientCareTeam = patientCareTeam.sort((a, b) =>
    a.firstName.localeCompare(b.firstName)
  )

  const orderedProvidersInPatientState = providersInPatientState.sort((a, b) =>
    a.firstName.localeCompare(b.firstName)
  )

  const orderedCoveringProviders = activeCoveringProviders.sort((a, b) =>
    a.firstName.localeCompare(b.firstName)
  )
  const orderedInactiveProviders = inactiveProviders.sort((a, b) =>
    a.firstName.localeCompare(b.firstName)
  )

  const orderedGroupOfUsers = groupOfUsers?.sort((a, b) => a.name.localeCompare(b.name))
  const groupedGroupOfUsers = orderedGroupOfUsers
    ? [
        {
          label: 'GROUPS',
          options: orderedGroupOfUsers.map(group => ({
            label: `${group.name}`,
            value: group.id || null,
            disabledReason: null,
            category: 'Groups',
          })),
        },
      ]
    : []

  const groupedCareTeam = careTeam
    ? [
        {
          label: 'CARE TEAM',
          options: orderedPatientCareTeam.map(provider => ({
            label: `${provider.assigneeGroup?.name}`,
            value: provider.assigneeGroup?.id || null,
            disabledReason: disabledReason(provider),
            category: 'Care Team',
          })),
        },
      ]
    : []

  const groupedProvidersInPatientState = providersInPatientState
    ? [
        {
          label: 'CLINICIANS IN STATE',
          options: orderedProvidersInPatientState.map(provider => ({
            label: `${provider.assigneeGroup?.name}`,
            value: provider.assigneeGroup?.id || null,
            disabledReason: disabledReason(provider),
            category: 'Clinicians In State',
          })),
        },
      ]
    : []

  const groupedProviders = [
    {
      label: 'COVERING TEAM',
      options: orderedCoveringProviders.map(provider => ({
        label: `${provider.assigneeGroup?.name}`,
        value: provider.assigneeGroup?.id || null,
        disabledReason: disabledReason(provider),
        category: 'Covering Team',
      })),
    },
    {
      label: 'INACTIVE CARE TEAM',
      options: orderedInactiveProviders.map(provider => ({
        label: `${provider.assigneeGroup?.name}`,
        value: provider.assigneeGroup?.id || null,
        disabledReason: disabledReason(provider),
        category: 'Inactive Care Team',
      })),
    },
  ]

  let groupedOptions =
    canAssignToPatient && patient
      ? [
          {
            label: 'PATIENT',
            options: [
              {
                label: `${patient?.assigneeGroup?.name}`,
                value: patient?.assigneeGroup?.id || null,
                category: 'Patient',
              },
            ],
          },
        ]
      : []

  groupedOptions = groupedOptions.concat(
    groupedCareTeam,
    groupedGroupOfUsers,
    groupedProvidersInPatientState,
    groupedProviders
  )
  return groupedOptions
}
