import { CircularProgress, Box } from '@mui/material'
import AutoSizer from 'react-virtualized-auto-sizer'
import InfiniteLoader from 'react-window-infinite-loader'
import { FixedSizeList as List } from 'react-window'
import { useState, useEffect } from 'react'
import type { FC } from 'react'
import Moment from 'moment'
import { CPTCode, ProviderSearchResults } from '@fireflyhealth/core'
import { ProviderListItem, ProviderDetailWithAdditionalData } from './ProviderListItem'
import { apiClient } from '~/api/rest'
import { useDispatch } from 'react-redux'
import { queueNotification } from '~/redux/actions/notifications'
import { SpecialtyGroupSearchCategory, LocationTypeSearchCategory } from './ProviderSearch'
import { SteerageProvider } from '~/components/PatientDetail/SteerageView/types'
import { EventType, logEvent, SteerageSearchEventPayload } from '~/utils/events'

const PROVIDER_SEARCH_PAGE_SIZE_HUNDRED = 100

interface ProviderListProps {
  person: any
  locationType: LocationTypeSearchCategory | null
  specialtyGroup: SpecialtyGroupSearchCategory | null
  zipCode: string | null | undefined
  distance: number
  npi: string | null
  providerName: string | null
  gender: string | null
  facilityName: string | null
  procedureCode: CPTCode | null
  steerageId: number | null
  resultCountCallback: (val: number) => void
  loadingResultsCallback: (val: boolean) => void
  steerageProviderList?: Array<SteerageProvider>
  states?: Array<any>
  isProviderSearch: Boolean | null
  searchAvailabilityId: number | undefined
}

export const ProviderList: FC<ProviderListProps> = (props: ProviderListProps) => {
  const dispatch = useDispatch()

  const page_size = PROVIDER_SEARCH_PAGE_SIZE_HUNDRED

  // Derived values from the progressive search that are used to control page functionality
  const [loadingProviders, setLoadingProviders] = useState<boolean>(false)
  const [isFacilityList, setIsFacilityList] = useState<boolean>(
    !!props.locationType || !!props.facilityName
  )
  const [resultCount, setResultCount] = useState<number | null>(null)
  const [currentPageNumber, setCurrentPageNumber] = useState<number>(0)
  const [hasNextPage, setHasNextPage] = useState<boolean>(false)
  const [providers, setProviders] = useState<ProviderDetailWithAdditionalData[]>([])
  const [searchRequestId, setSearchRequestId] = useState<number | null>(null)

  // Always return resultCount to caller
  useEffect(() => {
    props.resultCountCallback(resultCount == null ? 0 : resultCount)
  }, [resultCount])

  const fetchNextPage = async () => {
    if (loadingProviders) {
      return
    }
    setLoadingProviders(true)

    try {
      let npi: string[] | null = null
      if (props.npi == null || props.npi.length === 0) {
        npi = null
      } else {
        npi = [props.npi]
      }
      let nextPageNumber = currentPageNumber + 1
      let searchResults: ProviderSearchResults
      let requestStartTime = Moment()

      if (props.isProviderSearch) {
        searchResults = await apiClient.referral.searchRibbonProviders(
          props.person.id,
          props.zipCode || null,
          props.specialtyGroup?.id || null,
          props.distance,
          nextPageNumber,
          page_size,
          npi,
          props.providerName,
          props.gender,
          props.procedureCode?.id || null,
          props?.steerageId || null,
          props.searchAvailabilityId || null
        )
      } else {
        searchResults = await apiClient.referral.searchRibbonFacilities(
          props.person.id,
          props.zipCode || null,
          props.locationType?.id || null,
          props.distance,
          nextPageNumber,
          page_size,
          props.facilityName,
          props?.steerageId || null,
          props.searchAvailabilityId || null
        )
      }
      setSearchRequestId(searchResults.id)

      let eventType: EventType = 'SEARCH_REQUEST'
      if (props.steerageId != null) {
        eventType = 'STEERAGE_SEARCH_REQUEST'
      }
      let requestDurationInSeconds = Moment.duration(Moment().diff(requestStartTime)).asSeconds()
      logEvent<SteerageSearchEventPayload>(eventType, {
        steerageId: props.steerageId,
        searchRequestId: searchResults.id,
        pageSize: page_size,
        numberOfProviders: (searchResults.results || []).length,
        requestDurationInSeconds: requestDurationInSeconds,
        SearchAvailabilityId: props?.searchAvailabilityId || null,
      })
      setCurrentPageNumber(nextPageNumber)
      setProviders(p => p.concat(searchResults.results as ProviderDetailWithAdditionalData[]))
      // There exists a next page of results in ribbon
      // if the current page multiplied by the page size (which is the total number
      // of results loaded from ribbon) is less than the total number of ribbon results
      setHasNextPage(searchResults.recordsAvailable > page_size * nextPageNumber)
      const uniqueProvidersSet = new Set()
      for (const result of searchResults.results) {
        // use npi for providers and id for locations
        let identifier = result.npi != null ? result.npi : result.id
        uniqueProvidersSet.add(identifier)
      }
      let totalRecordsAvailable = resultCount == null ? searchResults.recordsAvailable : resultCount

      // Ribbon gives us one practitioner object that contains all the locations for that
      // practitioner. Our backend then splits this result into one row per practitioner-location combination.
      // But the recordsAvailable param still indicates the number of providers in the resultset.
      // Here's some math to true up the recordsAvailable param.
      // Whenever we get data from ribbon
      //    From the recordsAvailableParam
      //    - deduct the number of unique providers in the resultset(
      //      which is what ribbon accounts for)
      //    - and add the number of actual rows from our APIs
      // By doing this we essentially replace the count from ribbon with the count of data
      // from our internal APIs
      totalRecordsAvailable =
        // total number of provider results available from ribbon
        totalRecordsAvailable -
        // number of provider results loaded from ribbon for the current page
        uniqueProvidersSet.size +
        // number of generated provider-location combinations for the current page
        (searchResults.results || []).length

      setResultCount(totalRecordsAvailable)
    } catch (e) {
      dispatch(
        queueNotification({
          message: 'Unable to retrieve search results',
          variant: 'error',
        })
      )
      console.error(e)
    }
    setLoadingProviders(false)
  }

  // Always attempt a fetch on an empty list (first page load, search-reset)
  useEffect(() => {
    if (providers.length === 0) fetchNextPage()
  }, [providers])

  useEffect(() => {
    props.loadingResultsCallback(loadingProviders)
  }, [loadingProviders])

  // Reset search whenever a search term changes
  useEffect(() => {
    setProviders([])
    setIsFacilityList(!!props.locationType || !!props.facilityName)
    setResultCount(null)
    setCurrentPageNumber(0)
    setHasNextPage(false)
  }, [
    props.locationType,
    props.specialtyGroup,
    props.distance,
    props.zipCode,
    props.facilityName,
    props.npi,
    props.providerName,
    props.gender,
    props.procedureCode,
  ])

  const loadMoreItems = loadingProviders ? () => {} : fetchNextPage

  // Every row is loaded except for our loading indicator row.
  const isItemLoaded = index => {
    return !hasNextPage || index < providers.length
  }

  const Item = ({ index, style }) => {
    if (resultCount != null && index >= resultCount) {
      return null
    } else {
      // We suspect that ribbon is sending us less number of records than we
      // expect. This leads to accessing a provider which is out of providers
      // array bound. Adding this check as a temporary fix to return null if
      // index is out of bound
      if (index >= providers.length) {
        return null
      }

      const provider = providers[index]
      return isItemLoaded(index) ? (
        <ProviderListItem
          provider={provider}
          locationType={props.locationType?.label}
          npi={props.npi}
          providerName={props.providerName}
          facilityName={props.facilityName}
          style={style}
          steerageProviderList={props.steerageProviderList}
          steerageID={props.steerageId}
          states={props.states}
          specialtyGroupSearchCategory={props.specialtyGroup}
          isProviderSearch={props.isProviderSearch}
          indexInResults={index}
          searchRequestId={searchRequestId}
        />
      ) : (
        // Display progress loader if item isn't yet loaded
        <Box style={style} display="flex" alignItems="center" justifyContent="center">
          <CircularProgress size={10} />
          <Box ml={2}>Loading more providers...</Box>
        </Box>
      )
    }
  }

  return (
    <Box flex={1} overflow="auto">
      {resultCount != null && resultCount > 0 && (
        <AutoSizer>
          {({ height, width }) => (
            <InfiniteLoader
              isItemLoaded={isItemLoaded}
              itemCount={resultCount}
              loadMoreItems={loadMoreItems}
            >
              {({ onItemsRendered, ref }) => (
                <List
                  ref={ref}
                  onItemsRendered={onItemsRendered}
                  height={height}
                  width={width}
                  itemCount={resultCount}
                  // TODO: Use a component that allows for responsive heights
                  // with infinite scrolling
                  itemSize={isFacilityList ? 275 : 345}
                >
                  {Item}
                </List>
              )}
            </InfiniteLoader>
          )}
        </AutoSizer>
      )}
    </Box>
  )
}

export default ProviderList
