import { useEffect } from 'react'
import type { FC } from 'react'
import { Route, RouteProps, Redirect } from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react'
import { useMutation, usePromise } from '@fireflyhealth/core'
import { batch, useDispatch, useSelector } from 'react-redux'
import { datadogRum } from '@datadog/browser-rum'
import { datadogLogs } from '@datadog/browser-logs'

import Loader from '~/components/Loader'
import { getProviders } from '~/redux/actions/providers'
import { getMe } from '~/redux/actions/me'
import { setStopSync } from '~/redux/actions/sync'
import macros from '~/redux/slices/macros'
import { setSessionToken, setIdToken } from '~/utils/auth'
import { usePubNubUtils } from '~/utils/pubnub'
import { getAssigneeGroups } from '~/redux/actions/assigneeGroup'
import SessionNotifier from '../Notifications/SessionNotifier'

// Override RouteProps to enable render return type incompatibility
interface ProtectedRouteProps extends RouteProps {
  component: NonNullable<RouteProps['component']>
}

export const ProtectedRoute: FC<ProtectedRouteProps> = ({
  component: Component,
  ...routeProps
}) => {
  const { isAuthenticated, isLoading, getAccessTokenSilently, logout, getIdTokenClaims } =
    useAuth0()
  const dispatch = useDispatch()
  const me = useSelector(state => state.me)
  const { authenticate: authenticateChat } = usePubNubUtils()

  // Fetch authentication token and store it before getting "me"
  const { result: getMeSuccessful } = usePromise(async () => {
    try {
      const token = await getAccessTokenSilently()
      const idToken = await (await getIdTokenClaims()).__raw
      setSessionToken(token)
      setIdToken(idToken)
      await dispatch(getMe())
      authenticateChat()
      return true
    } catch (e) {
      console.error('[Session] Failed to fetch authentication token', e)
      logout({ openUrl: false })
      return false
    }
  })

  const fetchDependencies = useMutation(async () => {
    batch(async () => {
      dispatch(getProviders())
      dispatch(getAssigneeGroups())
      dispatch(macros.thunks.getAll({ createdBy: me?.id }))
      dispatch(setStopSync())
    })
  })

  const redirectUri =
    location.pathname === '/' ? '/' : `/?next=${location.pathname}${location.search}`

  useEffect(() => {
    if (getMeSuccessful === true) {
      if (me.id != null) {
        datadogLogs.setUser({ id: me.id.toString() })
        datadogRum.setUser({
          id: me.id.toString(),
          email: me.email,
        })
      }
      fetchDependencies.handler()
    }
  }, [getMeSuccessful])

  if (isLoading || typeof getMeSuccessful === 'undefined') return <Loader />

  return (
    <>
      <SessionNotifier />
      <Route
        {...routeProps}
        render={props =>
          isAuthenticated && getMeSuccessful ? (
            <Component {...props} />
          ) : (
            <Redirect to={encodeURI(redirectUri)} />
          )
        }
      />
    </>
  )
}

export default ProtectedRoute
