import { useState } from 'react'
import { makeStyles } from 'tss-react/mui'
import { useQueryClient } from 'react-query'
import type { FC } from 'react'
import ThumbUpIcon from '@mui/icons-material/ThumbUp'
import ThumbDownIcon from '@mui/icons-material/ThumbDown'
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline'
import { VerifiedUser as VerifiedUserIcon } from '@mui/icons-material'
import { CalendarToday as CalendarTodayIcon } from '@mui/icons-material'
import { NotInterested as NotInterestedIcon } from '@mui/icons-material'
import { format, parse } from 'date-fns'
import {
  RecommendationStatus,
  Steerage,
  SteerageProvider,
  SteerageProviderWaiverLevel,
} from './types'
import {
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Modal,
  Radio,
  Typography,
} from '@mui/material'
import Loader from '~/components/Loader'
import waiver_level from '~/assets/images/waiver_level.svg'
import { PermContactCalendar } from '@mui/icons-material'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import SteerageProviderForm from './SteerageProviderForm'
import { EditProviderAndAvailabilityPayload, logEvent } from '~/utils/events'
import {
  listSteerageKey,
  useEditSteerageProviderWaiverLevel,
  useSelectSteerageProvider,
  useStates,
} from '~/api/SteerageService'
import {
  getNetworkPartnerDisplayName,
  listSteerageSteerageProvidersKey,
  useDeleteProviderFromSteerage,
} from '~/api/SteerageService'
import EditField from '~/components/Generic/EditField'
import { UpdateProviderAvailabilityModal } from '../ProviderSearch/UpdateProviderAvailabilityModal'
import { ProviderDetailWithAdditionalData } from '../ProviderSearch/ProviderListItem'

export interface SteerageProviderListItemProps {
  steerage: Steerage
  provider: SteerageProvider
  setShowEditProviderForm: (providerId: number | null) => void
  showEditProviderForm: number | null
  showAddProviderForm: boolean
  setShowAddProviderForm: (showAddProviderForm: boolean) => void
  validReasonsForException
  memberSelectedProvider: number | null
  setMemberSelectedProvider: (providerId: number | null) => void
}
const editProviderModalStyle = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: '16px',
  width: '490px',
}

// TODO: As appropriate, refactor custom in-line styling into classes here.
const useStyles = makeStyles()(theme => {
  return {
    inlineIcon: {
      marginBottom: '-6px',
    },
  }
})

const SteerageProviderListItem: FC<SteerageProviderListItemProps> = (
  props: SteerageProviderListItemProps
) => {
  const client = useQueryClient()
  const { classes } = useStyles()
  const provider = props?.provider
  const steerage = props?.steerage
  const availability = props?.provider?.liveAvailability
  const showEditProviderForm = props?.showEditProviderForm

  const { mutateAsync: handleDeleteProviderFromSteerage } = useDeleteProviderFromSteerage()
  const { data: states } = useStates()

  //  API for updating the waiver level
  const { isLoading: isEditingWaiverLevel, mutateAsync: handleEditSteerageProviderWaiverLevel } =
    useEditSteerageProviderWaiverLevel()

  const [selectedProviderId, setSelectedProviderId] = useState<number | null>(null)
  const handleMenuClick = event => {
    let providerIdevent = event.currentTarget.getAttribute('data-provider-id') ?? null
    setSelectedProviderId(providerIdevent)
    setAnchorEl(event.currentTarget)
  }
  // for opening and closing menu icon
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClose = () => {
    setAnchorEl(null)
  }

  //API's for selecting/unselecting the steerage provider on behalf of a member
  const { isLoading: isSelectingProvider, mutateAsync: handleSelectingSteerageProvider } =
    useSelectSteerageProvider()

  // For opening and closing Availability modal
  const [openAvailabilityModal, setOpenAvailabilityModal] = useState(false)
  const handleOpenAvailabilityModal = () => {
    setOpenAvailabilityModal(true)
    // Log the opening of the edit Availability modal to full story
    logEvent<EditProviderAndAvailabilityPayload>('EDIT_AVAILABILITY_BUTTON_CLICK', {
      providerNpi: provider?.npi ?? null,
      careOrganisationName: provider?.careOrganizationName ?? null,
      steerageId: steerage?.id,
      isEditedInDirectory: false,
      isProviderSearch: props?.provider?.npi ? true : false,
    })
  }
  const handleCloseAvailabilityModal = () => {
    setOpenAvailabilityModal(false)
    handleClose()
  }

  const isWaiverLevelDisabled = (provider: SteerageProvider) => {
    // Waiver level dropdown should only be enabled in below scenarios
    // 1. When the both provider npi and facility details are present
    // 2. When waiverLevel value is blank
    if (!provider.waiverLevel) return false
    if (provider?.npi && provider?.careOrganizationName) return false
    return true
  }

  const storeWaiverLevel = (value, providerId) => {
    let payload = {
      waiverLevel: value,
      providerId: providerId,
      steerageId: props?.steerage?.id,
    }

    handleEditSteerageProviderWaiverLevel(payload).then(async () => {
      // invalidate queries for refetching the providers
      await client.cancelQueries([listSteerageSteerageProvidersKey, props?.steerage?.id])
      await client.invalidateQueries([listSteerageSteerageProvidersKey, props?.steerage?.id])
    })
  }

  const updateAvailabilityProviderObject = () => {
    let providerData: ProviderDetailWithAdditionalData = {
      id: '',
      middleName: '',
      npi: provider?.npi?.toString() ?? '',
      inNetwork: null,
      uniqueIdentifier: '',
      firstName: provider?.firstName,
      lastName: provider?.lastName,
      name:
        provider?.firstName || provider?.lastName
          ? provider?.firstName + ' ' + provider?.lastName
          : provider?.careOrganizationName,
      specialties: [],
      specialtyGroups: null,
      locationTypes: null,
      locations: [
        {
          uuid: '',
          uniqueIdentifier: '',
          name: provider?.careOrganizationName ? provider?.careOrganizationName : null,
          addressDetails: {
            street: provider?.streetAddress ?? '',
            addressLine1: provider?.addressLine1 ?? '',
            addressLine2: provider?.addressLine2 ?? '',
            city: provider?.city ?? '',
            state: provider?.state?.abbreviation ? provider?.state?.abbreviation : '',
            zip: provider?.zipCode?.toString() ?? '',
          },
          phoneNumbers: null,
          faxNumbers: null,
          availability: provider?.liveAvailability,
          confidence: null,
          networkPartnerAgreementType: null,
          locationInNetwork: null,
          distance: null,
          isVerified: null,
          partnerName: null,
        },
      ],
    }
    return providerData
  }

  const getRecommendationStatusElementForProvider = (provider: SteerageProvider) => {
    let recommendationStatus = provider?.recommendationStatus
      ? RecommendationStatus[provider?.recommendationStatus]
      : null

    return (
      <Box display="inline-block" alignItems="center" style={{ color: '#BDBDBD' }} ml={1}>
        {recommendationStatus != null && recommendationStatus == 'RECOMMENDED' && (
          <Box display="inline-block" font-size="small">
            <ThumbUpIcon fontSize="small" />
          </Box>
        )}
        {recommendationStatus != null && recommendationStatus == 'NOT_RECOMMENDED' && (
          <Box display="inline-block">
            <ThumbDownIcon fontSize="small" />
          </Box>
        )}
        {recommendationStatus != null &&
          (recommendationStatus == 'NO_RATING' || recommendationStatus == 'NETURAL') && (
            <Box display="inline-block">
              <RemoveCircleOutlineIcon fontSize="small" />
            </Box>
          )}
        <Box display="inline-block" ml={1}>
          <Typography variant="subtitle2">
            {recommendationStatus == 'NO_RATING' || recommendationStatus == 'NEUTRAL'
              ? 'Unknown Rec.'
              : recommendationStatus}
          </Typography>
        </Box>
      </Box>
    )
  }
  const getReasonForException = (value): string => {
    if (!props?.validReasonsForException) return ''
    for (const reasonForException of props?.validReasonsForException) {
      if (reasonForException[0] == value) {
        return reasonForException[1]
      }
    }
    return ''
  }
  const showEditSteerageProviderUI = providerId => {
    handleClose()
    // show the edit form on first click of edit button otherwise hide the form and also hide the create form
    showEditProviderForm == providerId
      ? props?.setShowEditProviderForm(null)
      : props?.setShowEditProviderForm(providerId)
    props?.setShowAddProviderForm(false)
  }

  // delete provider from Steerage
  const handleDeleteSteerageProvider = providerId => {
    handleClose()
    // calling the delete api for deleting provider from the steerage
    const payload = {
      steerageId: props?.steerage?.id,
      providerId: providerId,
    }
    handleDeleteProviderFromSteerage(payload).then(async () => {
      // invalidate queries for fetching the steerage
      await client.cancelQueries([listSteerageKey, props?.steerage?.id])
      await client.invalidateQueries([listSteerageKey, props?.steerage?.id])
      await client.cancelQueries([listSteerageSteerageProvidersKey, props?.steerage?.id])
      await client.invalidateQueries([listSteerageSteerageProvidersKey, props?.steerage?.id])
    })
  }

  const hideAddUpdateProviderForm = event => {
    props?.setShowAddProviderForm(false)
    props?.setShowEditProviderForm(null)
  }

  const handleMemberSelectedProviderChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let providerId = Number(event.target.value)
    props?.setMemberSelectedProvider(providerId)

    let payload = {
      steerageId: props?.steerage?.id ?? null,
      providerId: providerId ? providerId : null,
    }

    // when the providerId is not null then we are selecting a provider
    // else unselecting the steerage provider
    if (providerId) {
      handleSelectingSteerageProvider(payload).then(async () => {
        // invalidate queries for refetching the providers
        await client.cancelQueries([listSteerageSteerageProvidersKey, props?.steerage?.id])
        await client.invalidateQueries([listSteerageSteerageProvidersKey, props?.steerage?.id])
      })
    }
  }
  const showEditingMenu = !steerage?.isLocked
  return (
    <>
      <Box key={provider.id}>
        <Modal
          open={openAvailabilityModal}
          onClose={handleCloseAvailabilityModal}
          disableEscapeKeyDown
          disableScrollLock
          hideBackdrop
        >
          <Box sx={editProviderModalStyle}>
            <UpdateProviderAvailabilityModal
              provider={updateAvailabilityProviderObject()}
              handleCloseAvailabilityModal={() => handleCloseAvailabilityModal()}
              isProviderSearch={provider.npi ? true : false}
              steerageId={steerage?.id}
              isEditedInDirectory={false}
              specialtyGroups={null}
            />
          </Box>
        </Modal>

        {isEditingWaiverLevel ? <Loader /> : null}
        <br />
        {/* Hide the readonly view of provider when a provider selected for edit*/}
        {showEditProviderForm != provider?.id && (
          <Box>
            <Grid container>
              {/* Only show the radio buttons when it is locked and length of provider is greater then one */}
              {steerage?.isLocked &&
                steerage?.steerageProviders &&
                steerage?.steerageProviders?.length > 1 && (
                  <Grid item xs={1}>
                    <Box mt={-1}>
                      <Radio
                        checked={props?.memberSelectedProvider == provider?.id}
                        value={provider?.id}
                        name="radio-buttons"
                        onChange={handleMemberSelectedProviderChange}
                      />
                    </Box>
                  </Grid>
                )}
              <Grid item xs={steerage?.isLocked ? 11 : 12}>
                {steerage?.isLocked && steerage?.hasActiveWaiver && (
                  <Typography style={{ marginBottom: '10px' }}>
                    {provider.waiverLevel && (
                      <Box display="inline-block">
                        <img
                          src={waiver_level}
                          alt="Coverage"
                          style={{ width: 'auto', height: '1.3rem', marginRight: '1rem' }}
                        />
                        {SteerageProviderWaiverLevel[provider.waiverLevel]}
                      </Box>
                    )}
                  </Typography>
                )}

                <Box>
                  {!steerage?.isLocked && steerage?.hasActiveWaiver && (
                    <Grid container>
                      <Grid item xs={6}>
                        <Box style={{ display: 'flex' }}>
                          <Box style={{ lineHeight: 2.6 }}>Care Pass for:</Box>{' '}
                          <EditField
                            select
                            InputLabelProps={{ shrink: true }}
                            SelectProps={{ native: true }}
                            onChange={e => storeWaiverLevel(e.target.value, provider.id)}
                            value={provider.waiverLevel}
                            name="waiverLevel"
                            variant="outlined"
                            size="small"
                            style={{ marginBottom: '15px', marginLeft: '10px' }}
                            disabled={isWaiverLevelDisabled(provider)}
                          >
                            <option value="">None</option>
                            {Object.keys(SteerageProviderWaiverLevel)?.map(key => (
                              <option key={SteerageProviderWaiverLevel[key]} value={key}>
                                {SteerageProviderWaiverLevel[key]}
                              </option>
                            ))}
                          </EditField>
                        </Box>
                      </Grid>
                    </Grid>
                  )}
                  <Grid container>
                    <Grid item xs={6}>
                      <Box key={provider.id} color="text.primary">
                        <Box
                          display="flex"
                          alignItems="baseline"
                          justifyContent="space-between"
                          mr={1}
                        >
                          <Typography style={{ display: 'flex' }}>
                            <strong>
                              {provider?.firstName} {provider?.lastName}
                              {/* When a practitioner exists : surface the recommendation status beside the practitioner name */}
                              {(provider?.firstName != null || provider?.lastName != null) &&
                                getRecommendationStatusElementForProvider(provider)}
                            </strong>
                          </Typography>
                        </Box>
                        <Box alignItems="baseline" justifyContent="space-between" mr={1}>
                          <Grid container>
                            <Grid item>
                              <Typography>{provider?.careOrganizationName}</Typography>
                              {/* When a care-organization exists without a practitioner : surface the recommendation status beside the care-organization name */}
                              {provider?.careOrganizationName != null &&
                                provider?.firstName == null &&
                                provider?.lastName == null &&
                                getRecommendationStatusElementForProvider(provider)}
                            </Grid>
                          </Grid>
                        </Box>

                        <Box key={provider.id} color="text.secondary">
                          {provider?.npi && (
                            <Typography>
                              {'NPI '}
                              {provider?.npi}
                            </Typography>
                          )}
                        </Box>

                        {provider?.networkPartnerAgreementType && (
                          <Grid container alignItems="flex-start">
                            <Box mt={1}>
                              <PermContactCalendar fontSize="small" />
                            </Box>
                            <Box mt={1} ml={1}>
                              <Typography variant="subtitle2">
                                {getNetworkPartnerDisplayName(
                                  provider?.networkPartnerAgreementType,
                                  provider?.partnership?.partnershipType
                                )}
                              </Typography>
                            </Box>
                          </Grid>
                        )}
                      </Box>
                    </Grid>
                    <Grid item xs={5}>
                      {provider?.hasVerifiedContactDetails && (
                        <Box mb={1}>
                          <Typography variant="subtitle2">
                            <VerifiedUserIcon fontSize="small" className={classes.inlineIcon} />{' '}
                            Phone/Fax Verified
                          </Typography>
                        </Box>
                      )}
                      <Box color="text.primary" key={provider.id}>
                        {provider?.addressLine1 && (
                          <Typography>{provider?.addressLine1}</Typography>
                        )}
                        {provider?.addressLine2 && (
                          <Typography>{provider?.addressLine2}</Typography>
                        )}
                        <Typography>
                          {provider?.city}
                          {provider?.city && provider?.state && ', '}{' '}
                          {provider?.state?.abbreviation} {provider.zipCode}
                        </Typography>
                        {provider?.phone && <Typography>Ph: {provider?.phone}</Typography>}
                        {provider?.fax && <Typography>Fax: {provider?.fax}</Typography>}
                        {provider?.reasonForException && (
                          <Typography>
                            {getReasonForException(provider?.reasonForException)}
                          </Typography>
                        )}
                        {/* Three cases
                              1. If availability present and doesProviderExist is true then show the booking out
                              2. doesProviderExist is true then show Availability as not not accepting
                              3. else show the Unknown
                        */}
                        {availability?.daysTillNextAvailability &&
                        availability?.doesProviderExist != false ? (
                          <Box mt={1}>
                            <Typography component="legend">
                              <CalendarTodayIcon className={classes.inlineIcon} />{' '}
                              {'Booking out ' + availability?.daysTillNextAvailability + ' days'}
                            </Typography>
                            <Typography component="legend">
                              {availability?.addedOn
                                ? '(verified ' +
                                  format(parse(availability?.addedOn), 'MMM D, YYYY') +
                                  ')'
                                : null}
                            </Typography>
                          </Box>
                        ) : availability?.doesProviderExist == false ? (
                          <Box mt={1}>
                            <Typography component="legend">
                              <NotInterestedIcon className={classes.inlineIcon} /> Not accepting
                              patients
                            </Typography>
                            {availability?.addedOn && (
                              <Typography>
                                (verified {format(parse(availability?.addedOn), 'MMM D, YYYY')})
                              </Typography>
                            )}
                          </Box>
                        ) : (
                          <Box mt={1}>
                            <Button component="span" onClick={handleOpenAvailabilityModal}>
                              + Add availability
                            </Button>
                          </Box>
                        )}
                      </Box>
                    </Grid>
                    <Grid item xs={1}>
                      {showEditingMenu && (
                        <IconButton
                          style={{ padding: '0px', marginLeft: '0%' }}
                          data-provider-id={provider.id}
                          onClick={handleMenuClick}
                        >
                          <MoreVertIcon />
                        </IconButton>
                      )}
                      {showEditingMenu && (
                        <Menu
                          elevation={1}
                          anchorEl={anchorEl}
                          open={open}
                          onClose={handleClose}
                          MenuListProps={{
                            disablePadding: true,
                          }}
                        >
                          {!steerage?.isLocked && (
                            <MenuItem
                              onClick={() => showEditSteerageProviderUI(selectedProviderId)}
                            >
                              Edit provider
                            </MenuItem>
                          )}
                          <MenuItem onClick={() => handleOpenAvailabilityModal()}>
                            Edit availability
                          </MenuItem>
                          {!steerage?.isLocked && (
                            <MenuItem
                              onClick={() => handleDeleteSteerageProvider(selectedProviderId)}
                            >
                              Remove
                            </MenuItem>
                          )}
                        </Menu>
                      )}
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
            <br />
            <Divider />
          </Box>
        )}

        {!steerage?.isLocked && showEditProviderForm == provider?.id && (
          <SteerageProviderForm
            steerage={props?.steerage}
            steerageProviderCreateForm={false}
            steerageProvider={provider}
            onCancel={hideAddUpdateProviderForm}
            reasonForExceptionList={props?.validReasonsForException}
            states={states ?? []}
          />
        )}
      </Box>
      {isSelectingProvider ? <Loader /> : null}
    </>
  )
}

export default SteerageProviderListItem
