import { Question } from '@fireflyhealth/core'
import { Box } from '@mui/material'
import { makeStyles } from 'tss-react/mui'
import { camelize } from 'humps'
import type { FC } from 'react'

interface FormAnswerDisplayProps {
  question: Question
  answer: any
}

const FormAnswerDisplay: FC<FormAnswerDisplayProps> = props => {
  const { question, answer } = props
  const { classes } = useStyles()

  if (question.type === 'info') {
    if ('content' in question.typeOptions) {
      return (
        <p
          className={typeof answer === 'undefined' ? classes.greyText : ''}
          style={{ whiteSpace: 'pre-line' }}
        >
          {question.typeOptions.content}
        </p>
      )
    }
    return null
  }

  // question was not shown to patient
  if (typeof answer === 'undefined') {
    return <p className={classes.greyText}>-</p>
  }

  // TODO: Sometimes answer is `{}`; remove this condition when this is fixed.
  if (answer === null || JSON.stringify(answer) === '{}') {
    return <p>-</p>
  }

  if (question.type === 'multiple_choice') {
    const { otherField, choices } = question.typeOptions
    const otherValue = otherField && answer[camelize(otherField)]
    return (
      // Values are camelized from their original json representation, doing a quick camelizing here until
      // we can revisit that in the forms API
      <>
        <p>
          {choices
            .filter(c => answer[camelize(c.value.toString())])
            .map(c => c.label)
            .join(', ')}
        </p>
        {otherValue && (
          <p>
            <em>Other:</em>
            <br />
            {otherValue}
          </p>
        )}
      </>
    )
  }

  if (question.type === 'choice') {
    // Values are camelized from their original json representation, doing a quick camelizing here until
    // we can revisit that in the forms API
    const choice =
      question.typeOptions.choices.find(c => camelize(c.value.toString()) === answer) ||
      question.typeOptions.choices.find(c => c.value.toString() === answer)
    return choice ? (
      <p>
        {choice.label} <i>({choice.value})</i>
      </p>
    ) : null
  }

  if (question.type === 'allergy') {
    return (
      <Box>
        <p>Medications</p>
        {answer.medication ? (
          answer.medication.map(medication => (
            <p key={medication.name}>
              {medication.name} - {medication.reactionList.join(', ')}
              {medication.otherReaction ? `(${medication.otherReaction})` : ''}
            </p>
          ))
        ) : (
          <p>None</p>
        )}
        <b>Allergy</b>
        {answer.foodSubstance ? (
          answer.foodSubstance.map(allergy => (
            <p key={allergy.name}>
              {allergy.name} - {allergy.reactionList.join(', ')}
              {allergy.otherReaction ? ` (${allergy.otherReaction})` : ''}
            </p>
          ))
        ) : (
          <p>None</p>
        )}
      </Box>
    )
  }

  if (
    question.type === 'text' ||
    question.type === 'long_text' ||
    question.type === 'rating' ||
    question.type === 'calculation' ||
    question.type === 'date'
  ) {
    return <p>{answer}</p>
  }

  if (question.type === 'address') {
    return (
      <p>
        {answer.streetAddress} {answer.streetAddress2} {answer.city} {answer.state},{' '}
        {answer.zipcode}
      </p>
    )
  }

  if (question.type === 'name') {
    return (
      <p>
        {answer.first != null && answer.last != null && (
          <p>
            {answer.first} {answer.last}
          </p>
        )}
        {answer.form != null && <p>Forms: {answer.form}</p>}
        {answer.medicationName != null && <p>Medication Name: {answer.medicationName}</p>}
        {answer.dose != null && <p>Dose: {answer.dose}</p>}
      </p>
    )
  }
  if (question.type === 'facility_address') {
    return (
      <p>
        <p>Facility Name: {answer.name}</p>
        {answer.city != null && <p>City: {answer.city}</p>}
        {answer.state != null && <p>State:{answer.state}</p>}
      </p>
    )
  }
  if (question.type === 'providerInfo') {
    return (
      <p>
        <p>Provider Name: {answer.providerName}</p>
        {answer.practiceName != null && <p>Practice Name: {answer.practiceName}</p>}
        <p>Specility: {answer.specility}</p>
        {answer.city != null && <p>City: {answer.city}</p>}
      </p>
    )
  }

  return null
}

const useStyles = makeStyles()(theme => {
  return {
    greyText: {
      color: theme.palette.grey[500],
    },
  }
})

export default FormAnswerDisplay
