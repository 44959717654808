import { ArrowDownward, ReportProblemOutlined } from '@mui/icons-material'
import { Box, Button, Typography } from '@mui/material'
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { PatientUser } from '@fireflyhealth/core'
import { throttle, sortBy } from 'lodash'
import { find, sortedIndexBy } from 'lodash/fp'
import { getDefaultPatientThread, getPubnubChannelForThread } from '~/utils/chat'
import ChatInput from './ChatInput'
import ChatMarkRead from './ChatMarkRead'
import ChatMessageAttachment from './ChatMessageAttachment'
import ChatMessageDocument from './ChatMessageDocument'
import ChatMessageEvent from './ChatMessageEvent'
import ChatReadReceipt from './ChatReadReceipt'
import { ChatSearchResults } from './ChatSearchResults'
import ChatTypingIndicator from './ChatTypingIndicator'
import ChatWelcomeTips from './ChatWelcomeTips'
import { ICase, ICaseRelation } from '~/models/Case'
import { IPhoneCall } from '~/models/PhoneCall'
import JoinChatBanner from './JoinChatBanner'
import Loader from '~/components/Loader'
import Moment from 'moment'
import { parse } from 'date-fns'
import { providerCanProvideClinicalCareToPatient } from '~/utils/index'
import qs from 'query-string'
import { usePhoneCallDispositions } from '~/api/PhoneCallService'
import { v4 as uuidv4 } from 'uuid'
import { usePubNubUtils } from '~/utils/pubnub'
import { SelectedChatItem, SelectedChatMessage } from '~/models/Communication'
import { useDispatch, useSelector } from 'react-redux'
import chatSlice from '~/redux/slices/chat'
import { queueNotification } from '~/redux/actions/notifications'
import ChatMessage from './ChatMessage'
import PhoneCallMessage from '../PhoneCall/PhoneCallMessage'
import { ChatMessage as CoreChatMessage } from '@fireflyhealth/core'
import { IMessageTag } from '~/api/ChatService'
import { SelectedTodoContext } from '~/utils/useManageSelectedTodo'
import { logger } from '~/utils/logger'

export interface IChatMessage extends CoreChatMessage {
  // kind is used for discriminated union checking. Leaving as undefined for backwards
  // compatibility.
  kind?: 'phonecall'
  attachment: number
  fileAttachment: { id: number } | number // This is a very confusing type...TODO: fix
  timetoken: number
  sender: number
  event?: 'arrival' | 'assignment'
  cases: ICase[]
  messageTags?: IMessageTag[]
}

export interface IPhoneCallMessage extends IPhoneCall {
  kind: 'phonecall'
  id: number
  sentAt: string
}

interface IChatMessagesProps {
  patientThread: any
  patient: PatientUser
  messages: IChatMessage[]
  selectedItem: SelectedChatItem | null
  linkedMessages: ICaseRelation[]
  isSelectingMultiple: boolean
  setIsSelectingMultiple: (arg0: boolean) => void
  toggleMultiSelectMessage: (message: SelectedChatMessage) => void
  multiSelectedMessages: SelectedChatMessage[]
}

export type Message = IChatMessage | IPhoneCallMessage

const ChatMessages = (props: IChatMessagesProps) => {
  const { patientThread, messages, linkedMessages, selectedItem, patient } = props

  const me = useSelector(state => state.me)
  const providers = useSelector(state => state.providers)

  // Places welcome tips after the first automated messages from Lucian
  const chatTipIndex = useMemo(() => {
    // Look at the thread cursor to make sure that the entire thread is loaded
    if (patientThread.messageCursor !== null) return null
    const firstMessageSender = messages[0]?.sender
    // Only render if first messages are automated
    if (firstMessageSender !== Number(process.env.REACT_APP_FIREFLY_BOT_ID)) return null
    const nextMessageSenderIndex = messages.findIndex(m => m.sender !== firstMessageSender)
    // If member hasn't sent a message, show at the end
    if (nextMessageSenderIndex === -1) return messages.length
    return nextMessageSenderIndex
  }, [patientThread.messageCursor, messages])

  // Sort all messages (chat and phone calls) by their sentAt timestamp. Parse the timestamp first;
  // even though they should be in RFC 3339 format, different time zone offsets make the
  // lexicographic order not necessarily match the chronological order.
  const allMessages: Message[] = sortBy([...messages], m => parse(m.sentAt))

  const { result: dispositions } = usePhoneCallDispositions()
  const patientMembership = find(
    membership => membership.user === patient.id,
    patientThread.members
  )

  let patientLastReadMessageIndex: number | null = null
  let patientLastRead: string | null = null
  if (patientMembership) {
    patientLastRead = patientMembership.lastReadAt as string // TODO: fix typing on patientThread.
    // Display a read receipt after the last message sent before patient read timestamp
    patientLastReadMessageIndex =
      (sortedIndexBy<Pick<IChatMessage | IPhoneCallMessage, 'sentAt'>>(
        m => m.sentAt,
        {
          sentAt: patientLastRead,
        },
        allMessages
      ) as number) - 1
  }

  let messageComponents: JSX.Element[] = []
  if (patientLastReadMessageIndex === -1) {
    messageComponents.push(<ChatReadReceipt key="chat-read-receipt" readAt={patientLastRead} />)
  }

  messageComponents = messageComponents.concat(
    allMessages.map((message, i) => {
      // A message should be hiighlighted if it's currently selected
      // for example as the current search result
      // Or if it's one of the current "linked message"
      // for example when we are viewing the phone calls linked to a Case
      let higlightChatMessageContent = false
      const highlightPhoneCall =
        linkedMessages && linkedMessages.length > 0
          ? linkedMessages.find(cr => cr.objectId === message.id && cr.contentType === 'phonecall')
          : undefined
      const highlightChat =
        linkedMessages && linkedMessages.length > 0
          ? linkedMessages.find(
              cr => cr.objectId === message.id && cr.contentType === 'chatmessagev2'
            )
          : undefined

      const isLastMessage = allMessages.length - 1 === i

      if (message.kind === 'phonecall') {
        higlightChatMessageContent = highlightPhoneCall !== undefined
        return (
          <PhoneCallMessage
            isLastMessage={isLastMessage}
            key={`phonecall-${message.id}`}
            phoneCall={message}
            patient={patient}
            phoneCallCases={message.cases || []}
            higlightChatMessageContent={higlightChatMessageContent}
            patientReadAt={patientLastReadMessageIndex === i ? patientLastRead : null}
            providers={providers}
            dispositions={dispositions}
            isSelectingMultiple={props.isSelectingMultiple}
          />
        )
      }

      const isEvent = !!message.event
      if (isEvent) {
        return (
          <ChatMessageEvent
            key={message.uid || `message_{i}`}
            message={message}
            patient={patient}
          />
        )
      }

      const hasAttachment = !!message.attachment
      const hasDocument = !!message.fileAttachment
      // If returned from the api, fileAttachment is an object
      let documentId
      if (hasDocument && isNaN(message.fileAttachment as number))
        documentId = (message.fileAttachment as { id: number }).id

      const patientId = typeof message.sender !== 'undefined' ? message.sender : null
      const mine = me.id === patientId
      // This looks like it should be a bug (primary key collision), but works because both ProviderUser and
      // PatientUser are Users, and so they share the same primary key space in the same table.
      // parseInt also works with numbers and null in addition to strings, so leaving it in with the type assertion for
      // now.
      // TODO: Fix types?
      const user =
        providers.find(user => parseInt(user.id as any) === parseInt(patientId as any)) || patient

      const highlightChatMessage = !!(selectedItem?.id && selectedItem.id === message.id)
      higlightChatMessageContent = highlightChat !== undefined || highlightChatMessage

      return (
        <ChatMessage
          key={message.uid || `message_{i}`}
          patient={patient}
          mine={mine}
          user={user}
          message={message}
          highlightChatMessage={highlightChatMessage}
          higlightChatMessageContent={higlightChatMessageContent}
          messageCases={message.cases || []}
          patientReadAt={patientLastReadMessageIndex === i ? patientLastRead : null}
          isLastMessage={isLastMessage}
          isSelectingMultiple={props.isSelectingMultiple}
          setIsSelectingMultiple={props.setIsSelectingMultiple}
          multiSelectedMessages={props.multiSelectedMessages}
          toggleMultiSelectMessage={props.toggleMultiSelectMessage}
        >
          {hasDocument && (
            <ChatMessageDocument user={user} id={documentId ?? message.fileAttachment} />
          )}
          {hasAttachment && (
            <ChatMessageAttachment
              userId={user.id}
              attachment={message.attachment}
              mine={mine}
              timetoken={message.timetoken}
            />
          )}
        </ChatMessage>
      )
    })
  )

  if (chatTipIndex !== null) {
    messageComponents.splice(chatTipIndex, 0, <ChatWelcomeTips />)
  }
  return <>{messageComponents}</>
}

export interface IChatProps {
  lastSearchQuery: any
  patient: any
  isSearching: boolean
  linkedMessages: ICaseRelation[]
  selectedMessage: SelectedChatItem | null
}

const useScrollManager = (props: {
  onScrollCallback?: ({
    isScrolledToBottom,
    currentScrollTop,
  }: {
    isScrolledToBottom: boolean
    currentScrollTop: number
    amountScrolledUp: number
  }) => void
}) => {
  // Manages scrolling and testing current scroll position within a container
  // https://react.dev/learn/manipulating-the-dom-with-refs#example-scrolling-to-an-element
  //
  // Expect Elements, not RefObjects
  // To test "readiness" for scrolling, we need a re-render when ref is first set
  //
  // A ref to the component in which the user can scroll
  const [scrollContainerRef, setScrollContainerRef] = useState<HTMLDivElement | null>(null)
  // An invisible anchor included at the bottom of the scrollable container
  const [scrollBottomAnchorRef, setScrollBottomAnchorRef] = useState<HTMLDivElement | null>(null)
  // An invisible anchor included at the top of the scrollable container
  const [scrollTopAnchorRef, setScrollTopAnchorRef] = useState<HTMLDivElement | null>(null)
  const [isScrolledToBottom, setIsScrolledToBottom] = useState(false)
  // A measure of how far up the container is scrolled as a multiple of the container height
  const [amountScrolledUp, setAmountScrolledUp] = useState(1)

  const scrollToTop = () => {
    if (scrollTopAnchorRef) {
      // https://stackoverflow.com/a/58714347
      scrollTopAnchorRef.scrollIntoView({ block: 'center' })
    }
  }

  const scrollToBottom = () => {
    if (scrollBottomAnchorRef) {
      scrollBottomAnchorRef.scrollIntoView({ block: 'center' })
    }
  }

  const onContainerScroll = _event => {
    // When the user scrolls within the container...
    //
    // Provide this as the "onScroll" prop to the passed container element
    if (!scrollContainerRef) return
    const currentScrollTop = scrollContainerRef.scrollTop
    // https://stackoverflow.com/a/49573628
    const isScrolledToBottom =
      Math.abs(
        scrollContainerRef.scrollHeight -
          (scrollContainerRef.scrollTop + scrollContainerRef.clientHeight)
      ) <= 1
    setIsScrolledToBottom(isScrolledToBottom)
    setAmountScrolledUp(
      Math.abs(scrollContainerRef.scrollHeight - scrollContainerRef.scrollTop) /
        scrollContainerRef.clientHeight
    )
    if (props.onScrollCallback) {
      props.onScrollCallback({ isScrolledToBottom, currentScrollTop, amountScrolledUp })
    }
  }

  return {
    scrollToTop,
    scrollToBottom,
    isScrolledToBottom,
    onContainerScroll,
    amountScrolledUp,
    scrollContainerRef,
    scrollTopAnchorRef,
    scrollBottomAnchorRef,
    setScrollContainerRef,
    setScrollBottomAnchorRef,
    setScrollTopAnchorRef,
  }
}

const Chat = (props: IChatProps) => {
  const [loaded, setLoaded] = useState(false)
  const [fetching, setFetching] = useState(false)
  // The selected item in the thread (message, phone call, etc.)
  // from text search, viewing case-linked messages, etc.
  const [selectedItem, setSelectedItem] = useState<SelectedChatItem | null>(null)
  // Keep track of automatic scrolling to a message
  const [messageToScrollTo, setMessageToScrollTo] = useState<SelectedChatItem | null>(null)
  // Track an optimistic "send" action by a clinician in order to update the UI
  // without re-fetching the whole thread
  const [messageWasSentByCurrentUser, setMessageWasSentByCurrentUser] = useState(false)
  const idleTimeout = useRef<ReturnType<typeof setTimeout> | null>(null)
  const [isSelectingMultiple, setIsSelectingMultiple] = useState(false)
  const [multiSelectedMessages, setMultiSelectedMessages] = useState<SelectedChatMessage[]>([])
  const pubNubUtils = usePubNubUtils()
  const searchResults = useSelector(state => state.chat.searchResults)
  const searchResultIndex = useSelector(state => state.chat.searchResultIndex)
  const patientThread = useSelector(state => state.chat.patientThreads?.[props.patient.id])
  const historicalMessages = useSelector(state => state.chat.historicalMessages)
  const noMorePreviousMessages = useSelector(state => state.chat.noMorePreviousMessages)
  const noMoreNextMessages = useSelector(state => state.chat.noMoreNextMessages)
  const me = useSelector(state => state.me)
  const providers = useSelector(state => state.providers)
  const [lastMessage] = (patientThread?.messages || []).slice(-1)
  const pubnub = useSelector(state => state.chat.pubnub)
  const dispatch = useDispatch()
  // If we're viewing historical messages but we haven't loaded into the future far enough
  // to catch up with the recent messages in the thread object
  // then there is a gap between the 2 sets of messages
  const gapExistsBetweenHistoricalMessagesAndThread = !!(historicalMessages && !noMoreNextMessages)

  const searchResultKey =
    patientThread && props.lastSearchQuery ? `${patientThread.uid},${props.lastSearchQuery}` : null
  const searchResultHits = searchResultKey != null ? searchResults[searchResultKey]?.hits : null

  /**
   * Activated when user scrolls to the very top of message history
   */
  const fetchPreviousMessages = async () => {
    const { patient } = props
    if (!fetching && patientThread) {
      setFetching(true)
      if (historicalMessages && !noMorePreviousMessages) {
        // Scroll further back into history
        const msgId = historicalMessages[0].id
        await dispatch(chatSlice.thunks.loadThreadHistoryAt(patientThread.uid, msgId, 10, 0, null))
        setMessageToScrollTo({ id: msgId, contentType: 'chatmessagev2' })
      } else if (patientThread.messageCursor) {
        await dispatch(
          chatSlice.thunks.loadThreadMoreHistory(patient.id, patientThread.messageCursor)
        )
      }
      setFetching(false)
    }
  }

  /**
   * Activated when user scrolls to the very bottom of search message history
   */
  const fetchNextMessages = async () => {
    if (!fetching && patientThread) {
      setFetching(true)
      if (historicalMessages && !noMoreNextMessages) {
        const msgId = historicalMessages[historicalMessages.length - 1].id
        await dispatch(chatSlice.thunks.loadThreadHistoryAt(patientThread.uid, msgId, 0, 10, null))
      }
      setFetching(false)
    }
  }

  const onScrollCallback = ({ isScrolledToBottom, currentScrollTop }) => {
    if (currentScrollTop === 0) {
      // Fetch previous messages when the user "pulls to refresh"
      // unless the scrolling was due to an automatic scroll
      // e.g. navigating between a Case's linked messages
      if (messageToScrollTo) {
        setMessageToScrollTo(null)
        return
      }
      fetchPreviousMessages()
    } else if (isScrolledToBottom) {
      fetchNextMessages()
    }
  }

  const {
    scrollToBottom,
    onContainerScroll,
    scrollContainerRef,
    setScrollContainerRef,
    setScrollBottomAnchorRef,
    setScrollTopAnchorRef,
  } = useScrollManager({
    onScrollCallback,
  })

  const defaultThread = getDefaultPatientThread(props.patient.id)
  const currentMessages = historicalMessages || patientThread?.messages

  useEffect(() => {
    if (!scrollContainerRef || !messageToScrollTo) return
    const messageElement = scrollContainerRef.querySelector(
      `[data-id="${messageToScrollTo.contentType === 'phonecall' ? 'phonecall-' : ''}${
        messageToScrollTo.id
      }`
    )
    if (messageElement) {
      messageElement.scrollIntoView({ block: 'center', behavior: 'smooth' })
    }
  }, [scrollContainerRef, messageToScrollTo, currentMessages])

  useEffect(() => {
    ;(async () => {
      await dispatch(chatSlice.thunks.loadThreadHistory(defaultThread, props.patient.person.id))
      setLoaded(true)
    })()
  }, [])

  useEffect(() => {
    if (loaded) {
      scrollToBottom()
    }
  }, [loaded])

  const { selectPayload } = useContext(SelectedTodoContext)

  useEffect(() => {
    // When the selected item is updated, make sure we load the thread back to that point
    // and then scroll to it
    if (!selectedItem) {
      // When we clear a selection, get back to the default thread
      handleJumpToRecent()
      return
    }
    ;(async () => {
      // Skip scrolling if we're just trying to create a forward task from a message we're already looking at
      if (selectPayload?.type == 'createForwardTask') return
      if (selectedItem && selectedItem.contentType === 'chatmessagev2') {
        // TODO: optimize by making sure we don't load history if the history
        // to this point is already loaded
        await dispatch(
          chatSlice.thunks.loadThreadHistoryAt(defaultThread, selectedItem.id, 10, 10, null)
        )
        setMessageToScrollTo(selectedItem)
      } else if (selectedItem && selectedItem.contentType === 'phonecall') {
        setMessageToScrollTo(selectedItem)
      }
      return
    })()
  }, [selectedItem])

  useEffect(() => {
    // Find which message should be scrolled to / highlighted
    let messageIdFromLocation = qs.parse(location.search).scrollToMessageId as string
    if (messageIdFromLocation) {
      // When passed via URL params, this always references a chat message
      setSelectedItem({ id: parseInt(messageIdFromLocation), contentType: 'chatmessagev2' })
    } else {
      if (props.selectedMessage) {
        setSelectedItem(props.selectedMessage)
      }
    }
  }, [location.search, props.selectedMessage])

  useEffect(() => {
    // When search is closed, de-select all
    if (!searchResultIndex) {
      setSelectedItem(null)
    }
  }, [searchResultIndex])

  useEffect(() => {
    // When linked messages are cleared, de-select all
    if (!props.linkedMessages?.length) {
      setSelectedItem(null)
    }
  }, [props.linkedMessages])

  useEffect(() => {
    // Scrolling between search results is represented by updating searchResultIndex
    if (searchResultHits && searchResultIndex) {
      const msgId = searchResultHits[searchResultIndex].id
      setSelectedItem({ id: parseInt(msgId), contentType: 'chatmessagev2' })
    }
  }, [props.lastSearchQuery, searchResultIndex])

  const handleJumpToRecent = async () => {
    await dispatch(chatSlice.actions.clearHistoricalMessages())
    scrollToBottom()
  }

  // Use 'attachment' for images, 'file_attachment' for documents
  const sendChat = async (text, attachment = null, file_attachment = null) => {
    const { patient } = props
    if (!pubnub) {
      logger.error('In sendChat, no pubnub client available')
      return
    }
    const publishResponse = await pubnub.publish({
      message: {
        attachment,
        file_attachment,
        text: text.trim(),
        uid: uuidv4(),
        sender: me.id,
        patient_id: patient.id,
        tenant: me.providerFields?.tenant,
      },
      channel: getPubnubChannelForThread(getDefaultPatientThread(patient.id)),
    })
    logger.info('In sendChat, received publishResponse', publishResponse)
    // Optimistically assert that the clinician has sent a message
    setMessageWasSentByCurrentUser(true)
  }

  useEffect(() => {
    // New real-time messages (which do not have an ID)
    // will be appended to both the thread object and historicalMessages
    // Scroll them into view and exist out of any historical view
    if (lastMessage && !lastMessage.id) {
      handleJumpToRecent()
    }
  }, [patientThread?.messages?.length, lastMessage])

  useEffect(() => {
    if (!isSelectingMultiple) setMultiSelectedMessages([])
  }, [isSelectingMultiple])

  const notifyTyping = () => {
    pubNubUtils.updatePresence({
      typing: true,
      viewing: true,
      patientId: props.patient.id,
    })
  }

  const throttledNotifyTyping = useCallback(
    throttle(notifyTyping, 3000, { leading: true, trailing: false }),
    []
  )

  const handleChatType = e => {
    const SET_IDLE_TIMEOUT = 1250
    throttledNotifyTyping()

    if (idleTimeout.current) {
      clearTimeout(idleTimeout.current)
    }

    idleTimeout.current = setTimeout(() => {
      pubNubUtils.updatePresence({
        typing: false,
        viewing: true,
        patientId: props.patient.id,
      })
    }, SET_IDLE_TIMEOUT)
  }

  const handleResultClick = async (msgId: string) => {
    if (!patientThread || !searchResultHits) return
    const searchResultIndex = searchResultHits.findIndex(result => result.id === msgId)
    const selectedResultMessage: SelectedChatItem = {
      id: parseInt(msgId),
      contentType: 'chatmessagev2',
    }
    if (searchResultIndex === -1) {
      dispatch(
        queueNotification({
          variant: 'error',
          message: 'Search Result was not found',
        })
      )
    } else {
      await dispatch(
        chatSlice.thunks.loadThreadHistoryAt(patientThread.uid, msgId, 10, 10, searchResultIndex)
      )
      setSelectedItem(selectedResultMessage)
    }
  }

  let formattedShowingDate: Moment.Moment | null = null
  if (patientThread && searchResultHits && historicalMessages && selectedItem) {
    const showingDate = searchResultHits[searchResultIndex!]?.source?.sentAt
    formattedShowingDate = showingDate ? Moment(new Date(showingDate)) : null
  }

  const lastMessageDate: string = formattedShowingDate ? formattedShowingDate.format('MMM.DD') : ''

  // If there is already an "arrival" style event payload from the clinician
  // in the thread, hide the announcement banner to prevent duplicate posts
  const arrivalMessageInThread = patientThread
    ? !!patientThread.messages.find(message => {
        return 'event' in message && message.event === 'arrival' && message.sender === me.id
      })
    : false

  const myProvider = (providers || []).find(provider => provider.id === me.id)
  const showIntroductionReminder =
    !patientThread?.clinicianHasChattedWithPatient &&
    myProvider &&
    (myProvider.groups || []).find(group => group.name === 'VisibleToPatient') &&
    !messageWasSentByCurrentUser

  const toggleMultiSelectMessage = (message: SelectedChatMessage) => {
    if (multiSelectedMessages.map(el => el.uid).includes(message.uid)) {
      setMultiSelectedMessages(current => current.filter(item => item.uid !== message.uid))
    } else {
      setMultiSelectedMessages(current => current.concat([message]))
    }
  }

  return (
    <Box
      sx={{
        position: 'absolute',
        height: '100%',
        width: '100%',
        top: 0,
        left: 0,
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Box
        sx={{
          flex: 1,
          overflow: 'auto',
          paddingLeft: 1,
          paddingRight: 1,
          paddingBottom: 1,
        }}
        ref={setScrollContainerRef}
        onScroll={onContainerScroll}
      >
        <div ref={setScrollTopAnchorRef} />

        {fetching ? (
          <Box
            sx={theme => ({
              position: 'relative',
              height: '7.5rem',
              color: theme.palette.primary.light,
            })}
          >
            <Loader inline />
          </Box>
        ) : null}

        {loaded && patientThread?.messages ? (
          <Box sx={{ overflow: 'hidden' }}>
            {props.isSearching && searchResultIndex == null && props.lastSearchQuery ? (
              <Box
                sx={theme => ({
                  position: 'absolute',
                  height: '100%',
                  width: '100%',
                  top: 0,
                  left: 0,
                  display: 'flex',
                  flexDirection: 'column',
                  backgroundColor: theme.palette.background.default,
                  zIndex: 3,
                })}
              >
                <ChatSearchResults
                  threadUid={patientThread.uid}
                  patientId={props.patient.id}
                  personId={props.patient.person.id}
                  searchQuery={props.lastSearchQuery}
                  handleResultClick={handleResultClick}
                />
              </Box>
            ) : null}
            {(patientThread?.messages && !patientThread.messageCursor) ||
            (historicalMessages && noMorePreviousMessages && !fetching) ? (
              <Box sx={{ textAlign: 'center', fontSize: '1.2rem' }}>No more messages</Box>
            ) : null}
            <ChatMessages
              {...props}
              patientThread={patientThread}
              selectedItem={selectedItem}
              messages={currentMessages}
              isSelectingMultiple={isSelectingMultiple}
              setIsSelectingMultiple={setIsSelectingMultiple}
              toggleMultiSelectMessage={toggleMultiSelectMessage}
              multiSelectedMessages={multiSelectedMessages}
            />
            <ChatMarkRead thread={patientThread} />
          </Box>
        ) : (
          <div data-testid="thread-loading">
            <Loader />
          </div>
        )}
        {isSelectingMultiple ? (
          <Box
            sx={{
              position: 'absolute',
              height: '14rem',
              width: '100%',
              bottom: 0,
              right: '1rem',
              display: 'flex',
              flexDirection: 'row-reverse',
            }}
          >
            <Button
              color="primary"
              variant={'contained'}
              onClick={() => setIsSelectingMultiple(false)}
              sx={{
                height: '40px',
                '& p': {
                  fontSize: '1.4rem',
                },
                '&.MuiButton-containedSizeMedium': {
                  borderRadius: '24px',
                },
              }}
            >
              <Typography>{'Cancel Multiselect'}</Typography>
            </Button>
          </Box>
        ) : null}
        {gapExistsBetweenHistoricalMessagesAndThread ? (
          <Box
            sx={{
              position: 'absolute',
              height: '14rem',
              width: '100%',
              bottom: 0,
              right: '1rem',
              display: 'flex',
              flexDirection: 'row-reverse',
            }}
          >
            <Button
              variant="contained"
              size="small"
              sx={{
                textTransform: 'none',
                backgroundColor: 'white',
                width: '25rem',
                position: 'absolute' as const,
              }}
              onClick={handleJumpToRecent}
            >
              {lastMessageDate && <div>Showing {lastMessageDate}</div>}
              <Box sx={theme => ({ color: theme.palette.primary.main, marginLeft: '.5rem' })}>
                Back to Recent
              </Box>
              <ArrowDownward
                sx={theme => ({ marginLeft: '.5rem', color: theme.palette.primary.main })}
              />
            </Button>
          </Box>
        ) : null}
        <div ref={setScrollBottomAnchorRef} />
      </Box>
      {!providerCanProvideClinicalCareToPatient(me, props.patient) ? (
        <Box
          sx={theme => ({
            display: 'flex' as const,
            padding: '1rem',
            backgroundColor: theme.palette.primary.light,
            marginTop: '0.5rem',
          })}
        >
          <ReportProblemOutlined
            sx={theme => ({ color: theme.palette.warning[500], marginRight: '1rem' })}
          />
          <Typography variant="body2">
            {`This member is located in ${props.patient.person.insuranceInfo.addresses[0].state}. You
              are unable to provide clinical care.`}
          </Typography>
        </Box>
      ) : null}
      <JoinChatBanner
        announced={arrivalMessageInThread}
        showIntroductionReminder={!!showIntroductionReminder}
      />
      <ChatInput
        onInput={handleChatType}
        sendChat={sendChat}
        patient={props.patient}
        showIntroductionReminder={!!showIntroductionReminder}
        providerCanProvideClinicalCareToPatient={providerCanProvideClinicalCareToPatient(
          me,
          props.patient
        )}
      />
      {me?.id && (
        <Box sx={{ position: 'absolute', bottom: 0, left: 2 }}>
          <ChatTypingIndicator patientId={props.patient.id} currentUser={me as { id: number }} />
        </Box>
      )}
    </Box>
  )
}

export default Chat
