import type { FC } from 'react'

import { Box, Card, CardContent, Typography, Grid, Divider, Button } from '@mui/material'
import { Steerage } from './types'
import EditField from '~/components/Generic/EditField'
import { useFormik } from 'formik'
import Loader from '~/components/Loader'
import {
  useStates,
  useEditInsuranceAuthProvider,
  EditInsuranceAuthProviderInterface,
} from '../../../api/SteerageService'

interface SteerageProps {
  steerage: Steerage
}

const InsuranceAuthProviderForm: FC<SteerageProps> = props => {
  const { data: states } = useStates()
  const {
    isLoading: isEditingInsuranceAuthProvider,
    mutateAsync: handleEditInsuranceAuthProvider,
  } = useEditInsuranceAuthProvider()
  const referralInsuranceAuthorization = props?.steerage?.referral?.referralInsuranceAuthorization
    ? props?.steerage?.referral?.referralInsuranceAuthorization
    : null

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      firstName: referralInsuranceAuthorization?.firstName ?? '',
      lastName: referralInsuranceAuthorization?.lastName ?? '',
      middleName: referralInsuranceAuthorization?.middleName ?? '',
      npi: referralInsuranceAuthorization?.npi ?? '',
      practiceName: referralInsuranceAuthorization?.careOrganizationName ?? '',
      addressLine1: referralInsuranceAuthorization?.addressLine1 ?? '',
      addressLine2: referralInsuranceAuthorization?.addressLine2 ?? '',
      city: referralInsuranceAuthorization?.city ?? '',
      state: referralInsuranceAuthorization?.state ?? '',
      phone: referralInsuranceAuthorization?.phone ?? '',
      fax: referralInsuranceAuthorization?.fax ?? '',
      zipCode: referralInsuranceAuthorization?.zipCode ?? '',
    },
    onSubmit: values => {
      let payload: EditInsuranceAuthProviderInterface = {
        firstName: values['firstName']?.trim() || null,
        middleName: values['middleName']?.trim() || null,
        lastName: values['lastName']?.trim() || null,
        npi: values['npi'] || null,
        careOrganizationName: values['practiceName']?.trim() || null,
        addressLine1: values['addressLine1'] || null,
        addressLine2: values['addressLine2'] || null,
        city: values['city'] || null,
        state: values['state'] || null,
        zipCode: values['zipCode'] || null,
        phone: values['phone'] || null,
        fax: values['fax'] || null,
        referral: props.steerage.referral?.id ?? null,
        insuranceAuthId: props.steerage.referral?.referralInsuranceAuthorization?.id ?? null,
      }

      // calling the update provider api
      handleEditInsuranceAuthProvider(payload)
    },
  })

  const handleClear = () => {
    let payload: EditInsuranceAuthProviderInterface = {
      firstName: null,
      middleName: null,
      lastName: null,
      npi: null,
      careOrganizationName: null,
      addressLine1: null,
      addressLine2: null,
      city: null,
      state: null,
      zipCode: null,
      phone: null,
      fax: null,
      referral: props.steerage.referral?.id ?? null,
      insuranceAuthId: props.steerage.referral?.referralInsuranceAuthorization?.id ?? null,
    }

    // calling the update provider api
    handleEditInsuranceAuthProvider(payload).then(async () => {
      // invalidate queries for refetching the providers
      formik.resetForm()
    })
  }

  return (
    <Box>
      {isEditingInsuranceAuthProvider ? <Loader /> : null}
      <Card>
        <CardContent>
          <Box>
            <Typography style={{ fontWeight: 'bold' }}>Add Provider</Typography>
          </Box>
          <br />
          <Grid container spacing={6}>
            <EditField
              InputLabelProps={{ shrink: true }}
              label="First Name"
              type="input"
              name="firstName"
              onChange={formik.handleChange}
              value={formik.values.firstName}
              disabled={isEditingInsuranceAuthProvider}
            />
            <EditField
              InputLabelProps={{ shrink: true }}
              label="Middle Name"
              type="input"
              name="middleName"
              onChange={formik.handleChange}
              value={formik.values.middleName}
              disabled={isEditingInsuranceAuthProvider}
            />
          </Grid>
          <Grid container spacing={6}>
            <EditField
              InputLabelProps={{ shrink: true }}
              label="Last Name"
              type="input"
              name="lastName"
              onChange={formik.handleChange}
              value={formik.values.lastName}
              disabled={isEditingInsuranceAuthProvider}
            />
            <EditField
              InputLabelProps={{ shrink: true }}
              label="NPI"
              type="number"
              name="npi"
              onChange={formik.handleChange}
              value={formik.values.npi}
              disabled={isEditingInsuranceAuthProvider}
            />
          </Grid>
          <Grid container spacing={6}>
            <EditField
              InputLabelProps={{ shrink: true }}
              label="Practice Name"
              type="input"
              name="practiceName"
              onChange={formik.handleChange}
              value={formik.values.practiceName}
              disabled={isEditingInsuranceAuthProvider}
            />
            <EditField
              InputLabelProps={{ shrink: true }}
              label="Address Line 1"
              type="input"
              name="addressLine1"
              onChange={formik.handleChange}
              value={formik.values.addressLine1}
              disabled={isEditingInsuranceAuthProvider}
            />
          </Grid>

          <Grid container spacing={6}>
            <EditField
              InputLabelProps={{ shrink: true }}
              label="Address Line 2"
              type="input"
              name="addressLine2"
              onChange={formik.handleChange}
              value={formik.values.addressLine2}
              disabled={isEditingInsuranceAuthProvider}
            />
            <EditField
              InputLabelProps={{ shrink: true }}
              label="City"
              type="input"
              name="city"
              onChange={formik.handleChange}
              value={formik.values.city}
              disabled={isEditingInsuranceAuthProvider}
            />
          </Grid>
          <Grid container spacing={6}>
            <EditField
              label="state"
              select
              InputLabelProps={{ shrink: true }}
              SelectProps={{ native: true }}
              onChange={formik.handleChange}
              value={formik.values.state}
              name="state"
              disabled={isEditingInsuranceAuthProvider}
            >
              <option value="">None</option>
              {states?.map(state => (
                <option key={state?.id} value={state.id}>
                  {state?.abbreviation} - {state?.name}
                </option>
              ))}
            </EditField>
            <EditField
              InputLabelProps={{ shrink: true }}
              label="Zip Code"
              type="input"
              name="zipCode"
              onChange={formik.handleChange}
              value={formik.values.zipCode}
              disabled={isEditingInsuranceAuthProvider}
            />
          </Grid>
          <Grid container spacing={6}>
            <EditField
              InputLabelProps={{ shrink: true }}
              label="Phone"
              name="phone"
              onChange={formik.handleChange}
              value={formik.values.phone}
              disabled={isEditingInsuranceAuthProvider}
            />
            <EditField
              InputLabelProps={{ shrink: true }}
              label="Fax"
              type="number"
              name="fax"
              onChange={formik.handleChange}
              value={formik.values.fax}
              disabled={isEditingInsuranceAuthProvider}
            />
          </Grid>
          <Grid container spacing={4} justifyContent="flex-end">
            <Grid item>
              <Box mt={4}>
                <Button style={{ color: 'black', fontSize: '1.2rem' }} onClick={handleClear}>
                  Clear
                </Button>
                <Button
                  onClick={() => formik.handleSubmit()}
                  color="primary"
                  variant="contained"
                  style={{ fontSize: '1.2rem' }}
                  disabled={isEditingInsuranceAuthProvider}
                >
                  Save Details
                </Button>
              </Box>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <br />
      <Divider />
    </Box>
  )
}

export default InsuranceAuthProviderForm
